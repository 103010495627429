import React, { Component } from "react";
import {
  Button,
  Icon,
  Tooltip,
  Table,
  Tag,
  Divider,
  Card,
  Badge,
  Menu,
  Layout,
  Row,
  Col,
} from "antd";
import { ExportOutlined } from "@ant-design/icons";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  removeUsername,
  removeToken,
  getUsername,
  getEmail,
  removeCache,
} from "../../helper";
import capitaland_logo from "../../img/customerLogos/capitaland.png";
import wework_logo from "../../img/customerLogos/wework.png";
import claircologo from "../../img/assets/Claircologo.png";
import Supervisorimg from "../../img/assets/supervisor.png";
import { isUndefined } from "lodash";
const { Header, Content, Footer, Sider } = Layout;

const accessToUV = [
  "146c159d-96bc-4e9c-83dc-2d955eda1886",
  "2fbd88c5-0799-46a1-bda4-b9f448633016",
  "6411f849-83d9-4566-aab5-28cd433a47a0",
  '46d4dc23-c07e-4220-a67c-7af82d650ac3',
  '62d1d24c-26c4-40b6-998f-aeb07288960a',
  '84bf8c5c-8176-4e99-9b24-d0449a83d1d8',
  'be995266-b5c1-421a-a528-232669b329aa',
  'd00f6af9-2089-4c4f-8c8f-d1a78756fb39',
  '985a1e2f-7005-42cd-8542-060e2bbf6382'
];

export default class Supervisor extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Customer name",
        dataIndex: "name",
        key: "id",
        defaultSortOrder: "ascend",
        sorter: (a, b) => {
          return a.name.localeCompare(b.name);
        },
      },
      { title: "Air Quality Index", dataIndex: "aqi", key: "aqi" },
      // { title: 'Alerts', dataIndex: 'alerts', key: 'alerts', render: (text, record) => <Badge showZero className={record.alerts<1?'badge_low':'badge_high'} count={record.alerts}/>},
      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        render: (id) => (
          <Button
            icon="edit"
            type="primary"
            onClick={() => {
              this.viewDashboard(id);
            }}
          >
            Dashboard
          </Button>
        ),
      },
    ];
    this.state = {
      data: isUndefined(this.props.location.state)
        ? JSON.parse(localStorage.getItem("cust_data"))
        : this.props.location.state.data,
      show: false,
      collapsed: false,
      username: getUsername(),
      clairco_logo:
        getEmail() === "vishal.agarwal@capitaland.com" ||
        getEmail() === "sekar.sankar@capitaland.com" ||
        getEmail() === "george.fernandes@capitaland.com" ||
        getEmail() === "gopiraj.r@capitaland.com"
          ? capitaland_logo
          : claircologo,
      dataSource: [],
    };
  }

  async componentDidMount() {
    // console.log("props: ", this.props);
  }
  viewDashboard = (id) => {
    // console.log("customer id ", id);
    const redirectToUVDashboard = accessToUV.some((uv_id) => uv_id == id);
    // console.log("should redirect to uv " + redirectToUVDashboard);
    if (redirectToUVDashboard) {
      this.props.history.push("/uv/dashboard", { id });
      // this.props.history.push()
    } else
      this.props.history.push("/dashboard", { id: id, referer: "supervisor" });
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  handleLogoutClick = (e) => {
    removeToken();
    removeUsername();
    removeCache();
    this.props.history.push("/login");
  };
  render() {
    let { username, show, collapsed, clairco_logo, data } = this.state;
    var cardVariable = (
      <Row gutter={[32, 32]}>
        {data !== undefined ? (
          data.map((item, index) => (
            <Col key={index} span={8}>
              <Card
                style={{
                  // width: 230,
                  border: "1px solid #00796b",
                  borderRadius: "10px",
                  textAlign: "center",
                  // marginTop: "5%",
                }}
                className="center"
              >
                <h1 style={{ color: "#01579b ", fontSize: "130%" }}>
                  {item.name}
                </h1>
                <br></br>
                <Button
                  style={{ marginTop: "5%" }}
                  onClick={() => {
                    this.viewDashboard(item.id);
                  }}
                >
                  View Dashboard
                </Button>
              </Card>
            </Col>
          ))
        ) : (
          <div className="container center">
            <h1>NA</h1>
          </div>
        )}
      </Row>
    );
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
          collapsedWidth={0}
        >
          {
            <div className="claircoLogoContainer">
              <img src={clairco_logo} className="claircoLogo_admin" />
            </div>
          }
          <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
            <Divider />
            <SubMenu
              key="sub1"
              title={
                <span>
                  <span>{username}</span>
                </span>
              }
            >
              <Menu.ItemGroup key="g1">
                <Menu.Item key="option:1">Edit Profile</Menu.Item>
                <Menu.Item key="option:2">Settings</Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout>
          <Header
            className="site-layout-sub-header-background"
            style={{ padding: 0 }}
          >
            <div className="headerLogo">
              <Icon
                className="trigger"
                type={collapsed ? "menu-unfold" : "menu-fold"}
                onClick={this.toggle}
              />
              {/* Powered by <img src={clairco_logo} className="claircoLogo"/> */}
              <span style={{ fontSize: 25 }} className="admin-title">
                Welcome Admin
              </span>
            </div>
            <div className="adminMenuHolder">
              <div className="notifications">
                <Menu mode="horizontal" style={{ backgroundColor: "#FFF" }}>
                  <SubMenu
                    title={
                      <Badge dot={show}>
                        <Icon type="bell" style={{ fontSize: "26px" }} />
                      </Badge>
                    }
                  >
                    <div className="notificationShade">
                      No New Notifications
                    </div>
                  </SubMenu>
                </Menu>
              </div>
              <Menu mode="horizontal" style={{ backgroundColor: "#FFF" }}>
                <SubMenu
                  title={
                    <div>
                      <Icon type="menu" style={{ fontSize: "26px" }} />
                    </div>
                  }
                >
                  <Menu.Item
                    key="setting:1"
                    onClick={this.handleLogoutClick}
                    style={{ color: "#fff" }}
                  >
                    <ExportOutlined
                      style={{
                        fontSize: "15px",
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                    />
                    Logout
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </div>
          </Header>
          <Content style={{ margin: "24px 16px 0" }}>
            <Row>
              <Col lg={{ span: 18 }} sm={24}>
                <h1
                  style={{
                    color: "#00695c",
                    marginLeft: "5%",
                    fontSize: "250%",
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: "bold",
                    overflow: "auto",
                  }}
                >
                  Supervisor Management
                </h1>
                <div
                  style={{ marginTop: "10%", marginLeft: "5%" }}
                  className="card-align"
                >
                  {
                    //     <Table
                    //     className="components-table-demo-nested"
                    //     columns={this.columns}
                    //     style={{background: '#eeeeee', margin: '20px'}}
                    //     bordered={false}
                    //     tableLayout='auto'
                    //     pagination={{ position: 'none' }}
                    //     dataSource={this.state.data}
                    // />
                  }
                  {cardVariable}
                </div>
              </Col>
              <Col lg={{ span: 6 }} sm={0} className="superVisorImage">
                <img
                  src={Supervisorimg}
                  width="90%"
                  align="right"
                  style={{ marginTop: "50%" }}
                />
              </Col>
            </Row>

            <Footer
              style={{
                textAlign: "center",
                marginTop: "3%",
              }}
            >
              <Row>
                <Col sm={24}>
                  <p>
                    In case of any queries, please reach out to Clairco at
                    +91-9898036463
                  </p>
                  Aliferous Technologies pvt. ltd. ©2018
                </Col>
              </Row>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    );
  }
}
