import React, {Component} from "react";
import {Button, Alert, Form, Input, Radio, Spin} from "antd";
import { getCustomer } from "../api";
  import _ from "lodash";

const FormItem = Form.Item;
export default class UpdateCustomer extends Component {
    constructor(props) {
        super(props);
        this.customerId = this.props.location.state.id;
        this.state = {
          loading: false,
          id: this.customerId,
          response: "",
          cities:["Bengaluru","Delhi","Hyderabad"]
        };
      }

    async componentDidMount() {
        try {
          let {data} = (await getCustomer(this.state.id, true)).data;
          let properties = data.Properties;
        //   console.log('Dashboard.data: ',data);
          // console.log('summaryData: ',summaryData);
          this.setState({
            loadingData: false,
          })
          if(data.Properties.length>0){
          this.setState({
            data,
            properties
          });
          }
        } catch (error) {
          console.log(error);
        }
    }  

    render() {
        const { data } = this.state;
        if(!this.state.data){
            return <div><Spin size="large"/></div>;
        }
        // console.log(data.name);
        return(
            <Form layout="vertical">
                <FormItem label="Customer Name">
                    <Input placeholder={data.name} value={data.name}/>
                </FormItem>
            </Form>
        );
    }
}