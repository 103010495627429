import React, {Component} from "react";
import {
  Chart,
  Axis,
  Coord,
  Geom,
  Guide,
  Shape
} from 'bizcharts';
const {Html, Arc, Line} = Guide;

Shape.registerShape('point', 'pointer', {
  drawShape(cfg, group) {
    let point = cfg.points[0];
    point = this.parsePoint(point);
    const center = this.parsePoint({x: 0, y: 0});
    group.addShape('line', {
      attrs: {
        x1: center.x,
        y1: center.y,
        x2: point.x,
        y2: point.y,
        stroke: cfg.color,
        lineWidth: 2,
        lineCap: 'round'
      }
    });
    return group.addShape('circle', {
      attrs: {
        x: center.x,
        y: center.y,
        r: 3,
        stroke: cfg.color,
        lineWidth: 1,
        fill: '#fff'
      }
    });
  }
});


const PmCardOps = (props)=>{ 
    const {data,label,max,color} = props
    //data.forEach(item=>{if(!item.value)item.value = null});
    const cols = {
      value: {
        min: 0,
        max: max,
        nice: false
      }
    };
    return (
        <Chart
          forceFit
          height={70}
          padding={[10,10,16,10]}
          data={data}
          scale={cols}>
          <Coord
            type="polar"
            startAngle={-9 / 8 * Math.PI}
            endAngle={1 / 8 * Math.PI}
            radius={1.0}/>
          <Axis
            name="value"
            zIndex={2}
            line={null}
            tickLine={{
              lineWidth:1,
              length: -2, 
            }}
            label={{
            offset: -4,
            textStyle: {
              fontSize: 7,
              fill: 'rgba(0, 0, 0, 0.65)',
              textAlign: 'center'
            }
          }}/>
          <Axis name="1" visible={false}/>
          <Guide>
            <Line
              start={[max/3, 0.905]}
              end={[max/3, 0.85]}
              lineStyle={{
              stroke: color,
              lineDash: null,
              lineWidth: 1
            }}/>
            <Line
              start={[max/2, 0.905]}
              end={[max/2, 0.85]}
              lineStyle={{
              stroke: color,
              lineDash: null,
              lineWidth: 1
            }}/>
            <Line
              start={[max/1.5, 0.905]}
              end={[max/1.5, 0.85]}
              lineStyle={{
              stroke: color,
              lineDash: null,
              lineWidth: 1
            }}/>
            <Arc
              zIndex={0}
              start={[0, 0.965]}
              end={[max, 0.965]}
              style={{
              stroke: '#000',
              lineWidth: 3,
              opacity: 0.09
            }}/>
            <Arc
              zIndex={1}
              start={[0, 0.965]}
              end={[data[0].value, 0.965]}
              style={{
              stroke: color,
              lineWidth: 3
            }}/>
            <Html
              position={['50%', '120%']}
              html={() => (`<div style="width: 100px;text-align: center;font-size: 8px!important;"><p style="font-size: 0.75em; color: rgba(0,0,0,0.43);margin: 0;">${label}</p><p style="font-size: 1.00em;color: rgba(0,0,0,0.85);margin: 0;">   ${data[0].value?data[0].value:"NA"}<span style="font-size:5px"> ${data[0].value?"ug/m<sup>3</sup>":""}</p></div>`)}/>
          </Guide>
          <Geom
            type="point"
            position="value*1"
            shape="pointer"
            color={color}
            active={false}
            style={{
            stroke: '#fff',
            lineWidth: 1
          }}/>
        </Chart>
    )
  }

export default PmCardOps;