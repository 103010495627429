import React, {Component} from "react";
import {Layout, Spin, Menu, Button, Icon, Dropdown, Avatar, Modal, Switch, Badge} from "antd";
import {getAllUsers} from "../api";
import moment from 'moment';
import Lottie from 'react-lottie'
import _ from "lodash";
import ChatView from "../components/ChatView";
import animationData from '../assets/whatsapp-on.json';

const {Header, Content, Footer, Sider} = Layout;
const SubMenu = Menu.SubMenu;

export default class WhatsApp extends Component {

    constructor(props){
        super(props);
        this.state = {
            isStopped: false, 
            isPaused: false,
            selectedMenu: undefined
        }
    }

    async componentDidMount() {
        let data = [];
        try {
            let users = ((await getAllUsers()).data);
            // console.log(users);
            for(let i=0; i<users.length; i++){
                data.push(users[i]);
            }
        } catch (e) {
            console.log(e);
        }
        this.setState({
                data: data
        });
    }

    handleClick = e => {
        if(this.state.selectedMenu !== e.keyPath[0])
        {
          const data = e.keyPath[0].split(":");
          const name = data[0];
          const number = data[1];
          this.setState({
            selectedMenu: e.keyPath[0],
            name: name,
            number: number
          });
        }
    };

    render(){
        let {data} = this.state;
        if(data === undefined){
            return (<Spin size="large"/>);
        }
        const buttonStyle = {
          display: 'inline-block',
          margin: '10px auto',
          marginRight: '10px',
          border: 'none',
          color: 'white',
          backgroundColor: '#647DFF',
          borderRadius: '2px',
          fontSize: '15px'
    
        };
    
        const defaultOptions = {
          loop: false,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        };
        // console.log(data);
        const usersList = data.map(user => {
            return(
                <Menu 
                    className="innerMenuItems"
                    onClick={this.handleClick}
                    mode="inline">
                    <Menu.Item
                        key={user.first_name+":"+user.phone_number}
                        style={{
                            color: '#FFF'
                        }}>
                        {user.first_name}
                    </Menu.Item>
                </Menu>
            );
        });
        return(
        //   <div className="controlled">
        //     <h1>Controlled Lottie</h1>
        //     <p>Uses state manipulation to start, stop and pause animations</p>
        //     <Lottie options={defaultOptions}
        //           height={400}
        //           width={400}
        //           isStopped={this.state.isStopped}
        //           isPaused={this.state.isPaused}
        //     />
        //     <button style={buttonStyle} onClick={() => this.setState({isStopped: true})}>Stop</button>
        //     <button style={buttonStyle} onClick={() => this.setState({isStopped: false, isPaused: false })}>Play</button>
        //     <button style={buttonStyle} onClick={() => this.setState({isPaused: !this.state.isPaused})}>Pause</button>
        //   </div>
        <Layout>
            <Sider
                width={180}
                style={{
                    position: "relative",
                    height: "80vh",
                    overflow: "scroll"
                }}>
                    {usersList}
            </Sider>
            <Layout
                style={{
                    overflow: "hidden"
                }}>
                <Content>
                  {this.state.selectedMenu?<ChatView name={this.state.name} contact={this.state.number}/>: null}
                </Content>
            </Layout>
        </Layout>
        )
      }
}