import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactCardFlip from "react-card-flip";
import {
  Spin,
  Card,
  Row,
  Col,
  Modal,
  Menu,
  Dropdown,
  Button,
  Icon,
  Layout,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Thermometer, DropletHalf } from "react-bootstrap-icons";
import { getAQ } from "../../api";
import "./PropertiesCapitaLand.less";
import moment from "moment";
import AqProgressBar from "./AqProgressBar";
import PmChartCapitaLand from "./PmChartCapitaLand";
import CHECK_DEVICE_CONFIG from "../../utils/checkDeviceConfig";
import { CreateAqData } from "../../utils/createAqData";
import {
  GetDateRangesTxt,
  GetDateRangeArrForMenuIndex,
} from "../../utils/dateRange";

const dateRanges = GetDateRangesTxt();

const { Header } = Layout;
const maxEstates_customerId = "e0bd2d59-1f36-44a0-9167-fe9c03fc4d0b";
const max_house_okhla = "fd6a8e6d-a0c1-4387-8b84-a6a20b48559e";
const test_id = "df3c456b-88d6-4fe6-abaf-3e377d39c26a";
const societe_general = "63f5a7e9-f3cf-497a-8beb-5016e926f6f6";
const rmz_id = "bb930af8-eb2c-444a-8fc9-bbf5faeb6531";

export default class PropertiesCapitaLand extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props);
    const device = this.props.selectedMenu.split(":");
    // console.log(this.props);
    this.propertyId = device[0];
    this.spaceDevId = device[1];
    this.propDevId = device[2];
    this.spaceName = device[3];
    this.propName = device[4];
    this.spaceId = device[5];
    this.customerId = device[6];
    this.opHours = this.props.opHours;
    this.state = {
      dataIsLoaded: false,
      id: this.props.customerId,
      spaceName: this.spaceName,
      isFlipped: false,
      spaceDevId: this.spaceDevId,
      propName: this.propName,
      propDevId: this.propDevId,
      propertyId: this.propertyId,
      spaceId: this.spaceId,
      customerId: this.customerId,
      selectedDateRangeIndex: this.props.dateRangeIndex,
      outsideDataShow: this.props.outsideDataShow,
      aqiRange: this.props.aqiRange,
      iaq_visible: false,
      logo: this.props.logo,
      showSixParameters: this.props.showSixParameters,
    };
  }

  // async fetchDataAndSetState(dateRangeIndex) {
  //   try {
  //     const dateRangeArr = GetDateRangeArrForMenuIndex(dateRangeIndex);
  //     const spaceAqData = (
  //       await getAQ(
  //         this.state.spaceId,
  //         this.state.propertyId,
  //         this.state.spaceDevId,
  //         this.state.propDevId,
  //         dateRangeArr,
  //       )
  //     ).data;
  //     const { aqTrendData } = CreateAqData(
  //       spaceAqData,
  //       this.state.spaceDevId,
  //       dateRangeArr,
  //     );
  //     this.setState({
  //       aqTrendData,
  //       dateRangeArr,
  //       dataIsLoaded: true,
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  translateKeyToSinceDay = (sinceDay) => {
    return sinceDay === "aq24hr"
      ? "Yesterday"
      : sinceDay === "aq7day"
      ? "LastWeek"
      : "Today";
  };

  async componentDidMount() {
    try {
      const dateRangeArr = GetDateRangeArrForMenuIndex(
        this.state.selectedDateRangeIndex,
      );
      let { data } = await getAQ(
        this.spaceId,
        this.propertyId,
        this.spaceDevId,
        this.propDevId,
        dateRangeArr,
      );
      // console.log(data);
      const {
        aqTrendData,
        avgAq,
        selectedAqTrendIndex,
        insAq,
        outAq,
      } = CreateAqData(data, this.spaceDevId, dateRangeArr);
      // console.log("aqTrendData:", aqTrendData);
      // console.log(insAq);
      // console.log(outAq);
      let co_flag = false;
      if (
        this.state.id === maxEstates_customerId ||
        test_id === this.customerId
      )
        co_flag = true;
      let propData = {
        customerName: this.state.spaceName,
        customerCity: this.state.propName,
        avgIns: insAq,
        avgOut: outAq,
      };
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const currentDate = new Date().toLocaleDateString("en-US", options);
      let sla_flag = false;
      // console.log("CUSTOMER ID ", this.customerId);
      // id === '3b0c8b10-8dac-4466-a728-0937239baefe'
      //   ? sla_flag = true
      //     : id === 'a2b4c6e4-e9f0-4f3e-82cd-d4d1bf47387c'
      //       ? sla_flag = true
      //         : id === 'a5ac3d08-851e-4c3e-85c1-63be20054d21'
      //           ? sla_flag = true
      //             : id === '527c32d4-140f-4501-a9ef-1cdd284d5bcf';
      setInterval(() => {
        this.setState({
          isFlipped: !this.state.isFlipped,
        });
      }, 10000);
      this.setState({
        aqTrendData,
        propData,
        selectedAqTrendIndex,
        dateRangeArr,
        insAq,
        outAq,
        co_flag,
        opHours: data.opHours,
        nonOpDays: data.nonOpDays,
        dataIsLoaded: true,
        currentDate: currentDate,
        adminFlag: this.props.adminFlag,
        sla_flag,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // console.log("component updated");
    // console.log("card flipped");
    // if (prevProps.isFlipped !== this.state.isFlipped) {
    // console.log("card flip state updated");
    const [
      propertyId,
      spaceDevId,
      propDevId,
      spaceName,
      propName,
      spaceId,
    ] = this.props.selectedMenu.split(":");
    // console.log("space id in component update");
    // console.log(spaceId);
    const prevSpaceId = prevProps.selectedMenu.split(":")[5];
    const dateRangeArr = GetDateRangeArrForMenuIndex(
      this.state.selectedDateRangeIndex,
    );
    const dateRangeSelectionChanged =
      dateRangeArr[0].split("T")[0] !==
        this.state.dateRangeArr[0].split("T")[0] ||
      dateRangeArr[1].split("T")[0] !== this.state.dateRangeArr[1].split("T")[0]
        ? true
        : false;

    // console.log("is date changed ? ", dateRangeSelectionChanged);
    // console.log("has space id changed ? ", spaceId !== prevSpaceId);
    // console.log(dateRangeSelectionChanged);
    // if (prevState.isFlipped !== this.state.isFlipped)
    //   console.log("card flipped");
    if (spaceId !== prevSpaceId || dateRangeSelectionChanged) {
      // if (prevState.isFlipped !== this.state.isFlipped) {
      const dateRangeIndex = dateRangeSelectionChanged
        ? this.state.selectedDateRangeIndex
        : this.props.dateRangeIndex;

      // reinitialize everything that gets initialized before componentDidMount - START
      this.propertyId = propertyId;
      this.spaceDevId = spaceDevId;
      this.propDevId = propDevId;
      this.spaceName = spaceName;
      this.propName = propName;
      this.spaceId = spaceId;
      this.opHours = this.props.opHours;
      this.setState({
        dataIsLoaded: false,
        spaceName: this.spaceName,
        spaceDevId: this.spaceDevId,
        propName: this.propName,
        propDevId: this.propDevId,
        propertyId: this.propertyId,
        spaceId: this.spaceId,
        selectedDateRangeIndex: dateRangeIndex,
        outsideDataShow: this.props.outsideDataShow,
        dateRangeArr,
      });
      // reinitialize everything that gets initialized before componentDidMount - START

      try {
        let { data } = await getAQ(
          spaceId,
          propertyId,
          spaceDevId,
          propDevId,
          dateRangeArr,
        );
        // console.log("Data in component update");
        // console.log(data);
        const {
          aqTrendData,
          avgAq,
          selectedAqTrendIndex,
          insAq,
          outAq,
        } = CreateAqData(data, spaceDevId, dateRangeArr);
        // console.log("Aq Trends data in component update");
        // console.log(aqTrendData);
        // console.log(
        //   "first hour of component update aqtrends ",
        //   aqTrendData[0][0].hour,
        // );
        let propData = {
          customerName: this.state.spaceName,
          customerCity: this.state.propName,
          avgIns: insAq,
          avgOut: outAq,
        };
        this.setState({
          aqTrendData,
          propData,
          insAq,
          outAq,
          dataIsLoaded: true,
        });
      } catch (err) {
        console.log(err);
      }
    }
    // }
  }

  carouselRef = React.createRef();

  handlePrev = () => {
    this.carouselRef.current.prev();
  };

  handleNext = () => {
    this.carouselRef.current.next();
  };

  showCommentModal = () => {
    this.setState({ commentModalVisible: true });
  };

  showSourceModal = () => {
    this.setState({ sourceModalVisible: true });
  };

  handleCommentModalOk = (e) => {
    this.setState({ commentModalVisible: false });
  };

  handleSourceModalOk = (e) => {
    this.setState({ sourceModalVisible: false });
  };

  kioskDisplay = () => {
    this.props.history.push("/kiosk", {
      refferrer: "space",
      data: this.state.spaceId,
      customer: this.state.customerId,
      logo: this.state.logo,
    });
  };

  handleAqTrendDropdownClick = (e) => {
    let keyIndex = parseInt(
      e.key.toString().substr("aqTrendDropdownItem".length),
    );
    this.setState({
      selectedAqTrendIndex: keyIndex,
    });
  };

  handleDateRangeDropdownClick = (e) => {
    let keyIndex = parseInt(
      e.key.toString().substr("dateRangeDropdownItem".length),
    );
    this.setState({
      selectedDateRangeIndex: keyIndex,
      dataIsLoaded: false,
    });
  };

  setVisible = (e) => {
    this.setState({
      [e]: !this.state[e],
    });
  };

  render() {
    if (!this.state.insAq) {
      return (
        <div className="dashLoaderContainer">
          <Spin size="large" />
        </div>
      );
    }
    if (!this.state.dataIsLoaded) {
      return (
        <div className="dashLoaderContainer">
          <Spin size="large" />
        </div>
      );
    }
    const spaceDevId = this.spaceDevId;
    let propDevId = this.propDevId;
    const {
      insAq,
      outAq,
      dataIsLoaded,
      opHours,
      nonOpDays,
      currentDate,
      aqTrendData,
      spaceName,
      propName,
      dateRangeArr,
      outsideDataShow,
      aqiRange,
      selectedDateRangeIndex,
      adminFlag,
      co_flag,
    } = this.state;
    // console.log(this.state);
    // console.log(dateRangeArr);
    // console.log('selectedDateRangeIndex: ',selectedDateRangeIndex);
    // adminFlag === undefined ? (adminFlag=false) : (adminFlag);

    const aqPollutants = [
      "PM 2.5",
      "PM 10",
      "CO",
      "CO2",
      "VOC",
      "TEMP",
      "HUM",
      "LUX",
      "UV",
      "SND",
    ];

    const insCardSpan = outsideDataShow
      ? {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24,
          xl: 24,
          xxl: 24,
        }
      : {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24,
          xl: 24,
          xxl: 24,
        };
    const outCardSpan = outsideDataShow
      ? {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 7,
          xl: 7,
          xxl: 7,
        }
      : {
          xs: 0,
          sm: 0,
          md: 0,
          lg: 0,
          xl: 0,
          xxl: 0,
        };
    const aqiTableCardSpan = outsideDataShow
      ? {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 7,
          xl: 7,
          xxl: 7,
        }
      : {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 10,
          xl: 10,
          xxl: 10,
        };

    const WinWidth = window.innerWidth;
    const insCardConfig =
      WinWidth <= 1366
        ? {
            rowHeightGoodAq: 100,
            span: insCardSpan,
          }
        : {
            rowHeightGoodAq: 105,
            span: insCardSpan,
          };
    const outCardConfig = { span: outCardSpan };
    const aqiTableCardConfig = { span: aqiTableCardSpan };
    const aqTrendsCardConfig =
      WinWidth <= 1280
        ? {
            chartMarginBottom: "-11%",
            chartMarginTop: "-3%",
          }
        : WinWidth <= 1366
        ? {
            chartMarginBottom: "-10%",
            chartMarginTop: "-3%",
          }
        : WinWidth <= 1440
        ? {
            chartMarginBottom: "-7%",
            chartMarginTop: "-2%",
          }
        : {
            chartMarginBottom: "-5%",
            chartMarginTop: "-1%",
          };

    const outRtAqItems = outAq
      ? aqPollutants.map((item, i) => {
          let p, v;
          if (outAq && outAq.pData[i] >= 0) {
            v = outAq.pData[i];
            return (
              <AqProgressBar
                key={"aqOutProgressBar" + i}
                label={item}
                data={[{ value: v }]}
              />
            );
          }
        })
      : null;

    const insRtAqItems = aqPollutants.map((item, i) => {
      let p, v, aqi_color_inside, aqi_color_outside;
      // console.log(insAq && insAq.lux && item === "LUX");
      // console.log(insAq.lux);
      if (insAq && insAq.lux && item === "LUX") {
        v = insAq.lux;
      } else if (insAq && insAq.pData[i] >= 0) {
        v = insAq.pData[i];
      } else if (insAq && insAq.snd && item === "SND") {
        v = insAq.snd;
      } else if (insAq && insAq.uv && item === "UV") {
        v = insAq.uv;
      }

      if (outAq && outAq.pData[i] >= 0) {
        p = outAq.pData[i];
      }
      if (item === "PM 2.5" || item === "PM 10") {
        if (item === "PM 2.5") {
          if (v > 35) {
            aqi_color_inside = "bad";
          } else if (v > 25) {
            aqi_color_inside = "moderate";
          } else {
            aqi_color_inside = "good";
          }
          if (p > 35) {
            aqi_color_outside = "bad";
          } else if (p > 25) {
            aqi_color_outside = "moderate";
          } else {
            aqi_color_outside = "good";
          }
        } else {
          if (v > 150) {
            aqi_color_inside = "bad";
          } else if (v > 51) {
            aqi_color_inside = "moderate";
          } else {
            aqi_color_inside = "good";
          }

          if (p > 150) {
            aqi_color_outside = "bad";
          } else if (p > 51) {
            aqi_color_outside = "moderate";
          } else {
            aqi_color_outside = "good";
          }
        }
        return (
          <Col span={5}>
            <ReactCardFlip
              isFlipped={this.state.isFlipped}
              flipDirection="vertical"
            >
              <Card
                className={"aqi_holder_card " + aqi_color_inside}
                onClick={() => this.setVisible("iaq_visible")}
                title={
                  <div>
                    <span className="aqi_card_header_title indoor">Indoor</span>
                    <Tooltip placement="bottom">
                      <span
                        className="aqi_card_header"
                        onClick={() => this.setVisible(true)}
                      >
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  </div>
                }
                bordered={true}
              >
                <span className="aqi_holder_card_name">
                  {item ? item : "NA"}
                </span>
                <br />
                <span className="aqi_holer_card_value">
                  {v || v === 0 ? v : "NA"}
                </span>
                <span className="aqi_holder_card_unit">
                  {(v || v === 0) && " ug/m3"}
                </span>
              </Card>

              <Card
                className={"aqi_holder_card " + aqi_color_outside}
                onClick={() => this.setVisible("iaq_visible")}
                title={
                  <div>
                    <span className="aqi_card_header_title outdoor">
                      Outdoor
                    </span>
                    <Tooltip placement="bottom" text={text}>
                      <span
                        className="aqi_card_header"
                        onClick={() => this.setVisible(true)}
                      >
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  </div>
                }
                bordered={true}
              >
                <span className="aqi_holder_card_name">
                  {item ? item : "NA"}
                </span>
                <br />
                <span className="aqi_holer_card_value">
                  {p || p === 0 ? p : "NA"}
                </span>
                <span className="aqi_holder_card_unit">
                  {(p || p === 0) && " ug/m3"}
                </span>
              </Card>
            </ReactCardFlip>
          </Col>
        );
      } else if (item === "CO" && co_flag) {
        if (v > 40) {
          aqi_color_inside = "bad";
        } else if (v > 8) {
          aqi_color_inside = "moderate";
        } else {
          aqi_color_inside = "good";
        }
        return (
          <Col span={5}>
            {/* <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="vertical"> */}
            <Card
              className={"aqi_holder_card " + aqi_color_inside}
              onClick={() => this.setVisible("iaq_visible")}
              title={
                <Tooltip placement="bottom">
                  <span
                    className="aqi_card_header"
                    onClick={() => this.setVisible(true)}
                  >
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              }
              bordered={true}
            >
              <span className="aqi_holder_card_name">{item ? item : "NA"}</span>
              <br />
              <span className="aqi_holer_card_value">
                {v || v === 0 ? v : "NA"}
              </span>

              {(v || v === 0) && (
                <span className="aqi_holder_card_unit">
                  {item === "CO2" || item === "CO" ? " ppm" : " ppb"}
                </span>
              )}
            </Card>
            {/* </ReactCardFlip> */}
          </Col>
        );
      } else if (
        item === "CO2" ||
        (item === "VOC" && test_id !== this.customerId)
      ) {
        if (item === "CO2") {
          if (v > 1000) {
            aqi_color_inside = "bad";
          } else if (v > 800) {
            aqi_color_inside = "moderate";
          } else {
            aqi_color_inside = "good";
          }

          if (p > 1000) {
            aqi_color_outside = "bad";
          } else if (p > 800) {
            aqi_color_outside = "moderate";
          } else {
            aqi_color_outside = "good";
          }
        } else if (item === "VOC") {
          if (v > 500) {
            aqi_color_inside = "bad";
          } else if (v > 300) {
            aqi_color_inside = "moderate";
          } else {
            aqi_color_inside = "good";
          }

          if (p > 500) {
            aqi_color_outside = "bad";
          } else if (p > 300) {
            aqi_color_outside = "moderate";
          } else {
            aqi_color_outside = "good";
          }
        }
        return (
          <Col span={5}>
            {this.customerId === societe_general ? (
              <ReactCardFlip
                isFlipped={this.state.isFlipped}
                flipDirection="vertical"
              >
                <Card
                  className={"aqi_holder_card " + aqi_color_inside}
                  onClick={() => this.setVisible("iaq_visible")}
                  title={
                    <div>
                      <span className="aqi_card_header_title indoor">
                        Indoor
                      </span>
                      <Tooltip placement="bottom">
                        <span
                          className="aqi_card_header"
                          onClick={() => this.setVisible(true)}
                        >
                          <InfoCircleOutlined />
                        </span>
                      </Tooltip>
                    </div>
                  }
                  bordered={true}
                >
                  <span className="aqi_holder_card_name">
                    {item ? item : "NA"}
                  </span>
                  <br />
                  <span className="aqi_holer_card_value">
                    {v || v === 0 ? v : "NA"}
                  </span>
                  {(v || v === 0) && (
                    <span className="aqi_holder_card_unit">
                      {item === "CO2" || item === "CO" ? " ppm" : " ppb"}
                    </span>
                  )}
                </Card>

                <Card
                  className={"aqi_holder_card " + aqi_color_outside}
                  onClick={() => this.setVisible("iaq_visible")}
                  title={
                    <div>
                      <span className="aqi_card_header_title">Outdoor</span>
                      <Tooltip placement="bottom" text={text}>
                        <span
                          className="aqi_card_header"
                          onClick={() => this.setVisible(true)}
                        >
                          <InfoCircleOutlined />
                        </span>
                      </Tooltip>
                    </div>
                  }
                  bordered={true}
                >
                  <span className="aqi_holder_card_name">
                    {item ? item : "NA"}
                  </span>
                  <br />
                  <span className="aqi_holer_card_value">{p ? p : "NA"}</span>
                  {(p || p === 0) && (
                    <span className="aqi_holder_card_unit">
                      {item === "CO2" || item === "CO" ? " ppm" : " ppb"}
                    </span>
                  )}
                </Card>
              </ReactCardFlip>
            ) : (
              <Card
                className={"aqi_holder_card " + aqi_color_inside}
                onClick={() => this.setVisible("iaq_visible")}
                title={
                  <Tooltip placement="bottom">
                    <span
                      className="aqi_card_header"
                      onClick={() => this.setVisible(true)}
                    >
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                }
                bordered={true}
              >
                <span className="aqi_holder_card_name">
                  {item ? item : "NA"}
                </span>
                <br />
                <span className="aqi_holer_card_value">
                  {v || v === 0 ? v : "NA"}
                </span>
                {(v || v === 0) && (
                  <span className="aqi_holder_card_unit">
                    {item === "CO2" || item === "CO" ? " ppm" : " ppb"}
                  </span>
                )}
              </Card>
            )}
          </Col>
        );
      } else if (
        (test_id === this.customerId && item === "LUX" && v !== undefined) ||
        (test_id === this.customerId && item === "UV" && v !== undefined) ||
        (test_id === this.customerId && item === "SND" && v !== undefined)
      ) {
        if (item === "LUX") {
          if (v > 800) aqi_color_inside = "bad";
          else if (v > 500) aqi_color_inside = "moderate";
          else aqi_color_inside = "good";
        }
        if (item === "UV") {
          if (v > 14) aqi_color_inside = "bad";
          else if (v > 2) aqi_color_inside = "moderate";
          else aqi_color_inside = "good";
        }
        if (item === "SND") {
          if (v > 60) aqi_color_inside = "bad";
          else if (v > 40) aqi_color_inside = "moderate";
          else aqi_color_inside = "good";
        }

        return (
          <Col span={5}>
            <Card
              className={"aqi_holder_card " + aqi_color_inside}
              onClick={() => this.setVisible("iaq_visible")}
              title={
                <div>
                  <span className="aqi_card_header_title indoor">Indoor</span>
                  <Tooltip placement="bottom">
                    <span
                      className="aqi_card_header"
                      onClick={() => this.setVisible(true)}
                    >
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                </div>
              }
              bordered={true}
            >
              <span className="aqi_holder_card_name">{item ? item : "NA"}</span>
              <br />
              <span className="aqi_holer_card_value">{v ? v : "NA"}</span>
              <span className="aqi_holder_card_unit">
                {v &&
                  (item === "UV"
                    ? " uW/cm2"
                    : item === "LUX"
                    ? " LUX"
                    : item === "SND"
                    ? " dB"
                    : "")}
              </span>
            </Card>
          </Col>
        );
      } else if (
        // this.customerId === societe_general &&
        this.state.showSixParameters === true &&
        (item === "TEMP" || item === "HUM")
      ) {
        if (item === "TEMP") {
          if (v > 60) aqi_color_inside = "bad";
          else if (v > 40) aqi_color_inside = "moderate";
          else aqi_color_inside = "good";
        }
        if (item === "HUM") {
          if (v > 60) aqi_color_inside = "bad";
          else if (v > 40) aqi_color_inside = "moderate";
          else aqi_color_inside = "good";
        }
        return (
          <Col span={5}>
            {(this.customerId === societe_general || this.customerId === rmz_id) ?
              <ReactCardFlip
              isFlipped={this.state.isFlipped}
              flipDirection="vertical"
            >
              <Card
                className={"aqi_holder_card " + aqi_color_inside}
                onClick={() => this.setVisible("iaq_visible")}
                title={
                  <div>
                    <span className="aqi_card_header_title indoor">Indoor</span>
                    <Tooltip placement="bottom" text={text}>
                      <span
                        className="aqi_card_header"
                        onClick={() => this.setVisible(true)}
                      >
                        <InfoCircleOutlined style={{ color: "#FFF" }} />
                      </span>
                    </Tooltip>
                  </div>
                }
                bordered={true}
              >
                <span className="aqi_holder_card_name">{item}</span>
                <br />
                <span className="aqi_holer_card_value">
                  {v || v === 0 ? v : "NA"}
                </span>
                {(v || v === 0) && (
                  <span className="aqi_holder_card_unit">
                    {item === "TEMP" ? (
                      <span>
                        <sup> o</sup>C
                      </span>
                    ) : item === "HUM" ? (
                      " %"
                    ) : (
                      ""
                    )}
                  </span>
                )}
              </Card>
              <Card
                className="aqi_holder_card_outside"
                onClick={() => this.setVisible("iaq_visible")}
                title={
                  <div>
                    <span className="aqi_card_header_title outdoor">
                      Outdoor
                    </span>
                    <Tooltip placement="bottom" text={text}>
                      <span
                        className="aqi_card_header"
                        onClick={() => this.setVisible(true)}
                      >
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  </div>
                }
                bordered={true}
              >
                <span className="aqi_holder_card_name">{item}</span>
                <br />
                <span className="aqi_holer_card_value">
                  {p || p === 0 ? p : "NA"}
                </span>
                {(p || p === 0) && (
                  <span className="aqi_holder_card_unit">
                    {item === "TEMP" ? (
                      <span>
                        <sup> o</sup>C
                      </span>
                    ) : item === "HUM" ? (
                      " %"
                    ) : (
                      ""
                    )}
                  </span>
                )}
              </Card>
              </ReactCardFlip>
              : <Card
              className={"aqi_holder_card " + aqi_color_inside}
              onClick={() => this.setVisible("iaq_visible")}
              title={
                <div>
                  <span className="aqi_card_header_title indoor">Indoor</span>
                  <Tooltip placement="bottom" text={text}>
                    <span
                      className="aqi_card_header"
                      onClick={() => this.setVisible(true)}
                    >
                      <InfoCircleOutlined style={{ color: "#FFF" }} />
                    </span>
                  </Tooltip>
                </div>
              }
              bordered={true}
            >
              <span className="aqi_holder_card_name">{item}</span>
              <br />
              <span className="aqi_holer_card_value">{v}</span>
              <span className="aqi_holder_card_unit">
                {item === "TEMP" ? (
                  <span>
                    <sup> o</sup>C
                  </span>
                ) : item === "HUM" ? (
                  " %"
                ) : (
                  ""
                )}
              </span>
            </Card>
            }
            
          </Col>
        );
      }
    });
    let insTimeInputForMoment;
    if (typeof insAq.time === "object") {
      let time = insAq.time.dateTime;
      insTimeInputForMoment = !dataIsLoaded
        ? new Date().toISOString()
        : CHECK_DEVICE_CONFIG.devIdIsInEnggList(spaceDevId)
        ? new Date(new Date().setTime(new Date(time).getTime())).toISOString()
        : time + ".000Z";
    } else {
      insTimeInputForMoment = !dataIsLoaded
        ? new Date().toISOString()
        : CHECK_DEVICE_CONFIG.devIdIsInEnggList(spaceDevId)
        ? new Date(
            new Date().setTime(new Date(insAq.time).getTime()),
          ).toISOString()
        : insAq.time + ".000Z";
    }

    // console.log('insTimeInputForMoment: ',insTimeInputForMoment);
    const outTimeInputForMoment = outAq
      ? !dataIsLoaded
        ? new Date().toISOString()
        : CHECK_DEVICE_CONFIG.devIdIsInEnggList(propDevId)
        ? new Date(
            new Date().setTime(new Date(outAq.time).getTime()),
          ).toISOString()
        : outAq.time
      : "";

    const commentToShow = (time) => {
      return moment().diff(moment(time), "hours") > 1;
    };
    const commentModalFooter = [
      <Button key="submit" type="primary" onClick={this.handleCommentModalOk}>
        OK
      </Button>,
    ];
    const sourceModalFooter = [
      <Button key="submit" type="primary" onClick={this.handleSourceModalOk}>
        OK
      </Button>,
    ];
    const CommentModal = () => (
      <Modal
        title="Possible reasons for not seeing real time data"
        visible={this.state.commentModalVisible}
        footer={commentModalFooter}
        onOk={this.handleCommentModalOk}
        closable={false}
      >
        <div>
          <ul>
            <li>Air quality monitor not installed.</li>
            <li>No internet connection.</li>
            <li>Air quality monitor damaged.</li>
          </ul>
          <h4>Note:</h4>
          <span>Please check whether the above causes exist at your side.</span>
          <span>
            If the issue persists even after doing that, kindly inform clairco
            support team.
          </span>
          <br />
          <span>
            Incase of any enquiry please reach out to:
            <br />
            Udayan Banerjee
            <br />
            udayan@clairco.in
            <br />
            +919898036463
          </span>
        </div>
      </Modal>
    );
    const CommentMark = () => (
      <Button
        type="danger"
        onClick={this.showCommentModal}
        ghost
        size="small"
        icon="question"
        shape="circle"
        style={{ height: 18, fontSize: 12, width: 18 }}
      />
    );
    const SourceModal = () => (
      <Modal
        title="Outside data source"
        visible={this.state.sourceModalVisible}
        footer={sourceModalFooter}
        onOk={this.handleSourceModalOk}
        closable={false}
      >
        <div>
          <ul>
            <li>
              <b>Source: </b>
              {outAq ? outAq.source : null}
            </li>
            <li>
              <b>Refernce: </b>
              <a>https://www.airpollutionapi.com/</a>
            </li>
          </ul>
        </div>
      </Modal>
    );
    const SourceMark = () => (
      <Button
        type="danger"
        onClick={this.showSourceModal}
        ghost
        size="small"
        icon="info"
        shape="circle"
        style={{ height: 18, fontSize: 12, width: 18 }}
      />
    );
    const rtCardExtra = (time, label) => {
      // console.log(moment(time).fromNow());
      if (label === "inside") {
        return (
          <span className="extraCardHeader" style={{ marginRight: "50px" }}>
            Updated {moment(time).fromNow() + "  "}
            {commentToShow(time) && <CommentMark />}
          </span>
        );
      } else {
        return (
          <span className="extraCardHeader">
            Updated {moment(time).fromNow() + "  "}
            {commentToShow(time) && <SourceMark />}
          </span>
        );
      }
    };

    const rtCardActions = () => {
      if (insAq.pData[4] > 0 || insAq.pData[5] > 0) {
        return [
          <div>
            <Tooltip placement="bottom" title="Temperature">
              Indoor
              <Thermometer size={20} />
              {insAq.pData[5]} &deg;C
            </Tooltip>
            /
            <Tooltip placement="bottom" title="Temperature">
              Outdoor
              <Thermometer size={20} />
              {outAq.pData[4]} &deg;C
            </Tooltip>
          </div>,
          <div>
            <Tooltip placement="bottom" title="Humidity">
              Indoor
              <DropletHalf size={20} />
              {insAq.pData[6]}%
            </Tooltip>
            /
            <Tooltip placement="bottom" title="Humidity">
              Outdoor
              <DropletHalf size={20} />
              {outAq.pData[5]}%
            </Tooltip>
          </div>,
        ];
      }
    };

    const cardsTitleFontSize = 14;
    const cardsTitleFontWeight = 500;

    const insRealtimeAqCard = (
      <Card
        style={{ height: "100%", marginBottom: "-20px" }}
        bordered={false}
        // actions={rtCardActions()}
        extra={rtCardExtra(insTimeInputForMoment, "inside")}
      >
        <Row>
          <Col span={24}>{insRtAqItems}</Col>
        </Row>
      </Card>
    );

    // const aqiTableCardExtra = <span>(CPCB - Govt. of India)</span>;

    // const aqTrendDropdownItems = aqPollutants.map((item, i) => (
    //   <Menu.Item key={"aqTrendDropdownItem" + i}>{item}</Menu.Item>
    // ));

    // const aqTrendDropdownMenu = () => {
    //   if (
    //     CHECK_DEVICE_CONFIG.devIdIsInEnggList(spaceDevId) ||
    //     CHECK_DEVICE_CONFIG.devIdIsInEnggList(propDevId)
    //   ) {
    //     return (
    //       <Menu onClick={(e) => this.handleAqTrendDropdownClick(e)}>
    //         {aqTrendDropdownItems}
    //       </Menu>
    //     );
    //   } else {
    //     return <Menu>{aqPollutants[0]}</Menu>;
    //   }
    // };

    const dateRangeDropdownItems = dateRanges.map((item, i) => {
      return <Menu.Item key={"dateRangeDropdownItem" + i}>{item}</Menu.Item>;
    });

    const dateRangeDropdownMenu = () => {
      return (
        <Menu onClick={(e) => this.handleDateRangeDropdownClick(e)}>
          {dateRangeDropdownItems}
        </Menu>
      );
    };

    const aqTrendsCardExtra = (
      <Dropdown overlay={dateRangeDropdownMenu}>
        <Button>
          <Icon type="calendar" />
          {dateRanges[selectedDateRangeIndex]}
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
    const aqTrendsCard = (
      <Card
        style={{ height: "100%" }}
        bordered={false}
        title={
          <span
            style={{
              fontSize: cardsTitleFontSize,
              fontWeight: cardsTitleFontWeight,
            }}
          >
            {spaceName}
          </span>
        }
        extra={aqTrendsCardExtra}
      >
        <div>
          <div
            style={{
              display: "flex",
              marginBottom: aqTrendsCardConfig.chartMarginBottom,
              marginTop: aqTrendsCardConfig.chartMarginTop,
            }}
          >
            <div style={{ width: "50%" }}>
              {/* {console.log("display aq trends for graph")}
              {console.log(aqTrendData[0][0].hour)} */}
              {aqTrendData !== undefined && aqTrendData[0] !== undefined ? (
                <PmChartCapitaLand
                  aqTrendData={aqTrendData[0]}
                  label={aqPollutants[0]}
                  dateRangeArr={dateRangeArr}
                  outsideDataShow={outsideDataShow}
                  spaceDevId={spaceDevId}
                  opHours={opHours}
                />
              ) : (
                <div></div>
              )}
            </div>
            <div style={{ width: "50%" }}>
              {aqTrendData !== undefined && aqTrendData[1] !== undefined ? (
                <PmChartCapitaLand
                  aqTrendData={aqTrendData[1]}
                  label={aqPollutants[1]}
                  dateRangeArr={dateRangeArr}
                  outsideDataShow={outsideDataShow}
                  spaceDevId={spaceDevId}
                  opHours={opHours}
                />
              ) : (
                <div />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: aqTrendsCardConfig.chartMarginBottom,
              marginTop: aqTrendsCardConfig.chartMarginTop,
            }}
          >
            {co_flag ? (
              <div style={{ width: "50%" }}>
                {aqTrendData !== undefined && aqTrendData[2] !== undefined ? (
                  <PmChartCapitaLand
                    aqTrendData={aqTrendData[2]}
                    label={aqPollutants[2]}
                    dateRangeArr={dateRangeArr}
                    outsideDataShow={outsideDataShow}
                    spaceDevId={spaceDevId}
                    opHours={opHours}
                  />
                ) : (
                  <div />
                )}
              </div>
            ) : null}
            <div style={{ width: "50%" }}>
              {aqTrendData !== undefined && aqTrendData[3] !== undefined ? (
                <PmChartCapitaLand
                  aqTrendData={aqTrendData[3]}
                  label={aqPollutants[3]}
                  dateRangeArr={dateRangeArr}
                  outsideDataShow={outsideDataShow}
                  spaceDevId={spaceDevId}
                  opHours={opHours}
                />
              ) : (
                <div />
              )}
            </div>
            {this.customerId !== test_id && (
              <div style={{ width: "50%" }}>
                {aqTrendData !== undefined && aqTrendData[1] !== undefined ? (
                  <PmChartCapitaLand
                    aqTrendData={aqTrendData[4]}
                    label={aqPollutants[4]}
                    dateRangeArr={dateRangeArr}
                    outsideDataShow={outsideDataShow}
                    spaceDevId={spaceDevId}
                    opHours={opHours}
                  />
                ) : (
                  <div />
                )}
              </div>
            )}
          </div>
        </div>
      </Card>
    );
    return !dataIsLoaded ? (
      <div className="propertiesLoaderContainer">
        <Spin size="large" />
      </div>
    ) : (
      <div
        style={{ minHeight: 600 }}
        // className='propertiesContainer'
      >
        <Header className="summaryHeaderContainer">
          {adminFlag ? (
            <Row type="flex" style={{ padding: 21 }}>
              <Col span={6}>
                Property: <b>{spaceName}</b>
              </Col>
              <Col span={5}>
                Inside Device: <b>{spaceDevId}</b>
              </Col>
              <Col span={5}>
                Outside Device: <b>{propDevId == null ? propDevId : "NA"} </b>
              </Col>
              <Col span={8}>
                Date: <b>{currentDate}</b>
              </Col>
            </Row>
          ) : (
            <div className="summaryHeader">
              <b>{spaceName + " : "}</b>
              {currentDate}
              {this.state.id === maxEstates_customerId || max_house_okhla ? (
                <Tooltip title="Show Kiosk" align="bottom">
                  <Button
                    className="kioskIcon"
                    onClick={() => this.kioskDisplay()}
                  >
                    <Icon type="desktop" />
                  </Button>
                </Tooltip>
              ) : null}
            </div>
          )}
        </Header>
        <Row gutter={[8, 16]} type="flex">
          <Col
            xs={insCardConfig.span.xs}
            sm={insCardConfig.span.sm}
            md={insCardConfig.span.md}
            lg={insCardConfig.span.lg}
            xl={insCardConfig.span.xl}
            xxl={insCardConfig.span.xxl}
            style={{ marginLeft: "4%" }}
          >
            <Modal
              title="Air Quality Guidelines"
              footer={null}
              centered
              visible={this.state.iaq_visible}
              onCancel={() => this.setVisible("iaq_visible")}
              width={600}
            >
              <p>
                Air quality guideline is an annual mean concentration guideline
                for particulate matter from the World Health Organization, most
                recently updated in 2005.
              </p>
              <p>
                In terms of health effects, the guideline states that PM2.5
                concentration of 10 is the lowest level at which total,
                cardiopulmonary and lung cancer mortality have been shown to
                increase with more than 95% confidence in response to long-term
                exposure to PM2.5.
              </p>
              <p>
                Along with cardiopulmonary and lung cancer deaths, the chances
                of which an individual increases their risk of being diagnosed
                with these is highly coordinated to fine particulate matter and
                sulfur dioxide-related pollution. A 2002 study found that "Each
                10 μg/m3 elevation in fine particulate air pollution was
                associated with approximately a 4%, 6% and 8% increased risk of
                all-cause, cardiopulmonary, and lung cancer mortality,
                respectively."
              </p>
              <p>These guidelines are as follows:</p>
              <table id="customers">
                <tr>
                  <th>Pollutant</th>
                  <th>Good</th>
                  <th>Moderate</th>
                  <th>Severe</th>
                </tr>
                <tr>
                  <td>
                    PM 2.5 (ug/m<sup>3</sup>)
                  </td>
                  <td>{"< " + this.state.aqiRange[0].min}</td>
                  <td>
                    {parseInt(this.state.aqiRange[0].min) + 1} -{" "}
                    {this.state.aqiRange[0].max - 1}
                  </td>
                  <td>{"> " + this.state.aqiRange[0].max}</td>
                </tr>
                <tr>
                  <td>
                    PM 10 (ug/m<sup>3</sup>)
                  </td>
                  <td>{"< " + this.state.aqiRange[1].min}</td>
                  <td>
                    {parseInt(this.state.aqiRange[1].min) + 1} -{" "}
                    {this.state.aqiRange[1].max - 1}
                  </td>
                  <td>{"> " + this.state.aqiRange[1].max}</td>
                </tr>
                <tr>
                  <td>
                    CO<sub>2</sub> (ppm)
                  </td>
                  <td>{"< " + this.state.aqiRange[2].min}</td>
                  <td>
                    {parseInt(this.state.aqiRange[2].min) + 1} -{" "}
                    {this.state.aqiRange[2].max - 1}
                  </td>
                  <td>{"> " + this.state.aqiRange[2].max}</td>
                </tr>
                <tr>
                  <td>VOC (ppb)</td>
                  <td>{"< " + this.state.aqiRange[3].min}</td>
                  <td>
                    {parseInt(this.state.aqiRange[3].min) + 1} -{" "}
                    {this.state.aqiRange[3].max - 1}
                  </td>
                  <td>{"> " + this.state.aqiRange[3].max}</td>
                </tr>
              </table>
              <br />
            </Modal>
            {insRealtimeAqCard}
          </Col>
        </Row>

        <Row gutter={[8, 16]} type="flex">
          <Col span={24}>{aqTrendsCard}</Col>
        </Row>
        <CommentModal />
        <SourceModal />
      </div>
    );
  }
}

const text = <span>Info</span>;

PropertiesCapitaLand.propTypes = {
  selectedMenu: PropTypes.string.isRequired,
};
