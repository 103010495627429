import React, { Component } from "react";
import { Table, Tag, Spin, Layout, Button } from "antd";
import { Input, Space } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { getAllCustomerUsers, getCustomer } from "../api";
import UserManagement from "./UserManagement";

const { Header, Footer, Sider, Content } = Layout;
const { Search } = Input;

export default class UserControl extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Name",
        dataIndex: "first_name",
        key: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Contact",
        dataIndex: "phone_number",
        key: "phone_number",
      },
      {
        title: "Designation",
        dataIndex: "Roles",
        key: "Roles",
        render: (Roles) => {
          return <span>{Roles[0].name}</span>;
        },
      },
      {
        title: "Last Login",
        key: "last_login",
        dataIndex: "last_login",
      },
    ];
    this.state = {
      id: this.props.id,
      data: null,
      selectedmenu: 0,
    };
  }

  async componentDidMount() {
    try {
      let data = (await getCustomer(this.state.id)).data;
      let users = data.data.Users;
      // console.log(users);
      this.setState({
        data: users,
      });
    } catch (error) {
      // console.log('Error: ', error);
    }
  }

  // onSearch = value => console.log(value)/;

  userForm = () => {
    this.setState({
      selectedmenu: 1,
    });
  };

  render() {
    let { data, selectedmenu, id } = this.state;
    if (!data) {
      return <Spin />;
    }
    return (
      <div>
        {selectedmenu === 1 ? (
          <UserManagement id={id} />
        ) : (
          <Layout>
            <Header style={{ backgroundColor: "white" }}>
              <div>
                <h1
                  style={{
                    float: "left",
                    color: "#00695c",
                    fontSize: "250%",
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  User Management
                </h1>
                <Button
                  onClick={this.userForm}
                  style={{
                    float: "right",
                    marginTop: "1.25%",
                    marginLeft: "3%",
                  }}
                  size="large"
                  type="primary"
                  shape="round"
                >
                  Add User
                </Button>
              </div>
            </Header>
            <Layout>
              <Content>
                <Table
                  columns={this.columns}
                  dataSource={data}
                  style={{ marginTop: "2%" }}
                />
              </Content>
            </Layout>
          </Layout>
        )}
      </div>
    );
  }
}
