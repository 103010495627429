import React, { Component } from "react";
import {
  Button,
  Form,
  Divider,
  InputNumber,
  Checkbox,
  Select,
  Input,
  DatePicker,
  Radio,
} from "antd";
const FormItem = Form.Item;
const Option = Select.Option;

const ACForm = Form.create()(
  class extends React.Component {
    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Form layout="vertical">
          <FormItem label="Brand">
            {getFieldDecorator("brand", {
              rules: [
                {
                  required: true,
                  message: "Please fill brand",
                },
              ],
            })(
              <Select showSearch placeholder="Please choose brand">
                {this.props.brands.map((brand) => {
                  return <Option value={brand}>{brand}</Option>;
                })}
              </Select>,
            )}
          </FormItem>
          <FormItem label="AC type">
            {getFieldDecorator("ac_type", {
              rules: [
                {
                  required: true,
                  message: "Please fill ac type",
                },
              ],
            })(
              <Select placeholder="Choose AC type">
                <Option value="0">Cassette</Option>
                <Option value="1">Split</Option>
              </Select>,
            )}
          </FormItem>
          <FormItem label="Any other info(Optional)">
            {getFieldDecorator("info")(
              <Input placeholder="Write additonal info about this AC if you have anything" />,
            )}
          </FormItem>
          <Divider />
          <h2>Filter Info</h2>
          <h4>
            Enter the detials of the filter which is going inside above
            mentioned AC
          </h4>
          <FormItem label="Info(Optional)">
            {getFieldDecorator("filter_info")(
              <Input placeholder="Write about the filter. Ex: 3m electrostatic filter" />,
            )}
          </FormItem>
          <FormItem label="Installing On">
            {getFieldDecorator("installed_on", {
              rules: [
                {
                  type: "object",
                  required: true,
                  message: "Please select installation date!",
                },
              ],
            })(<DatePicker />)}
          </FormItem>
        </Form>
      );
    }
  },
);

export default ACForm;
