export const LOGIN_AUTH_ENABLED = true;

// IMPORTANT: In case any changes are made here make sure to run generate excel file and functions in customer_controller to check if everything works smoothly.
const opHourShifts={ 
    0: [
      [6, 11+12] // 6am to 11pm
    ],
    1: [
      [10,8+12] // 10 am to 8 pm
    ],
    2: [
      [6,12], // 6 am to 12 pm
      [4+12,10+12] // 4 pm to 10 pm 
    ],
    3: [
      [7,7+12]
    ]
  };
  const nonOpDays={
    0: [],
    1: [
      'Sunday',
      'Saturday'
    ]
  };

export const OP_HOUR_SHIFTS = opHourShifts;
export const NON_OP_DAYS = nonOpDays;  