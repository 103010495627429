import React, {Component} from "react";
import {
  Chart,
  Axis,
  Coord,
  Geom,
  Guide,
  Shape
} from 'bizcharts';
import { GET_CLR_POLLUTANT_LVL } from '../../colors';
import { GET_P_UNIT_HTML } from '../../pLevels';
const {Html, Arc, Line} = Guide;

Shape.registerShape('point', 'userpointer', {
  drawShape(cfg, group) {
    let point = cfg.points[0];
    point = this.parsePoint(point);
    const center = this.parsePoint({x: 0, y: 0});
    group.addShape('line', {
      attrs: {
        x1: center.x,
        y1: center.y,
        x2: point.x,
        y2: point.y,
        stroke: cfg.color,
        lineWidth: 4,
        lineCap: 'round'
      }
    });
    return group.addShape('circle', {
      attrs: {
        x: center.x,
        y: center.y,
        r: 8,
        stroke: cfg.color,
        lineWidth: 4,
        fill: '#fff'
      }
    });
  }
});

const getMaxValue = function (value) {
  let retVal = Math.floor((((value * 2) + 99) / 100 ) * 100);
  return retVal;
};

const getColor = function(value, pollutantLabel) {
  return GET_CLR_POLLUTANT_LVL(value, pollutantLabel);
};

const PmCard = (props)=>{ 
    const {data,label} = props;
    //data.forEach(item=>{if(!item.value)item.value = null});
    // console.log('data: ',data);
    // console.log('data[0]: ',data[0]);
    // console.log('data[0].value: ',data[0].value);
    // console.log('label: ',label);
    // console.log('label.length: ',label.length);
    const pUnitHtml = GET_P_UNIT_HTML(label);
    // console.log('pUnitHtml: ',pUnitHtml);
    const max = getMaxValue(data[0].value);
    const color = getColor(data[0].value, label);
    // console.log('max: ',max);
    // console.log('color: ',color);
    const cols = {
      value: {
        min: 0,
        max: max,
        nice: false
      }
    };
    const width = window.innerWidth;
    const gaugeHeight = width <= 1366 ? 125 : 180;
    return (
        <Chart
          forceFit
          height={gaugeHeight}
          padding={[16,10,16,10]}
          data={data}
          scale={cols}>
          <Coord
            type="polar"
            startAngle={-9 / 8 * Math.PI}
            endAngle={1 / 8 * Math.PI}
            radius={1.0}/>
          <Axis
            name="value"
            zIndex={2}
            line={null}
            tickLine={{
              lineWidth:1,
              length: -3, 
            }}
            label={{
            offset: -12,
            textStyle: {
              fontSize: 10,
              fill: 'rgba(0, 0, 0, 0.65)',
              textAlign: 'center'
            }
          }}/>
          <Axis name="1" visible={false}/>
          <Guide>
            <Line
              start={[max/3, 0.905]}
              end={[max/3, 0.85]}
              lineStyle={{
              stroke: color,
              lineDash: null,
              lineWidth: 2
            }}/>
            <Line
              start={[max/2, 0.905]}
              end={[max/2, 0.85]}
              lineStyle={{
              stroke: color,
              lineDash: null,
              lineWidth: 2
            }}/>
            <Line
              start={[max/1.5, 0.905]}
              end={[max/1.5, 0.85]}
              lineStyle={{
              stroke: color,
              lineDash: null,
              lineWidth: 2
            }}/>
            <Arc
              zIndex={0}
              start={[0, 0.965]}
              end={[max, 0.965]}
              style={{
              stroke: '#000',
              lineWidth: 10,
              opacity: 0.09
            }}/>
            <Arc
              zIndex={1}
              start={[0, 0.965]}
              end={[data[0].value, 0.965]}
              style={{
              stroke: color,
              lineWidth: 10
            }}/>
            <Html
              position={['50%', '120%']}
              html={() => (`<div style="width: 100px;text-align: center;font-size: 8px!important;"><p style="font-size: 1.75em; color: rgba(0,0,0,0.43);margin: 0;">${label}</p><p style="font-size: 3em;color: rgba(0,0,0,0.85);margin: 0;">   ${data[0].value?data[0].value:"NA"}<span style="font-size:10px"> ${data[0].value ? (`<span>ug/m<sup>3</sup></span>`):""}</span></p></div>`)}/>
          </Guide>
          <Geom
            type="point"
            position="value*1"
            shape="userpointer"
            color={color}
            active={false}
            style={{
            stroke: '#fff',
            lineWidth: 1
          }}/>
        </Chart>
    )
  }

export default PmCard;