import React, {Component} from "react";

const PM2_5_LVL_MIN_VAL = {
  goodMin: 0,
  satisfactoryMin: 30 + 1,
  moderateMin: 60 + 1,
  poorMin: 90 + 1,
  veryPoorMin: 120 + 1,
  severeMin: 250 + 1,
  severeMax: 500
};

const PM10_LVL_MIN_VAL = {
  goodMin: 0,
  satisfactoryMin: 50 + 1,
  moderateMin: 100 + 1,
  poorMin: 250 + 1,
  veryPoorMin: 350 + 1,
  severeMin: 430 + 1,
  severeMax: 860
};

const CO2_LVL_MIN_VAL = {
  goodMin: 0,
  satisfactoryMin: 450 + 1,
  moderateMin: 900 + 1,
  poorMin: 1350 + 1,
  veryPoorMin: 1800 + 1,
  severeMin: 2500 + 1,
  severeMax: 5000
};

const VOC_LVL_MIN_VAL = {
  goodMin: 0,
  satisfactoryMin: 109 + 1,
  moderateMin: 218 + 1,
  poorMin: 327 + 1,
  veryPoorMin: 436 + 1,
  severeMin: 500 + 1,
  severeMax: 1000
};

const TEMP_LVL_MIN_VAL = {
  goodMin: 0,
  satisfactoryMin: 109 + 1,
  moderateMin: 218 + 1,
  poorMin: 327 + 1,
  veryPoorMin: 436 + 1,
  severeMin: 500 + 1,
  severeMax: 1000
};

const HUM_LVL_MIN_VAL = {
  goodMin: 0,
  satisfactoryMin: 109 + 1,
  moderateMin: 218 + 1,
  poorMin: 327 + 1,
  veryPoorMin: 436 + 1,
  severeMin: 500 + 1,
  severeMax: 1000
};

export const P_LVL_TXT = {
  good: 'good',
  satisfactory: 'satisfactory',
  moderate: 'moderate',
  poor: 'poor',
  veryPoor: 'very poor',
  severe: 'severe'
};

export const P_LVL_KEY = {
  good: 'good',
  satisfactory: 'satisfactory',
  moderate: 'moderate',
  poor: 'poor',
  veryPoor: 'veryPoor',
  severe: 'severe'
};

export const GET_P_LVL_MIN_VAL_DICT = function(pollutantLabel) {
  return pollutantLabel === 'PM2.5'
          ? PM2_5_LVL_MIN_VAL
          : pollutantLabel === 'PM10'
            ? PM10_LVL_MIN_VAL
            : pollutantLabel === 'CO2'
              ? CO2_LVL_MIN_VAL
              : pollutantLabel === 'VOC'
                ? VOC_LVL_MIN_VAL
                : pollutantLabel === 'TEMP'
                  ? TEMP_LVL_MIN_VAL
                    : pollutantLabel === 'HUM'
                      ? HUM_LVL_MIN_VAL
                        : null;
};

export const GET_P_UNIT_HTML = function (pollutantLabel) {
  return ((pollutantLabel === 'PM2.5') || (pollutantLabel === 'PM10'))
          ? <span>ug/m<sup>3</sup></span>
          : pollutantLabel === 'CO2'
            ? 'ppm'
            : pollutantLabel === 'VOC'
              ? 'ppb'
              : pollutantLabel === 'TEMP'
                ? <span><sup>o</sup>C</span>
                  : pollutantLabel === 'HUM'
                    ? '%'
                      : '';
};

export const GET_P_LVL_BASE_PERCENT_VALUE = function(value, pollutantLabel){
  const minValDict = GET_P_LVL_MIN_VAL_DICT(pollutantLabel);
  const basePercentValue = value === undefined
                            ? 100
                            : value < minValDict.satisfactoryMin
                              ? 98
                              : value < minValDict.moderateMin
                                ? 70
                                : value < minValDict.poorMin
                                  ? 50
                                  : value < minValDict.veryPoorMin
                                    ? 35
                                    : value < minValDict.severeMin
                                      ? 25
                                      : 0;

  return basePercentValue;                  
}