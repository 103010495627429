import React from 'react';
import {Line} from 'react-chartjs-2';

const state = {
  labels: ['2021-01-01', '2021-01-02', '2021-01-03', '2021-01-04', 
            '2021-01-05', '2021-01-06', '2021-01-07', '2021-01-08'],
  datasets: [
    {
      label: 'Pressure Drop',
      lineTension: 0.5,
      backgroundColor: "rgba(240, 89, 69, 0.4)",
      borderColor: 'rgba(240, 89, 69, 1)',
      borderWidth: 1,
      data: [65, 101, 21, 110, 56, 64, 78, 82]
    }
  ]
}

export default class AqiGraphDPT extends React.Component {
  render() {
    return (
      <div>
        <Line
          data={state}
          options={{
            responsive: true,
            title:{
              display:false,
              text:'Average Rainfall per month',
              fontSize:20
            }
          }}
        />
      </div>
    );
  }
}