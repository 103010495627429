import React, {Component} from "react";
import {getPurifiers, getPurifierExcelReport, updatePurifier} from "../api";
import {Spin, Card, Row, Col, Icon, Result, Button, Modal, Alert,Tooltip} from "antd";
import PurifierEditForm from '../components/PurifierEditForm';
import moment from 'moment';
import _ from "lodash";

const text = <span>Click to change Purifier State</span>;

export default class PurifierView extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            customer_id: this.props.id,
            edit_visible: false,
            response: null
        }
    }

    async componentDidMount() {
        try {
            var {data} = (await getPurifiers(this.state.customer_id)).data;
            this.setState({ 
                data: data,
                loading: false
            })
        } catch (err) {
            console.log(err);
        }
    }

    handleDownloadClick = async (e) => {
        try{
            await getPurifierExcelReport(this.state.customer_id);
        } catch (err) {
            console.log(err);
        }
    }

    handleEditDevice = (model_id) => {        
        var defaultData = _.filter(this.state.data.devices, x => x._id === model_id);
        defaultData[0].turn_on_time = moment( defaultData[0].turn_on_time, 'HH:mm');
        defaultData[0].turn_off_time = moment( defaultData[0].turn_off_time, 'HH:mm');
        this.setState({
            edit_visible: true,
            selected_model_id: model_id,
            deviceDefaultData: defaultData[0]
        });
    }

    onUpdate = () => {        
        const editPurifierForm = this.editPurifierRef.props.form;

        editPurifierForm.validateFields(async(err, formdetails) => {
            var turn_on_time = moment(formdetails['turn_on_time']._d).format('HH:mm');
            var turn_off_time = moment(formdetails['turn_off_time']._d).format('HH:mm')
            formdetails['purifier_device_id'] = this.state.selected_model_id;
            formdetails['turn_on_time'] = turn_on_time;
            formdetails['turn_off_time'] = turn_off_time;
            formdetails['customer_id'] = this.state.customer_id;
            if(err) {
                return err;
            }
            try{
                const response = await updatePurifier({
                    ...formdetails
                });
                editPurifierForm.resetFields();
                this.setState({code: 200, response: 'Device Updated'});
            } catch (e) {
                this.setState({ code: 400, response: 'Error Updating'});
            }
        })
    }

    handelCancel = () => {
        this.setState({
            visible: false,
            edit_visible: false
          });
    }

    handleOk = () => {
        this.setState({
            visible: false,
            edit_visible: false
          });
    }

    editPurifierForm = (formRef) => {
        this.editPurifierRef = formRef;
    }

    render() {
        const {data, loading, devices, edit_visible, deviceDefaultData} = this.state;
        if(!data){
            return (
                <div className="dashLoaderContainer"><Spin size="large"/></div>   
            );
        }
        if(data.length==0){
            return (
                <Result
                title="No purifiers added."
              />
            )
        }
        const device_card_raw = data.devices.map((item, i) => {
            return (
                <Col key={item._id} span= {6}>
                <Card
                    className='header_block_card'
                    style={{width: '81%'}}>
                        <Row gutter={[16, 16]}>
                            {/* <Tooltip placement="bottom" title={item.status==false?' Click to turn Purifier ON':'Click to turn Purifier  OFF'}> */}
                            <Col className={
                               item.connected==false
                               ?"block_card_icon fail"
                               :(item.status == true ? "block_card_icon success":"block_card_icon disconnection")} span={12}>
                                {item.connected==false
                                    ?<Icon type="stop"/>
                                    :(item.status==true
                                        ?<Icon type="check"/>
                                        :<Icon type="warning"/>)}
                            </Col>
                            {/* </Tooltip> */}
                            <Col span={12}>
                            <div className='block_card_info_header'>{item._id}</div>
                            <div className="info_units">{item.space_name}</div>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <span><Icon type="history" style={{marginRight: '10px'}}/>Refreshed just now.</span>
                            <span style={{marginLeft: '8px'}}><Icon type="setting" onClick={() => this.handleEditDevice(item._id)}/></span>
                        </Row>
                </Card>
                </Col>
            )
        })
        const device_card = (
            <Row>
                {device_card_raw}
            </Row>
        )
        return(
            <div>
                {/* <Divider orientation="left" plain>
                    Purifiers
                </Divider> */}
                <Modal
                    title={"Edit Purifier Details: ("+this.state.selected_model_id+")"}
                    centered
                    onOk={this.handleOk}
                    onCancel={this.handelCancel}
                    visible={edit_visible}
                    footer={[
                        <Button key="cancel" onClick={this.handelCancel}>
                            Cancel
                        </Button>
                    ]}>
                    <div>
                        <PurifierEditForm wrappedComponentRef={this.editPurifierForm} defaultData={deviceDefaultData}/>
                        <Button icon="edit" key="submit" type="primary" onClick={this.onUpdate}>
                            Update Purifier
                        </Button>
                        <br/>
                        {this.state.response!==null
                            ?<Alert message={this.state.response} type="info"/>
                            :null}
                    </div>
                </Modal>
                {device_card}
            </div>
        )
    }
}