import { isNull } from "lodash";
import React from "react";
import "./DeviceList.less";

const GSM = ({ gsm_data, id }) => {
  return (
    <div>
      <table id="customers" style={{ marginBottom: "10px" }}>
        <tr>
          <th>ID</th>
          <th>PM 2.5</th>
          <th>PM 10</th>
          <th>CO</th>
          <th>
            CO<sub>2</sub>
          </th>
          <th>VOC</th>
          <th>Space</th>
          <th>Property</th>
          <th>Updated at</th>
        </tr>
        <tr>
          {" "}
          <td>{id}</td>
          {gsm_data.map((data, index) => (
            <td key={index}>{isNull(data) ? "NA" : data}</td>
          ))}{" "}
        </tr>
      </table>
    </div>
  );
};

// {
// []
//}

export default GSM;
