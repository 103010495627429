import React, {Component} from "react";
import {
  Button,
  Spin,
  PageHeader,
  Table,
  Icon,
  Popconfirm,
  Alert,
  Modal,
  notification,
  Collapse
} from "antd";
import UVForm from '../components/UVForm';
import DPTForm from "../components/DPTForm";
import {getFilters, deleteFilter, addUVDevice, addDPTController, getCustomer} from "../api";
import moment from 'moment';
import _ from 'lodash';
const { Panel } = Collapse;
const key = `open${Date.now()}`;
export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.columns = [
        { title: 'AHU name', dataIndex: 'name', key: 'name' },
        { title: 'Next maintainence', dataIndex: 'last_update', key:'last_update', render: () => 'NA'},
        { title: 'Configure AHU', 
          dataIndex: 'uv_status', 
          key:'uv_status', 
          render: (text, record) => record.uv_device_id==null||record.uv_device_id==undefined
                  ?<Button onClick={() => this.showModal('uv', record._id)}><Icon type="setting"/></Button>
                  :<Button><Icon type="eye"/></Button>
        },
        {title: 'Action',
        children: [
          { title: 'View Logs', 
            dataIndex: 'customer_id', 
            key: 'customer_id', 
            render: (id) => <Button onClick={()=>this.props.onClick('MaintainenceLogs', id)}><Icon type="eye"/></Button> 
          },
          { title: 'Update Logs', 
            dataIndex: '_id', 
            key: '_id', 
            render: (id) => <Button onClick={()=>this.props.onClick('AddLog', id)}><Icon type="edit"/></Button> 
          },
          { title: 'Delete', 
            dataIndex: '_id', 
            key: "id",
            render: (id) => 
                  <Popconfirm
                    placement="topRight"
                    title="Are you sure?"
                    onConfirm={() => {this.deleteAHU(id)}}
                    okText="Yes"
                    cancelText="No">
                    <Button>
                      <Icon type="delete" style={{color: "#FF0000"}}/>
                    </Button>
                  </Popconfirm> 
          },
        ]}
      ];
    this.state = {
      loading: false,
      data: null,
      uv_protected: false,
      visible: false
    };
  }

  async componentDidMount() {
    this.customerId = this.props.id;
    try {
      let {data} = (await getCustomer(this.customerId, true)).data;
      if(data.Properties.length>0){
        data = _(data.Properties)
        .groupBy(property => property.city)
        .map((value, key) => ({name: key, properties: value}))
        .sortBy(cityRegion => cityRegion.name)
        .value();
      }
      let ahu_Data = [];
      for(var j=0; j<data.length; j++) {
        for(var i=0; i<data[j].properties.length; i++){
          let property_filter = (await getFilters(data[j].properties[i].id)).data;
          // console.log(property_filter.data);
          for(var k=0; k<property_filter.data.length; k++){
            ahu_Data.push(property_filter.data[k]);
          }
        }
      }
      this.setState({data: ahu_Data});
    } catch (e) {
      console.log(e);
    }
  };

  async componentDidUpdate(prevState) {    
    if(prevState.deleted!==this.state.deleted){
      this.customerId = this.props.id;
      try {
        let {data} = (await getCustomer(this.customerId, true)).data;
        if(data.Properties.length>0){
          data = _(data.Properties)
          .groupBy(property => property.city)
          .map((value, key) => ({name: key, properties: value}))
          .sortBy(cityRegion => cityRegion.name)
          .value();
        }
        let ahu_Data = [];
        for(var j=0; j<data.length; j++) {
          for(var i=0; i<data[j].properties.length; i++){
            let property_filter = (await getFilters(data[j].properties[i].id)).data;
            ahu_Data.push(property_filter.data);
          }
        }
        this.setState({data: ahu_Data});
      } catch (e) {
        console.log(e);
      }
    }
  }
  
  showModal = (label, id) => {
    notification.close(key);
    var selected_uv_data = _.filter(this.state.data, x => x._id === this.state.temp_filter_id);
    // console.log(selected_uv_data);
    this.setState({
      visible: true,
      temp_filter_id: id
    })
  };  

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  prevComponent = () => {
    this.props.onClick('Customer', this.customerId, null);
  }
  
  addAHU = () => {
    this.props.onClick('AddAHU', this.customerId);
  };

  async deleteAHU(id) {
    try {
      await deleteFilter(id);
      this.setState({
        deleted: true
      })
    } catch (e) {
      console.log(e);
    }
  }

  saveUVForm = (formRef) => {
    this.uvFormRef = formRef;
  };

  saveDPTForm = (formRef) => {
    this.dptFormRef = formRef;
  }

  onSubmit = () => {
    const uvForm = this.uvFormRef.props.form;
    
    uvForm.validateFields(async(err, uvDetails) => {
        uvDetails['filter_id'] = this.state.temp_filter_id;
        if(err){
            return;
        }
        try {
            const response = await addUVDevice({
                ...uvDetails
            });
            uvForm.resetFields();
            this.setState({code: 200, response: 'Device added', visible: false})
        } catch (e) {
            this.setState({code: 400, response: 'Filter not added'})
        }
    })
  }

  onSubmitDPT = () => {
    const dptForm = this.dptFormRef.props.form;

    dptForm.validateFields(async(err, dptdetails) => {
      dptdetails['filter_id'] = this.state.temp_filter_id;
      if(err){
        return;
      }
      try {
        const response = await addDPTController({
          ...dptdetails
        })
        dptForm.resetFields();
        this.setState({code: 200, response: 'Controller Added', visible: false})
      } catch (e) {
        this.setState({code: 400, response: 'Filter not added'})
      }
    })
  }

  render() {
    const {data, uv_protected, visible, uvdefaultData, dptdefaultData} = this.state;
    if (!data) {
      return (
        <div style={style.loader}>
          <Spin size="large" tip="Loading space data.."/>
        </div>
      );
    }
    const btn = (
      <Button type="primary" size="small" onClick={() => this.showModal('uv')}>
        Add UV Device
      </Button>
    );

    return (
      <div style={style.container}>
        <Modal
          title="Configure AHU"
          centered
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          visible={visible}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>
          ]}>
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <Icon type="caret-right" />}
              className="site-collapse-custom-collapse">
              <Panel header="UV Device" key="1" className="site-collapse-custom-panel">
                <div>
                  <UVForm wrappedComponentRef={this.saveUVForm} defaultData={uvdefaultData}/>
                  <Button icon="plus" key="submit" type="primary" onClick={this.onSubmit}>
                    Add Device
                  </Button>
                </div>
              </Panel>
              <Panel header="DPT Controller" key="2" className="site-collapse-custom-panel">
                  <DPTForm wrappedComponentRef={this.saveDPTForm} defaultData={dptdefaultData}/>
                  <Button icon="plus" key="submit" type="primary" onClick={this.onSubmitDPT}>
                    Add Controller
                  </Button>
              </Panel>
            </Collapse>
        </Modal>
        <PageHeader
            className="site-page-header"
            onBack={() => this.prevComponent()}
            title="Maintain Customer AHU's"
        />
        {/* {uv_banner} */}
        <Button
          icon="plus"
          style={style.button}
          onClick={this.addAHU}
          type="primary">
          Add AHU
        </Button>
        <Table
            className="components-table-demo-nested"
            columns={this.columns}
            bordered
            dataSource={data}
        />
      </div>
    );
  }
}

const style = {
  container: {
    margin: "auto",    
    background: '#FFF',
    border: "3 px solid green",
    paddingTop: 30,
    maxwidth: 500
  },
  button: {
    margin: 16
  },
  saveBtn: {
    marginTop: 20,
    marginBottom: 20
  },
  loader: {
    width: "100%",
    textAlign: "center",
    marginTop: 100
  },
  listStyle: {
    marginTop: 20
  }
};
