import React, {Component} from "react";
import {Button, Form, Input, Radio,Select} from "antd";
const {Option} = Select
const FormItem = Form.Item;
const UserForm = Form.create()(class extends React.Component {
    render() {
        const {form} = this.props;
        const {getFieldDecorator} = form;
        return (
            <Form layout="vertical">
                <FormItem label="Email">
                    {getFieldDecorator("email", {
                        rules: [
                            {
                                required: true,
                                message: "Please fill email!"
                            }
                        ]
                    })(<Input placeholder="Enter user's email here"/>)}
                </FormItem>
                <FormItem label="First name">
                    {getFieldDecorator("first_name", {
                        rules: [
                            {
                                required: true,
                                message: "Please fill first name!"
                            }
                        ]
                    })(<Input placeholder="First name here"/>)}
                </FormItem>
                <FormItem label="Last name">
                    {getFieldDecorator("last_name", {
                        rules: [
                            {
                                required: true,
                                message: "Please fill last name!"
                            }
                        ]
                    })(<Input placeholder="Last name here"/>)}
                </FormItem>
                <FormItem label="Phone number">
                    {getFieldDecorator("phone_number", {
                        rules: [
                            {
                                required: true,
                                message: "Please fill phone number!"
                            }
                        ]
                    })(<Input placeholder="Phone number here"/>)}
                </FormItem>
                <Form.Item label="Role">
                  {getFieldDecorator("designation", {

                    rules: [
                      {
                        required: true,
                        message: "Please input your user designation!",
                      },
                    ],
                  })(
                  
                    <Select
                      defaultValue="Customer" placeholder="select a role">
                      <Option value="Customer">Customer</Option>
                      <Option value="Admin">Admin</Option>

                    </Select>
                  )}
                </Form.Item>{" "}
            </Form>
        );
    }
})

export default UserForm