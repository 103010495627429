import React, { Component } from "react";
import { Row, Col, DatePicker, Form, Input, Select, TreeSelect } from "antd";
import { render } from "react-dom";

const FormItem = Form.Item;
const Option = Select.Option;

const PurifierForm = Form.create()(
  class extends React.Component {
    state = {
      data: this.props.data,
    };
    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Form layout="horizontal">
          <FormItem label="Enter Device ID">
            {getFieldDecorator("device_id", {
              rules: [
                {
                  required: true,
                  message: "Important",
                },
              ],
            })(<Input placeholder="e.g. MW300001" />)}
          </FormItem>
          <FormItem label="Installed On">
            {getFieldDecorator("installed_on", {
              rules: [
                {
                  type: "object",
                  required: true,
                  message: "Please select installation date!",
                },
              ],
            })(<DatePicker />)}
          </FormItem>
          <FormItem label="Space">
            {getFieldDecorator("space", {
              rules: [
                {
                  required: true,
                  message: "Please select space",
                },
              ],
            })(
              <TreeSelect
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                treeData={this.state.data}
                placeholder="Please select"
                onChange={(val) => console.log(val)}
                treeDefaultExpandAll
              />,
            )}
          </FormItem>
        </Form>
      );
    }
  },
);

export default PurifierForm;
