import React, {Component} from "react";
import{Progress, Row, Col, Typography} from 'antd';
import { COLOR, GET_CLR_POLLUTANT_LVL } from './colorsCapitaLand';
import { GET_P_UNIT_HTML, GET_P_LVL_PERCENT_VALUE } from './pLevelsCapitaLand';

const winWidth = window.innerWidth;
const dashConfig = {
  totalWidth: winWidth <= 1366 ? 140 : winWidth <= 1440 ? 160 : 220,
  strokeWidth: winWidth <= 1366 ? 10 : winWidth <= 1440 ? 10 : 11,
  mTop: winWidth <= 1366 ? '-100px' : winWidth <= 1440 ? '-110px' : '-140px',
  mRight: winWidth <= 1366 ? '0px' : winWidth <= 1440 ? '0px' : '0px',
  mBottom: winWidth <= 1366 ? '0px' : winWidth <= 1440 ? '0px' : '0px',
  mLeft: winWidth <= 1366 ? '-2px' : winWidth <= 1440 ? '6px' : '-20px'
};

const AqProgressBar = (props) => {
    const {data,label,range} = props;
    // console.log('range: ',range);
    const value = data[0].value ? parseInt(data[0].value): 0;
    const pUnitHtml = GET_P_UNIT_HTML(label);
    // console.log('label: ', label);
    // console.log('value: ',value);
    const percentValue = GET_P_LVL_PERCENT_VALUE(value, label, range);
    // console.log('percentValue: ',percentValue);
    const gaugeColor = GET_CLR_POLLUTANT_LVL(value, label);
    // console.log('gaugeColor: ',gaugeColor);
    const cellFontSize = 13;

    return (
      <div style={{paddingLeft: 10}}>
        <Row gutter={[8, 8]}>
          <Col span={5} key={'name'+label}>
            <Typography.Text style={{ fontSize: cellFontSize }}>
              {label}
            </Typography.Text>
          </Col>
          
          <Col span={13} key={'value'+label}>
            
          </Col>
          <Col span={5} key={'unit'+label}>
          <Typography.Text style={{ fontSize: cellFontSize }}>
              {value >= 0
                ? value
                  :"NA"
              }
            </Typography.Text>
            <Typography.Text style={{ fontSize: cellFontSize }}>
              {pUnitHtml}
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col span={22} key={'bar'+label}>
            <Progress
              strokeColor={gaugeColor}
              strokeWidth={dashConfig.strokeWidth}
              width={dashConfig.totalWidth} gapDegree={120}
              percent={percentValue} showInfo={false}/>
          </Col>
        </Row>
      </div>
    )    
}

export default AqProgressBar;