import React, { Component } from "react";
import {
  Button,
  Alert,
  Form,
  Input,
  Radio,
  PageHeader,
  message,
  notification,
} from "antd";
import DeviceForm from "../components/DeviceForm";
import { addDevice } from "../api";

const FormItem = Form.Item;

export default class AddDevice extends Component {
  constructor(props) {
    super(props);
    this.parentId = this.props.location.state.id;
    this.isOutSide = this.props.location.state.isOutSide;
    this.state = {
      loading: false,
      response: "",
      data: null,
      propertyId: "",
      previousDeviceInfo: "",
      previousDeviceData: {
        id: "",
        space: "",
        property: "",
      },
    };
  }

  async componentDidMount() {
    var customerId = this.props.id;
    try {
      var propertyId = this.props.referrer;
      this.setState({ propertyId: this.props.id, customerId });
    } catch (e) {
      console.log(e);
    }
  }
  prevComponent = () => {
    // this.props.onClick("Property", this.state.propertyId, null);
    this.props.history.goBack();
  };
  saveDevice = () => {
    const form = this.formRef.props.form;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      this.setState({ loading: true });
      try {
        const response = await addDevice({
          ...values,
          parent_id: this.parentId,
          is_outside: this.isOutSide,
        });

        form.resetFields();
        this.setState({ code: 200, response: "Data created successfully" });
      } catch (e) {
        console.log(e);
        if (e.response.data) {
          message.error(e.response.data.message);
        }
        // this.setState({
        //   code: 400,
        //   response: e.response.data.message,
        //   previousDeviceData: {
        //     id: e.response.data.deviceInfo.id,
        //     space: e.response.data.deviceInfo.space,
        //     property: e.response.data.deviceInfo.property,
        //   },
        // });
        // this.openNotification();
      }
      this.setState({ loading: false });
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };
  openNotification = () => {
    return notification.open({
      message: "Device Info",
      description: `Device ID : ${this.state.previousDeviceData.id} is installed on ${this.state.previousDeviceData.space} at ${this.state.previousDeviceData.property}`,
      duration: 10,
    });
  };
  render() {
    return (
      <div style={style.container}>
        <PageHeader
          className="site-page-header"
          onBack={() => this.prevComponent()}
          title={<h1>{"Add Device"}</h1>}
        />
        <DeviceForm wrappedComponentRef={this.saveFormRef} />
        {this.state.response && (
            <Alert
              message={this.state.response}
              type={this.state.code == 200 ? "success" : "error"}
              showIcon
            />
        )}
        <Button
          style={style.saveBtn}
          type="primary"
          loading={this.state.loading}
          onClick={this.saveDevice}
        >
          Save
        </Button>
      </div>
    );
  }
}

const style = {
  container: {
    margin: "auto",
    maxWidth: 500,
    border: "3 px solid green",
    paddingTop: 30,
  },
  saveBtn: {
    marginTop: 20,
    marginBottom: 20,
  },
};
