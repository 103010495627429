import { isUndefined } from "lodash";
import { locale } from "moment";
import { LOGIN_AUTH_ENABLED } from "./utils/developerConfig";

export function getToken() {
  if (LOGIN_AUTH_ENABLED) {
    // if (localStorage.getItem("token")) {
    //   return localStorage.getItem("token");
    // } else {
    //   return localStorage.getItem("admintoken");
    // }
    return localStorage.getItem("token");
  } else return {};
}

export function removeCache() {
  localStorage.clear();
}

export function setToken(token) {
  localStorage.setItem("token", token);
}

export function setEmail(email) {
  localStorage.setItem("email", email);
}

export function getEmail() {
  return localStorage.getItem("email");
}

export function setUsername(name) {
  localStorage.setItem("username", name);
}

export function getUsername() {
  return localStorage.getItem("username");
}

export function removeToken() {
  localStorage.removeItem("token");
}

export function removeUsername() {
  localStorage.removeItem("username");
}

export function setUserId(id) {
  localStorage.setItem("userId", id);
}

export function getUserId() {
  return localStorage.getItem("userId");
}

export function setCsrId(CsrId) {
  localStorage.setItem("CsrId", CsrId);
}

export function getCsrId() {
  if (LOGIN_AUTH_ENABLED) {
    if (localStorage.getItem("CsrId")) return localStorage.getItem("CsrId");
    else return JSON.parse(localStorage.getItem("cust_data"))[0].id;
  } else return;

  // if (LOGIN_AUTH_ENABLED) {
  //   if (localStorage.getItem("CsrId")) {
  //     return localStorage.getItem("CsrId");
  //   } else return JSON.parse(localStorage.getItem("cust_data"))[0].id;
  // } else return retVal;
}

export function removeCsrId() {
  localStorage.removeItem("CsrId");
}

export function getAdminToken() {
  if (LOGIN_AUTH_ENABLED) return localStorage.getItem("admintoken");
  else return {};
}

export function setAdminToken(admintoken) {
  localStorage.setItem("admintoken", admintoken);
}

export function setSupervisorData(data) {
  localStorage.setItem("cust_data", JSON.stringify(data, null, 2));
}

export function getSupervisorData() {
  return JSON.parse(localStorage.getItem("cust_data"));
}

export function removeAdminToken() {
  localStorage.removeItem("admintoken");
  localStorage.getItem("CsrId") && localStorage.removeItem("CsrId");
}
export function setUserRole(role) {
  localStorage.setItem("user_role", role);
}

export function getUserRole() {
  return localStorage.getItem("user_role");
}

export function removeUserRole() {
  localStorage.removeItem("user_role");
}
