import React, { Component } from "react";
import { Button, Alert, Form, Input, List, PageHeader, message } from "antd";
import { Row, Col } from "antd";
import { addStation, fetchLatLonData } from "../api";
import axios from "axios";
import { CheckOutlined } from "@ant-design/icons";
import { HomeOutlined } from "@ant-design/icons";

const FormItem = Form.Item;
const PI = Math.PI;

export default class AddStation extends Component {
  constructor(props) {
    super(props);
    // this.propertyId = this.props.location.state.id;
    this.state = {
      // loading: false,
      response: false,
      value_list: [],
      list: [],
      station_list: undefined,
      lat: 0.0,
      lon: 0.0,
      propertyId: "",
    };
  }

  componentDidMount() {
    this.setState({ propertyId: this.props.id });
  }
  prevComponent = () => {
    this.props.onClick("Property", this.state.propertyId, null);
  };

  renderItems() {
    if (this.state.station_list) {
      var sendArray = [];
      for (var i = 0; i < this.state.station_list.length; i++) {
        var temp_Data = this.state.station_list[i][0];
        var postval = [
          temp_Data.lat,
          temp_Data.lon,
          temp_Data.station.name,
          temp_Data.station.time,
        ];
        sendArray.push(
          <Row>
            <Col span={15}>
              <HomeOutlined /> {temp_Data.station.name}
            </Col>
            <Col span={4}>{this.state.station_list[i][1]} kms</Col>
            <Col span={5}>
              <Button
                size="small"
                key={temp_Data.uid}
                value={postval}
                onClick={this.handleClick}
              >
                Select
                <CheckOutlined />
              </Button>
            </Col>
          </Row>,
          <br></br>,
        );
      }
      return sendArray;
    }
  }

  handleClick = async (e) => {
    var postvalue = e.currentTarget.value;
    postvalue = postvalue.split(",");
    const postdata = {
      lat: postvalue[0],
      lon: postvalue[1],
      name: postvalue[2] + postvalue[3] + postvalue[4],
      timezone: postvalue[5],
      propertyId: this.state.propertyId,
    };

    let data = undefined;
    data = await addStation(postdata);
    console.log(data);
    if (data !== undefined) {
      //   this.setState({
      //     response: true,
      //   });
      message.success("Station ID added successfully");
      setTimeout(() => {
        this.props.onClick("Property", this.state.propertyId, null);
      }, 2000);
    }
  };

  clickHandler = async (e) => {
    e.preventDefault();
    let id = this.propertyId;
    let data = await fetchLatLonData(id);
    let fetchlat = data.data[0];
    let fetchlon = data.data[1];
    this.setState({
      lat: fetchlat,
      lon: fetchlon,
    });
    this.postHandler();
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  postHandler = () => {
    const API_KEY = "30b48b3e1940060cdcdfb029a46979b9f0deb88e";
    const HOTSPOT_STATION_DATA_FETCH_API = "https://api.waqi.info/map/bounds/?";
    var lat = this.state.lat;
    var lon = this.state.lon;
    var l1 = parseFloat(lat) - 0.1;
    var l2 = parseFloat(lon) - 0.1;
    var l3 = parseFloat(lat) + 0.1;
    var l4 = parseFloat(lon) + 0.1;
    const hotspotStationDataFetchUrl =
      HOTSPOT_STATION_DATA_FETCH_API +
      "latlng=" +
      l1 +
      "," +
      l2 +
      "," +
      l3 +
      "," +
      l4 +
      "&token=" +
      API_KEY;
    axios
      .get(hotspotStationDataFetchUrl)
      .then((res) => {
        res = res.data;

        var result = [];
        for (var x = 0; x < res.data.length; x++) {
          var latDist = (PI / 180) * Math.abs(res.data[x].lat - lat);
          var lonDist = (PI / 180) * Math.abs(res.data[x].lon - lon);
          var a =
            Math.sin(latDist / 2) * Math.sin(latDist / 2) +
            Math.cos((PI / 180) * lat) *
              Math.cos((PI / 180) * res.data[x].lat) *
              (Math.sin(lonDist / 2) * Math.sin(lonDist / 2));
          var c = 2 * Math.atan(Math.sqrt(a), Math.sqrt(1 - a));
          var val = parseInt(Math.round(6371 * c));
          if (val <= 10) {
            result.push([res.data[x], val]);
          }
        }
        this.setState({
          station_list: result,
        });
      })
      .catch((err) => {
        console.log("error in fetch", err);
      });
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.postHandler();
  };

  render() {
    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 12,
      },
    };

    const tailLayout = {
      wrapperCol: {
        offset: 8,
        span: 8,
      },
    };

    const { response } = this.state;
    const success = (
      <div>
        <br></br>
        {response ? (
          <div
            align="middle"
            style={{
              backgroundColor: "#009688",
              color: "white",
              padding: "10px",
            }}
          >
            <h3 style={{ color: "white" }}>Succesful Addition of Station</h3>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );

    return (
      <div style={style.container}>
        {/* <h1 style={{ marginLeft: "1%" }}>Add Station</h1> */}
        <PageHeader
          className="site-page-header"
          onBack={() => this.prevComponent()}
          title={<h1>{"Add Station"}</h1>}
        />
        <Form {...layout} name="basic" onSubmit={this.submitHandler}>
          <Row>
            <Col span={9}>
              <Form.Item
                label="Latitude"
                name="latitude"
                rules={[
                  {
                    required: true,
                    message: "Please enter Latitude!",
                  },
                ]}
              >
                <Input
                  style={style.latdata}
                  name="lat"
                  required
                  placeholder="Ex: 12.9121"
                  onChange={this.changeHandler}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Longitude"
                name="longitude"
                rules={[
                  {
                    required: true,
                    message: "Please enter Longitude!",
                  },
                ]}
              >
                <Input
                  style={style.londata}
                  name="lon"
                  required
                  placeholder="Ex: 77.6446"
                  onChange={this.changeHandler}
                />
              </Form.Item>
            </Col>

            <Col span={5} offset={2}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <br></br>
          <h4 align="middle">OR</h4>
          <br></br>
          <h4 align="middle">Fetch Using Customer Details</h4>
          <br></br>
          <div align="middle">
            <Button type="primary" onClick={this.clickHandler}>
              Fetch!
            </Button>
          </div>
        </Form>

        <Row>
          <Col span={24}>{this.renderItems()}</Col>
        </Row>

        <Row>
          <Col span={24}>{success}</Col>
        </Row>
      </div>
    );
  }
}

const style = {
  container: {
    margin: "auto",
    maxWidth: 800,
    border: "3 px solid green",
    paddingTop: 30,
  },
  saveBtn: {
    marginTop: 20,
    marginBottom: 20,
  },
  londata: {
    marginLeft: 8,
  },
  latdata: {
    marginLeft: 8,
  },
};
