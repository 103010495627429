import React, { Component } from "react";
import { Button, PageHeader, Form, Alert, Spin } from "antd";
import { getCustomer, addFilter } from "../api";
import AHUForm from "../components/AHUForm";

const FormItem = Form.Item;

export default class AddAHU extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingData : true,
            response: "",
            properties: [],
            ahuProperty: undefined
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    async componentDidMount () {
        this.customerId = this.props.id;
        try {
            let {data} = (await getCustomer(this.customerId, true)).data;
            var spaces = [];
            var properties = [];
            for(var i=0; i<data.Properties.length; i++){
                var property = data.Properties[i];
                properties.push({
                    id: property.id,
                    name: property.name
                });
                for(var j=0; j<property.Spaces.length; j++){
                    var space = property.Spaces[j];
                    spaces.push(space.name);
                }
            }
            this.setState({
                data: data,
                spaces: spaces,
                properties: properties,
                loadingData: false,
                customerId: this.customerId
            })
        }catch (error){
            console.log(error);
        }
    };

    onSubmit = () => {
        const ahuForm = this.ahuFormRef.props.form;
        ahuForm.validateFields(async(err, ahuDetails) => {
            ahuDetails['customer_id'] = this.state.customerId;
            ahuDetails['property'] = this.state.ahuProperty;
            if(err){
                return;
            }
            try {
                const response = await addFilter({
                    ...ahuDetails
                });
                ahuForm.resetFields();
                this.setState({code: 200, response: 'Filter added'})
            } catch (e) {
                this.setState({code: 400, response: 'Filter not added'})
            }
        })
    }
    saveAHUForm = (formRef) => {
        this.ahuFormRef = formRef;
    };
    
    prevComponent = () => {
        this.props.onClick('Filter', this.customerId, null);
    };

    handleInputChange(event) {
        const target = event.target;
        this.setState({
            ahuProperty: target.value
        })
      }

    render() {
        if(this.state.loadingData){
            return <div className="dashLoaderContainer"><Spin size="large"/></div>;
        }
        let {spaces, customerId, properties} = this.state;
        return(
            <div style={{background: '#FFF'}}>
                <div style={style.container}>
                    <PageHeader
                    className="site-page-header"
                    onBack={() => this.prevComponent()}
                    title={<h1>Add AHU</h1>}                
                    />
                    <AHUForm wrappedComponentRef={this.saveAHUForm} 
                             spaces={spaces}
                             properties={properties}
                             onChange={this.handleInputChange}/>
                    {this.state.response && <Alert
                        message={this.state.response}
                        type={this.state.code == 200
                        ? "success"
                        : "error"}
                        showIcon/>}
                    <Button
                    style={style.saveBtn}
                    type="primary"
                    onClick={this.onSubmit}>
                        Save
                    </Button>
                </div>
            </div>
        );
    }
};

const style = {
    container: {
      margin: "auto",
      maxWidth: 500,
      border: "3 px solid green",
      paddingTop: 30
    },
    saveBtn: {
      marginTop: 20,
      marginBottom: 20
    }
  };