const DATE_RANGES = [
  {
    'txt': 'Today',
    'fromDaysAgo': 0,
    'toDaysAgo': 0
  },
  {
    'txt': 'Last 7 days',
    'fromDaysAgo': 7,
    'toDaysAgo': 0
  },
  {
    'txt': 'Last 30 days',
    'fromDaysAgo': 30,
    'toDaysAgo': 0
  },
  {
    'txt': 'This month',
    'fromDaysAgo': () => { return startDateOfXmonthAgo(0); },
    'toDaysAgo': 0
  }
];

export function GetDateRangeArrForMenuIndex(menuIndex) {
  // console.log('menuIndex: ',menuIndex);
  const from = typeof(DATE_RANGES[menuIndex].fromDaysAgo) === 'function'
                ? DATE_RANGES[menuIndex].fromDaysAgo()
                : calculateDate(DATE_RANGES[menuIndex].fromDaysAgo);
  const to = calculateDate((DATE_RANGES[menuIndex]).toDaysAgo);

  // console.log('Date Range is updated! Menu index: ',menuIndex);
  // console.log('date from: ',from);
  // console.log('date to: ',to);

  return [from.toISOString(), to.toISOString()];
}

function calculateDate(offset) {
  return (new Date(((new Date()).setDate((new Date()).getDate() - offset))));
}

export function GetDateRangesTxt() {
  let dateRanges = [];
  for(var item of DATE_RANGES) {
    dateRanges.push(item.txt);
  }
  
  return dateRanges;
}

function startDateOfXmonthAgo(XmonthAgo) {
  const dateObj = new Date();
  const retVal = new Date(dateObj.getFullYear(), dateObj.getMonth() - XmonthAgo, 1);
  // console.log('retVal: ',retVal);

  return retVal;
}