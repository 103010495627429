import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const base_aqi_percent = {
  51: {
    label: "GOOD",
    percentage: 8,
  },
  101: {
    label: "SATISFACTORY",
    percentage: 16,
  },
  201: {
    label: "MODERATE",
    percentage: 31,
  },
  301: {
    label: "POOR",
    percentage: 46,
  },
  401: {
    label: "SEVERE",
    percentage: 91,
  },
};
let test = undefined;

const ClockGauge = (props) => {
  // Size of the enclosing square
  const sqSize = props.sqSize;
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (props.sqSize - props.strokeWidth) / 2;
  // console.log("props ", props);
  let label = props.item;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;

  function calculate_aqi_percentage(value) {
    for (let key in base_aqi_percent) {
      if (parseInt(value) < parseInt(key)) {
        let base_precentage_obj = base_aqi_percent[key];
        let base_percentage = base_precentage_obj.percentage;
        let return_percentage =
          base_percentage + (parseInt(value) / parseInt(key)) * 10;
        return return_percentage;
        break;
      }
    }
  }
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * props.percentage) / 100;
  let aqi_progress_color_indoor = "good_aqi";
  let aqi_progress_color_outdoor = "good_aqi";
  if (props.percentage > 90) {
    aqi_progress_color_indoor = "bad_aqi";
  } else if (props.percentage > 50) {
    aqi_progress_color_indoor = "moderate_aqi";
  } else if (props.percentage > 30) {
    aqi_progress_color_indoor = "good_aqi";
  } else {
    aqi_progress_color_indoor = "excellent_aqi";
  }
  if (props.v > 45) {
    aqi_progress_color_outdoor = "#cc0033";
  } else if (props.v > 30) {
    aqi_progress_color_outdoor = "#ff9933";
  } else {
    aqi_progress_color_outdoor = "#009966";
  }
  if (label === "AQI") {
    return (
      <div>
        <div className="gauge_outer_ring">
          <svg width={props.sqSize} height={props.sqSize} viewBox={viewBox}>
            <circle
              className="circle-background"
              cx={props.sqSize / 2}
              cy={props.sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
            />
            <circle
              className="circle-progress"
              cx={props.sqSize / 2}
              cy={props.sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
              // Start progress marker at 12 O'Clock
              transform={`rotate(-90 ${props.sqSize / 2} ${props.sqSize / 2})`}
              style={{
                strokeDasharray: dashArray,
                strokeDashoffset: dashOffset,
              }}
            />
            <circle
              className="circle-dashes"
              cx={props.sqSize / 2}
              cy={props.sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
              style={{
                strokeDasharray: "8 2",
              }}
            />
          </svg>
        </div>
        <div className="gauge_inside_progress">
          <Tooltip placement="top" text="Outdoor AQI">
            <CircularProgressbarWithChildren
              value={calculate_aqi_percentage(props.v)}
              //this value is  outer circle's. When the value is zero or null, it doesn't color the outer circle. When the value is undefined,it would color the entire circle
              strokeWidth={8}
              // text does not display value if that value is 0. So, the 0 should be converted to string
              text={
                props.percentage || props.percentage === 0
                  ? props.percentage.toString()
                  : "NA"
              }
              styles={buildStyles({
                pathColor: aqi_progress_color_outdoor,
                trailColor: "transparent",
                pathTransitionDuration: 2,
              })}
            >
              {/*
                      Width here needs to be (100 - 2 * strokeWidth)% 
                      in order to fit exactly inside the outer progressbar.
                      */}
              <div style={{ width: "75%" }}>
                <Tooltip placement="top" text="Indoor AQI">
                  <CircularProgressbar
                    className={"inner_circle " + aqi_progress_color_indoor}
                    pathColor="#000"
                    // this value is inner circle's. When the value is zero, inner circle won't be colored
                    value={calculate_aqi_percentage(props.percentage)}
                    styles={buildStyles({
                      trailColor: "transparent",
                      pathTransitionDuration: 2,
                    })}
                  />
                </Tooltip>
              </div>
            </CircularProgressbarWithChildren>
          </Tooltip>
        </div>
      </div>
    );
  } else if (label === "PRO") {
    return (
      <div>
        <div className="gauge_outer_ring">
          <svg width={props.sqSize} height={props.sqSize} viewBox={viewBox}>
            <circle
              className="circle-background"
              cx={props.sqSize / 2}
              cy={props.sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
            />
            <circle
              className="circle-progress"
              cx={props.sqSize / 2}
              cy={props.sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
              // Start progress marker at 12 O'Clock
              transform={`rotate(-90 ${props.sqSize / 2} ${props.sqSize / 2})`}
              style={{
                strokeDasharray: dashArray,
                strokeDashoffset: dashOffset,
              }}
            />
            <circle
              className="circle-dashes"
              cx={props.sqSize / 2}
              cy={props.sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
              style={{
                strokeDasharray: "8 2",
              }}
            />
          </svg>
        </div>
        <div className="gauge_inside_progress">
          {/* {console.log("percentage ", parseInt(100 - props.percentage - 2))} */}
          <CircularProgressbarWithChildren
            value={100 - props.percentage - 2}
            strokeWidth={8}
            text={
              parseInt(100 - props.percentage)
                ? parseInt(100 - props.percentage) + "%"
                : "NA"
            }
            styles={buildStyles({
              pathColor: "#26a69a",
              trailColor: "transparent",
              pathTransitionDuration: 2,
            })}
          >
            {/*
                      Width here needs to be (100 - 2 * strokeWidth)% 
                      in order to fit exactly inside the outer progressbar.
                      */}
            {/* <div style={{ width: "75%" }}>
                      <CircularProgressbar
                          className="inner_circle"
                          value={40}
                          styles={buildStyles({
                          trailColor: "transparent"
                          })}
                      />
                      </div> */}
          </CircularProgressbarWithChildren>
        </div>
      </div>
    );
  } else if (label === "ENG") {
    return (
      <div>
        <div className="gauge_outer_ring">
          <svg width={props.sqSize} height={props.sqSize} viewBox={viewBox}>
            <circle
              className="circle-background"
              cx={props.sqSize / 2}
              cy={props.sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
            />
            <circle
              className="circle-progress"
              cx={props.sqSize / 2}
              cy={props.sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
              // Start progress marker at 12 O'Clock
              transform={`rotate(-90 ${props.sqSize / 2} ${props.sqSize / 2})`}
              style={{
                strokeDasharray: dashArray,
                strokeDashoffset: dashOffset,
              }}
            />
            <circle
              className="circle-dashes"
              cx={props.sqSize / 2}
              cy={props.sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
              style={{
                strokeDasharray: "8 2",
              }}
            />
          </svg>
        </div>
        <div className="gauge_inside_progress">
          <CircularProgressbarWithChildren
            value={0}
            strokeWidth={8}
            text={"NA"}
            styles={buildStyles({
              pathColor: "#f00",
              trailColor: "transparent",
              pathTransitionDuration: 2,
            })}
          >
            {/*
                      Width here needs to be (100 - 2 * strokeWidth)% 
                      in order to fit exactly inside the outer progressbar.
                      */}
            {/* <div style={{ width: "75%" }}>
                      <CircularProgressbar
                          className="inner_circle"
                          value={40}
                          styles={buildStyles({
                          trailColor: "transparent"
                          })}
                      />
                      </div> */}
          </CircularProgressbarWithChildren>
        </div>
      </div>
    );
  } else if (label === "FL") {
    return (
      <div>
        <div className="gauge_outer_ring">
          <svg width={props.sqSize} height={props.sqSize} viewBox={viewBox}>
            <circle
              className="circle-background"
              cx={props.sqSize / 2}
              cy={props.sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
            />
            <circle
              className="circle-progress"
              cx={props.sqSize / 2}
              cy={props.sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
              // Start progress marker at 12 O'Clock
              transform={`rotate(-90 ${props.sqSize / 2} ${props.sqSize / 2})`}
              style={{
                strokeDasharray: dashArray,
                strokeDashoffset: dashOffset,
              }}
            />
            <circle
              className="circle-dashes"
              cx={props.sqSize / 2}
              cy={props.sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
              style={{
                strokeDasharray: "8 2",
              }}
            />
          </svg>
        </div>
        <div className="gauge_inside_progress">
          <CircularProgressbarWithChildren
            value={89}
            strokeWidth={8}
            text={"89%"}
            styles={buildStyles({
              pathColor: "#26a69a",
              trailColor: "transparent",
              pathTransitionDuration: 2,
            })}
          >
            {/*
                      Width here needs to be (100 - 2 * strokeWidth)% 
                      in order to fit exactly inside the outer progressbar.
                      */}
            {/* <div style={{ width: "75%" }}>
                      <CircularProgressbar
                          className="inner_circle"
                          value={40}
                          styles={buildStyles({
                          trailColor: "transparent"
                          })}
                      />
                      </div> */}
          </CircularProgressbarWithChildren>
        </div>
      </div>
    );
  }
};

export default ClockGauge;
