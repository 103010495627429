import {
  Card,
  Col,
  Row,
  Menu,
  Icon,
  Layout,
  Spin,
  message,
  Popover,
} from "antd";
import { ExportOutlined, BlockOutlined } from "@ant-design/icons";
import claircologo from "../../img/assets/Claircologo.png";
import ultraviolet from "../../img/assets/ultraviolet.png";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { getCustomer, getUvData, getUvDataById } from "../../api";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import {
  removeToken,
  removeCsrId,
  removeCache,
  getSupervisorData,
} from "../../helper";

const SubMenu = Menu.SubMenu;
const { Header, Sider, Content, Footer } = Layout;
const TXP = [21, 32, 31, 28];
//const TXP = ["CTS-13", "CTS-14", "CTS-15", "CTS-16"];

const MEPZ = [19, 20, 22, 23, 25, 26, 27, 29, 33, 34];
// const MEPZ = [
//   "CTS-05",
//   "CTS-08",
//   "CTS-12",
//   "CTS-11",
//   "CTS-04",
//   "CTS-09",
//   "CTS-07",
//   "CTS-17",
//   "CTS-06",
// ];

const TCO = [35, 36, 37, 38];
const IBS = [22, 20, 30, 21];
const IBS_KOCHI = [68, 69, 71, 70, 27, 72, 53, 28, 26, 48];
const IBS_TRI_P1 = [38, 51, 55, 61, 52, 42, 58, 33, 32, 60, 44, 39, 37];
const IBS_TRI_P2 = [63, 23, 64, 24, 56, 43];
const IBS_TRI_CH = [59, 47, 34, 62, 54, 35, 57, 36];
const IBS_TRI_NI = [45, 40, 50, 46, 49, 41];

//const TCO = ["CTS-01", "CTS-02", "CTS-03", "CTS-18"];

const UvDashboard = (props) => {
  const [uv_data, setUv_data] = useState([]);
  const [showPanelData, setShowPanelData] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("dashboard");
  const [panelBoxData, setPanelBoxData] = useState([]);
  const [uv_lights, setUv_lights] = useState([]);
  const [connected, setConnected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [runnningHours, setRunnningHours] = useState(0);
  const [totalRunningHours, setTotalRunningHours] = useState(0);
  const [runningHoursForAllUVLights, setRunnningHoursForAllUVLights] = useState(
    [],
  );
  const [customerName, setCustomerName] = useState("");
  const [username, setUsername] = useState("");
  const [customerId, setcustomerId] = useState("");
  const fetchUvData = async () => {
    try {
      let id;
      if (localStorage.getItem("user_role") === "supervisor") {
        id = props.location.state.id
        setcustomerId(id);
        let { data } = (await getCustomer(id, true)).data;
        // console.log(data);
        setCustomerName(data.name);
        localStorage.setItem("uv_customer", data.name);
      }
      // console.log(localStorage.getItem("CsrId"));
      if (localStorage.getItem("CsrId")) {
        id = localStorage.getItem("CsrId");
        let { data } = (
          await getCustomer(localStorage.getItem("CsrId"), true)
        ).data;
        // console.log(data);
        setCustomerName(data.name);
        setcustomerId(id);
        localStorage.setItem("uv_customer", data.name);
      }

      const { data } = await getUvData(id);
      setUsername(localStorage.getItem("username"));
      // console.log(localStorage.getItem("uv_customer"));
      if (localStorage.getItem("uv_customer") === "CTS MEPZ") {
        const finalData = data.filter((ids) => MEPZ.includes(ids[0]));
        // console.log(finalData);
        setUv_data(finalData);
        setLoading(false);
      } else if (localStorage.getItem("uv_customer") === "CTS TCO") {
        const finalData = data.filter((ids) => TCO.includes(ids[0]));
        // console.log(finalData);
        setUv_data(finalData);
        setLoading(false);
      } else if (localStorage.getItem("uv_customer") === 'CTS TXP'){
        const finalData = data.filter((ids) => TXP.includes(ids[0]));
        //  console.log(finalData);
        setUv_data(finalData);
        setLoading(false);
      } else if (localStorage.getItem("uv_customer") === 'IBS Bangalore'){
        const finalData = data.filter((ids) => IBS.includes(ids[0]));
        //  console.log(finalData);
        setUv_data(finalData);
        setLoading(false);
      } else if (localStorage.getItem("uv_customer") === 'IBS Trivandrum Phase 1'){
        const finalData = data.filter((ids) => IBS_TRI_P1.includes(ids[0]));
        //  console.log(finalData);
        setUv_data(finalData);
        setLoading(false);
      } else if (localStorage.getItem("uv_customer") === 'IBS Trivandrum Phase-2 Campus'){
        const finalData = data.filter((ids) => IBS_TRI_P2.includes(ids[0]));
        //  console.log(finalData);
        setUv_data(finalData);
        setLoading(false);
      } else if (localStorage.getItem("uv_customer") === 'IBS Kochi'){
        const finalData = data.filter((ids) => IBS_KOCHI.includes(ids[0]));
        //  console.log(finalData);
        setUv_data(finalData);
        setLoading(false);
      } else if (localStorage.getItem("uv_customer") === 'IBS Trivandrum Nila'){
        const finalData = data.filter((ids) => IBS_TRI_NI.includes(ids[0]));
        //  console.log(finalData);
        setUv_data(finalData);
        setLoading(false);
      } else {
        const finalData = data.filter((ids) => IBS_TRI_CH.includes(ids[0]));
        //  console.log(finalData);
        setUv_data(finalData);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        message.error(error.response.data.message);
      } else {
        message.error("Cannot fetch data now. Please try again later");
      }
    }
  };
  useEffect(() => {
    setLoading(true);

    fetchUvData();
  }, []);
  const fetchPanelBoxData = async (id) => {
    try {
      const { data } = await getUvDataById(id, customerId);
      // console.log(data);
      setDataLoading(true);
      setPanelBoxData(data);

      data.map((info) => {
        const arr = [
          info[1]
            .replace(/["]+/g, "")
            .replace(/[\[\]']+/g, "")
            .replace(/["']/g, ""),
        ];
        const arr1 = arr[0].replace(/,/g, "").split(" ");
        let count = 0;
        arr1.map((item) => {
          if (
            item === "1" &&
            Math.round(Date.now() / 1000) -
              Math.round(new Date(`${info[6]} ${info[5]}`).getTime() / 1000) <=
              900
          ) {
            ++count;
          }
        });
        // console.log("count " + count);
        setUv_lights(arr1);
        setConnected(count);
        setDataLoading(false);
      });
      data.map((info) => {
        if (info[2]) {
          const arr = [
            info[2]
              .replace(/["]+/g, "")
              .replace(/[\[\]']+/g, "")
              .replace(/["']/g, ""),
          ];
          const arr1 = arr[0].replace(/,/g, "").split(" ");
          // console.log(arr1);
          setRunnningHoursForAllUVLights(arr1);
          setTotalRunningHours(info[3]);
        } else {
          setRunnningHoursForAllUVLights(0);
          setTotalRunningHours(0);
        }
      });
    } catch (error) {
      console.log(error);
      if (error.response) {
        message.error(error.response.data.message);
      } else {
        message.error("Cannot fetch data now. Please try again later");
      }
    }
  };
  const content = (
    <div>
      <p>
        Running hours :{" "}
        {runnningHours == undefined || runnningHours == null
          ? "NA"
          : runnningHours + " hrs"}
      </p>
      <p>
        Remaining running hours :{" "}
        {runnningHours !== undefined
          ? totalRunningHours - runnningHours + " hrs"
          : "NA"}
      </p>
    </div>
  );
  const handleReferer = () => {
    var data = getSupervisorData();
    props.history.push("/customer/admin", { data: data });
  };

  if (loading) {
    return (
      <Spin
        size="large"
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  } else {
    return (
      <Layout>
        <Sider style={{ height: "100vh" }}>
          <div>
            <div>
              <img
                src={claircologo}
                style={{
                  width: "100%",
                  zIndex: 1,
                  background: "white",
                  margin: "20px 0",
                }}
              />
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#FFF",
              padding: "10px",
              textAlign: "center",
              borderRadius: "20px",
              fontSize: "14px",
              color: "#00695c",
              width: "170px",
              margin: "0 auto 20px auto",
            }}
          >
            {customerName}
          </div>
          <Menu mode="inline" defaultSelectedKeys={["dashboard"]}>
            <Menu.Item
              key="dashboard"
              onClick={(e) => {
                setSelectedMenu(e.keyPath[0]);
                setShowPanelData(false);
                fetchUvData();
              }}
            >
              <Icon type="desktop" />
              <span>Dashboard</span>
            </Menu.Item>
            <SubMenu
              key="sub1"
              title={
                <span>
                  <Icon type="database" />
                  <span>AHUs</span>
                </span>
              }
            >
              {uv_data.map((info) => (
                <Menu.Item
                  key={info[0]}
                  onClick={(e) => {
                    // console.log(e.keyPath[0]);
                    setShowPanelData(true);
                    fetchPanelBoxData(e.keyPath[0]);
                  }}
                >
                  {info[3] !== null ? info[3] : info[0] + " - " + info[1]}
                </Menu.Item>
              ))}
            </SubMenu>
          </Menu>
        </Sider>

        <Content>
          <Header
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#FFF",
              alignItems: "center",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <div style={{ fontSize: "22px" }}>
              Welcome <b>{username}</b>{" "}
            </div>
            <Menu mode="horizontal" style={{ backgroundColor: "#FFF" }}>
              <SubMenu
                title={
                  <div>
                    <Icon type="menu" style={{ fontSize: "26px" }} />
                  </div>
                }
              >
                {localStorage.getItem("user_role") === "supervisor" && (
                  <Menu.Item onClick={handleReferer}>
                    <BlockOutlined
                      style={{
                        fontSize: "15px",
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                    />

                    <span>Change Dashboard</span>
                  </Menu.Item>
                )}
                <Menu.Item
                  onClick={() => {
                    removeToken();
                    removeCsrId();
                    removeCache();
                    props.history.push("/");
                  }}
                >
                  <ExportOutlined
                    style={{
                      fontSize: "15px",
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                  />
                  <span>Logout</span>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Header>

          {selectedMenu === "dashboard" && showPanelData == false && (
            <div style={{ background: "#ECECEC", padding: "30px" }}>
              <Row gutter={[16, 16]}>
                {uv_data.map((info) => (
                  <Col span={8}>
                    <Card title={info[0]} bordered={false}>
                      <Title
                        style={{ display: "flex", justifyContent: "center" }}
                        level={2}
                      >
                        {/* {info[1]}{" "} */}
                        {info[3] !== null ? info[3] : info[0] + " - " + info[1]}
                      </Title>
                      <Title
                        level={4}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          color: "gray",
                          fontSize: "20px",
                        }}
                      >
                        {Math.round(Date.now() / 1000) -
                          Math.round(new Date(`${info[2]}`).getTime() / 1000) >
                        900
                          ? "System Status : OFF"
                          : "System Status : ON"}
                        <br />
                      </Title>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          )}

          {showPanelData && panelBoxData.length > 0 && (
            <div style={{ padding: "30px" }}>
              <Row gutter={16}>
                {panelBoxData.map((info) => (
                  <Col span={24}>
                    <Card
                      bordered={false}
                      style={{ width: "100%" }}
                      title={
                        Math.round(Date.now() / 1000) -
                          Math.round(
                            new Date(`${info[6]} ${info[5]}`).getTime() / 1000,
                          ) >
                        900
                          ? "System Status : OFF"
                          : "System Status : ON"
                      }
                      actions={[
                        // "Updated  " + moment(info[6] + " " + info[5]).fromNow(),
                        "Updated 15 minutes ago",
                      ]}
                    >
                      <Title
                        style={{ display: "flex", justifyContent: "center" }}
                        level={2}
                      >
                        {info[4] !== null ? info[4] : "NA"}
                      </Title>
                      <Title
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          color: "gray",
                          fontWeight: "400",
                          fontSize: "16px",
                          marginBottom: "15px",
                        }}
                      >
                        <span
                          style={{
                            margin: "0 10px",
                            paddingRight: "10px",
                          }}
                        >
                          <b> UV Lamp Status : </b>
                        </span>
                        TOTAL :{" "}
                        <span
                          style={{
                            borderRight: "2px solid gray",
                            margin: "0 10px",
                            paddingRight: "10px",
                          }}
                        >
                          <b> {uv_lights.length} </b>
                        </span>
                        ON :
                        <span
                          style={{
                            borderRight: "2px solid gray",
                            margin: "0 10px",
                            paddingRight: "10px",
                          }}
                        >
                          <b>{connected}</b>
                        </span>
                        OFF :{" "}
                        <span style={{ paddingLeft: "10px" }}>
                          {" "}
                          <b>{uv_lights.length - connected} </b>{" "}
                        </span>
                      </Title>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginBottom: "30px",
                        }}
                      >
                        {uv_lights.map((light, i) => (
                          <Popover
                            content={content}
                            title={
                              "Updated  " +
                              moment(info[6] + " " + info[5]).fromNow()
                            }
                            trigger="hover"
                            placement="right"
                          >
                            <div
                              key={i + 1}
                              style={{
                                width: "100px",
                                height: "100px",
                                backgroundColor:
                                  Math.round(Date.now() / 1000) -
                                    Math.round(
                                      new Date(
                                        `${info[6]} ${info[5]}`,
                                      ).getTime() / 1000,
                                    ) <=
                                    900 && light === "1"
                                    ? "green"
                                    : "red",
                                // display: "flex",
                                color: "white",
                                margin: "15px 15px  15px 15px ",
                                fontWeight: "bold",
                                cursor: "pointer",
                                fontSize: "16px",
                              }}
                              onMouseEnter={(e) =>
                                setRunnningHours(runningHoursForAllUVLights[i])
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "15px",
                                  border: "3px solid black",
                                  height: "100%",
                                }}
                              >
                                <div>
                                  <img
                                    src={ultraviolet}
                                    width="90%"
                                    style={{
                                      display: "block",
                                      margin: "0 auto",
                                    }}
                                  />
                                </div>
                                <div> {i + 1}</div>
                              </div>
                            </div>
                          </Popover>
                        ))}
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "20px",
                          }}
                        >
                          <div
                            style={{
                              width: "100px",
                              height: "30px",
                              backgroundColor: "green",
                              margin: "5px 5px  0 0 ",
                              textAlign: "center",
                              fontWeight: "bold",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "white",
                              borderRadius: "25px",
                            }}
                          >
                            UVC LAMP
                          </div>
                          <div
                            style={{
                              marginTop: "5px",
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "gray",
                              fontSize: "18px",
                            }}
                          >
                            <b> ON </b>
                          </div>
                        </div>
                        <span style={{ borderRight: "2px solid gray" }}></span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "20px",
                          }}
                        >
                          <div
                            style={{
                              width: "100px",
                              height: "30px",
                              backgroundColor: "red",
                              margin: "5px 5px  0 0 ",
                              textAlign: "center",
                              fontWeight: "bold",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "white",
                              borderRadius: "25px",
                            }}
                          >
                            UVC LAMP{" "}
                          </div>
                          <div
                            style={{
                              marginTop: "5px",
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "gray",
                              fontSize: "18px",
                            }}
                          >
                            <b> OFF</b>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </Content>
      </Layout>
    );
  }
};

export default UvDashboard;