import React, { Component } from "react";
import {
  Chart,
  Guide
} from 'bizcharts';
const {Html} = Guide;

const HumidityBoxOps = (props)=>{ 
    const {data,label} = props
    return (
        <Chart
          forceFit
          height={30}
          padding={[0,0,0,0]}
          data={data}>
          <Guide>
            <Html
              position={['30%', '80%']}
              html={() => (`<div style="width: 120px;text-align: right;font-size: 8px!important;"><p style="font-size: 0.9355em; color: rgba(0,0,0,0.43);margin: 0;">${label}</p><p style="font-size: 1.1em;color: rgba(0,0,0,0.85);margin: 0;">   ${data[0].value?data[0].value:"NA"} ${data[0].value?"%":""}</p></div>`)}/>
          </Guide>
        </Chart>
    )
  }

export default HumidityBoxOps;