import React, { Component } from 'react';
import moment from "moment";
import { Table, Button, Form, Input, Switch, message, Modal, Alert, DatePicker, Select } from 'antd';
import SpaceTable from './SpaceTable';
import _, { isNull } from 'lodash';
import PropertyTable from './PropertyTable';
import {
  DownloadOutlined,
} from "@ant-design/icons";
import {
  getAllDevices,
  getGsmDevice,
  searchByProperty,
  searchBySpace,
  getAllCustomer,
  getAllDisconnectedDevices,
  getDptDataFile
} from '../../api';
import DeviceList from './DeviceList';
import Customers from './Customers';
import GSM from './GSM';
import DisconnectedDevices from './DisconnectedDevices';
const { Option } = Select;

export default class Devices extends Component {
  constructor(props) {
    super(props);
    // this.eventSource = new EventSource("http://localhost:3001/v1");
    this.state = {
      logs: false,
      data_switch: false,
      value: '',
      devices: [],
      gsmDevice: [],
      isLoading: true,
      isClicked: false,
      gateway_switch: false,
      customer_input: '',
      customers: [],
      spaces: [],
      properties: [],
      selected_val: '',
      view_disconnected_devices: false,
      disconnected_devices: [],
      deviceID: '',
      visible: false,
      startValue: null,
      endValue: null,
      endOpen: false,
    };
  }

  // async componentDidMount() {
  //   this.eventSource.addEventListener("stateUpdate", (e) =>
  //     this.updateDeviceConnection(JSON.parse(e.data))
  //   );
  //   this.eventSource.addEventListener("alertLog", (e) =>
  //     this.updateDeviceLog(JSON.parse(e.data))
  //   );

  // }

  updateDeviceConnection() {}

  updateDeviceLog() {}

  handleSubmit = async (e) => {
    e.preventDefault();

    const data = await getAllDevices();
    const device_id = this.state.value;

    if (
      this.state.value.startsWith('30') ||
      this.state.value.startsWith('40')
    ) {
      try {
        const gsm_data = await getGsmDevice(device_id);

        this.setState({
          gsmDevice: [
            gsm_data.data.data.pm25,
            gsm_data.data.data.pm10,
            gsm_data.data.data.co,
            gsm_data.data.data.co2,
            gsm_data.data.data.voc,
            gsm_data.data.data.space,
            gsm_data.data.data.property,
            typeof gsm_data.data.data.time === 'object'
              ? gsm_data.data.data.time.dateTime
              : gsm_data.data.data.time,
          ],
          deviceID: gsm_data.data.data.id,
        });
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
          message.error(error.response.data.message);
        } else {
          console.log(error);
          message.error('Cannot fetch data');
        }
      }
    }

    const response_data = await data.data.response_data;
    const mqt = await data.data.d;

    for (let d = 0; d < response_data.length; d++) {
      for (let i = 0; i < mqt.length; i++) {
        response_data[i] = Object.assign(response_data[i], mqt[i]);
      }
    }

    this.setState({
      devices: response_data,
      isLoading: false,
      isClicked: true,
      customers: [],
    });
  };

  handleToggle = (checked, e) => {
    this.setState({
      gateway_switch: checked,
      spaces: [],
      properties: [],
      disconnected_devices: [],
    });
  };

  // code below is for finding data concerning device id barring gateway id

  handleChange = (event) => {
    this.setState({ value: event.target.value.toUpperCase() });
  };

  // code below is for updating the device input value based on clicked device in space table

  handleInputVal = async (inp) => {
    this.setState({ value: inp });
    const gsm_data = await getGsmDevice(inp);
    this.setState({
      gsmDevice: [
        gsm_data.data.data.pm25,
        gsm_data.data.data.pm10,
        gsm_data.data.data.co,
        gsm_data.data.data.co2,
        gsm_data.data.data.voc,
        typeof gsm_data.data.data.time === 'object'
          ? gsm_data.data.data.time.dateTime
          : gsm_data.data.data.time,
      ],
    });
  };

  handleDisconnectedDeviceInput = async (inp) => {
    this.setState({ value: inp.trim() });
    const gsm_data = await getGsmDevice(inp);
    gsm_data.data.data.splice(5, 2);
    this.setState({
      gsmDevice: gsm_data.data.data,
    });
  };

  //code below is for select option such as property or space

  handleQuery = async (value) => {
    this.setState({ selected_val: value });

    if (value === 'space') {
      const val = this.state.customer_input.trim();

      const result = await searchBySpace(val);
      this.setState({ spaces: result.data, properties: [] });
    }
    if (value === 'property') {
      const val = this.state.customer_input.trim();

      const result = await searchByProperty(val);
      this.setState({
        spaces: [],
        properties: result.data,
        value: '',
      });
    }
  };

  //code below is for fetching particular space data when the user has access to properties table
  
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handleClickedSpace = async (val) => {
    this.setState({ customer_input: '' });
    this.setState({ customer_input: val });
  };

  //code below is for fetching particular property data when the user has access to space table

  handleClickedProperty = async (val) => {
    this.setState({ customer_input: '' });
    this.setState({ customer_input: val });
  };

  //code below is for fetching data based on the selected value

  handleSpaceorPropertyChange = async (val) => {
    const selected = this.state.selected_val;
    this.setState({ value: '' });
    if (this.state.customer_input.length >= 2) {
      if (selected === 'property') {
        const val = this.state.customer_input
          .split(' ')
          .filter((s) => s)
          .join(' ');

        const result = await searchByProperty(val);
        this.setState({ spaces: [], properties: result.data });
      } else {
        const val = this.state.customer_input
          .split(' ')
          .filter((s) => s)
          .join(' ');
        const result = await searchBySpace(val);
        this.setState({ spaces: result.data, properties: [] });
      }
    }
  };

  getDisconnectedDevices = async () => {
    let { data } = await getAllDisconnectedDevices();

    let sum = 0;
    data.map((device) => (sum += device.devices_disconnected));
    // console.log("Total disconnected devices  " + sum)
    this.setState({
      disconnected_devices: data,
      spaces: [],
      properties: [],
      devices: [],
    });
  };

  handleReset = () => {
    this.setState({
      value: '',
      devices: [],
      gsmDevice: [],
      isLoading: true,
      isClicked: false,
      gateway_switch: false,
      customer_input: '',
      customers: [],
      spaces: [],
      properties: [],
      view_disconnected_devices: false,
      disconnected_devices: [],
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleDownloadClick = async (e) => {
    const deepData = true;
    const { startValue, endValue, reportType } = this.state;
    if (startValue === null || endValue === null) {
      this.setState({
        showAlert: true,
      });
    } else {
      this.setState({ confirmLoading: true, showAlert: false });
      try {
          await getDptDataFile(
            this.state.value,
            startValue.utc().format("YYYY-MM-DD"),
            endValue.utc().format("YYYY-MM-DD"),
          );
      } catch (err) {
        console.log(err);
      }
      this.setState({ confirmLoading: false, visible: false });
    }
  };

  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  render() {
    let {visible, confirmLoading, startValue, endValue, endOpen} = this.state;
    return (
      <div>
        <Form
          layout="inline"
          className="device-data-selection-form"
          onSubmit={this.handleSubmit}
          style={{ marginBottom: 16 }}
        >
          <Form.Item>
            <Input
              name="devId"
              onChange={this.handleChange}
              value={this.state.value}
              placeholder="Device ID"
            />
          </Form.Item>
          <Form.Item>
            <Select
              style={{ width: 120 }}
              onChange={this.handleQuery}
              defaultValue="space"
            >
              <Option value="space">Space</Option>
              <Option value="property">Property</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Input
              name="devId"
              onChange={(e) => {
                this.setState({
                  customer_input: e.target.value,
                  gateway_switch: false,
                });
                this.handleSpaceorPropertyChange(e.target.value.trimLeft());
              }}
              onPaste={(event) => {
                this.setState({
                  customer_input: event.clipboardData.getData('text/plain'),
                  gateway_switch: false,
                });
                this.handleSpaceorPropertyChange(event.target.value.trim());
              }}
              placeholder="search space or property"
              value={this.state.customer_input}
            />
          </Form.Item>
          {/* <Form.Item label="Get Logs">
            <Switch
              onClick={(checked, e) => this.handleToggle(checked, "logs")}
            />
          </Form.Item>
          <Form.Item label="Get Data">
            <Switch
              onClick={(checked, e) =>
                this.handleToggle(checked, "data_switch")
              }
            />
          </Form.Item> */}
          <Form.Item label="Get Gateway">
            <Switch
              onChange={(checked, e) => {
                this.handleToggle(checked, 'gateway_switch');
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Fetch Data
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="button"
              style={{ backgroundColor: '#006d86' }}
              onClick={() => {
                this.setState({
                  view_disconnected_devices: true,
                });
                this.getDisconnectedDevices();
              }}
            >
              Disconnected Devices{' '}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="secondary"
              htmlType="button"
              onClick={this.handleReset}
            >
              Reset{' '}
            </Button>
          </Form.Item>
          <Form.Item>
          <Button
                type='secondary'
                htmlType='button'
                onClick={this.showModal}
              >
                <DownloadOutlined style={{ fontSize: "21px" }} />
                Download DPT Report
          </Button>
          </Form.Item>

        </Form>
          <Modal
            title="Download DPT Data Report"
            visible={visible}
            width={480}
            onOk={this.handleDownloadClick}
            confirmLoading={confirmLoading}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button
                icon="download"
                key="submit"
                type="primary"
                loading={confirmLoading}
                onClick={this.handleDownloadClick}
              >
                Download
              </Button>,
            ]}
          >
            <div>
              {this.state.showAlert && (
                <Alert
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  message={this.state.error}
                  type="error"
                  showIcon
                />
              )}
              <DatePicker
                disabledDate={this.disabledDate}
                // defaultValue={moment(lastDate, "DD-MM-YYYY")}
                format="YYYY-MM-DD"
                value={startValue}
                placeholder="Start Date"
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
              />
              <DatePicker
                className="endDatePicker"
                disabledDate={this.disabledDate}
                format="YYYY-MM-DD"
                value={endValue}
                placeholder="End Date"
                onChange={this.onEndChange}
                open={endOpen}
                onOpenChange={this.handleEndOpenChange}
              />
            </div>
            <div
              style={{
                marginTop: 20,                
              }}>
                <div>
                <Input
                    style={{ width: '50%' }}
                    name="inputDevId"
                    onChange={this.handleChange}
                    value={this.state.value}
                    placeholder="e.g., DPT00001"
                  />
                </div>
            </div>
          </Modal>
        {!this.state.isClicked ? (
          ''
        ) : this.state.isLoading ? (
          'Loading...'
        ) : this.state.value.startsWith('30') ||
          this.state.value.startsWith('40') ? (
          <GSM gsm_data={this.state.gsmDevice} id={this.state.deviceID} />
        ) : this.state.gateway_switch ? (
          this.state.devices.length && (
            <DeviceList
              devices={this.state.devices}
              inputVal={this.state.value}
              gateway_switch={this.state.gateway_switch}
            />
          )
        ) : (
          <div>turn on filters</div>
        )}
        {/* {this.state.spaces.length > 0 && (
          <Spaces
            spaces={this.state.spaces}
            handleInputVal={this.handleInputVal}
            handleClickedProperty={this.handleClickedProperty}
          />
        )} */}
        {this.state.spaces.length > 0 && (
          <SpaceTable
            spaces={this.state.spaces}
            handleClickedProperty={this.handleClickedProperty}
            handleInputVal={this.handleInputVal}
          />
        )}
        {/* {this.state.properties.length > 0 && (
          <Property
            properties={this.state.properties}
            handleClickedSpace={this.handleClickedSpace}
          />
        )} */}
        {this.state.properties.length > 0 && (
          <PropertyTable
            properties={this.state.properties}
            handleClickedSpace={this.handleClickedSpace}
          />
        )}
        {this.state.view_disconnected_devices && (
          <DisconnectedDevices
            disconnected_devices={this.state.disconnected_devices}
            handleDisconnectedDeviceInput={this.handleDisconnectedDeviceInput}
          />
        )}
      </div>
    );
  }
}
