import React, { Component } from "react";
import {
  Button,
  Modal,
  Spin,
  PageHeader,
  List,
  Switch,
  Col,
  Row,
  Alert,
  Icon,
  Input,
  Tooltip,
  Table,
  Form,
  Select,
  message,
  Popconfirm,
} from "antd";
import {
  getCustomer,
  updateUser,
  updateCustomerSLA,
  addCustomer,
  deleteUser,
  getAllUsers,
  removeUserAssociationWithCustoner,
  addUserToCustomerWithRole,
  editCustomer,
  deleteProperty,
} from "../api";

import SLAEditForm from "../components/SLAEditForm";
import _ from "lodash";
const aqPollutants = ["PM2.5", "PM10", "CO2", "VOC"];

const { Option } = Select;
let id = 0;

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      visible: false,
      uv_protected: false,
      sla_response: null,
      visible2: false,
      userList: [],
      user: { name: "", phone: "", email: "", role: "" },
      alert: false,
      showUpdate: false,
      user_id: null,
      allUsers: [],
      showAddToCustomerBtn: false,
      isCustomerEditModalVisible: false,

      keys: [0], // this is for dynamic edit customer form
    };
  }

  viewAdminDashboard = async (id) => {
    try {
      const { data } = (await getCustomer(this.customerId, true)).data;
      // console.log(data);
      const users = (await getAllUsers()).data;
      const arr = [];
      data.Users.length !== 0 &&
        data.Users.map((user, i) => {
          arr.push({
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone: user.phone_number,
            role: user.Roles.length !== 0 ? user.Roles[0].name : "NA",
            id: user.CustomerUser.user_id,
          });
        });
      this.setState({ userList: arr, alert: false, allUsers: users.data });
      const aqi_ranges = {};
      aqi_ranges["pm25_range"] = data.pm25_range;
      aqi_ranges["pm10_range"] = data.pm10_range;
      aqi_ranges["co2_range"] = data.co2_range;
      aqi_ranges["voc_range"] = data.voc_range;
      var aqi_value_obj = [
        { key: "pm25_range", min: "", max: "", label: "PM2.5" },
        { key: "pm10_range", min: "", max: "", label: "PM10" },
        { key: "co2_range", min: "", max: "", label: "CO2" },
        { key: "voc_range", min: "", max: "", label: "VOC" },
      ];
      for (var label in aqi_ranges) {
        var label_data = data[label].split(",");
        var select_data = _.filter(aqi_value_obj, (x) => x.key === label);
        select_data[0].min = label_data[0];
        select_data[0].max = label_data[1];
      }
      const dashboard_switch = data.Users[0] && data.Users[0].dashboard_active;
      const auto_email_switch = data.Users[0] && data.Users[0].Auto_email;
      const outdoor_switch = data.Users[0] && data.Users[0].OutdoorDataShow;
      const keys = data.metadata ? data.metadata : [0];
      // console.log(keys);
      this.setState({
        data,
        dashboard_switch,
        auto_email_switch,
        outdoor_switch,
        aqi_ranges: aqi_value_obj,
        // keys: data.metadata,
        keys,
      });
      // console.log(data);
    } catch (e) {
      console.log(e);
    }
  };

  async componentDidMount() {
    this.customerId = this.props.id;
    this.viewAdminDashboard(this.customerId);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.alert !== this.state.alert) {
      await getCustomer(this.customerId, false).then((response) => {
        const arr = [];
        response.data.data.Users.map((user, i) => {
          arr.push({
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone: user.phone_number,
            role: user.Roles.length !== 0 ? user.Roles[0].name : "NA",
            id: user.CustomerUser.user_id,
          });
        });
        this.setState({
          userList: arr,
          data: response.data.data,
        });
        this.props.form.resetFields();
      });
      const users = (await getAllUsers()).data;
      this.viewAdminDashboard(this.props.id);
      this.setState({ allUsers: users.data, alert: false });
    }
  }
  prevComponent = () => {
    this.props.onClick("Home", null, null);
  };

  addProperty = () => {
    this.props.onClick("AddProperty", this.customerId);
  };

  addAHU = () => {
    this.props.onClick("Filter", this.customerId);
  };
  addPurifier = () => {
    this.props.onClick("Purifier", this.customerId);
  };
  viewProperty = (id) => {
    this.props.onClick("Property", id, this.customerId);
  };

  updateCustomer = () => {
    this.props.history.push("/admin/updatecustomer", {
      id: this.customerId,
    });
  };
  viewDashboard = () => {
    this.props.history.push("/admin/dashboard/", {
      id: this.customerId,
    });
    // const id = this.customerId;
    // this.props.onClick("Dashboard", id);
  };
  saveCustomerFormRef = (formRef) => {
    this.customerFormRef = formRef;
  };
  saveUserFormRef = (formRef) => {
    this.userFormRef = formRef;
  };
  showModal = (label) => {
    label == "warning"
      ? Modal.warning({
          title: "Warning",
          content:
            "Please change these options wisely. As they take affect immediately.",
        })
      : this.setState({
          visible: true,
        });
  };

  showUserModal = () => {
    this.setState({
      visible2: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
      visible2: false,
      showUpdate: false,
    });
    this.props.form.resetFields();
  };
  handleCancel = (e) => {
    this.setState({
      visible: false,
      visible2: false,
      showUpdate: false,
    });
    this.props.form.resetFields();
  };
  switchChange = (checked, e) => {
    try {
      const { response } = updateUser(this.customerId, e, checked);
    } catch (e) {
      console.log(e);
    }
  };
  editSLAForm = (formRef) => {
    this.editSLARef = formRef;
  };
  updateSLA = () => {
    const editSLAForm = this.editSLARef.props.form;

    editSLAForm.validateFields(async (err, formdetails) => {
      formdetails["id"] = this.props.id;
      if (err) {
        return err;
      }
      try {
        const response = await updateCustomerSLA({
          ...formdetails,
        });
        editSLAForm.resetFields();

        this.setState({
          code: 200,
          // sla_response: "SLA updated",
          alert: true,
        });
        message.success("SLA updated");
      } catch (e) {
        message.error("Cannot update SLA");
        this.setState({
          code: 400,
          // sla_response: "Error updated",
        });
      }
    });
  };

  getUserDetail = (record) => {
    this.props.form.setFieldsValue({
      first_name: record.first_name,
      last_name: record.last_name,
      email: record.email,
      phone: record.phone,
      role: record.role,
    });

    this.setState({ showUpdate: true, user_id: record.id });
  };

  updateUserDetail = async () => {
    const userDetails = this.props.form.getFieldsValue();
    const payload = {
      customer_id: this.customerId,
      user_id: this.state.user_id,
      update_data: {
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        phone_number: userDetails.phone,
        role: userDetails.role,
      },
    };
    await addUserToCustomerWithRole(payload)
      .then((response) => {
        this.setState({ alert: true, showUpdate: false, user_id: null });
        message.success("User is successfully updated.");
      })
      .catch((err) => {
        console.log(err);
        if (!err.response) {
          message.error("server error");
        } else if (err.response.status == 401 || err.response.status == 403) {
          message.error(err.response.data.message);
        } else message.error("Cannot update User. Please try again");
      });
  };

  deleteUser = async (record) => {
    await deleteUser(record.id)
      .then((response) => {
        this.setState({ alert: true });
        message.success(`${record.first_name} ${record.last_name} is deleted`);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          message.error(error.response.data.message);
        } else message.error("Cannot delete User now. Please try again");
      });
  };

  selectUser = (name) => {
    // console.log(name)
    const userss = this.state.allUsers.filter(
      (user) => user.first_name === name || user.last_name === name,
    );
    this.props.form.setFieldsValue({
      first_name: userss[0].first_name,
      last_name: userss[0].last_name,
      email: userss[0].email ? userss[0].email : "",
      phone: userss[0].phone_number,
      role: userss[0].Roles.length !== 0 ? userss[0].Roles[0].name : "",
    });
    this.setState({ user_id: userss[0].id, showUpdate: true });
  };

  addUserToCustomer = async () => {
    const userDetails = this.props.form.getFieldsValue();
    const payload = {
      customer_id: this.customerId,
      user_id: this.state.user_id,
      update_data: {
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        phone_number: userDetails.phone,
        role: userDetails.role,
      },
    };
    await addUserToCustomerWithRole(payload)
      .then(() => {
        this.setState({ alert: true, user_id: null });
      })
      .catch((err) => {
        console.log(err);
        if (!err.response) {
          message.error("server error");
        } else if (err.response.status == 401 || err.response.status == 403) {
          message.error(err.response.data.message);
        } else {
          message.error(
            "Cannot add User to the customer now. Please try again later",
          );
        }
      });
  };

  removeUserCustomerAssociation = async (record) => {
    const payload = {
      user_id: record.id,
      customer_id: this.customerId,
    };
    await removeUserAssociationWithCustoner(payload)
      .then(() => {
        this.setState({ alert: true });
        message.success("Assigned role is successfuly removed.");
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          message.error(err.response.data.message);
        } else
          message.error(
            "Cannot remove user association now. Please try again later",
          );
      });
  };

  editCustomerForm = () => {
    this.props.form.validateFields(
      ["customer_name", "customer_id", "keys"],
      async (err, values) => {
        let key_values = values.keys
          ? values.keys.filter((key) => key.key && key.value)
          : null;
        if (key_values && key_values.length == 0) key_values = null;
        if (!err) {
          const payload = {
            id: values.customer_id,
            name: values.customer_name,
            // metadata: values.keys ? values.keys : null,
            metadata: key_values,
          };
          console.log(payload);
          console.log("details for edit ");
          console.log(values);
          await editCustomer(payload)
            .then(() => {
              this.setState({ alert: true, isCustomerEditModalVisible: false });
              message.success("Customer is successfully updated");
            })
            .catch((e) => {
              console.log(e);
              message.error(
                "cannot update customer name now. Please try again later",
              );
            });
        }
      },
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(
      ["first_name", "last_name", "role", "email", "phone"],
      async (err, values) => {
        if (!err) {
          const payload = {
            customer_id: this.customerId,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone_number: values.phone,
            designation: values.role,
          };

          await addCustomer(payload)
            .then(() => {
              this.setState({ alert: true });
              message.success("User is successfully added");
            })
            .catch((e) => {
              console.log(e);
              message.warning(
                "Could not add user. Please try with different inputs. Email should be unique",
              );
            });
        }
      },
    );
  };

  deletePropertyData = async (id) => {
    try {
      const { Spaces, OutsideDevice } = this.state.data.Properties.find(
        (property) => property.id == id,
      );
      if (Spaces.length !== 0 || OutsideDevice !== null) {
        message.info("Devices exist at this property");
      } else {
        const { data, status, statusText } = await deleteProperty(id);
        if (status == 200) {
          message.success(data.message);
          // console.log(data);
          this.setState({
            alert: true,
          });
        }
      }
    } catch (e) {
      if (!e.response) {
        message.error("server error");
      } else if (e.response.status == 401) {
        message.error(e.response.data.message);
      } else if (e.response.status == 403) {
        message.error(e.response.data.message);
      } else {
        message.error("Property deletion failed");
      }
    }
  };

  remove = (k) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter((key) => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  handleAdd = () => {
    const { keys } = this.state;
    const nextKeys = keys.concat({ key: "", value: "" });
    this.setState({ keys: nextKeys });
  };

  handleRemove = (k) => {
    const { keys } = this.state;
    this.setState({ keys: keys.filter((key, index) => index !== k) });
  };

  render() {
    const {
      data,
      dashboard_switch,
      auto_email_switch,
      outdoor_switch,
      visible,
      aqi_ranges,
      keys,
    } = this.state;
    if (!data) {
      return (
        <div style={style.loader}>
          <PageHeader
            className="site-page-header"
            onBack={() => this.prevComponent()}
            title={<h1>Loading...</h1>}
            subTitle={<h4>(please wait while the data is being loaded)</h4>}
          />
          <Spin size="large" tip="Loading customer data.." />
        </div>
      );
    }
    if (!aqi_ranges) {
      return <Spin size="small"></Spin>;
    }
    const aqi_range_card = aqPollutants.map((item, i) => {
      var select_range = _.filter(aqi_ranges, (x) => x.label === item);
      var min_value = select_range[0].min;
      var max_value = select_range[0].max;
      return (
        <div>
          <div style={{ margin: "10px", textAlign: "center" }}>
            <span style={{ marginRight: "15px" }}>{item}</span>
            <Input
              id={select_range[0].key + "_min"}
              type="number"
              addonBefore="min"
              defaultValue={min_value}
              style={{ width: 150, marginRight: "5px" }}
            />
            <Input
              id={select_range[0].key + "_max"}
              type="number"
              addonBefore="max"
              defaultValue={max_value}
              style={{ width: 150, marginLeft: "5px" }}
            />
          </div>
        </div>
      );
    });
    const { getFieldDecorator, getFieldValue } = this.props.form;

    const columns = [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "First Name",
        dataIndex: "first_name",
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
      },
      {
        title: "Phone",
        dataIndex: "phone",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Role",
        dataIndex: "role",
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (actions, record) => {
          return (
            <div>
              <a
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  this.getUserDetail(record);
                }}
              >
                <Tooltip placement="top" title="Update User">
                  <Icon type="edit" style={{ color: "green" }} />
                </Tooltip>
              </a>
              <a
                style={{ marginLeft: "10px" }}
                onClick={() => this.deleteUser(record)}
              >
                <Tooltip placement="top" title="Delete User">
                  <Icon type="delete" style={{ color: "red" }} />
                </Tooltip>
              </a>
              <a
                style={{ marginLeft: "10px" }}
                onClick={() => this.removeUserCustomerAssociation(record)}
              >
                <Tooltip placement="top" title="Remove Association">
                  <Icon type="user-delete" style={{ color: "maroon" }} />
                </Tooltip>
              </a>
            </div>
          );
        },
      },
    ];

    // getFieldDecorator("keys", { initialValue: [] });
    // const keys = this.props.form.getFieldValue("keys");
    // console.log(keys);

    // const dynamicFormItemsForEditCustomer = keys.map((k, index) => (
    //   <Form.Item
    //     {...(index === 0
    //       ? {
    //           labelCol: {
    //             xs: { span: 24 },
    //             sm: { span: 4 },
    //           },
    //           wrapperCol: {
    //             xs: { span: 24 },
    //             sm: { span: 20 },
    //           },
    //         }
    //       : {
    //           wrapperCol: {
    //             xs: { span: 24, offset: 0 },
    //             sm: { span: 20, offset: 4 },
    //           },
    //         })}
    //     label={index === 0 ? "Passengers" : ""}
    //     required={false}
    //     key={k}
    //   >
    //     {getFieldDecorator(`names[${k}]`, {
    //       validateTrigger: ["onChange", "onBlur"],
    //       rules: [
    //         {
    //           required: true,
    //           whitespace: true,
    //           message: "Please input passenger's name or delete this field.",
    //         },
    //       ],
    //     })(
    //       <>
    //         <Input
    //           placeholder="passenger name"
    //           style={{ width: "60%", marginRight: 8 }}
    //         />
    //         <Input
    //           placeholder="passenger name"
    //           style={{ width: "60%", marginRight: 8 }}
    //         />
    //       </>,
    //     )}
    //     {keys.length > 1 ? (
    //       <Icon
    //         className="dynamic-delete-button"
    //         type="minus-circle-o"
    //         onClick={() => this.remove(k)}
    //       />
    //     ) : null}
    //   </Form.Item>
    // ));

    return (
      <div style={style.container}>
        <Modal
          title="Users"
          visible={this.state.visible2}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={850}
        >
          <Table
            dataSource={this.state.userList}
            columns={columns.filter((column) => column.dataIndex !== "id")}
          />

          <hr />
          <h2>ADD or UPDATE USER</h2>
          <div>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select a person"
              optionFilterProp="children"
              onChange={this.selectUser}
              filterOption={(input, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.allUsers.map((user, i) => (
                <Option value={user.first_name} key={user.phone_number}>
                  {user.first_name} {user.last_name}
                </Option>
              ))}
            </Select>

            <Form
              onSubmit={this.handleSubmit}
              className="user-form"
              layout="inline"
              initialValues={{ name: this.state.user.name }}
            >
              <div>
                <Form.Item>
                  {getFieldDecorator("first_name", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="first name"
                      onChange={(e) =>
                        this.setState({
                          ...this.state.user,
                          name: e.target.value,
                        })
                      }
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("last_name", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="last name"
                      onChange={(e) =>
                        this.setState({
                          ...this.state.user,
                          name: e.target.value,
                        })
                      }
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("role", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your user role!",
                      },
                    ],
                  })(
                    <Select
                      style={{ width: 213 }}
                      defaultValue="Customer"
                      placeholder="select role"
                    >
                      <Option value="Supervisor">Supervisor</Option>
                      <Option value="Customer">Customer</Option>
                    </Select>,
                  )}
                </Form.Item>{" "}
              </div>
              <div>
                <Form.Item>
                  {getFieldDecorator("email", {
                    rules: [
                      { required: true, message: "Please input your email!" },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="Email"
                    />,
                  )}
                </Form.Item>{" "}
                <Form.Item>
                  {getFieldDecorator("phone", {
                    rules: [
                      { required: true, message: "Please input your phone!" },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="phone"
                      // value={this.state.user.phone}
                      onChange={(e) =>
                        this.setState({
                          ...this.state.user,
                          phone: e.target.value,
                        })
                      }
                    />,
                  )}
                </Form.Item>
              </div>

              <Form.Item>
                {this.state.showUpdate ? (
                  <Button
                    type="primary"
                    htmlType="button"
                    className="login-form-button"
                    onClick={this.updateUserDetail}
                  >
                    UPDATE{" "}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    disabled={
                      this.state.showAddToCustomerBtn == true ? true : false
                    }
                  >
                    ADD{" "}
                  </Button>
                )}

                {this.state.showAddToCustomerBtn && (
                  <Button
                    type="primary"
                    htmlType="button"
                    className="login-form-button"
                    onClick={this.addUserToCustomer}
                  >
                    ADD USER
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Modal
          title="Edit Customer Details"
          visible={this.state.isCustomerEditModalVisible}
          onOk={() => this.setState({ isCustomerEditModalVisible: false })}
          onCancel={() => this.setState({ isCustomerEditModalVisible: false })}
          footer={[
            <Button
              key="back"
              onClick={() =>
                this.setState({ isCustomerEditModalVisible: false })
              }
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                this.editCustomerForm();
              }}
            >
              Update{" "}
            </Button>,
          ]}
        >
          <Form layout="vertical">
            <Form.Item label="ID" style={{ display: "none" }}>
              {getFieldDecorator(
                "customer_id",
                {},
              )(<Input placeholder="Clairco" />)}
            </Form.Item>
            <Form.Item label="Name">
              {getFieldDecorator("customer_name", {
                rules: [
                  {
                    required: true,
                    message: "Please fill customer name",
                  },
                ],
              })(<Input placeholder="Clairco" />)}
            </Form.Item>

            {/**this is working */}

            {/* <Button type="dashed" onClick={this.add} style={{ width: "60%" }}>
              <Icon type="plus" /> Add field
            </Button>{" "}
            {dynamicFormItemsForEditCustomer} */}

            {/************** */}

            {/* {this.state.keys.map((k) => (
              <div key={k}>
                <Form.Item label="Key">
                  {getFieldDecorator(`keys[${k}].key`, {
                    rules: [{ required: true, message: "Please input a key" }],
                  })(<Input placeholder="Key" />)}
                </Form.Item>
                <Form.Item label="Value">
                  {getFieldDecorator(`keys[${k}].value`, {
                    rules: [
                      { required: true, message: "Please input a value" },
                    ],
                  })(<Input placeholder="Value" />)}
                </Form.Item>
                <Icon
                  className="dynamic-delete-button"
                  type="minus-circle-o"
                  onClick={() => this.handleRemove(k)}
                />
              </div>
            ))} */}

            <Alert
              message="Please add any customization if required. Key will hold the name of the customization and the value will hold relevant values of those keys. Eg: six_params : true"
              type="info"
              showIcon
            />
            <br />

            {keys.map((key, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* {console.table({ key: key.key, value: key.value })} */}
                <Form.Item label="Key">
                  {getFieldDecorator(`keys[${index}].key`, {
                    initialValue: key.key,
                    // rules: [{ required: true, message: "Please input a key" }],
                  })(<Input placeholder="Key" />)}
                </Form.Item>
                <Form.Item label="Value">
                  {getFieldDecorator(`keys[${index}].value`, {
                    initialValue: key.value,
                    // rules: [
                    //   { required: true, message: "Please input a value" },
                    // ],
                  })(<Input placeholder="Value" />)}
                </Form.Item>
                <Icon
                  className="dynamic-delete-button"
                  type="minus-circle-o"
                  onClick={() => this.handleRemove(index)}
                  style={{ color: "#e31c25" }}
                />
              </div>
            ))}

            <Form.Item>
              <Button type="dashed" onClick={this.handleAdd}>
                <Icon type="plus" /> Add Key-Value Pair
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <PageHeader
          className="site-page-header"
          onBack={() => this.prevComponent()}
          title={
            <div>
              <span>{data.name}</span>{" "}
              <Button
                style={{
                  marginLeft: 16,
                }}
                shape="circle"
                icon="edit"
                type="primary"
                onClick={() => {
                  this.setState({
                    isCustomerEditModalVisible: true,
                  });
                  this.props.form.setFieldsValue({
                    customer_name: data.name,
                    customer_id: data.id,
                  });
                }}
              />
            </div>
          }
          subTitle={<h1>{data.address}</h1>}
          extra={[
            <Button
              icon="user-add"
              size="large"
              type="primary"
              onClick={this.showUserModal}
            >
              View Users
            </Button>,
            <Button
              icon="dashboard"
              style={style.button}
              onClick={this.viewDashboard}
              size="large"
              type="primary"
            >
              View Dashboard
            </Button>,
          ]}
        />
        <Row className="body-card-header" gutter={[16, 16]}>
          <Col span={8} justify="center" align="middle">
            Customer Dashboard:{" "}
            <Switch
              defaultChecked={dashboard_switch}
              onClick={(checked, e) =>
                this.switchChange(checked, "dashboard_active")
              }
              checkedChildren="Active"
              unCheckedChildren="Disabled"
            />
            <Button
              type="danger"
              ghost
              size="small"
              icon="info"
              shape="circle"
              onClick={() => this.showModal("warning")}
              style={{
                height: 23,
                width: 18,
                fontSize: 12,
                margin: 5,
              }}
            />
          </Col>
          <Col span={8} justify="center" align="middle">
            Auto email:{" "}
            <Switch
              defaultChecked={auto_email_switch}
              onClick={(checked, e) => this.switchChange(checked, "Auto_email")}
              checkedChildren="Active"
              unCheckedChildren="Disabled"
            />
            <Button
              type="danger"
              ghost
              size="small"
              icon="info"
              shape="circle"
              onClick={() => this.showModal("warning")}
              style={{
                height: 23,
                width: 18,
                fontSize: 12,
                margin: 5,
              }}
            />
          </Col>
          <Col span={8} justify="center" align="middle">
            Show Outdoor Data:{" "}
            <Switch
              defaultChecked={outdoor_switch}
              onClick={(checked, e) =>
                this.switchChange(checked, "OutdoorDataShow")
              }
              checkedChildren="Active"
              unCheckedChildren="Disabled"
            />
            <Button
              type="danger"
              ghost
              size="small"
              icon="info"
              shape="circle"
              onClick={() => this.showModal("warning")}
              style={{
                height: 23,
                width: 18,
                fontSize: 12,
                margin: 5,
              }}
            />
          </Col>
        </Row>
        <br />

        <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
          <Col className="body-card-container" span={11}>
            <div className="body-card-header-pop">Property List</div>
            <List
              style={style.listStyle}
              bordered
              dataSource={data.Properties}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <a onClick={() => this.viewProperty(item.id)}>
                        {item.name}
                      </a>
                    }
                  />
                  <Popconfirm
                    title="Are you sure to delete this property?"
                    onConfirm={() => this.deletePropertyData(item.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{
                        marginLeft: 16,
                      }}
                      shape="circle"
                      icon="delete"
                      type="primary"
                    />
                  </Popconfirm>
                </List.Item>
              )}
            />
            <Tooltip placement="bottom" title="Add Property">
              <Button
                className="add_button"
                icon="plus"
                style={style.button}
                onClick={this.addProperty}
                type="primary"
              ></Button>
            </Tooltip>
          </Col>
          <Col className="body-card-container" span={11}>
            <div className="body-card-header-pop">SLA Values</div>
            <SLAEditForm
              wrappedComponentRef={this.editSLAForm}
              defaultData={aqi_ranges}
            />
            {this.state.sla_response !== null ? (
              <Alert message={this.state.sla_response} type="info" />
            ) : null}
            <Tooltip placement="bottom" title="Update SLA">
              <Button
                className="add_button"
                icon="save"
                style={{ marginTop: "-47px" }}
                onClick={this.updateSLA}
                type="primary"
              ></Button>
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Button icon="setting" onClick={this.addAHU} type="primary">
              Maintain AHU's
            </Button>
          </Col>
          <Col span={6}>
            <Button icon="setting" onClick={this.addPurifier} type="primary">
              Purifiers
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Form.create()(Customer);

const style = {
  saveBtn: {
    marginTop: 20,
    marginBottom: 20,
  },
  loader: {
    width: "100%",
    textAlign: "center",
    marginTop: 100,
  },
  listStyle: {
    marginTop: 20,
  },
};
