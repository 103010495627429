import React, {Component} from "react";
import PropTypes from "prop-types";
import {
  Layout,
  Modal,
  Spin,
  Card,
  Menu,
  Button,
  Icon,
  Row,
  Col
} from "antd";
import ColorInfoOps from "../components/dashboardContent/ColorInfoOps";
import {COLOR} from "../colors";
import {getAQ} from "../api";
import GaugeOps from "../components/dashboardContent/GaugeOps";
import TemperatureBoxOps from "../components/dashboardContent/TemperatureOps";
import HumidityBoxOps from "../components/dashboardContent/HumidityOps";
import PmChartOps from "../components/dashboardContent/PmChartOps";
import AqiTable from "../components/aqiTable/AqiTable";
import "../components/dashboardContent/DashboardContent.less";
import moment from "moment";
import clairco_logo from "../img/customerLogos/clairco.png";
import './DashboardOps.less';
import {GetDateRangeArrForMenuIndex} from './../utils/dateRange';

const { Meta } = Card;
const noOfPastHoursToShow = 24;

export default class DashboardContent extends Component {
  constructor(props) {
    super(props);
    const device = props
      .match
      .params
      .id
      .split("_");
    this.deviceId = device[0]
    this.outsideDeviceId = device[1]
    this.state = {};
  }

  showModal = () => {
    this.setState({commnetVisible: true});
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({commnetVisible: false});
  }

  getUTCDay = (date) => {
    const day = date.getUTCDate()
    const month = date.getUTCMonth() + 1;
    return date.getUTCFullYear() + "-" + (month < 10
      ? ('0' + month)
      : month) + "-" + (day < 10
      ? ('0' + day)
      : day);
  }
  createData = (data) => {
    const aqData = [];
    const date = new Date();
    const currentHour = date.getHours();
    date.setHours(0);
    // console.log('data: ', data);
    while (date.getHours() <= currentHour) {
      const day = this.getUTCDay(date)
      const insideData = data
        .inside
        .find(item => item.day === day);
      const outsideData = data
        .outside
        .find(item => item.day === day);
      const hour = date.getUTCHours()
      const item = {
        inside: (insideData && insideData.hours)
          ? insideData.hours[hour]
          : null,
        outside: (outsideData && outsideData.hours)
          ? outsideData.hours[hour]
          : null,
        hour: date.toString()
      }
      aqData.push(item);
      date.setHours(date.getHours() + 1)
    }
    const opHours = data.opHours;
    const nonOpDays = data.nonOpDays;
    // console.log(aqData);
    this.setState({aqData,
                   opHours,
                   nonOpDays, 
                   insideAQ: data.current.inside, 
                   outsideAQ: data.current.outside});
  }

  async componentDidMount() {
    try {
      this.interval = setInterval(() => this.setState({ time: Date.now() }), 120 * 1000); // every 120 seconds the dashboard refreshes by itself
      let {data} = await getAQ(null,this.deviceId,this.outsideDeviceId,"1d");
      this.createData(data);
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval); // need to use setInterval to trigger the change,
                                    // but also need to clear the timer when the
                                    // component unmounts to prevent it leaving
                                    // errors and leaking memory.
  }

  getMaxValue = value => {
    return value < 100
      ? 100
      : value < 200
        ? 200
        : value < 300
          ? 300
          : value < 400
            ? 400
            : 1000;
  };

  getColor = (value, pm) => {
    if (pm == "pm2.5") {
      return value < 31
        ? COLOR.good
        : value < 61
          ? COLOR.satisfactory
          : value < 91
            ? COLOR.moderate
            : value < 121
              ? COLOR.poor
              : value < 251
                ? COLOR.veryPoor
                : COLOR.severe;
    }
    return value < 51
      ? COLOR.good
      : value < 101
        ? COLOR.satisfactory
        : value < 251
          ? COLOR.moderate
          : value < 351
            ? COLOR.poor
            : value < 431
              ? COLOR.veryPoor
              : COLOR.severe;
  };

  render() {
    if (!this.state.insideAQ) {
      return (
        <div className="dashLoaderContainer">
          <Spin size="large"/>
        </div>
      );
    }
    const {insideAQ, outsideAQ, opHours, nonOpDays} = this.state;
    let {aqData} = this.state;
    
    // Structure the time series data from DynamoDB correctly for logic
    let aqDataT = aqData.slice();
    aqData = [].splice();
    // console.log('aqDataT:',aqDataT);
    if(aqDataT.length !== 24) {
      for(let i=aqDataT.length,l=24;i<l;i++) {
        aqDataT.push({});
      }
    }
    for(let i=0,l=24;i<l;i++) {
      let newD;
      // console.log('i: ',i);
      // console.log('aqDataT[i]: ',aqDataT[i]);
      if(aqDataT[i].hour !== undefined) {
        // console.log('aqDataT[i].hour: ',aqDataT[i].hour);
        newD = new Date(aqDataT[i].hour);
        // console.log('newD: ',newD);
      } else {
        // console.log('aqDataT[i].hour: ',undefined);
        newD = new Date();
        // console.log('newD: ',newD);
        newD.setHours(i);
        // console.log('newD: ',newD);
        aqDataT[i]['hour'] = newD.toString();
      }
      // Data sent at 6.30am (IST) is stored in DynamoDB as data of 12.00am
        // But, data at 6.31(IST) is stored as 1.00am. This and above is due to
        // Dynamodb automatically converting data timestamp to UTC before storing.
        // Similarly, while reading, the data is converted back to local time.
        // Timezones with GMT+5 has no problems.
        // But, Timezones with GMT+530 has problems. IST is GMT+530.
          //Example:
          // (1) if requested at 6.00am-6.30am (IST), data is 0.00am(UTC) (12.00am)
          // (2) if requested at 6.31am-6.59am (IST), data is 1.00am(UTC)
        // Below correction is to overcome the problem:
          // (1) if requested at 6.00am-6.29am (IST), data is 0.00am(UTC) (this is the 1st hour of the day, 12.00am)
          // (2) if requested at 6.30am-6.59am (IST), data is 0.00am(UTC)
      let correctionFactor = (new Date()).getMinutes() < 30 ? 0 : 1;

      if(correctionFactor > 0) {
        aqDataT[i].hour = (new Date(
            (new Date(aqDataT[i].hour)).getTime() // epoch time
            + 30*60*1000 // adding 30 minutes worth milliseconds to epoch time
          )).toString();

        // From this hour's 30 mins onwards, the aq graph shows point in next hour also
          // Example: 8.29pm, aq graph shows only till 8pm
          // 8.30pm (upto 8.59pm), aq graph shows till 9pm
          // 9.00pm (upto 9.29pm), aq graph shows till 9pm
          // 9.30pm, aq graph shows till 10pm
          // JUGAAD FIX: from this hour's 30th minute, set next hours data to null
        if(((new Date(aqDataT[i].hour)).getHours())
          - ((new Date()).getHours()) > 0) {
          aqDataT[i]['inside'] = null;
          aqDataT[i]['outside'] = null;
        }
      }
      // console.log('aqDataT[i].hour: ',aqDataT[i].hour);
    }
    // console.log('aqDataT:',aqDataT);

    // For customers whose operational hours are split into two parts
      // 6am-12pm and 4pm-10pm
    let aqData2 = [];
    if(opHours.length === 0) {
      aqData = aqDataT.slice();
    } else {
      // console.log('opHours: ',opHours);
      for(let i=0,l=24;i<l;i++) {
        for(let j=0,m=opHours.length;j<m;j++) {
          if(((new Date(aqDataT[i].hour)).getHours() >= opHours[j][0])
            && ((new Date(aqDataT[i].hour)).getHours() <= opHours[j][1])) {
            if(aqDataT[i] === undefined) {
              aqDataT[i] = aqDataT[0].slice();
              aqDataT[i]['inside'] = null;
              aqDataT[i]['outside'] = null;
            }
            if(j===0) { aqData.push(aqDataT[i]); }
            else { aqData2.push(aqDataT[i]); }
          }
        }
      }
      if(opHours.length === 2) {
        const nowHour = (new Date()).getHours();
        if((nowHour >= opHours[1][0]) 
          && (nowHour <= opHours[1][1])) {
          aqData = aqData2.slice();
        }
      }
    }
    const insideAQPM25 = parseInt(insideAQ.pm25);
    const insideAQPM10 = parseInt(insideAQ.pm10);
    const insideTemperature = Math.round(parseInt(insideAQ.temp));
    const insideHumidity = Math.round(parseInt(insideAQ.humidity))
    const outsideAQPM25 = parseInt(outsideAQ.pm25);
    const outsideAQPM10 = parseInt(outsideAQ.pm10);
    const outsideTemperature = Math.round(parseInt(outsideAQ.temp));
    const outsideHumidity = Math.round(parseInt(outsideAQ.humidity))
    const outsideShowComment = moment().diff(moment(outsideAQ.time + ".000Z"), 'hours') > 1;
    const insideShowComment = moment().diff(moment(insideAQ.time + ".000Z"), 'hours') > 1;
    const CommentMark = (props) => (<Button
      type="danger"
      onClick={this.showModal}
      ghost
      size="small"
      style={{
      height: 8,
      fontSize: 7,
      width: 8
    }}
      icon="question"
      shape="circle"/>)
    const insidecardExtra = (
      <span>Updated {moment(insideAQ.time + ".000Z").fromNow() + "  "}
        {insideShowComment &&< CommentMark />}</span>
    );
    const outsideCardExtra = (
      <span>Updated {moment(outsideAQ.time + ".000Z").fromNow() + "  "}
        {outsideShowComment &&< CommentMark />}</span>
    );
    const commentFooter = [(
        <Button key="submit" type="primary" onClick={this.handleOk}>
          OK
        </Button>
      )]

    let bestPracticesToFollow = [
      'Keep the ACs ON in fan mode if cooling is not required.',
      'After cleaning hours, switch ON the fresh air system.',
      'Switch ON the fresh air duct fans.\nSwitch OFF the exhaust fans.',
      'Keep doors and windows closed whenever not in use.',
      'DO NOT remove adaptor of Clairco Air Quality Monitors.',
      'DO NOT switch OFF power source of Clairco Air Quality Monitors.'
    ];

    return (
      <div style={{
        minHeight: 484,
        maxHeight: 484,
        maxWidth: 360,
        minWidth: 360,
        marginLeft: "auto",
        marginRight: "auto"
      }}>
        <Card bordered={false} className="containerOps">
          <Row>
            <Col>
              <Card
                bordered={true}
                className="pmCardOps"
                title={<p style={{fontSize: 8,marginTop:3, marginBottom: 0, padding: [0, 0, 0, 0]}}>Inside Air Quality</p>}
                style={{fontSize: 8}}
                extra={insidecardExtra}>
                <Row gutter={[24, 8]}>
                  <Col span={8}>
                    <GaugeOps
                      label="PM2.5"
                      data={[{
                        value: insideAQPM25
                      }
                    ]}
                      max={this.getMaxValue(insideAQPM25)}
                      color={this.getColor(insideAQPM25, "pm2.5")}/>
                  </Col>
                  <Col span={8}>
                    <GaugeOps
                      label="PM10"
                      data={[{
                        value: insideAQPM10
                      }
                    ]}
                      max={this.getMaxValue(insideAQPM10)}
                      color={this.getColor(insideAQPM10, "pm10")}/>
                  </Col>
                  <Col span={6}>
                    <Col>
                        <TemperatureBoxOps
                          label="TEMPERATURE"
                          data={[{
                            value: insideTemperature
                          }]}/>
                        <HumidityBoxOps
                          label="HUMIDITY"
                          data={[{
                            value: insideHumidity
                          }]}/>
                    </Col>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                bordered={true}
                className="pmCardOps"
                title={<p style={{fontSize: 8,marginTop:3, marginBottom: 0}}>Outside Air Quality</p>}
                style={{fontSize: 7}}
                extra={outsideCardExtra}>    
                <Row gutter={[24, 8]}>
                <Col span={8}>
                    <GaugeOps
                      label="PM2.5"
                      data={[{
                        value: outsideAQPM25
                      }
                    ]}
                      max={this.getMaxValue(outsideAQPM25)}
                      color={this.getColor(outsideAQPM25, "pm2.5")}/>
                  </Col>
                  <Col span={8}>
                    <GaugeOps
                      label="PM10"
                      data={[{
                        value: outsideAQPM10
                      }
                    ]}
                      max={this.getMaxValue(outsideAQPM10)}
                      color={this.getColor(outsideAQPM10, "pm10")}/>
                  </Col>
                  <Col span={6}>
                    <Col>
                        <TemperatureBoxOps
                          label="TEMPERATURE"
                          data={[{
                            value: outsideTemperature
                          }]}/>
                        <HumidityBoxOps
                          label="HUMIDITY"
                          data={[{
                            value: outsideHumidity
                          }]}/>
                    </Col>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <ColorInfoOps/>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col>
            <Card
              className="pmChartDisplayOps"
              title={<p style={{fontSize: 8,marginTop:3, marginBottom: 0}}>PM2.5 concentration measured</p>}
              style={{fontSize: 10}}
              bordered={true}
              style={{
              padding: 0,  
              height: 165,
              marginTop: 0,
              marginRight: 24,
              marginLeft: 24
            }}>
              {aqData.length
                ? (<PmChartOps 
                  data={aqData}/>)
                : (
                  <div className="noDataPmChart">
                    <h3>No data to display</h3>
                  </div>
                )}
            </Card>
          </Col>
        </Row>
        <Row className='brandingImageRowOps'
                type='flex'
                justify='center'>
          <Col>
          <Card
            className="brand_logoOps"
            bordered={false}
            style={{ width: 110, fontSize:10 }}
            cover={<img alt="Clairco logo" src={clairco_logo} />}>
          </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
DashboardContent.propTypes = {
  selectedMenu: PropTypes.string.isRequired
};
