import React, { Component } from "react";
import {Form, Input, Select} from "antd";

const FormItem = Form.Item;
const Option = Select.Option;

const DPTForm = Form.create()(class extends React.Component {
    render() {
        const { form, defaultData } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form layout="horizontal">
                <FormItem label="Gateway ID">
                    {getFieldDecorator("gateway_id", {
                        rules: [
                            {
                                required: true,
                                message: "Important"
                            }
                        ]
                    })(<Input placeholder="e.g. GTW00001"/>)}
                </FormItem>
                <FormItem label="Node ID">
                    {getFieldDecorator("node_id", {
                        rules: [
                            {
                                required: true,
                                message: "Important"
                            }
                        ]
                    })(<Input placeholder="eg. 1"/>)}
                </FormItem>
            </Form>
        )
    }
})

export default DPTForm;