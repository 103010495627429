import React, { Component } from "react";
import { Button, Badge, Layout, Icon, Menu, Avatar, Divider } from "antd";
import { removeAdminToken, getUsername, removeUsername } from "../../helper";
import clairco_logo from "../../img/customerLogos/clairco.png";
import "./Admin.less";
import Home from "./Home";
import Customer from "../Customer";
import AddCustomer from "../AddCustomer";
import AddProperty from "../AddProperty";
import AddDevice from "../AddDevice";
import AddSpace from "../AddSpace";
import AddAHU from "../AddAHU";
import Filter from "../Filter";
import Property from "../Property";
import Space from "../Space";
import AddLog from "../AddLog";
import Purifier from "../Purifier";
import MaintainenceLogs from "../../components/MaintainenceLogs";
import WhatsApp from "../WhatsApp";
import Devices from "../Admin/Devices";
import Projects from "./Projects";
import ItemControl from "../Admin/ItemControl";
import Vendor from "./Vendor";
import AddStation from "../AddStation";
import PO from "./PO";

const SubMenu = Menu.SubMenu;
const { Header, Content, Footer, Sider } = Layout;

export default class Admin extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    // this.handleSelectedMenu = this.handleSelectedMenu.bind(this);
    this.state = {
      selectedMenu: "home",
      childComponentName: "Home",
      show: false,
      collapsed: false,
      msg: "edwdevf",
      pu_or: [],
    };
  }

  async componentDidMount() {
    try {
      const name = getUsername();
      this.setState({
        username: name,
      });
    } catch (error) {
      console.log(error);
    }
  }

  handleLogoutClick = (e) => {
    removeAdminToken();
    removeUsername();
    this.props.history.push("/");
  };

  getChildComponent = (childComponentName) => {
    const childComponents = {
        Home,
        Customer,
        AddCustomer,
        AddProperty,
        Property,
        AddDevice,
        AddStation,
        AddSpace,
        AddAHU,
        Filter,
        Property,
        Space,
        AddLog,
        MaintainenceLogs,
        Purifier,
        WhatsApp,
        Devices,
        Projects,
        ItemControl,
        Vendor,
        PO,
      },
      componentProps = Object.assign({}, this.props, this.state, {
        onClick: this.onClick,
      });
    if (childComponents[childComponentName]) {
      return React.createElement(
        childComponents[childComponentName],
        componentProps,
      );
    }
    return null;
  };

  onSelect = (value) => {
    this.props.history.push("/admin/customer", { id: value });
  };

  onClick = (value, child_param, referrer_Component) => {
    this.setState({
      childComponentName: value,
      id: child_param,
      referrer: referrer_Component,
    });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  addPO = (inp) => {
    this.setState({
      pu_or: inp,
    });
  };

  render() {
    let { username, show, collapsed } = this.state;
    return (
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          collapsed={collapsed}
          onBreakpoint={(broken) => {
            this.setState({ collapsed: broken });
            // console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            // console.log(collapsed, type);
          }}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          {
            <div className="claircoLogoContainer">
              <img src={clairco_logo} className="claircoLogo_admin" />
            </div>
          }
          <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
            <Divider />
            <SubMenu
              key="sub1"
              title={
                <span>
                  <span>{username}</span>
                </span>
              }
            >
              <Menu.ItemGroup key="g1">
                <Menu.Item key="setting:1">Edit Profile</Menu.Item>
                <Menu.Item key="setting:2">Settings</Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
            <Divider />
            <Menu.Item key="1" onClick={() => this.onClick("Home")}>
              <span className="nav-text">Customers</span>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => this.onClick("WhatsApp")}>
              <span className="nav-text">What's App</span>
            </Menu.Item>
            <Menu.Item key="3" onClick={() => this.onClick("Devices")}>
              <span className="nav-text">Devices</span>
            </Menu.Item>
            <Menu.Item key="4">
              <span className="nav-text">Audits</span>
            </Menu.Item>
            <Menu.Item key="5" onClick={() => this.onClick("Projects")}>
              <span className="nav-text">Projects</span>
            </Menu.Item>
            <Menu.Item key="6" onClick={() => this.onClick("ItemControl")}>
              <span className="nav-text">Item Master</span>
            </Menu.Item>
            <Menu.Item key="7" onClick={() => this.onClick("Vendor")}>
              <span className="nav-text">Vendor </span>
            </Menu.Item>
            {/* <Menu.Item key="8" onClick={() => this.onClick("PO")}>
              <span className="nav-text">PO </span>
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Layout style={{ marginLeft: "200px" }}>
          <Header
            className="site-layout-sub-header-background"
            style={{ padding: 0 }}
          >
            <div className="headerLogo">
              {/* <Icon
                className="trigger"
                type={collapsed ? "menu-unfold" : "menu-fold"}
                onClick={this.toggle}
              /> */}
              {/* Powered by <img src={clairco_logo} className="claircoLogo"/> */}
              <span style={{ fontSize: 25 }}>Welcome Admin</span>
            </div>
            <div className="adminMenuHolder">
              <div>
                <Menu mode="horizontal" style={{ backgroundColor: "#FFF" }}>
                  <SubMenu
                    title={
                      <Badge dot={show}>
                        <Icon type="bell" style={{ fontSize: "26px" }} />
                      </Badge>
                    }
                  >
                    <div className="notificationShade">
                      No New Notifications
                    </div>
                  </SubMenu>
                </Menu>
              </div>
              <Menu mode="horizontal" style={{ backgroundColor: "#FFF" }}>
                <SubMenu
                  title={
                    <div>
                      <Icon type="menu" style={{ fontSize: "26px" }} />
                    </div>
                  }
                >
                  <Menu.Item
                    key="setting:2"
                    onClick={this.handleLogoutClick}
                    style={{ color: "#FFF" }}
                  >
                    Logout
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </div>
          </Header>
          <Content style={{ margin: "24px 16px 0" }}>
            {this.getChildComponent(this.state.childComponentName)}
          </Content>
          <Footer style={{ textAlign: "center" }}>
            {" "}
            <p>
              In case of any queries, please reach out to Clairco at
              +91-9898036463
            </p>
            Aliferous Technologies pvt. ltd. ©2018
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

const style = {
  welcome: {
    margin: 20,
  },
  container: {
    padding: 16,
    background: "#FFF",
  },
  button: {
    margin: 10,
  },
  DeviceCard: {
    width: 180,
    background: "#4a20ab",
    color: "#fff",
    padding: 20,
  },
};
