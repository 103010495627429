import React from "react";
import { Table } from "antd";
import { isNull } from "lodash";

const SpaceTable = ({ spaces, handleInputVal, handleClickedProperty }) => {
  const columns = [
    // {
    //   title: "Space ID",
    //   dataIndex: "spaceid",
    // },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Device",
      dataIndex: "device",
      render: (device) => {
        return (
          <a onClick={(e) => handleInputVal(e.target.textContent)}>{device}</a>
        );
      },
    },
    {
      title: "Property",
      dataIndex: "property",
      render: (property) => {
        return (
          <a onClick={(e) => handleClickedProperty(e.target.textContent)}>
            {property}
          </a>
        );
      },
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "Customer",
      dataIndex: "customer",
    },
  ];

  const data = [];

  spaces.map((space) =>
    data.push({
      // spaceid: space.id,
      name: space.name,
      device: isNull(space.Device) ? "NA" : space.Device.id,
      property: isNull(space.Property) ? "NA" : space.Property.name,
      city: isNull(space.Property) ? "NA" : space.Property.city,
      customer: isNull(space.Property) ? "NA" : space.Property.Customer.name,
    })
  );
  return (
    <Table columns={columns} dataSource={data} pagination={{ pageSize: 6 }} />
  );
};

export default SpaceTable;
