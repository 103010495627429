import React, {Component} from "react";
import axios from "axios";
import {
  Table,
  PageHeader,
  Button,
  Input,
  Result,
  Card,
  Row,
  Col,
  Tag,
  Spin, Divider
} from "antd";
import {getFilters, getLogs, deleteLog, makePostRequest} from "../api";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const fracta_gurgaon_id = 'f55022f0-64ff-40f8-807c-0043ef640a17';
const fracta_bangalore_id = "b060fb6b-f40f-4e70-a03a-249d04d6f032";
const fracta_mumbai_id = "55a74949-e121-44ee-b30b-a1e34a3c836c";

export default class MaintainenceLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            customerId: this.props.id,
            properties: this.props.properties,
            source: this.props.source,
            columns : [
                { 
                    title: 'AHU Name', 
                    dataIndex: 'filter_name', 
                    key: 'filter_name',
                    
                    onFilter: (value, record) => record.name.include(value),
                    sorter: (a, b) => {
                        const sorted = new Intl.Collator().compare(a.name, b.name);
                        return sorted;
                    },

                    ...this.getColumnSearchProps("filter_name")
                },
                { 
                    title: 'Action Taken', 
                    dataIndex: 'action', 
                    key: 'action', 
                    filters: [{
                        text: 'Changed',
                        value: '2',
                      }, 
                      {
                        text: 'Cleaned',
                        value: '1',
                      },
                      {
                        text: 'Installed',
                        value: '0',
                      }],
                    // onFilter: (value, record) => console.log(record.toString().indexof(value)),
                    onFilter: (value, record) => record.action.toString().indexOf(value) == 0,
                    render: (record) => record==2?
                            <Tag color="red">Changed</Tag>
                                :(record==1?<Tag color="orange">Cleaned</Tag>:<Tag color="green">Installed</Tag>)
                            
                },
                { title: 'Performed on', dataIndex: 'updated_on', key: 'updated_on'},
                { title: 'Performed by', dataIndex: 'updated_by', key: 'updated_by'},
                { title: 'Supervised by', dataIndex: 'supervised_by', key: 'supervised_by'}
            ]
        }
    }

    onChange(pagination, filters, sorter) {
        // console.log('params', pagination, filters, sorter);
    }

    getColumnSearchProps = (dataIndex) => ({
        searchInput: "",
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{padding: 8}}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => 
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => 
                        this.handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                    onClick={() => {
                        confirm({ closeDropdown: false })
                        this.setState({
                            searchText: selectedKeys[0],
                            seachedColumn: dataIndex
                        })
                    }}
                >Search</Button>
                <Button
                    onClick={()=>this.handleReset(clearFilters)}
                    size="small"
                    style={{ width:90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890FF" : undefined}}/>
        ),
        onFilter: (value, record) => 
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : "",
        onFilterDropdownVisibleChange: (visible) => {
            if(visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#FFC069",
                        padding: 0,
                    }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (text),

    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    async componentDidMount() {
        const id = this.state.customerId;  
        const { columns } = this.state;  
        var newColumn = {
            show: false
        };
        var admin_flag = false;
        
        try {
            var data = [];
            let dptDataArray;
            let responseData = [];
            let queryCon;
            if(this.state.customerId === fracta_gurgaon_id) {
                queryCon = "frac/gur/"
            } else if (this.state.customerId === fracta_mumbai_id) {
                queryCon = "frac/mub/"
            } else if (this.state.customerId === fracta_bangalore_id) {
                queryCon = "frac/bglr/"
            }
            if(this.state.customerId === fracta_gurgaon_id || this.state.customerId === fracta_mumbai_id || this.state.customerId === fracta_bangalore_id){
                let requestData = {    
                    "queryCol": "details",
                    "queryCon": queryCon
                }
            let devices = await axios({
                method: 'POST',
                url: '/device',
                baseURL: 'https://api.claircoair.com/api',
                headers: {
                    'api-key': 'lcvhzec0ae4u20047s36mfexcjr2ebd3cpk28b9yca9c9f95d17dgeda',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJzdF9uYW1lIjoiQXNoaXNoIiwiZW1haWwiOiJhc2hpc2gua3VtYXJAY2xhaXJjby5pbiIsImlkIjoiY2xhaXJjby1mZGZkOTNlNC1lYTljLTQxZWItODIzZS0xMjUwMmE5MTQxNTcudnRkZXB3ODZvdyIsImRvbWFpbiI6ImNsYWlyY28iLCJyb2xlcyI6WyJmZDU1YTM2OS0yNjA2LTQyODEtYjgyNS0yODBiNzBmYWM4MjkiXSwiaWF0IjoxNzEzMTYzNTYxLCJleHAiOjE3NDQ2OTk1NjF9.coHIb8hZ09bQ4f7jISgJVNpjw2WAc2Yi5lQ7CzyWy9Y'
                },
                data: requestData
            }).then((response) => {
                let devices = response.data.msg;
                let ffcDevices = devices.filter((item) => {                    
                    return item['id'].includes('DPT')
                })
                dptDataArray = ffcDevices;
            });
            for(var i=0; i<dptDataArray.length; i++){
                let data = await new Promise(async (resolve, reject) => {
                    let incomingResponse = await makePostRequest(dptDataArray[i]);
                    resolve(incomingResponse);
                });
                responseData.push({
                    key: dptDataArray[i].id,
                    metaData: dptDataArray[i].metaData,
                    data:data.data.msg
                });
            }
            this.setState({
                responseData
            });
            }
            for(var i=0; i<this.state.properties.length;i++){                          
                let property_filter = (await getFilters(this.state.properties[i].id)).data;
                if(property_filter){
                    data.push(property_filter.data);
                }
            } 
            if(data.length>0){ 
                let logs = [];
                for(var i=0; i < data.length; i++){
                    for(var j=0;j<data[i].length;j++){
                        const temp_data = (await getLogs(data[i][j]._id)).data;
                        for(var k=0; k<(temp_data.data).length;k++){
                            logs.push(temp_data.data[k]);
                        }
                    }
                }
                logs = logs.reverse();
                this.setState({
                    ahuData: data,
                    log_data: logs,
                    loading: false,
                    admin_flag,
                    columns: [...columns, newColumn]
                })
            } else {
                this.setState({
                    ahuData: undefined,
                    loading: false
                })
            }
        } catch (err) {
            console.log(err);
        }
    }

    prevComponent = () => {
        this.props.onClick('Filter', this.state.customerId);
    }

    async deleteLog(id) {
        try {
            await deleteLog(id);
            this.setState({
              deleted: true
            })
          } catch (e) {
            console.log(e);
          }
    }

    render() {
        let { ahuData, log_data, admin_flag, columns, source, responseData} = this.state;
        if(this.state.customerId === fracta_gurgaon_id || this.state.customerId === fracta_mumbai_id || this.state.customerId === fracta_bangalore_id){
            if(!responseData)
                return <Spin></Spin>
        }
        if(!ahuData){
            return (
                <Result
                    status="warning"
                    title="No AHUs Have been added."
                />
            )
        }
        let filterCardItems, filterItems;
        if(this.state.customerId === fracta_gurgaon_id || this.state.customerId === fracta_mumbai_id || this.state.customerId === fracta_bangalore_id){
            filterItems = responseData.map((item, i) => {
                return (<Col span={4} offset={1} style={{marginBottom: '20px'}}>
                <Card style={{boxShadow:'3px 3px 3px #e0e0e0'}}>
                    <Row style={{minHeight:'5vh'}}>
                        <Col className={
                            typeof item.data == 'string' || item.data.metaData.dp < 10
                            ?"block_card_icon fail"
                            :"block_card_icon success"}
                            span={12}>
                            <div>
                                {typeof item.data == 'string' || item.data.metaData.dp < 10
                                    ?'OFF'
                                    :'ON'}
                            </div>
                        </Col>    
                        <Col span={12}>
                            <br></br>
                            <div className="block_card_info_header"><h1 style={{fontSize:'200%'}}>Pressure</h1></div>
                            <div className="card_info" style={{fontSize:'160%'}}>
                                { typeof item.data == 'string'? 'NA' : item.data.metaData.dp+' Pa'}
                            </div>
                        </Col>
                    </Row>
                    <Divider className="custom_divider"/>
                    <Row style={{textAlign: "center", minHeight:'2vh'}}>
                        <span><b>{item.metaData.name+' - '+item.key}</b></span>
                    </Row>
                </Card>
            </Col>)
            });
            var renderSlides = () =>  {
                var items = [];
                for(var i=1; i<filterItems.length; i++){
                    var key = 'slide:'+i+1 ;
                    items.push(
                        <div  key={key}>
                            {filterItems}
                        </div>
                    )
                }
              return items;
            } 
            
        }
        filterCardItems = ( filterItems );
        return(
            <div style={{ minHeight: 600 }}>
                {admin_flag?(<PageHeader
                                className="site-page-header"
                                onBack={() => this.prevComponent()}
                                title={<h1>Maintainence</h1>}
                                />):null}
                {source==="dashboard" && (this.state.customerId === fracta_gurgaon_id || this.state.customerId === fracta_mumbai_id || this.state.customerId === fracta_bangalore_id) ? filterCardItems:null}
                                
                <Table
                    rowKey="uid"
                    {...this.state}
                    className="components-table-demo-nested"
                    bordered
                    columns={columns}
                    dataSource= {log_data}
                    onChange={this.onChange}
                    style={{marginTop:'3%'}}
                />
            </div>
        );
    }
};