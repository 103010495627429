import React, { Component } from "react";
import { Row, Col, Checkbox, Form, Input, Select } from "antd";
import { render } from "react-dom";

const FormItem = Form.Item;
const Option = Select.Option;

const AHUForm = Form.create()(class extends React.Component {
    render() {
        const { form, spaces, properties } = this.props;
        const { getFieldDecorator } = form;
        const propertyCheck = properties.map((item, i) => {            
            return (
                getFieldDecorator("property", {
                    rules: [
                        {
                            required: true
                        }
                    ]
                }) (<Col span={8}>
                        <Checkbox key={item["id"]} onChange={this.props.onChange} value={item['id']} style={{ lineHeight: '32px' }}>
                            {item['name']}
                        </Checkbox>
                    </Col>)); 
        })
        // const spaceCheck = spaces.map((item, i) => {
        //     return (
        //     getFieldDecorator("space",{
        //         rules: [
        //             {
        //                 required: true
        //             }
        //         ]
        //     }) (<Col span={8}>
        //             <Checkbox onChange={this.props.onChange} value={item} style={{ lineHeight: '32px' }}>
        //             {item}
        //             </Checkbox>
        //         </Col>));  
        // });

        const propertySelector = (
            <Row>
                {propertyCheck}
            </Row>
        );
        return (
            <Form layout="vertical">
                <FormItem label="AHU Name">
                    {getFieldDecorator("filter_name", {
                        rules: [
                            {
                                required: true,
                                message: "Important"
                            }
                        ]
                    })(<Input placeholder="e.g. AHU Number 1"/>)}
                </FormItem>
                <FormItem label="Property">
                    {propertySelector}
                </FormItem>
            </Form>
        );
    }
})

export default AHUForm;