import React, { useState } from "react";
import * as d3 from "d3-collection";
import "./DeviceList.less";
import { isNull, isUndefined } from "lodash";

const Table = ({ groupedData, gateway_switch }) => {
  const [span, setSpan] = useState(false);

  const handlespan = (val) => {
    setSpan(val);
  };
  return (
    <table id="customers">
      <thead>
        <tr>
          <th></th>
          <th colSpan="3">Gateway</th>
          <th colSpan="4">Status</th>
        </tr>
      </thead>

      {groupedData.map((device, i) => (
        <Accordion
          title={device.key}
          content={device.values}
          handlespan={handlespan}
          key={i}
        />
      ))}
    </table>
  );
};

const Accordion = ({ title, content, handlespan }) => {
  const [isActive, setIsActive] = useState(false);
  const [isMatched, setIsMatched] = useState(false);
  const [show, setShow] = useState(true);
  const handleclick = () => {
    setIsActive(!isActive);
    handlespan(!isActive);
  };

  return (
    <tbody>
      <tr onClick={handleclick} style={{ fontWeight: isActive && "bold" }}>
        <td style={{ fontSize: "18px" }}>{isActive ? "-" : "+"}</td>
        <td colSpan={isActive ? 3 : 3}>{title}</td>
        <td colSpan={isActive ? 4 : 4}>active</td>
      </tr>

      {isActive && (
        <div>
          <tr>
            <th>ID</th>
            <th>Node ID</th>
            <th>Space</th>
            <th>Customer</th>
            <th>PM2.5</th>
            <th>PM10</th>
            <th>CO2</th>
            <th>Updated at</th>
          </tr>
          {content.map((device, i) => (
            <span>
              <tr key={i}>
                <td key={device.id}>{device.id}</td>
                <td key={device.node_id}>{device.node_id}</td>
                <td key={device.Space.name}>{device.Space.name}</td>
                <td key={device.Space.Property.name}>
                  {device.Space.Property.name}
                </td>
                <td>
                  {isUndefined(device.data) || isNull(device.data)
                    ? "NA"
                    : device.data["1"]["pm2.5"] + " ug/m3"}
                </td>
                <td>
                  {isUndefined(device.data) || isNull(device.data)
                    ? "NA"
                    : device.data["1"]["pm10"] + " ug/m3"}
                </td>
                <td>
                  {isUndefined(device.data) || isNull(device.data)
                    ? "NA"
                    : device.data["1"]["co2"] + " ppm"}
                </td>
                <td>
                  {isUndefined(device.data) || isNull(device.data)
                    ? "NA"
                    : device.data["1"].last_timestamp}
                </td>
              </tr>
            </span>
          ))}
        </div>
      )}
    </tbody>
  );
};

const DeviceList = ({ devices, inputVal, gateway_switch }) => {
  const [deviceList, setDeviceList] = useState(null);

  var groupedData = d3
    .nest()
    .key(function(d) {
      return d.gateway_id;
    })
    .entries(devices);

  function searchRows(rows) {
    if (inputVal.length > 7 && !inputVal.startsWith("GTW")) {
      const da = rows.filter((row) =>
        row.values.some((d) => {
          if (d.id === inputVal) {
            return d;
          }
        })
      );
    
      const response = da[0].values
        .filter((row) => row.id === inputVal)
        .map((d) => d);

      const individual_data = [
        {
          key: response[0].gateway_id,
          values: response,
        },
      ];
      return individual_data;
    }
    return rows.filter((row) => row.key.toUpperCase().indexOf(inputVal) > -1);
  }

  return (
    <div>
      <Table
        groupedData={searchRows(groupedData)}
        gateway_switch={gateway_switch}
      />
    </div>
  );
};

export default DeviceList;
