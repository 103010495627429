import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Router } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import { Switch, Route, Redirect } from "react-router-dom";
import DashboardReception from "./scenes/DashboardReception";
import { Dashboard, AdminDashboard } from "./scenes";
import DashboardUser from "./scenes/DashboardUser";
import DashboardOps from "./scenes/DashboardOps";
import DashboardPhone from "./scenes/DashboardPhone";
import Admin from "./scenes/Admin/Admin";
import Login from "./scenes/Login";
import ChangePassword from "./scenes/ChangePassword";
import KioskSlider from "./components/kioskSlider";
import Kiosk from "./components/kiosk/Kiosk";
import { getToken, getAdminToken, getUserRole } from "./helper";
import api, { changePassword } from "./api.js";
import AddCustomer from "./scenes/AddCustomer";
import AddProperty from "./scenes/AddProperty";
import AddAHU from "./scenes/AddAHU";
import UpdateCustomer from "./scenes/UpdateCustomer";
import Customer from "./scenes/Customer";
import Property from "./scenes/Property";
import Space from "./scenes/Space";
import AddSpace from "./scenes/AddSpace";
import AddDevice from "./scenes/AddDevice";
import AddAC from "./scenes/AddAC";
import DeviceList from "./scenes/DeviceList";
import Supervisor from "./scenes/Dashboard/SupervisorDashboard";
import { createBrowserHistory } from "history";
import AddStation from "./scenes/AddStation";
import KioskManagement from "./components/KioskManagement";
import DashboardV2 from "./scenes/Dashboard/DashboardV2";
import DashboardHPE from "./scenes/Dashboard/DashboardHPE";
import UvDashboard from "./scenes/Dashboard/UvDashboard";

const history = createBrowserHistory();
// const initGA = (history) => {
//   (function (i, s, o, g, r, a, m) {
//     i['GoogleAnalyticsObject'] = r;
//     i[r] = i[r] || function () {
//       (i[r].q = i[r].q || []).push(arguments)
//     },
//     i[r].l = 1 * new Date();
//     a = s.createElement(o),
//     m = s.getElementsByTagName(o)[0];
//     a.async = 1;
//     a.src = g;
//     m
//       .parentNode
//       .insertBefore(a, m)
//   })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
//   const ga = window.ga;
//   ga('create', 'UA-125838003-1', 'auto');
//   ga('send', 'pageview');
//   // console.log("tracking page view: " + history.location.pathname);
//   ga('send', 'pageview', history.location.pathname);
//   history.listen((location) => {
//     // console.log("tracking page view: " + location.pathname);
//     ga('send', 'pageview', location.pathname);
//   });
// };

// initGA(history);

ReactDOM.render(
  <Router history={history}>
    <Switch>
      <Route
        exact
        path="/"
        component={() => {
          window.location.href = "http://clairco.in";
          return null;
        }}
      />
      <Route
        exact
        path="/dashboard"
        render={(props) => {
          if (getToken()) return <DashboardV2 {...props} />;
          else if (getAdminToken()) return <DashboardV2 {...props} />;
          else return <Redirect to="/login" {...props} />;
        }}
      />
      <Route
        exact
        path="/dashboard/hpe"
        component={()=>{
          return <DashboardHPE/>;
        }}
      />
      <Route
        exact
        path="/dashboard/:id"
        render={(props) => {
          if (getToken()) {
            return <Dashboard {...props} />;
          } else return <Redirect to="/login" {...props} />;
        }}
      />
      <Route
        exact
        path="/user/dashboard/:id"
        render={(props) => {
          // if (getToken())
          return <DashboardUser {...props} />;
          // else
          //   return <Redirect to="/login" {...props}/>;
        }}
      />
      <Route
        exact
        path="/bigtv/:id"
        render={(props) => {
          // if (getToken())
          return <DashboardReception {...props} />;
          // else
          //   return <Redirect to="/login" {...props}/>;
        }}
      />
      <Route
        exact
        path="/kiosk/dashboard/:id"
        render={(props) => {
          // if (getToken())
          props.refferrer = "space";
          return <KioskSlider {...props} />;
          // else
          //   return <Redirect to="/login" {...props}/>;
        }}
      />
      <Route
        exact
        path="/ops/dashboard/:id"
        render={(props) => {
          // if (getToken())
          return <DashboardOps {...props} />;
          // else
          //   return <Redirect to="/login" {...props}/>;
        }}
      />
      <Route
        exact
        path="/phone/dashboard/:id"
        render={(props) => {
          if (getToken()) return <DashboardPhone {...props} />;
          else return <Redirect to="/login" {...props} />;
        }}
      />
      <Route
        exact
        path="/changepassword"
        render={(props) => {
          if (getToken()) return <ChangePassword {...props} />;
          else return <Redirect to="/login" {...props} />;
        }}
      />
      <Route
        exact
        path="/kiosk"
        render={(props) => {
          return <KioskSlider {...props} />;
        }}
      />
      <Route
        exact
        path="/login"
        render={(props) => {
          if (getToken()) {
            if (getUserRole() === "supervisor")
              return <Redirect to="/customer/admin" {...props} />;
            else return <Redirect to="/dashboard" {...props} />;
          } else if (getAdminToken()) {
            return <Redirect to="/admin" {...props} />;
          } else {
            return <Login {...props} />;
          }
        }}
      />
      <Route
        exact
        path="/admin"
        render={(props) => {
          if (getAdminToken()) {
            return <Admin {...props} />;
          } else {
            return <Login {...props} />;
          }
        }}
      />
      <Route
        exact
        path="/customer/admin"
        render={(props) => {
          if (getToken()) {
            // console.log(props);
            return <Supervisor {...props} />;
          } else {
            return <Login {...props} />;
          }
        }}
      />
      <Route
        exact
        path="/admin/addcustomer"
        render={(props) => {
          if (getAdminToken()) return <AddCustomer {...props} />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/admin/addproperty"
        render={(props) => {
          if (getAdminToken()) return <AddProperty {...props} />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/admin/addahu"
        render={(props) => {
          if (getAdminToken()) return <AddAHU {...props} />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/admin/addspace"
        render={(props) => {
          if (getAdminToken()) return <AddSpace {...props} />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/admin/adddevice"
        render={(props) => {
          if (getAdminToken()) return <AddDevice {...props} />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/admin/addstation"
        render={(props) => {
          if (getAdminToken()) return <AddStation {...props} />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/admin/addac"
        render={(props) => {
          if (getAdminToken()) return <AddAC {...props} />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/admin/customer"
        render={(props) => {
          if (getAdminToken()) return <Customer {...props} />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/kiosk/management"
        render={(props) => {
          if (getToken()) return <KioskManagement />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/admin/updatecustomer"
        render={(props) => {
          if (getAdminToken()) return <UpdateCustomer {...props} />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/admin/devices"
        render={(props) => {
          if (getAdminToken()) return <DeviceList {...props} />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/admin/customer/property/:id"
        render={(props) => {
          if (getAdminToken()) return <Property {...props} />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/admin/dashboard/"
        render={(props) => {
          if (getAdminToken()) return <AdminDashboard {...props} />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/admin/customer/property/space/:id"
        render={(props) => {
          if (getAdminToken()) return <Space {...props} />;
          else
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    referrer: "admin",
                  },
                }}
              />
            );
        }}
      />
      <Route
        exact
        path="/testing/dashboard"
        render={(props) => {
          return <DashboardV2 />;
        }}
      />
      <Route
        exact
        path="/uv/dashboard"
        render={(props) => {
          return <UvDashboard {...props} />;
        }}
      />
    </Switch>
  </Router>,
  document.getElementById("root"),
);
registerServiceWorker();
