import React, { Component } from "react";
import _, { isUndefined } from "lodash";
import {
  Layout,
  Menu,
  Avatar,
  Badge,
  Icon,
  Modal,
  Spin,
  DatePicker,
  Alert,
  Dropdown,
  Switch,
  Button,
  Tooltip,
  Steps,
  Result,
} from "antd";
import {
  DownloadOutlined,
  UserOutlined,
  BarChartOutlined,
  WindowsOutlined,
  SettingOutlined,
  BlockOutlined,
  ExportOutlined,
  DesktopOutlined,
  EditOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import "./DashboardV2.less";
import {
  getCustomer,
  getSummaryAqData,
  getAqExcelFile,
  getPurifierExcelReport,
  getExcelFile,
} from "../../api";
import SummaryPage from "../../components/dashboardComponent/SummaryPage";
import SummaryPageHPE from "../../components/dashboardComponent/SummaryPageHPE";
import {
  removeToken,
  getCsrId,
  removeCsrId,
  getUsername,
  getSupervisorData,
  getUserRole,
  removeCache,
  getAdminToken,
} from "../../helper";
import moment from "moment";
import PurifierView from "../../components/PurifierView";
import Report from "../../components/Report";
import UserControl from "../../components/UserControl";
import MaintainenceLogs from "../../components/MaintainenceLogs";
import KioskManagement from "../../components/KioskManagement";
import PropertiesCapitaLand from "../../components/dashboardContentCapitaLand/PropertiesCapitaLand";
import claircologo from "../../img/assets/Claircologo.png";
import clairco_logo from "../../img/customerLogos/clairco.png";
import cureFit_logo from "../../img/customerLogos/cureFit.png";
import blueTokai_logo from "../../img/customerLogos/blueTokai.png";
import wtc_logo from "../../img/customerLogos/wtcBangalore.jpg";
import dlf_logo from "../../img/customerLogos/dlf.png";
import maxEstates_logo from "../../img/customerLogos/maxEstates.png";
import buzzworks_logo from "../../img/customerLogos/buzzworks.png";
import cowrks_logo from "../../img/customerLogos/cowrks.png";
import sarva_logo from "../../img/customerLogos/sarva.png";
import capitaland_logo from "../../img/customerLogos/capitaland.png";
import orionMall_logo from "../../img/customerLogos/orionMall.png";
import wework_logo from "../../img/customerLogos/wework.png";
import emaar_logo from "../../img/customerLogos/emaar.png";
import candorTechSpace_logo from "../../img/customerLogos/candorTechSpace.jpeg";
import knightFrank_logo from "../../img/customerLogos/knightFrank.png";
import mgfMetropolitan_logo from "../../img/customerLogos/mgfMetropolitan.png";
import suncityProjects_logo from "../../img/customerLogos/suncityProjects.png";
import aviva_logo from "../../img/customerLogos/aviva.png";
import tag_logo from "../../img/customerLogos/tag_logo.png";
import { COLOR } from "../../colors";
// import UvDashboard from "./UvDashboard";
import { Link } from "react-router-dom";
import FFUView from "../../components/FFUView";
const { Header, Sider, Content, Footer } = Layout;
const SubMenu = Menu.SubMenu;
const { Step } = Steps;
let lastDate = new Date();
lastDate.setDate(lastDate.getDate() - 1);
lastDate = lastDate.toLocaleDateString();

// Change below customer ids, then change in server also (customer_controller)
const cureFit_customerId = "827e9644-2de5-42e8-836e-3073afb3d8fe";
const blueTokai_customerId = "15ce89ec-7c1e-4d01-be22-0c9d867c5b2d";
const wtc_customerId = "b38f6c08-dcaf-4139-aea0-d30ee75d4ee1";
const wework_32nd = "3b0c8b10-8dac-4466-a728-0937239baefe";
const berger_wework = "a2b4c6e4-e9f0-4f3e-82cd-d4d1bf47387c";
const vijohn_wework = "a5ac3d08-851e-4c3e-85c1-63be20054d21";
const platina_wework = "527c32d4-140f-4501-a9ef-1cdd284d5bcf";
const dlf_customerId = "f0bd9405-61d7-4edf-8e59-1707155d7e84";
const maxEstates_customerId = "e0bd2d59-1f36-44a0-9167-fe9c03fc4d0b";
const axis_house = "eb53efcd-d4e8-47f8-970c-8938145eabd7";
const buzzworks_customerId = "3a119b92-0322-45f5-97a0-fa7ba20f0088";
const cowrks_customerId = "11e4ec5b-a7f0-4fd5-89f5-314f329c9ec2";
const sarva_customerId = "1493ab1e-a9ea-4496-9318-4e003918f562";
const capitaland_customerId = "9fb2d83a-7db7-475e-84ad-d26c56727366";
const capitaland_gurgaon_customerId = "5b541311-4e27-4785-8067-e8fc7586cdbc";
const capitaland_chennai_customerId = "9a0bbd3c-c2c9-4533-a76a-4c9c1626d249";
const capitaland_vitp_customerId = "46a5ef08-4a0f-4858-8c3f-9fe257771fc7";
const capitaland_aitpp_customerId = "d50be409-c9b9-4353-b22f-e221e7319225";
const orionMall_customerId = "5133df6a-fe15-4b05-a4ff-4c64bac8307d";
const wework_customerId = "4c3af777-5509-4ef3-bdd5-cb57ead81502";
const emaar_customerId = "1192008a-f73b-4929-a963-d024f1b3fe18";
const candorTechSpace_customerId = "5edf44a3-9c1b-452a-94be-c7968feea87a";
const knightFrank_customerId = "61edd950-3e5a-401d-bd2f-46ed77cdd765";
const mgfMetropolitan_customerId = "099b5cc0-bed6-4a3c-aa09-7d818941bff9";
const suncityProjects_customerId = "c82556c4-93cc-4ebd-a9ed-3a3f957a3685";
const aviva_customerId = "6e8c6026-4212-419d-9fa6-ccb3ea7c21d5";
const orionAvenue_customerId = "619c559c-eca3-4293-ac5a-9fa2ab2e3fab";
const tag_customerId = "1c90de1e-d6ff-4792-b72d-81021a07ec7e";
const maxhouse_okhla = "fd6a8e6d-a0c1-4387-8b84-a6a20b48559e";
const leela_customerId = "89a9cdca-6e34-4796-867c-48a2b96ba65b";
// const test_customerId = "1ab30d46-9b1a-4a5c-bf0e-d0bec0ab8cf1";
//const test_customerId = "ca056ff4-0b8c-45da-a8cd-ef4583556006";
const test_customerId = "1ab30d46-9b1a-4a5c-bf0e-d0bec0ab8cf1";
const ntpl_customerId = "5d35f443-bb96-465b-ace5-d5f46e0d0dd9";

const fractal_Ids = [
  "55a74949-e121-44ee-b30b-a1e34a3c836c",
  "b060fb6b-f40f-4e70-a03a-249d04d6f032",
  "f55022f0-64ff-40f8-807c-0043ef640a17"
]

const accessToUV = [
  "146c159d-96bc-4e9c-83dc-2d955eda1886",
  "2fbd88c5-0799-46a1-bda4-b9f448633016",
  "6411f849-83d9-4566-aab5-28cd433a47a0",
  "46d4dc23-c07e-4220-a67c-7af82d650ac3",
  "62d1d24c-26c4-40b6-998f-aeb07288960a",
  "84bf8c5c-8176-4e99-9b24-d0449a83d1d8",
  "be995266-b5c1-421a-a528-232669b329aa",
  "d00f6af9-2089-4c4f-8c8f-d1a78756fb39",
  "985a1e2f-7005-42cd-8542-060e2bbf6382",
];

export default class DashboardV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: !isUndefined(this.props.location.state)
        ? this.props.location.state.id
        : getCsrId(),
      loading: false,
      loadingData: true,
      startValue: null,
      endValue: null,
      endOpen: false,
      showAlert: false,
      error: "Please enter a Valid Date",
      show: false,
      fileType: true,
      reportType: true,
      report_flag_disable: true,
      selectedCard: 0,
      collapsed: false,
      visible: false,
      isFlipped: false,
      addUser: false,
      selectedCityIndex: 0,
      filterInfo: false,
      selectedCity: "",
      customerName: "",
      uv_data: null,
      customer_logo: "",
      showSixParameters: false,
    };
  }
  async componentDidMount() {
    try {
      let { data } = (await getCustomer(this.state.id, true)).data;
      // console.log(this.state.id);
      // console.log(data);
      // console.log(this.props);
      if (data.metadata) {
        this.setState({
          showSixParameters: true,
        });
      }
      const redirectToUVDashboard = accessToUV.some(
        (uv_id) => uv_id == data.id,
      );
      // if(data.id == '1ab30d46-9b1a-4a5c-bf0e-d0bec0ab8cf1'){
      //   this.props.history.push("/dashboard/hpe");
      // }
      // console.log("should redirect to uv " + redirectToUVDashboard);
      if (redirectToUVDashboard) {
        this.props.history.push("/uv/dashboard");
      }
      // console.log(data);
      this.setState({
        customerName: data.name,
        customer_logo: data.customer_logo ? data.customer_logo : "",
      });
      let user_list = data.Users;
      let summaryData = (
        await getSummaryAqData(this.state.id, true, "Yesterday")
      ).data;
      // console.log(summaryData);
      const dashboard_active = data.Users[0].dashboard_active;
      const outsideDataShow = data.Users[0].OutdoorDataShow;
      // console.log('Dashboard.data: ',data);
      const purifier_status = data.purifier_status;
      const aqi_ranges = {};
      aqi_ranges["pm25_range"] = data.pm25_range;
      aqi_ranges["pm10_range"] = data.pm10_range;
      aqi_ranges["co2_range"] = data.co2_range;
      aqi_ranges["voc_range"] = data.voc_range;
      // console.log('summaryData: ',summaryData.data);
      this.setState({
        filterInfo: summaryData.data[3].filter_life,
      });
      var aqi_value_obj = [
        { key: "pm25_range", min: "", max: "", label: "PM2.5" },
        { key: "pm10_range", min: "", max: "", label: "PM10" },
        { key: "co2_range", min: "", max: "", label: "CO2" },
        { key: "co_range", min: "40", max: "300", label: "CO" },
        { key: "voc_range", min: "", max: "", label: "VOC" },
        { key: "lux_range", min: "1000", max: "50000", label: "LUX" },
        { key: "uv_range", min: "0.2", max: "2.0", label: "UV" },
        { key: "snd_range", min: "10", max: "40", label: "NOISE" },
      ];
      for (var label in aqi_ranges) {
        var label_data = data[label].split(",");
        var select_data = _.filter(aqi_value_obj, (x) => x.key === label);
        select_data[0].min = label_data[0];
        select_data[0].max = label_data[1];
      }
      this.setState({
        loadingData: false,
      });
      const customerName = data.name;
      if (data.Properties.length > 0) {
        data = _(data.Properties)
          .groupBy((property) => property.city)
          .map((value, key) => ({ name: key, properties: value }))
          .sortBy((cityRegion) => cityRegion.name)
          .value();

        // console.log('data: ',data);
        let cityNamesList = [];
        for (let i = 0, l = data.length; i < l; i++) {
          cityNamesList.push(data[i].name);
        }
        let selectedCityIndex = 0;
        let selectedCity = cityNamesList[selectedCityIndex];
        let propDataForSummary = {};
        // propDataForSummary=summaryData.data[0];
        for (let i = 0, l = summaryData.data.length; i < l; i++) {
          if (
            summaryData.data[i].cityName === cityNamesList[selectedCityIndex]
          ) {
            propDataForSummary = summaryData.data[i];
            break;
          }
        }

        let uv_data_obj = summaryData.data[2 + cityNamesList.length][0];
        // console.log(uv_data_obj);
        // console.log(uv_data_obj.uv_data);
        propDataForSummary["uv_device_data"] =
          uv_data_obj != undefined || uv_data_obj != null
            ? uv_data_obj.uv_device_data
            : null;

        // console.log("propDataForSummary", propDataForSummary);
        var report_flag_disable = null;
        this.state.id === capitaland_customerId ||
        capitaland_gurgaon_customerId ||
        capitaland_chennai_customerId ||
        capitaland_vitp_customerId ||
        capitaland_vitp_customerId ||
        capitaland_aitpp_customerId
          ? (report_flag_disable = false)
          : (report_flag_disable = true);

        let supervisior_flag = false;
        if (getSupervisorData()) {
          supervisior_flag = true;
        }
        this.setState({
          data,
          summaryData,
          dashboard_active,
          outsideDataShow,
          defaultSelectedKeys: ["summary"],
          selectedMenu: "summary",
          defaultOpenKeys: ["summary"],
          customerName,
          cityNamesList,
          selectedCityIndex,
          selectedCity,
          propDataForSummary,
          report_flag_disable: report_flag_disable,
          aqi_ranges: aqi_value_obj,
          purifier_status: purifier_status,
          user_list,
          supervisior_flag,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleLogoutClick = (e) => {
    removeToken();
    removeCsrId();
    removeCache();
    this.props.history.push("/");
  };

  handleChangeClick = (e) => {
    this.props.history.push("/changePassword");
  };

  handleClick = (e) => {
    if (this.state.selectedMenu !== e.keyPath[0]) {
      this.setState({ selectedMenu: e.keyPath[0] });
    }
  };

  handleReferer = (e) => {
    var data = getSupervisorData();
    this.props.history.push("/customer/admin", { data: data });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleDownloadClick = async (e) => {
    const deepData = true;
    const { startValue, endValue, reportType } = this.state;
    if (startValue === null || endValue === null) {
      this.setState({
        showAlert: true,
      });
    } else {
      this.setState({ confirmLoading: true, showAlert: false });
      try {
        // console.log({
        //   id: this.state.id,
        //   deepData,
        //   startValue: startValue.utc().format("YYYY-MM-DD"),
        //   endValue: endValue.utc().format("YYYY-MM-DD"),
        //   fileType: this.state.fileType,
        //   city: this.state.selectedCity,
        //   name: this.state.customerName,
        // });
        if (reportType) {
          await getAqExcelFile(
            this.state.id,
            deepData,
            startValue.utc().format("YYYY-MM-DD"),
            endValue.utc().format("YYYY-MM-DD"),
            this.state.fileType,
            this.state.selectedCity,
            this.state.customerName,
          );
        } else {
          await getPurifierExcelReport(
            this.state.id,
            startValue.utc().format("YYYY-MM-DD"),
            endValue.utc().format("YYYY-MM-DD"),
          );
        }
      } catch (err) {
        console.log(err);
      }
      this.setState({ confirmLoading: false, visible: false });
    }
  };

  handleDropdownButtonClickForCity = (e) => {
    let index = parseInt(e.key);
    if (index !== this.state.selectedCityIndex) {
      for (let i = 0, l = this.state.summaryData.data.length; i < l; i++) {
        if (
          this.state.summaryData.data[i].cityName ===
          this.state.cityNamesList[index]
        ) {
          this.setState({
            selectedCityIndex: index,
            selectedCity: this.state.cityNamesList[index],
            selectedMenu: "summary",
            propDataForSummary: this.state.summaryData.data[i],
          });
          break;
        }
      }
    }
  };
  handleOk = () => {
    this.setState({
      ModalText: "The modal will be closed after two seconds",
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  };
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  disabledStartDate = (current) => {
    let customDate = "2019-11-01";
    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  ontypeChange = (e) => {
    this.setState({
      fileType: e.target.value,
    });
  };
  switchChange = (checked, e) => {
    if (e === "report_type") {
      if (checked) {
        this.setState({
          reportType: true,
        });
      } else {
        this.setState({
          reportType: false,
        });
      }
    } else if (e === "data_type") {
      if (checked) {
        this.setState({
          fileType: true,
        });
      } else {
        this.setState({
          fileType: false,
        });
      }
    }
  };

  kioskDisplay = (e) => {
    this.props.history.push("/kiosk", { refferer: "dashboard" });
  };

  render() {
    const {
      data,
      selectedCity,
      id,
      propDataForSummary,
      aqi_ranges,
      selectedMenu,
      cityNamesList,
      outsideDataShow,
      selectedCityIndex,
      visible,
      reportType,
      report_flag_disable,
      confirmLoading,
      dashboard_active,
      show,
      startValue,
      endValue,
      endOpen,
      user_list,
      supervisior_flag,
      purifier_status,
    } = this.state;
    const profile_menu = (
      <Menu>
        {supervisior_flag ? (
          <Menu.Item onClick={() => this.handleReferer()}>
            <BlockOutlined
              style={{ fontSize: "15px", marginLeft: "8px", cursor: "pointer" }}
            />
            <span>Change Dashboard</span>
          </Menu.Item>
        ) : null}
        {/* {localStorage.getItem("admintoken") && (
          <Menu.Item onClick={() => this.props.history.push("/admin")}>
            <BlockOutlined
              style={{ fontSize: "15px", marginLeft: "8px", cursor: "pointer" }}
            />
            <span>Admin Dashboard</span>
          </Menu.Item>
        )} */}

        {/* <Menu.Item onClick={() => this.kioskDisplay()}>
          <DesktopOutlined
            style={{ fontSize: "15px", marginLeft: "8px", cursor: "pointer" }}
          />
          <span>Kiosk</span>
        </Menu.Item> */}

        <Menu.Item onClick={() => this.handleLogoutClick()}>
          <ExportOutlined
            style={{ fontSize: "15px", marginLeft: "8px", cursor: "pointer" }}
          />
          <span>Logout</span>
        </Menu.Item>
      </Menu>
    );
    if (this.state.loadingData) {
      return (
        <div className="dashLoaderContainer">
          <Spin size="large" />
        </div>
      );
    }
    if (!data) {
      return (
        <Layout className="homeLayout">
          <Header className="homeHeader">
            <div className="homeHeaderContainer">
              <div className="logoContainer">
                <a href="/">
                  <img className="logoHome" src={claircologo} />
                </a>
              </div>
            </div>
          </Header>
          <Content className="homeContentContainer">
            <div className="contentContainer">
              <Steps className="dashDownload1" direction="vertical" current={1}>
                <Step
                  title="User Registered"
                  description="You have been registered."
                />
                <Step
                  title="Verification Pending"
                  description="Your account verification is pending.
                              Contact Udayan Banerjee at +91-9898036463 or udayan@clairco.in"
                />
                <Step title="User Verfied" description="Dashboard is live." />
              </Steps>
              <Button
                icon="logout"
                className="dashDownload1"
                onClick={this.handleLogoutClick}
                loading={this.state.loading}
                type="primary"
                shape="round"
                style={{}}
              >
                Log Out
              </Button>
            </div>
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            Aliferous Technologies pvt. ltd. ©2018
          </Footer>
        </Layout>
      );
    }
    if (!dashboard_active) {
      return (
        <Layout className="homeLayout">
          <Header className="homeHeader">
            <div className="homeHeaderContainer">
              <div className="logoContainer">
                <a href="/">
                  <img className="logoHome" src={claircologo} />
                </a>
              </div>
            </div>
          </Header>
          <Result
            style={{ marginTop: 100 }}
            status="500"
            title=""
            subTitle="Sorry, you are not authorized to access this page. 
                          Please, contact Udayan Banerjee at +91-9898036463 or udayan@clairco.in"
            extra={<Button type="primary">Back Home</Button>}
          />
        </Layout>
      );
    }
    const citiesSubmenu = data.map((city) => {
      const propSubmenu =
        city.name === selectedCity
          ? city.properties.map((property) => {
              const spacesMenuItem = property.Spaces.sort((a, b) => {
                return a.name.toString().localeCompare(b.name);
              }).map((space) => {
                return (
                  <Menu.Item
                    key={
                      property.id +
                      ":" +
                      space.Device.id +
                      ":" +
                      (property.OutsideDevice
                        ? property.OutsideDevice.id
                        : property.NeighbourProperty &&
                          property.NeighbourProperty.OutsideDevice
                        ? property.NeighbourProperty.OutsideDevice.id
                        : property.station_id
                        ? property.station_id
                        : null) +
                      ":" +
                      space.name +
                      ":" +
                      property.name +
                      ":" +
                      space.id +
                      ":" +
                      this.state.id
                    }
                  >
                    {space.name}
                  </Menu.Item>
                );
              });
              return (
                <SubMenu
                  key={property.id}
                  title={property.name}
                  children={spacesMenuItem}
                />
              );
            })
          : null;
      return propSubmenu != null ? (
        <SubMenu
          key={"properties"}
          style={{ paddingTop: "10px" }}
          title={
            <span>
              <Icon type="bank" style={{ color: "#FFF" }} />
              <span style={{ fontFamily: "'Montserrat', sans-serif" }}>
                Properties
              </span>
            </span>
          }
          children={propSubmenu}
        />
      ) : null;
    });

    const logo =
      id === cureFit_customerId
        ? cureFit_logo
        : id === blueTokai_customerId
        ? blueTokai_logo
        : id === wtc_customerId
        ? wtc_logo
        : id === dlf_customerId
        ? dlf_logo
        : id === maxEstates_customerId
        ? maxEstates_logo
        : id === buzzworks_customerId
        ? buzzworks_logo
        : id === cowrks_customerId
        ? cowrks_logo
        : id === sarva_customerId
        ? sarva_logo
        : id === capitaland_customerId ||
          id === capitaland_gurgaon_customerId ||
          id === capitaland_chennai_customerId ||
          id === capitaland_vitp_customerId ||
          id === capitaland_aitpp_customerId
        ? capitaland_logo
        : id === orionMall_customerId || id === orionAvenue_customerId
        ? orionMall_logo
        : id === wework_customerId
        ? wework_logo
        : id === emaar_customerId
        ? emaar_logo
        : id === candorTechSpace_customerId
        ? candorTechSpace_logo
        : id === knightFrank_customerId
        ? knightFrank_logo
        : id === mgfMetropolitan_customerId
        ? mgfMetropolitan_logo
        : id === suncityProjects_customerId
        ? suncityProjects_logo
        : id === aviva_customerId
        ? aviva_logo
        : id === tag_customerId
        ? tag_logo
        : clairco_logo;
    const cityMenuItems = cityNamesList.map((item, i) => (
      <Menu.Item key={i}>
        <Icon type="environment" />
        {item}
      </Menu.Item>
    ));

    const cityNamesDropdownButton = (
      <Menu onClick={this.handleDropdownButtonClickForCity}>
        {cityMenuItems}
      </Menu>
    );
    return (
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
          collapsedWidth={0}
        >
          <div className="customerLogoContainer">
            <img
              src={this.state.customer_logo ? this.state.customer_logo : logo}
              className="customerLogo"
            />
          </div>
          <div className="customer_name_container">
            {this.state.customerName}
          </div>
          <Dropdown overlay={cityNamesDropdownButton}>
            <Button className="buttonCityNamesList">
              <Icon
                className="locationIcon"
                type="environment"
                style={{ color: COLOR.good }}
              />
              {cityNamesList[selectedCityIndex]}
              <Icon type="down" />
            </Button>
          </Dropdown>
          <Menu
            class="sidermenu"
            style={{ minHeight: "40vh" }}
            theme="dark"
            onClick={this.handleClick}
            mode="inline"
            defaultSelectedKeys={["summary"]}
          >
            <Menu.Item
              style={{ marginTop: "20px" }}
              key="summary"
              title="Dashboard"
            >
              <WindowsOutlined style={{ fontSize: "21px" }} />
              <span style={{ fontFamily: "'Montserrat', sans-serif" }}>
                Dashboard
              </span>
            </Menu.Item>
            {citiesSubmenu}
            {id != test_customerId ? (
              <Menu.Item
                style={{ marginTop: "20px" }}
                key="logs"
                title="Maintainence"
              >
                <SettingOutlined style={{ fontSize: "21px" }} />
                <span style={{ fontFamily: "'Montserrat', sans-serif" }}>
                  AHU Maintenance
                </span>
                {/* nav 3 */}
              </Menu.Item>
            ) : null}
            {/* <Menu.Item
              style={{ marginTop: "20px" }}
              key="reports"
              title="Reports"
            >
              <BarChartOutlined style={{ fontSize: "21px" }} />
              <span style={{ fontFamily: "'Montserrat', sans-serif" }}>
                Reports
              </span>
            </Menu.Item> */}
            {purifier_status ? (
              <Menu.Item key="purifiers" style={{ marginTop: "20px" }}>
                <Icon type="tool" style={{ fontSize: "21px" }} />
                <span style={{ fontFamily: "'Montserrat', sans-serif" }}>
                  Air Purifiers
                </span>
              </Menu.Item>
            ) : null}
            {getUserRole() == "supervisor" ? (
              <Menu.Item
                style={{ marginTop: "20px" }}
                key="users"
                title="User Management"
              >
                <UserOutlined style={{ fontSize: "21px" }} />
                <span style={{ fontFamily: "'Montserrat', sans-serif" }}>
                  User Management
                </span>
                {/* nav 1 */}
              </Menu.Item>
            ) : null}
            {id === maxEstates_customerId || id === maxhouse_okhla ? (
              <Menu.Item key="kiosk">
                <Icon type="desktop" />
                Kiosk Management
              </Menu.Item>
            ) : null}

            {/* <Menu.Item title="UV Dashboard">
              <Link to="/uv/dashboard">
                <Icon type="monitor" /> UV Dashboard
              </Link>
            </Menu.Item> */}
          </Menu>
          {id !== ntpl_customerId ? (
            <Tooltip placement="right" text="Download Reports">
              <Button
                className="dashDownload"
                title="Download Reports"
                onClick={this.showModal}
              >
                <DownloadOutlined style={{ fontSize: "21px" }} />
                Download Reports
                {/* nav 3 */}
              </Button>
            </Tooltip>
          ) : (
            <div></div>
          )}
          <Modal
            title="Download Excel Report"
            visible={visible}
            width={480}
            onOk={this.handleDownloadClick}
            confirmLoading={confirmLoading}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button
                icon="download"
                key="submit"
                type="primary"
                loading={confirmLoading}
                onClick={this.handleDownloadClick}
              >
                Download
              </Button>,
            ]}
          >
            <div>
              {this.state.showAlert && (
                <Alert
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  message={this.state.error}
                  type="error"
                  showIcon
                />
              )}
              <DatePicker
                disabledDate={this.disabledDate}
                // defaultValue={moment(lastDate, "DD-MM-YYYY")}
                format="YYYY-MM-DD"
                value={startValue}
                placeholder="Start Date"
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
              />
              <DatePicker
                className="endDatePicker"
                disabledDate={this.disabledDate}
                format="YYYY-MM-DD"
                value={endValue}
                placeholder="End Date"
                onChange={this.onEndChange}
                open={endOpen}
                onOpenChange={this.handleEndOpenChange}
              />
            </div>
            <div
              style={{
                marginTop: 20,
                marginLeft: 20,
              }}
            >
              {id === capitaland_customerId || wtc_customerId ? (
                <div>
                  <div className="small_margin">
                    <b>Report Type:</b>
                    <Switch
                      disabled={report_flag_disable}
                      className="small_margin"
                      defaultChecked={this.state.reportType}
                      onClick={(checked, e) =>
                        this.switchChange(checked, "report_type")
                      }
                      checkedChildren="Data Report"
                      unCheckedChildren="Purifier Report"
                    />
                    <b>Data Type:</b>
                    <Switch
                      className="small_margin"
                      defaultChecked={this.state.fileType}
                      onClick={(checked, e) =>
                        this.switchChange(checked, "data_type")
                      }
                      checkedChildren="Daily Average"
                      unCheckedChildren="Hourly Average"
                    />
                    <br />
                  </div>
                  <div className="small_margin margin-top-15p">
                    <b>NOTE:</b> Toggle the switch to change the Report Type.
                  </div>
                  {/* Report Type: <br/>
                      <Radio.Group onChange={this.ontypeChange} value={fileType}>
                        <Radio value={1}>Purifier Report</Radio>
                        <Radio value={2}>Data Report</Radio>
                      </Radio.Group>
                      <br/>
                      Data Type: <br/>
                      <Radio.Group onChange={this.ontypeChange} value={fileType}>
                        <Radio value={1}>Daily Average Report</Radio>
                        <Radio value={2}>Hourly Data Report</Radio>
                      </Radio.Group> */}
                </div>
              ) : null}
            </div>
          </Modal>
        </Sider>
        <Layout
          className="site-layout"
          style={{ marginLeft: "200px", overflow: "hidden" }}
        >
          {/* <Header className="site-layout-background" style={{ padding: 0 }}>
                <div className="homeHeaderContainer">
                  <div className="logoContainer">
                    <a href="/">
                      <img className="logoHome" src={claircologo}/>
                    </a>
                  </div>
                </div>
                <div style={{right:'12%', position:'absolute', display:'inline-flex', top:'4%', cursor:'pointer'}}>
                {
                // <Dropdown overlay={profile_menu} placement="bottomLeft">
                //   <Button style={{backgroundColor:'red', cursor:'pointer', padding:'1px', border:'none', marginLeft:'20%'}}>
                //     <Avatar icon={<BellOutlined />} style={{marginRight: '10px'}}/>
                //   </Button>
                // </Dropdown>
      }
                </div>
                <div className="header_menu_holder">
                  <div className="header_user_menu_holder">
                    <Dropdown overlay={profile_menu} trigger={['click', 'hover', 'contextMenu']} placement="bottomLeft">
                      <Button style={{backgroundColor:'transparent', cursor:'pointer', padding:'1px', border:'none', marginLeft:'20%'}}>
                        <Avatar size={40} icon={<UserOutlined />} style={{marginRight: '10px', backgroundColor: '#00695c'}}/>
                          <span>
                            {
                              getUsername() 
                            }
                                    
                                </span>
                              </Button>
                          </Dropdown>
                        </div>
                </div>    
                            
              </Header>
                          </span>
                      </Button>
                    </Dropdown>
                  </div>
                </div>                
              </Header> */}
          <Header
            className="site-layout-sub-header-background"
            style={{ padding: 0 }}
          >
            <div className="headerLogo">
              {/* <Icon
                className="trigger"
                type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                onClick={this.toggle}
              /> */}
              {/* Powered by <img src={clairco_logo} className="claircoLogo"/> */}
              <span style={{ fontSize: 25 }}>
                Welcome <b>Admin</b>
              </span>
            </div>
            <div
              className="adminMenuHolder"
              style={{ backgroundColor: "#FFF" }}
            >
              <div>
                <Menu mode="horizontal" style={{ backgroundColor: "#FFF" }}>
                  <SubMenu
                    title={
                      <Badge dot={show}>
                        <Icon type="bell" style={{ fontSize: "26px" }} />
                      </Badge>
                    }
                  >
                    <div className="notificationShade">
                      No New Notifications
                    </div>
                  </SubMenu>
                </Menu>
              </div>
              <Menu mode="horizontal" style={{ backgroundColor: "#FFF" }}>
                <SubMenu
                  title={
                    <div>
                      <Icon type="menu" style={{ fontSize: "26px" }} />
                    </div>
                  }
                  // style={{ backgroundColor: "#FFF !important" }}
                >
                  {getUserRole() == "supervisor" ? (
                    <Menu.Item
                      onClick={() => this.handleReferer()}
                      style={{
                        color: "#fff",
                      }}
                    >
                      <BlockOutlined
                        style={{
                          fontSize: "15px",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                      />
                      <span>Change Dashboard</span>
                    </Menu.Item>
                  ) : null}
                  {localStorage.getItem("admintoken") && (
                    <Menu.Item
                      onClick={() =>
                        this.props.history.push("/admin/dashboard")
                      }
                      style={{
                        color: "#fff",
                      }}
                    >
                      <BlockOutlined
                        style={{
                          fontSize: "15px",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                      />
                      <span> Admin Dashboard</span>
                    </Menu.Item>
                  )}

                  <Menu.Item
                    onClick={() => this.kioskDisplay()}
                    style={{
                      color: "#fff",
                    }}
                    className="menu-item"
                  >
                    <DesktopOutlined
                      style={{
                        fontSize: "15px",
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                    />
                    <span>Kiosk</span>
                  </Menu.Item>
                  <Menu.Item
                    key="setting:1"
                    onClick={this.handleChangeClick}
                    style={{
                      color: "#FFF",
                    }}
                  >
                    <EditOutlined
                      style={{
                        fontSize: "15px",
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                    />
                    Change Password
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      // this.props.history.push("/ticket/dashboard");
                      window.open(
                        "http://localhost:3000/ticket/dashboard",
                        "_blank",
                      );
                    }}
                    style={{
                      color: "#FFF",
                    }}
                  >
                    <DesktopOutlined
                      style={{
                        fontSize: "15px",
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                    />
                    <span>Help for Issue</span>
                  </Menu.Item>
                  <Menu.Item
                    style={{
                      color: "#FFF",
                    }}
                    onClick={() => this.handleLogoutClick()}
                  >
                    <ExportOutlined
                      style={{
                        fontSize: "15px",
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                    />
                    <span>Logout</span>
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </div>
          </Header>
          <Content
            className="homeContentContainer site-layout-background"
            style={{
              minHeight: 500,
            }}
          >
            {selectedMenu === "kiosk" ? (
              <KioskManagement id={id}/>
            ) : selectedMenu === "summary" ? (
              id === test_customerId ? (
                <SummaryPageHPE id={id} aqiRange={aqi_ranges} />
              ) : (
                <SummaryPage
                  id={id}
                  data={propDataForSummary}
                  properties={data[selectedCityIndex].properties}
                  filterInfo={this.state.filterInfo}
                  selectedCity={selectedCity}
                  aqiRange={aqi_ranges}
                  users={user_list}
                  showSixParameters={this.state.showSixParameters}
                />
              )
            ) : selectedMenu === "logs" ? (
              <MaintainenceLogs
                id={id}
                properties={data[selectedCityIndex].properties}
                source={"dashboard"}
                logsCount={1}
              />
            ) : selectedMenu === "users" ? (
              <UserControl id={id} />
            ) : selectedMenu === "purifiers" ? (
              fractal_Ids.indexOf(id) > -1 ? <FFUView id={id} /> : <PurifierView id={id} />
            ) : (
              <PropertiesCapitaLand
                selectedMenu={selectedMenu}
                dateRangeIndex={1}
                outsideDataShow={outsideDataShow}
                aqiRange={aqi_ranges}
                {...this.props}
                customerId={id}
                logo={this.state.customer_logo}
                showSixParameters={this.state.showSixParameters}
              />
            )}
          </Content>
        </Layout>
      </Layout>
    );
  }
}
