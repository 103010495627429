import React, { Component } from "react";
import { Button, Alert, Form, Input, Radio } from "antd";
import SpaceForm from "../components/SpaceForm";
import ACForm from "../components/ACForm";
import { addAC } from "../api";
import { resolve } from "url";


const FormItem = Form.Item;

export default class AddAC extends Component {
  constructor(props) {
    super(props);
    this.spaceId = this.props.location.state.id;
    this.state = {
      loading: false,
      response: "",
      brands:["Voltas","Hitachi","LG","Blue Star","Samsung","Carrier","Lloyd","O General","Mitsubishi ELectric",
    "Daikin","Haier","Whirlpool","Videocon","Sansui","Kenstar","Mitashi","Kelvinator","Hyundai","Onida",
  "IFB","Godrej","Panasonic",""]
    };
  }

  saveAC = () => {
    const form = this.formRef.props.form;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      console.log(values.installed_on);
     values.installed_on=values.installed_on.utc().format("YYYY-MM-DD");
     this.setState({ loading: true });
      try {
        const response = await addAC({
          ...values,
          space_id: this.spaceId
        });
        form.resetFields();
        this.setState({ code: 200, response: "Data created successfully" });
      } catch (e) {
        console.log(e);
        this.setState({ code: 400, response: e.response.data.message });
      }
      this.setState({ loading: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    return (
      <div style={style.container}>
        <h1>Add AC and Filter</h1>
        <ACForm wrappedComponentRef={this.saveFormRef} brands={this.state.brands}/>
        {this.state.response && (
          <Alert
            message={this.state.response}
            type={this.state.code == 200 ? "success" : "error"}
            showIcon
          />
        )}
        <Button
          style={style.saveBtn}
          type="primary"
          loading={this.state.loading}
          onClick={this.saveAC}>
          Save
        </Button>
      </div>
    );
  }
}

const style = {
  container: {
    margin: "auto",
    maxWidth: 500,
    border: "3 px solid green",
    paddingTop: 30
  },
  saveBtn: {
    marginTop: 20,
    marginBottom: 20
  }
};
