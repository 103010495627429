import React, { Component } from "react";
import { Spin, Row, Col } from "antd";
import _ from "lodash";
import "./Kiosk.less";
import InsideBackground from "../../img/Indoor.png";
import OutsideBackground from "../../img/Outdoor.png";
import logo from "../../img/clairco.png";
import { COLOR, GET_CLR_POLLUTANT_LVL } from "../../colors";
import { GET_P_UNIT_HTML } from "../../pLevels";
import { getSummaryAqData, getCustomer } from "../../api";
import cbre_logo from "../../img/customerLogos/cbre.png";

const axis_house = "eb53efcd-d4e8-47f8-970c-8938145eabd7";
const max_house_okhla = "fd6a8e6d-a0c1-4387-8b84-a6a20b48559e";
const societe_general = "63f5a7e9-f3cf-497a-8beb-5016e926f6f6";
const rmz_id = "bb930af8-eb2c-444a-8fc9-bbf5faeb6531";
const fractal_mub_id = "55a74949-e121-44ee-b30b-a1e34a3c836c";
const network18_id = "231d76c9-d16d-4a6a-9b52-b1e7eeb3fb90";

export default class Kiosk extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      customerId: this.props.customerId || this.props.id,
      spaceName: this.props.spacename,
      propertyName: this.props.propertyname,
      dataIsLoaded: false,
      prop_data: this.props.data || this.props.location.state.data,
      data: undefined,
    };
  }

  getValueOut = (pollutantLabel) => {
    let { data } = this.state;
    console.log(data);
    // return pollutantLabel == "PM2.5"
    //   ? data.avgOut.pm2_5 || data.avgOut.pm25 || data.avgOut.data["1"]["pm2.5"]
    //   : pollutantLabel == "PM10"
    //   ? data.avgOut.pm10
    //   : null;
    return pollutantLabel == "PM2.5"
      ? data.avgOut.pm2_5 || data.avgOut.pm25 || data.avgOut['pm2.5']
      : pollutantLabel == "PM10"
      ? data.avgOut.pm10
      : pollutantLabel == "CO2"
      ? data.avgOut.co2
      : pollutantLabel == "VOC"
      ? data.avgOut.voc
      : pollutantLabel == "TEMP"
      ? data.avgOut.temp
      : pollutantLabel == "HUM"
      ? data.avgOut.hum
      : null;
  };

  getValueIn = (pollutantLabel) => {
    let { data } = this.state;
    return pollutantLabel == "PM2.5"
      ? data.avgIns.pm2_5 || data.avgIns.pm25
      : pollutantLabel == "PM10"
      ? data.avgIns.pm10
      : pollutantLabel == "CO2"
      ? data.avgIns.co2
      : pollutantLabel == "VOC"
      ? data.avgIns.voc
      : pollutantLabel == "TEMP"
      ? data.avgIns.temp
      : pollutantLabel == "HUM"
      ? data.avgIns.hum
      : null;
  };

  async componentDidMount() {
    this.fetchDataAndSetState();
  }

  async componentDidUpdate(prevProps, prevState) {
    // const prev_updated_time_in = prevProps.data.avgIns.time;
    // const prev_updated_time_out = prevProps.data.avgOut.time;
    // const current_time_in = this.props.data.avgIns.time;
    // const current_time_out = prevProps.data.avgOut.time;

    const current_pm25 =
      this.props.data.avgIns["pm2_5"] || this.props.data.avgIns["pm25"];
    const prev_pm25 =
      prevProps.data.avgIns["pm2_5"] || prevProps.data.avgIns["pm25"];
    // if((current_time_in != prev_updated_time_in) || (current_time_out != prev_updated_time_out)){
    //     this.setState({
    //         data: this.props.data
    //     })
    // } else
    if (prev_pm25 !== current_pm25) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  fetchDataAndSetState = async () => {
    let { prop_data } = this.state;
    let id = null;
    let temp_data = {};
    if (prop_data == undefined) {
      id = this.props.data.id;
      let { data } = (await getCustomer(id, true)).data;
      let summaryData = (await getSummaryAqData(id, true, "Yesterday")).data;
      const customerName = data.name;
      if (data.Properties.length > 0) {
        data = _(data.Properties)
          .groupBy((property) => property.city)
          .map((value, key) => ({ name: key, properties: value }))
          .sortBy((cityRegion) => cityRegion.name)
          .value();
        let cityNamesList = [];
        for (let i = 0, l = data.length; i < l; i++) {
          cityNamesList.push(data[i].name);
        }
        let selectedCityIndex = 0;
        for (let i = 0, l = summaryData.data.length; i < l; i++) {
          if (
            summaryData.data[i].cityName === cityNamesList[selectedCityIndex]
          ) {
            temp_data = summaryData.data[i];
            break;
          }
        }
        temp_data.customerName = customerName;
        id === axis_house
          ? (temp_data.outSpaceName = "Noida")
          : id === max_house_okhla
          ? (temp_data.outSpaceName = "New Delhi")
          : (temp_data.outSpaceName = "RajajiNagar");
        id === axis_house
          ? (temp_data.outPropertyName = "Uttar Pradesh")
          : id === max_house_okhla
          ? (temp_data.outPropertyName = "Delhi")
          : (temp_data.outPropertyName = "Bangalore");
        id === axis_house
          ? (temp_data.customerCity = "Uttar Pradesh")
          : (temp_data.customerCity = "Bangalore");

        temp_data.avgIns.voc = "0";
        temp_data.logo = data.customer_logo;
        // temp_data.avgOut == null?
        //     temp_data.avgOut = {
        //         'pm2_5': 240,
        //         'pm10': 290,
        //         'co2': 'NA',
        //         'voc': 'NA'
        //     }:0;
        this.setState({
          data: temp_data,
          id: id,
          prop_data: undefined,
        });
      } else {
      }
    } else if (prop_data.type == "space") {
      temp_data = prop_data;
      // temp_data.avgOut == null ?
      //                         temp_data.avgOut = {
      //                             'pm2_5': 46,
      //                             'pm10': 61
      //                         }:0;

      // temp_data.customerCity === 'Max House Okhla'
      //                         ? temp_data.id = max_house_okhla
      //                             : temp_data.customerCity === 'WTC Trades and Projects Pvt Ltd'
      //                                 ? temp_data.id = wtc_bangalore
      //                                     : temp_data.customerCity === 'Max Towers (Noida)'
      //                                         ? temp_data.id = maxEstates_customerId
      //                                             : null;
      // console.log(temp_data);
      this.setState({
        data: temp_data,
        id: this.props.customer,
      });
    } else {
      temp_data = prop_data;
      temp_data.outSpaceName = "Noida";
      temp_data.outPropertyName = "Uttar Pradesh";
      // temp_data.customerCity === 'Max House Okhla'
      //                         ? temp_data.id = max_house_okhla
      //                             : temp_data.customerCity === 'WTC Trades and Projects Pvt Ltd'
      //                                 ? temp_data.id = wtc_bangalore
      //                                     : temp_data.customerCity === 'Max Towers (Noida)'
      //                                         ? temp_data.id = maxEstates_customerId
      //                                             : null;
      // temp_data.avgOut == null?
      //         temp_data.avgOut = {
      //             'pm2_5': 240,
      //             'pm10': 290
      //         }:0;
      this.setState({
        data: temp_data,
        id: this.props.id,
        prop_data: undefined,
      });
    }
  };

  render() {
    const { data, id } = this.state;
    if (!data) {
      return (
        <div className="dashLoaderContainer">
          <Spin size="large" />
        </div>
      );
    }
    // const cust_logo =
    //   this.state.customerId === orionMall_customerId
    //     ? orionMall_logo
    //     : this.state.customerId === maxEstates_customerId
    //     ? maxEstates_logo
    //     : this.state.customerId === emaar_customerId
    //     ? emaar_logo
    //     : this.state.customerId === axis_house
    //     ? axis_logo
    //     : this.state.customerId === wtc_bangalore
    //     ? wtc_logo
    //     : this.state.customerId === max_house_okhla
    //     ? maxEstates_logo
    //     : null;
    const cust_logo = data.logo;
    const header_logo = this.state.customerId === axis_house ? cbre_logo : logo;
    const aqLevels = ["Good", "Satisfactory", "Moderate", "Poor", "Very Poor"];
    let aqLabels;
    aqLabels =
      (this.state.customerId === max_house_okhla || this.state.id === fractal_mub_id || this.state.customerId === network18_id)
        ? ["PM2.5", "PM10", "CO2", "VOC"]
        : (this.state.customerId === societe_general || this.state.customerId === rmz_id)
        ? ["PM2.5", "PM10", "CO2", "VOC", "TEMP", "HUM"]
        : ["PM2.5"];
    const tileContainerInside = aqLabels.map((item, i) => {
      return (
        <div key={i} className="valuesContainer">
          <div className="aqLabelHolder">{item}</div>

          <div
            className="aqValueHolder"
            style={{
              backgroundColor:
                this.getValueIn(item) || this.getValueIn(item) === 0
                  ? GET_CLR_POLLUTANT_LVL(this.getValueIn(item), item)
                  : "#fff",
              color:
                this.getValueIn(item) || this.getValueIn(item) === 0
                  ? "#fff"
                  : "#000",
            }}
          >
            {/* {aqValueInside} */}
            {this.getValueIn(item) || this.getValueIn(item) === 0
              ? this.getValueIn(item)
              : "NA"}
            <span style={{ fontSize: "0.5em" }}>
              {(this.getValueIn(item) || this.getValueIn(item) === 0) &&
                GET_P_UNIT_HTML(item)}
            </span>
          </div>
        </div>
      );
    });
    const tileContainerOutside = aqLabels.map((item, i) => {
      return (
        <div className="valuesContainer">
          <div className="aqLabelHolder">{item}</div>
          <div
            className="aqValueHolder"
            style={{
              backgroundColor:
                this.getValueOut(item) || this.getValueOut(item) === 0
                  ? GET_CLR_POLLUTANT_LVL(this.getValueOut(item), item)
                  : "#fff",
              color:
                this.getValueOut(item) || this.getValueOut(item) === 0
                  ? "#fff"
                  : "#000",
            }}
          >
            {/* {aqValueInside} */}
            {this.getValueOut(item) || this.getValueOut(item) === 0
              ? this.getValueOut(item)
              : "NA"}
            <span style={{ fontSize: "0.5em" }}>
              {(this.getValueOut(item) || this.getValueOut(item) === 0) &&
                GET_P_UNIT_HTML(item)}
            </span>
          </div>
        </div>
      );
    });

    const ftLvlItemsMapped = aqLevels.map((item, i) => {
      return (
        <Col
          key={i}
          className="ftlvlItems"
          span={4}
          style={{
            backgroundColor:
              item == "Good"
                ? COLOR.good
                : item == "Satisfactory"
                ? COLOR.satisfactory
                : item == "Moderate"
                ? COLOR.moderate
                : item == "Poor"
                ? COLOR.poor
                : item == "Very Poor"
                ? COLOR.veryPoor
                : COLOR.severe,
          }}
        >
          <Col className="ftlvlItemsContainer" justify="center" align="middle">
            {item}
          </Col>
        </Col>
      );
    });

    const ftLvlItems = <Row gutter={16}>{ftLvlItemsMapped}</Row>;
    return !this.state.data ? (
      <div className="propertiesLoaderContainer">
        <Spin size="large" />
      </div>
    ) : (
      <div className="kiosk_container">
        <Row
          className="header_row"
          gutter={[16, 16]}
          type="flex"
          justify="center"
          align="middle"
          style={{ height: "100px" }}
        >
          <Row style={{ width: "100%" }}>
            <img
              className="kiosk_footer_banner customer"
              src={cust_logo}
              alt="Customer"
            />
            <div className="headerlabel">
              <span style={{ color: "#000" }}> in association with </span>
            </div>
            <img className="kiosk_footer_banner clairco" src={header_logo} />
          </Row>
        </Row>
        <Row
          className="aqi_card_holders"
          gutter={[16, 16]}
          type="flex"
          align="middle"
          justify="center"
        >
          <Col
            className="inside_Card_holder"
            span={12}
            // style={{ boxShadow: "10px 0px #d3d3d3" }}
          >
            <Row type="flex" justify="center" align="middle">
              <div className="AqiLabel">
                <span style={{ color: "#000" }}>Indoor Air Quality</span>
              </div>
            </Row>
            <Row
              className="banner_row"
              type="flex"
              justify="center"
              align="middle"
            >
              <Col span={20}>
                <img className="inside_banner" src={InsideBackground} />
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
              {tileContainerInside}
            </Row>
          </Col>
          <Col
            className="outside_Card_holder"
            span={12}
            // style={{ boxShadow: "10px 0px #d3d3d3" }}
          >
            <Row type="flex" justify="center" align="middle">
              <div className="AqiLabel">
                <span style={{ color: "#000" }}>Outdoor Air Quality</span>
              </div>
            </Row>
            <Row
              className="banner_row"
              type="flex"
              justify="center"
              align="middle"
            >
              <Col span={20}>
                <img className="outside_banner" src={OutsideBackground} />
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
              {tileContainerOutside}
            </Row>
          </Col>
        </Row>
        <Row
          className="footerHeader"
          type="flex"
          justify="center"
          align="middle"
        >
          <div className="footerInside">Air Quality Index</div>
        </Row>
        <Row
          gutter={[16, 16]}
          className="footerContainer"
          type="flex"
          align="middle"
          justify="center"
        >
          <Col lg={{ span: 7 }} sm={{ span: 3 }}>
            {id === axis_house ? (
              <img className="bottom_logo" src={logo} />
            ) : null}
          </Col>
          <Col lg={{ span: 17 }} sm={{ span: 21 }}>
            <Row className="footer_items">{ftLvlItems}</Row>
          </Col>
          {/* <Col span={8}>
                    </Col> */}
        </Row>
      </div>
    );
  }
}
/* Tablet Size 947 * 520 */
