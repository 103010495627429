import React, { Component } from "react";
import GoogleMap from "google-map-react";
import _, { isUndefined } from "lodash";
import moment from "moment";
import { AcUnit, Opacity } from "@material-ui/icons";
import { Chart, Axis, Geom } from "bizcharts";
import { Menu, Button, Tooltip, Row, Col, Dropdown, Spin } from "antd";
import {
  UserOutlined,
  DownOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
// import "./DashboardV2.less";
import CHECK_DEVICE_CONFIG from "../../utils/checkDeviceConfig";
import {
  GetDateRangesTxt,
  GetDateRangeArrForMenuIndex,
} from "../../utils/dateRange";
import "../../scenes/Dashboard/DashboardV2.less";
import { getOutdoorSummaryAqData } from "../../api";
import AqProgressBar from "../dashboardContentCapitaLand/AqProgressBar";
import { CreateAqData } from "../../utils/createAqData";
import PmChartCapitaLand from "../dashboardContentCapitaLand/PmChartCapitaLand";
let lastDate = new Date();
lastDate.setDate(lastDate.getDate() - 1);
lastDate = lastDate.toLocaleDateString();
const aqPollutants = [
  { label: "PM2.5", key: "pm25" },
  { label: "PM10", key: "pm10" },
  { label: "CO2", key: "co2" },
  { label: "CO", key: "co" },
  { label: "LUX", key: "lux" },
  { label: "UV", key: "uv" },
  { label: "NOISE", key: "snd" },
];

const DeviceMarker = ({ text }) => (
  <div class="tooltip">
    <span class="tooltiptext">{text}</span>
  </div>
);

export default class SummaryPageHPE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      aqiRange: this.props.aqiRange,
      device_data: undefined,
      selected_device: null,
      dataIsLoaded: false,
      selected_device_index: 0,
    };
  }

  async componentDidMount() {
    try {
      let summaryData = (
        await getOutdoorSummaryAqData(this.state.id, true, "Yesterday")
      ).data.data;
      // console.log("summary_data: ", summaryData);
      let devices = [];
      summaryData[2]["SpaceData"].forEach((item) => {
        devices.push(item);
      });
      // console.log(devices);
      const dateRangeArr = GetDateRangeArrForMenuIndex(1);
      var trend_data = {};
      trend_data.insAqTrendOverDateRange =
        devices[this.state.selected_device_index].oaq_trend;
      trend_data.outAqTrendOverDateRange = [];
      // if(trend_data){

      // }
      let { aqTrendData } = CreateAqData(trend_data, null, dateRangeArr);
      // console.log(aqTrendData);
      this.setState({
        device_data: devices,
        aqTrendData,
        dateRangeArr,
        opHours: [],
        dataIsLoaded: true,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    let prevSelectedDeviceIndex = prevState.selected_device_index;
    if (this.state.selected_device_index != prevSelectedDeviceIndex) {
      var trend_data = {};
      trend_data.insAqTrendOverDateRange = this.state.device_data[
        this.state.selected_device_index
      ].oaq_trend;
      trend_data.outAqTrendOverDateRange = [];
      let { aqTrendData } = CreateAqData(
        trend_data,
        null,
        this.state.dateRangeArr,
      );
      this.setState({
        aqTrendData,
      });
    }
  }

  handleDeviceChangeClick = (e) => {
    // console.log(e);
    // var selected_menu = this.state.device_data[e.key].name;
    this.setState({
      selected_device_index: e.key,
    });
  };

  render() {
    const {
      id,
      selectedCityIndex,
      aqi_text,
      dataIsLoaded,
      device_data,
      selected_device_index,
      aqiRange,
      aqTrendData,
      dateRangeArr,
      opHours,
    } = this.state;
    if (!this.state.device_data || !this.state.dataIsLoaded) {
      return (
        <div className="summaryContainer">
          <Spin size="large" />
        </div>
      );
    }
    // console.log(device_data);
    const selected_device_data = device_data[selected_device_index];
    const insRtAqItems = aqPollutants.map((item, i) => {
      let p, v;
      var select_range = _.filter(aqiRange, (x) => x.label === item.label);
      // console.log(select_range);
      // if(insAq && insAq.pData[i]) {
      //     v = insAq.pData[i];
      // }
      v = selected_device_data.oaq[item.key];
      // var select_range = [{min: 0, max: 340}];
      return (
        <AqProgressBar
          key={"aqInsProgressBar" + i}
          label={item.label}
          data={[{ value: v }]}
          range={select_range}
        />
      );
    });
    const menu_item = device_data.map((item, i) => {
      return (
        <Menu.Item key={i} icon={<UserOutlined />}>
          {item.name}
        </Menu.Item>
      );
    });

    let insTimeInputForMoment;
    if (typeof selected_device_data.oaq["time"] === "object") {
      let time = selected_device_data.oaq["time"].dateTime;
      insTimeInputForMoment = !dataIsLoaded
        ? new Date().toISOString()
        : CHECK_DEVICE_CONFIG.devIdIsInEnggList(selected_device_data.oaq["id"])
        ? new Date(new Date().setTime(new Date(time).getTime())).toISOString()
        : time + ".000Z";
    } else {
      insTimeInputForMoment = !dataIsLoaded
        ? new Date().toISOString()
        : CHECK_DEVICE_CONFIG.devIdIsInEnggList(selected_device_data.oaq["id"])
        ? new Date(
            new Date().setTime(
              new Date(selected_device_data.oaq["time"]).getTime(),
            ),
          ).toISOString()
        : selected_device_data.oaq["time"] + ".000Z";
    }

    const menu = (
      <Menu onClick={(e) => this.handleDeviceChangeClick(e)}>{menu_item}</Menu>
    );
    return (
      // <Row>
      //   <Col span={14}>
      //     <Row>
      //       <Card className="user_aqi_info_card">
      //         <Col span={14}>
      //           <Row style={{marginTop: '10%'}}>
      //             <span
      //               style={{ fontFamily: "'Montserrat', sans-serif" }}
      //               className="aqi_info_card_title"
      //             >
      //               Hi {getUsername()}
      //             </span>
      //             <br />
      //             <span
      //               style={{ fontFamily: "'Montserrat', sans-serif" }}
      //               className="aqi_info_card_subtitle"
      //             >
      //               Air quality at your property is {aqi_text}
      //             </span>
      //             <br />
      //           </Row>
      //           {/* <Row style={{ marginTop: 30 }}>
      //             <span
      //               style={{
      //                 fontSize: "20px",
      //                 fontFamily: "'Montserrat', sans-serif",
      //               }}
      //             >
      //               {insAq.aqi} - Indoor AQI
      //             </span>
      //             <br />
      //             <span
      //               style={{
      //                 fontSize: "20px",
      //                 fontFamily: "'Montserrat', sans-serif",
      //               }}
      //             >
      //               {outAq.aqi} - Outdoor AQI
      //             </span>
      //             <br />
      //             <br />
      //             <img className="weather_icon" src={weatherIcon} />
      //             <span className="temp_data">
      //               {" "}
      //               34<sup>o</sup>C
      //             </span>
      //           </Row> */}
      //         </Col>
      //         <Col span={10} lg={10}>
      //           <img
      //             className="aqi_info_card_image"
      //             src={GirlImage}
      //             style={{ position: "static" }}
      //           />
      //         </Col>
      //       </Card>
      //     </Row>
      //     {propertyGauge}
      //   </Col>
      // </Row>
      <Row>
        <Row style={{ margin: "5px 0" }}>
          <Dropdown overlay={menu}>
            <Button>
              <EnvironmentOutlined /> {device_data[selected_device_index].name}
            </Button>
          </Dropdown>
        </Row>
        <Row gutter={[8, 8]}>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <div className="col_header_custom">Real Time Parameters</div>
              <Row gutter={[8, 16]} className="outdoor_data_main">
                <Col span={12} className="outdoor_main_row">
                  <Row className="outdoor_main_label">PM 2.5</Row>
                  <Row className="outdoor_main_value">
                    {device_data[selected_device_index].oaq.pm25}
                  </Row>
                  <Row className="outdoor_main_description">MODERATE</Row>
                  <Row gutter={[8, 8]}>
                    <Col span={4}></Col>
                    <Col span={8}>
                      <AcUnit />{" "}
                      <span className="inner_card_value">
                        {device_data[selected_device_index].oaq.temp}
                      </span>
                      <sup>o</sup>c
                    </Col>
                    <Col span={8}>
                      <Opacity />{" "}
                      <span className="inner_card_value">
                        {device_data[selected_device_index].oaq.hum}
                      </span>
                      <sup>%</sup>
                    </Col>
                    <Col span={4}></Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <span className="inner_card_label">Last Updated: </span>
                      <span className="inner_card_timestamp">
                        {" "}
                        {moment(insTimeInputForMoment).fromNow()}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>{insRtAqItems}</Col>
              </Row>
            </Col>
            <Col span={12}>
              <div className="col_header_custom">Device Location</div>
              <div style={{ height: "390px", width: "100%" }}>
                <GoogleMap
                  bootstrapURLKeys={{
                    key: "AIzaSyCz3-IfGt6NuK8tEAyR1yION1SaLATJ6pw",
                  }}
                  zoom={16}
                  center={{
                    lat: parseFloat(
                      device_data[selected_device_index].location["lat"],
                    ),
                    lng: parseFloat(
                      device_data[selected_device_index].location["lng"],
                    ),
                  }}
                >
                  <DeviceMarker
                    lat={parseFloat(
                      device_data[selected_device_index].location["lat"],
                    )}
                    lng={parseFloat(
                      device_data[selected_device_index].location["lng"],
                    )}
                    text={device_data[selected_device_index].name}
                  />
                </GoogleMap>
              </div>
            </Col>
          </Row>
          <Row></Row>
          <Row gutter={8}>
            <Col span={12}>
              <div className="col_header_custom">Noise Levels</div>
              {/* <Chart height={400} width={800} data={data} scale={scale} autoFit>
                    <Axis name="value" label='Value'
                    />
                    <Axis
                      title={{textStyle: {fontSize: '14'}}}
                      label={'Year', {textStyle: {fontSize: '11'}}}
                      name="year"
                    />
                    <Tooltip shared/>
                    <Geom
                      type="point"
                      position="year*value"
                      size={1}
                      shape={"circle"}
                      color={[
                        "space",
                        ['#1638D7']
                      ]}
                    >
                    <Geom
                      type="area"
                      position="year*value"
                      size={2}
                      shape={"smooth"}
                      color='#1638D7'
                    />
                    </Geom>
                  </Chart> */}
              <PmChartCapitaLand
                aqTrendData={aqTrendData[7]}
                label={"NOISE"}
                dateRangeArr={dateRangeArr}
                spaceDevId={device_data[selected_device_index].id}
                opHours={opHours}
              />
            </Col>
            <Col span={12}>
              <div className="col_header_custom">UV Radiation</div>
              {/* <Chart height={400} width={800} data={data2} scale={scale2} autoFit>
                    <Axis name="value" label='Value'
                    />
                    <Axis
                      title={{textStyle: {fontSize: '14'}}}
                      label={'Year', {textStyle: {fontSize: '11'}}}
                      name="year"
                    />
                    <Tooltip shared/>
                    <Geom
                      type="point"
                      position="year*value"
                      size={1}
                      shape={"circle"}
                      color={[
                        "space",
                        ['#1638D7']
                      ]}
                    >
                    <Geom
                      type="area"
                      position="year*value"
                      size={2}
                      shape={"smooth"}
                      color={"value",["red","#5225ab"]}
                    />
                    </Geom>
                  </Chart> */}
              <PmChartCapitaLand
                aqTrendData={aqTrendData[6]}
                label={"UV"}
                dateRangeArr={dateRangeArr}
                spaceDevId={device_data[selected_device_index].id}
                opHours={opHours}
              />
            </Col>
          </Row>
        </Row>
      </Row>
    );
  }
}
