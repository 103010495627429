import React, {Component} from "react";
import PropTypes from "prop-types";
import {
  Layout,
  Modal,
  Spin,
  Card,
  Menu,
  Button,
  Icon,
  Row,
  Col
} from "antd";
import ColorInfo from "../components/dashboardContent/ColorInfo";
import {COLOR} from "../colors";
import {getAQ} from "../api";
import Gauge from "../components/dashboardContent/Gauge";
import TemperatureBox from "../components/dashboardContent/Temperature";
import HumidityBox from "../components/dashboardContent/Humidity";
import PmChartUser from "../components/dashboardContent/PmChartUser";
import AqiTable from "../components/aqiTable/AqiTable";
import "../components/dashboardContent/DashboardContent.less";
import moment from "moment";
import CHECK_DEVICE_CONFIG from './../utils/checkDeviceConfig'; // Change it to direct call to function
import { CreateAqData } from './../utils/createAqData';
import {GetDateRangeArrForMenuIndex} from './../utils/dateRange';

export default class DashboardContent extends Component {
  constructor(props) {
    super(props);
    const device = props
      .match
      .params
      .id
      .split("_");
    this.spaceDevId = device[0]
    this.propDevId = device[1]
    this.state = {
      dataIsLoaded: false
    };
  }

  showModal = () => {
    this.setState({commentVisible: true});
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({commentVisible: false});
  }

  async componentDidMount() {
    try {
      const dateRangeArr = GetDateRangeArrForMenuIndex(0);
      let {data} = await getAQ(undefined, undefined,this.spaceDevId,this.propDevId,dateRangeArr);
      const {aqTrendData,avgAq,selectedAqTrendIndex,insAq,outAq} = CreateAqData(data,this.spaceDevId,dateRangeArr);
      this.setState({
        aqData: aqTrendData,
        aqTrendData,
        selectedAqTrendIndex,
        insideAQ: insAq,
        insAq,
        outsideAQ: outAq,
        outAq,
        opHours: data.opHours,
        nonOpDays: data.nonOpDays,
        dataIsLoaded: true
      });
      // this.createData(data);
    } catch (err) {
      console.log(err);
    }
  }

  getMaxValue = value => {
    return value < 100
      ? 100
      : value < 200
        ? 200
        : value < 300
          ? 300
          : value < 400
            ? 400
            : 1000;
  };

  getColor = (value, pm) => {
    if (pm == "pm2.5") {
      return value < 31
        ? COLOR.good
        : value < 61
          ? COLOR.satisfactory
          : value < 91
            ? COLOR.moderate
            : value < 121
              ? COLOR.poor
              : value < 251
                ? COLOR.veryPoor
                : COLOR.severe;
    }
    return value < 51
      ? COLOR.good
      : value < 101
        ? COLOR.satisfactory
        : value < 251
          ? COLOR.moderate
          : value < 351
            ? COLOR.poor
            : value < 431
              ? COLOR.veryPoor
              : COLOR.severe;
  };

  render() {
    if (!this.state.dataIsLoaded) {
      return (
        <div className="dashLoaderContainer"><Spin size="large"/></div>
      );
    }
    const {aqTrendData,selectedAqTrendIndex,insAq,outAq,
      opHours,nonOpDays,dataIsLoaded} = this.state;
    const {insideAQ,outsideAQ,aqData} = this.state;
    // console.log('insideAQ: ',insideAQ);
    // console.log('outsideAQ: ',outsideAQ);
    // console.log('spaceDevId: ',this.spaceDevId);
    // console.log('propDevId: ',this.propDevId);
    const insideAQPM25 = parseInt(insideAQ.pm25);
    const insideAQPM10 = parseInt(insideAQ.pm10);
    const insideTemperature = Math.round(parseInt(insideAQ.temp));
    const insideHumidity = Math.round(parseInt(insideAQ.humidity))
    const outsideAQPM25 = outsideAQ !== undefined ? parseInt(outsideAQ.pm25) : undefined;
    const outsideAQPM10 = outsideAQ !== undefined ? parseInt(outsideAQ.pm10) : undefined;
    const outsideTemperature = outsideAQ !== undefined ? Math.round(parseInt(outsideAQ.temp)) : undefined;
    const outsideHumidity = outsideAQ !== undefined ? Math.round(parseInt(outsideAQ.humidity)) : undefined;

    const timeInputForMoment = (devId,aq) => {
                                return !dataIsLoaded
                                  ? (new Date()).toISOString()
                                  : (CHECK_DEVICE_CONFIG.devIdIsInEnggList(devId))
                                    ? (
                                        new Date(
                                          (new Date())
                                            .setTime(
                                              (new Date(aq.time)).getTime()
                                            )
                                        )
                                      ).toISOString()
                                    : (aq.time+".000Z");
                                };

    const CommentMark = () => (
      <Button
        type="danger" onClick={this.showCommentModal}
        ghost size="small" icon="question" shape="circle"
        style={{ height: 18, fontSize: 12, width: 18 }}
      />
    );
    const commentModalFooter = [(
      <Button key="submit" type="primary" onClick={this.handleCommentModalOk}>
        OK
      </Button>
    )];
    const CommentModal = () => (
      <Modal
        title="Possible reasons for not seeing real time data"
        visible={this.state.commentModalVisible} footer={commentModalFooter}
        onOk={this.handleCommentModalOk} closable={false}>
        <div>
          <ul>
            <li>Air quality monitor not installed.</li>
            <li>No internet connection.</li>
            <li>Air quality monitor damaged.</li>
          </ul>
          <h4>Note:</h4>
          <span>Please check whether the above causes exist at your side.</span>
          <span>If the issue persists even after doing that, kindly inform clairco support team.</span>
          <br/>
          <span>Incase of any enquiry please reach out to:
            <br/>Udayan Banerjee
            <br/>udayan@clairco.in
            <br/>+919898036463
          </span>
        </div>
      </Modal>
    );

    const insideShowComment = moment().diff(moment(timeInputForMoment(this.spaceDevId,insAq)), 'hours') > 1;
    const insidecardExtra = (
      <span>Updated {moment(timeInputForMoment(this.spaceDevId,insAq)).fromNow() + "  "}
        {insideShowComment &&< CommentMark />}</span>
    );

    const outsideShowComment = moment().diff(moment(timeInputForMoment(this.propDevId,outAq)), 'hours') > 1;
    const outsideCardExtra = (
      <span>Updated {moment(timeInputForMoment(this.propDevId,outAq)).fromNow() + "  "}
        {outsideShowComment &&< CommentMark />}</span>
    );

    return (
      <div style={{
        minHeight: 720
      }}>
        <Card bordered={false}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Card
                title="Inside Air Quality"
                bordered={false}
                className="pmCard"
                extra={insidecardExtra}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Gauge
                      label="PM2.5"
                      data={[{
                        value: insideAQPM25
                      }
                    ]}
                      max={this.getMaxValue(insideAQPM25)}
                      color={this.getColor(insideAQPM25, "pm2.5")}/>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Gauge
                      label="PM10"
                      data={[{
                        value: insideAQPM10
                      }
                    ]}
                      max={this.getMaxValue(insideAQPM10)}
                      color={this.getColor(insideAQPM10, "pm10")}/>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Card
                bordered={false}
                className="pmCard"
                title="Outside Air Quality"
                extra={outsideCardExtra}>
                <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <Gauge
                      label="PM2.5"
                      data={[{
                        value: outsideAQPM25
                      }
                    ]}
                      max={this.getMaxValue(outsideAQPM25)}
                      color={this.getColor(outsideAQPM25, "pm2.5")}/>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Gauge
                      label="PM10"
                      data={[{
                        value: outsideAQPM10
                      }
                    ]}
                      max={this.getMaxValue(outsideAQPM10)}
                      color={this.getColor(outsideAQPM10, "pm10")}/>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <ColorInfo/>
        </Card>
        <Card
          bordered={false}
          style={{
          minHeight: 400,
          marginTop: 16
        }}>
          {aqData.length
            ? (<PmChartUser data={aqData}/>)
            : (
              <div className="noDataPmChart">
                <h3>No data to display</h3>
              </div>
            )}
        </Card>
        <Card
          bordered={false}
          style={{
          minHeight: 400,
          marginTop: 16
        }}>
          <AqiTable/>
        </Card>
        < CommentModal />
        {/* <Modal
          title="Possible reasons for not seeing live data"
          visible={this.state.commentVisible}
          onOk={this.handleOk}
          closable={false}
          footer={commentFooter}>
          <div>
            <ul>
              <li>
                No internet connection
              </li>
              <li>
                Air quality monitor damaged
              </li>
            </ul>
            <h4>Note:</h4>
            <span>Kindly inform clairco support team if wifi password changes.</span>
            <br/>
            <span>Incase of any enquiry please reach out to:
              <br/>Aayush Jha
              <br/>aayush@clairco.in<br/>+919910012334</span>
          </div>
        </Modal> */}

      </div>
    );
  }
}
DashboardContent.propTypes = {
  selectedMenu: PropTypes.string.isRequired
};
