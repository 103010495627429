import React, { Component } from "react";
import { Row, Col, Checkbox, Form, Input, Select } from "antd";
import { render } from "react-dom";

const FormItem = Form.Item;
const Option = Select.Option;

const UVForm = Form.create()(class extends React.Component {
    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form layout="horizontal">
                <FormItem label="Enter Device ID">
                    {getFieldDecorator("uv_device_id", {
                        rules: [
                            {
                                required: true,
                                message: "Important"
                            }
                        ]
                    })(<Input placeholder="e.g. MW300001"/>)}
                </FormItem>
                <FormItem label="No. of lights">
                    {getFieldDecorator("uv_light_count", {
                            rules: [
                                {
                                    required: true,
                                    message: "Important"
                                }
                            ]
                        })(<Input type='number'/>)}
                </FormItem>
            </Form>
        );
    }
})

export default UVForm;