import React, {Component} from "react";
import {
  Form,
  Icon,
  Alert,
  Input,
  Card,
  Button,
  Checkbox,
  message
} from "antd";
import "../Login.less"
import {login, changePassword} from "../api";
import {setToken, getEmail, setCsrId} from "../helper";
import {
  removeToken,
  removeCsrId,
  removeCache,
} from "../helper";
import { useHistory } from "react-router-dom";
import logo from '../img/Claircofinallogo.png';
const FormItem = Form.Item;

function confirm(e) {
  message.success('Password changed successfuly!');
    // removeToken();
    // removeCsrId();
    // removeCache();
    // this.props.history.push("/");
}
// function goBack(){
//     // removeToken();
//     // removeCsrId();
//     // removeCache();
// }
class ChangePassword extends Component {
  state = {
    loggingIn: false,
    showAlert: false,
    error: "Something has gone wrong"
  };
  
  handleSubmit = e => {
    this.setState({loggingIn: true, showAlert: false});
    e.preventDefault();
    this
      .props
      .form
      .validateFields(async(err, values) => {
        if (!err) {
          try {
            // console.log('getEmail: ', getEmail());
            // console.log('values.oldpassword: ',values.oldpassword);
            // console.log('values.newpassword: ',values.newpassword);
            const res = await changePassword(values.oldpassword, values.newpassword, getEmail());
            // console.log(res.data.message);
            // setToken(res.data.token);
            // setCsrId(res.data.CsrId);
            // setEmail(values.email);
            // if (this.props.location.state && this.props.location.state.referrer === "admin") {
            //   this
            //     .props
            //     .history
            //     .push("/admin");
            // } else {
            //   this
            //     .props
            //     .history
            //     .push("/dashboard/");
            // }
          } catch (err) {
            this.setState({showAlert: true, error: err.response.data.message});
          }
          this.setState({loggingIn: false});
        }
      });
  };

  goback = e => {
    this.props.history.push("/login");
  }

  render() {
    // console.log(this.props.location.state && this.props.location.state.referrer);
    const {getFieldDecorator} = this.props.form;

    return (
      <div className="loginContainer">
        <Card bordered={false} className="changePassBox">
          <div className="changePassLogoContainer">
            <a href="/dashboard"><img className="loginLogo" src={logo}/><br/>
            </a>
            <br/>
            Please update your password for <br/><b>{getEmail()}</b> here.
          </div>
          <Form
            onSubmit={this.handleSubmit}
            className="login-form"
            style={{
            width: 300,
            textAlign: "center"
          }}>
            <FormItem>
              {getFieldDecorator("oldpassword", {
                rules: [
                   {
                    required: true,
                    message: "Please input your Old Password!"
                  }
                ]
              })(
                <Input
                  prefix={< Icon type = "unlock" style = {{ color: "rgba(0,0,0,.25)" }}/>}
                  type="password"
                  placeholder="Your Old Password"/>
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("newpassword", {
                rules: [
                  {
                    required: true,
                    message: "Please input your New Password!"
                  }
                ]
              })(
                <Input
                  prefix={< Icon type = "lock" style = {{ color: "rgba(0,0,0,.25)" }}/>}
                  type="password"
                  placeholder="Your New Password"/>
              )}
            </FormItem>
            <FormItem>
              <Button
                loading={this.state.loggingIn}
                onClick={confirm}
                type="primary"
                htmlType="submit"
                className="loginFormButton">
                Confirm
              </Button>
              <br/>
              <Button
              // style={{
              //   margin: '10px'
              // }}
                onClick={this.goback}
                type="secondary">
                Back
              </Button>
              
              {this.state.showAlert && (<Alert
                style={{
                marginTop: 20
              }}
                message={this.state.error}
                type="error"
                showIcon/>)}
            </FormItem>
          </Form>
        </Card>
      </div>
    );
  }
}

export default Form.create()(ChangePassword);
