import React, { Component } from "react";
import { Button, Alert, Form, PageHeader, Radio } from "antd";
import PropertyForm from "../components/PropertyForm";
import { addProperty } from "../api";
import { resolve } from "url";

const FormItem = Form.Item;

export default class AddProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      response: "",
      cities: [
        "Bengaluru",
        "Delhi",
        "Hyderabad",
        "Chennai",
        "Mumbai",
        "Pune",
        "Kolkata",
      ],
    };
  }

  async componentDidMount() {
    var customerId = this.props.id;
    // console.log(this.props.id);
    this.setState({
      customerId,
    });
  }
  prevComponent = () => {
    this.props.onClick("Customer", this.state.customerId, null);
  };
  getLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(function(position) {
        resolve(position.coords);
      });
    });
  };
  saveProperty = () => {
    const propertyForm = this.propertyFormRef.props.form;
    propertyForm.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      this.setState({ loading: true });
      //const { latitude, longitude } = await this.getLocation();
      try {
        const response = await addProperty({
          ...values,
          customer_id: this.state.customerId,
        });
        propertyForm.resetFields();
        this.setState({ code: 200, response: "Data created successfully" });
      } catch (e) {
        console.log(e);
        this.setState({ code: 400, response: e.response.data.message });
      }
      this.setState({ loading: false });
    });
  };

  savePropertyFormRef = (formRef) => {
    this.propertyFormRef = formRef;
  };

  render() {
    return (
      <div style={{ background: "#FFF" }}>
        <div style={style.container}>
          <PageHeader
            className="site-page-header"
            onBack={() => this.prevComponent()}
            title={<h1>{"Add Property"}</h1>}
          />
          <PropertyForm
            cities={this.state.cities}
            wrappedComponentRef={this.savePropertyFormRef}
          />
          {this.state.response && (
            <Alert
              message={this.state.response}
              type={this.state.code == 200 ? "success" : "error"}
              showIcon
            />
          )}
          <Button
            style={style.saveBtn}
            type="primary"
            loading={this.state.loading}
            onClick={this.saveProperty}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

const style = {
  container: {
    margin: "auto",
    maxWidth: 500,
    border: "3 px solid green",
    paddingTop: 30,
  },
  saveBtn: {
    marginTop: 20,
    marginBottom: 20,
  },
};
