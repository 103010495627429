import React, { Component } from "react";
import {
  Chart,
  Axis,
  Coord,
  Geom,
  Tooltip,
  Guide,
  Legend,
  Shape,
  View,
  Label
} from "bizcharts";
import {
  Row,
  Col
} from 'antd';
import DataSet from "@antv/data-set";
import moment from "moment";
import { COLOR } from '../../colors';
import { GET_P_LVL_MIN_VAL_DICT, P_LVL_KEY } from '../../pLevels';

const DEV_MODE = false;

const PmChartOps = props => {
  const { data } = props;
  //console.log('data: ',JSON.stringify(data,null,2));
  let maxAqValue = 0;
  const minValDict = GET_P_LVL_MIN_VAL_DICT('PM2.5');
  for(let i=0, l=data.length;i<l;i++) {
    if(DEV_MODE) { // DEV CODE
      let compareValue = minValDict.severeMin-1;
      if(data[i].inside >= compareValue) { data[i].inside = compareValue; }
      if(data[i].outside >= compareValue) { data[i].outside = compareValue; }
    }
    if(data[i].inside > maxAqValue) { maxAqValue = data[i].inside; }
    if(data[i].outside > maxAqValue) { maxAqValue = data[i].outside; }
  }
  let showAqLevelLines = { // true: default show, false: only if needed show
    'good': false,
    'satisfactory': false,
    'moderate': false,
    'poor': false,
    'veryPoor': false,
    'severe': false
  };
  // console.log('showAqLevelLines: ',showAqLevelLines);
  if(maxAqValue >= minValDict.veryPoorMin) {
    if(!showAqLevelLines.severe) { showAqLevelLines.severe = true; }
    if(!showAqLevelLines.veryPoor) { showAqLevelLines.veryPoor = true; }
    if(!showAqLevelLines.poor) { showAqLevelLines.poor = true; }
    if(!showAqLevelLines.moderate) { showAqLevelLines.moderate = true; }
    if(!showAqLevelLines.satisfactory) { showAqLevelLines.satisfactory = true; }
    if(!showAqLevelLines.good) { showAqLevelLines.good = true; }
  } else if (maxAqValue >= minValDict.poorMin) {
    if(!showAqLevelLines.veryPoor) { showAqLevelLines.veryPoor = true; }
    if(!showAqLevelLines.poor) { showAqLevelLines.poor = true; }
    if(!showAqLevelLines.moderate) { showAqLevelLines.moderate = true; }
    if(!showAqLevelLines.satisfactory) { showAqLevelLines.satisfactory = true; }
    if(!showAqLevelLines.good) { showAqLevelLines.good = true; }
  } else if (maxAqValue >= minValDict.moderateMin) {
    if(!showAqLevelLines.poor) { showAqLevelLines.poor = true; }
    if(!showAqLevelLines.moderate) { showAqLevelLines.moderate = true; }
    if(!showAqLevelLines.satisfactory) { showAqLevelLines.satisfactory = true; }
    if(!showAqLevelLines.good) { showAqLevelLines.good = true; }
  } else if (maxAqValue >= minValDict.moderateMin) {
    if(!showAqLevelLines.moderate) { showAqLevelLines.moderate = true; }
    if(!showAqLevelLines.satisfactory) { showAqLevelLines.satisfactory = true; }
    if(!showAqLevelLines.good) { showAqLevelLines.good = true; }
  } else if (maxAqValue >= minValDict.satisfactoryMin) {
    if(!showAqLevelLines.satisfactory) { showAqLevelLines.satisfactory = true; }
    if(!showAqLevelLines.good) { showAqLevelLines.good = true; }
  } else if(maxAqValue > 0) {
    if(!showAqLevelLines.good) { showAqLevelLines.good = true; }
  }
  // console.log('showAqLevelLines: ',showAqLevelLines);
  let dataVisFields = ['inside','outside'];
  // console.log('dataVisFields: ',dataVisFields);
  for(let i=0, l=data.length;i<l;i++) {
    if(showAqLevelLines.good) {
      data[i][P_LVL_KEY.good] = minValDict.satisfactoryMin-1;
      if(!dataVisFields.includes(P_LVL_KEY.good)) { dataVisFields.push(P_LVL_KEY.good); }
    }
    if(showAqLevelLines.satisfactory) {
      data[i][P_LVL_KEY.satisfactory] = minValDict.moderateMin-1;
      if(!dataVisFields.includes(P_LVL_KEY.satisfactory)) { dataVisFields.push(P_LVL_KEY.satisfactory); }
    }
    if(showAqLevelLines.moderate) {
      data[i][P_LVL_KEY.moderate] = minValDict.poorMin-1;
      if(!dataVisFields.includes(P_LVL_KEY.moderate)) { dataVisFields.push(P_LVL_KEY.moderate); }
    }
    if(showAqLevelLines.poor) {
      data[i][P_LVL_KEY.poor] = minValDict.veryPoorMin-1;
      if(!dataVisFields.includes(P_LVL_KEY.poor)) { dataVisFields.push(P_LVL_KEY.poor); }
    }
    if(showAqLevelLines.veryPoor) {
      data[i][P_LVL_KEY.veryPoor] = minValDict.severeMin-1;
      if(!dataVisFields.includes(P_LVL_KEY.veryPoor)) { dataVisFields.push(P_LVL_KEY.veryPoor); }
    }
    if(showAqLevelLines.severe) {
      data[i][P_LVL_KEY.severe] = minValDict.severeMax;
      if(!dataVisFields.includes(P_LVL_KEY.severe)) { dataVisFields.push(P_LVL_KEY.severe); }
    }
  }
  // console.log('dataVisFields: ',dataVisFields);
  // console.log('data: ',JSON.stringify(data,null,2));
  const ds = new DataSet();
  const dv = ds.createView().source(data);
  dv.transform({
    type: "fold",
    fields: dataVisFields,
    key: "space",
    value: "pm2.5"
  });
  const width = window.innerWidth;
  let tickCount;
  if(width < 600) tickCount = 12; else if(width<800) tickCount = 24;
  const cols = {
    hour: {
      range: [0, 1],
      alias: "Hour of the day",
      tickCount,
    }
  };

  return (
    <div>
      {/* COMMENTED OUT REASON: Sarva should not display outside data.
          TODO BEFORE UNCOMMENTING: For Sarva and other customers for whom
            outside data should Not be shown, selectively avoid showing the
            Inside/Outside color circles in Kiosk and Phone url.
      <Row className="colorInfoContainerOps" type="flex" justify="center">
        {dataVisFields.includes('inside')
          ? (<Col lg={4} xs={8} sm={4} className="boxContainer">
              <div className="colorInfoInside colorInfoBarOps"></div>
              Inside
            </Col>)
          : (<div></div>)
        }
        {dataVisFields.includes('outside')
          ? (<Col lg={4} xs={8} sm={4} className="boxContainer">
              <div className="colorInfoOutside colorInfoBarOps"></div>
              Outside
            </Col>)
          : (<div></div>)
        }
      </Row> */}
      <Chart height={175} width={350} data={dv} scale={cols}>
        <Axis name="hour"
          label={{
            textStyle: {fontSize: '8'},
            formatter:(value)=>{
              return moment(value).format("hha").replace(/^0+/, '');
            }
          }}
        />
        <Axis
          title={{textStyle: {fontSize: '7'}, offset:25}}
          label={{textStyle: {fontSize: '7'}, autoRotate: false}}
          name="pm2.5"
        />
        <Geom
          type="line"
          position="hour*pm2.5"
          size={1}
          color={[
            "space",
            [ null,null,
              COLOR.good_light,COLOR.satisfactory_light,
              COLOR.moderate_light,COLOR.poor_light,
              COLOR.veryPoor_light,COLOR.severe_light]
          ]}
          shape={"smooth"}
        />
        <Geom
          type="line"
          position="hour*pm2.5"
          size={1}
          color={[
            "space",
            [ COLOR.insideAir,COLOR.outsideAir,
              null,null,null,null,null,null]
          ]}
          shape={"smooth"}
        />
      </Chart>
    </div>
  );
};

export default PmChartOps;
