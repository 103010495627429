import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Icon,
  Input,
  Tooltip,
  Badge,
  notification,
  message,
} from "antd";
import { Select } from "antd";
import "./projects.less";
import { isNull } from "lodash";
import Highlighter from "react-highlight-words";
import { PlusCircleOutlined } from "@ant-design/icons";
import POform from "./POform";
import { useForm, Controller } from "react-hook-form";
import {
  getPOsForProject,
  getProjects,
  uploadBOQ,
  addProject,
  deleteBOQForProject,
  deletePOForProject,
} from "../../api";
import PO from "./PO";

const Projects = ({ addPO }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    setValue,
    control,
    watch,
  } = useForm();
  const [projects, setProjects] = useState([]);
  const [file, setFile] = useState(null);
  const [showPO, setShowPO] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);

  const [project_record, setProject_record] = useState(null);
  const [projectID, setProjectID] = useState(null);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [createProject, setCreateProject] = useState({
    name: "",
    city: "",
    address: "",
    latitude: "",
    longitude: "",
    SPOCname: "",
    SPOCcontact: "",
    startDate: null,
    endDate: null,
  });
  const [poStock, setPoStock] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [alert, setAlert] = useState(false);

  const showModal = (id) => {
    setVisible1(true);
    setProjectID(id);
  };

  // getPOForProjectid() is used to get the POs created for the particular project

  const getPOForProjectid = async (id, name) => {
    const arr = [];
    await getPOsForProject(id).then((result) => {
      result.data.POs.map((po) => {
        arr.push({
          id: po.id,
          po: po.po,
          po_number: po.po_id,
          po_date: po.order_date,
          project_id: po.project_id,
          project: name,
        });
      });
    });

    setPurchaseOrders(arr);
  };

  const showModal2 = async (project_id, project_name) => {
    setVisible2(true);
    setProjectID(project_id);
    getPOForProjectid(project_id, project_name);
  };

  const showModal3 = () => {
    setVisible3(true);
  };

  const poConfirmation = () => {
    const args = {
      message: "Purchase Order Status",
      description: "Purchase Order is successfully created.",
      // duration: 0,
    };
    notification.open(args);
  };

  const fetchProjects = async () => {
    const result = await getProjects();

    const arr = [];
    result.data.map((project) => {
      arr.push({
        id: project.id,
        project_name: project.name,
        city: project.city,
        date: project.updated_at.substring(0, 10),
        boq: project.boq,
        pocreated: project.PurchaseOrders.length,
        po: "po",
        startDate: project.startDate.substring(0, 10),
        endDate: project.endDate.substring(0, 10),
      });
    });

    setProjects(arr);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (!alert) return;
    fetchProjects();
  }, [alert]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false);
      }, 1000);
    }
  }, [alert]);

  const handleCancel = () => {
    setVisible1(false);
    setVisible2(false);
    setVisible3(false);
  };

  const handlePO = (record) => {
    setShowPO(true);
    setProject_record(record);
  };

  // handleShowProjectList is used to get back to the projects component after po is sent.
  // setshowpo() toggles POform component

  const handleShowProjectList = (inp) => {
    setShowPO(false);
    poConfirmation();
    setTimeout(() => {
      setAlert(true);
    }, 1000);
  };

  const handleItemMaster = () => {
    setShowPO(false);
  };

  const handlePoTable = async (e) => {
    e.preventDefault();
    setVisible2(false);

    fetchProjects();
  };

  // handleCreateProject() is used to create a project

  const handleCreateProject = async () => {
    const payload = createProject;
    setLoading(false);
    setVisible3(false);
    await addProject(payload)
      .then((response) => {
        message.success(response.data.message);
        setCreateProject({
          name: "",
          city: "",
          address: "",
          latitude: "",
          longitude: "",
          SPOCname: "",
          SPOCcontact: "",
        });
        document.querySelector("#project_startDate").value = "";
        document.querySelector("#project_endDate").value = "";
      })
      .then(() => setAlert(true))
      .catch((error) => {
        console.log(error);
        message.error(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
        setVisible3(false);
        setFile(null);
      });
  };

  // handleOk() is used to upload boq for a particualr project

  const handleOk = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("myFile", file);
    formData.append("id", projectID);
    //   for (var value of formData.values()) {
    //     console.log(value);
    //  }

    await uploadBOQ(formData)
      .then((response) => {
        message.success(response.data.message);
        setTimeout(() => {
          setAlert(true);
        }, 1000);
        document.querySelector("#boqupload").value = "";
      })
      .catch((error) => {
        console.log(error);
        message.error(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
        setVisible1(false);
        setFile(null);
      });
  };

  const deleteBOQ = async (record) => {
    const payload = {
      id: record.id,
      boq: null,
      key: null,
    };

    await deleteBOQForProject(payload)
      .then((response) => {
        message.success(response.data.message);
        setAlert(true);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          message.error(error.response.data.message);
        } else {
          console.log(error.message);
          message.error("Network error. Please try again later");
        }
      });
  };

  const deletePO = async (record) => {
    await deletePOForProject(record.id)
      .then((response) => {
        getPOForProjectid(record.project_id, record.project);
        setAlert(true);
      })
      .catch((error) => console.log(error));
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      className: "hide",
    },
    {
      title: "Name",
      dataIndex: "project_name",
      ...getColumnSearchProps("project_name"),
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "Updated at",
      dataIndex: "date",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      render: (startDate) => {
        return isNull(startDate) ? <span>NA</span> : <span>{startDate}</span>;
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      render: (endDate) => {
        return isNull(endDate) ? <span>NA</span> : <span>{endDate}</span>;
      },
    },
    {
      title: "BOQ",
      dataIndex: "boq",

      render: (boq, record) => {
        if (isNull(boq)) {
          return (
            <Button type="primary" onClick={() => showModal(record.id)}>
              {" "}
              Upload BOQ{" "}
            </Button>
          );
        }
        return (
          <div>
            <a href={boq} target="_blank">
              <Tooltip placement="top" title="View BOQ">
                <Icon type="eye" />
              </Tooltip>
            </a>
            <a style={{ marginLeft: "10px" }} onClick={() => deleteBOQ(record)}>
              <Tooltip placement="top" title="Delete BOQ">
                <Icon type="delete" style={{ color: "red" }} />
              </Tooltip>
            </a>
          </div>
        );
      },
    },
    {
      title: "POs created",
      dataIndex: "pocreated",
      render: (pocreated, record) => {
        return (
          <div onClick={() => showModal2(record.id, record.project_name)}>
            Pos : {pocreated} <Button size="small">view POs</Button>
          </div>
        );
      },
    },
    {
      title: "PO",
      dataIndex: "po",
      render: (po, record) => {
        if (isNull(record.boq)) {
          return (
            <Button type="primary" onClick={() => handlePO(record)} disabled>
              Create PO
            </Button>
          );
        } else {
          return (
            <Button
              type="primary"
              onClick={() => {
                setPoStock(false);
                handlePO(record);
              }}
            >
              Create PO
            </Button>
          );
        }
      },
    },
  ];

  const po_columns = [
    {
      title: "ID",
      dataIndex: "id",
      className: "hide",
    },

    {
      title: "PO Number",
      dataIndex: "po_number",
    },
    {
      title: "PO Date",
      dataIndex: "po_date",
    },
    {
      title: "Project ID",
      dataIndex: "project_id",
      className: "hide",
    },
    {
      title: "project",
      dataIndex: "project",
    },

    {
      title: "PO",
      dataIndex: "po",
      render: (po, record) => {
        return (
          <div>
            <a href={po} target="_blank">
              <Tooltip placement="top" title="View PO">
                <Icon type="eye" size />
              </Tooltip>
            </a>
            <a style={{ marginLeft: "10px" }} onClick={() => deletePO(record)}>
              <Tooltip placement="top" title="Delete PO">
                <Icon type="delete" style={{ color: "red" }} />
              </Tooltip>
            </a>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        visible={visible1}
        title="Upload BOQ"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <div className="form-container">
          <form onSubmit={handleOk}>
            <div className="inputBox">
              <input type="text" value={projectID} readOnly hidden />
            </div>
            <div className="inputBox">
              <input
                type="file"
                id="boqupload"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                // onClick={e => e.target.value = null}
              />
            </div>
          </form>
        </div>
      </Modal>
      {showPO ? (
        // <POform
        //   record={project_record}
        //   showProjectList={handleItemMaster}
        //   poForStock={poStock}
        //   handleShowProjectList={handleShowProjectList}
        // />
        <PO
          record={project_record}
          showProjectList={handleItemMaster}
          poForStock={poStock}
          handleShowProjectList={handleShowProjectList}
        />
      ) : (
        <div>
          <Button
            type="primary"
            onClick={showModal3}
            style={{
              marginBottom: "20px",
              fontSize: "20px",
              padding: "8px 16px",
              height: "42px",
            }}
          >
            ADD PROJECT <PlusCircleOutlined />
          </Button>
          <Button
            type="primary"
            style={{
              marginBottom: "20px",
              fontSize: "20px",
              padding: "8px 16px",
              height: "42px",
              marginLeft: "40px",
            }}
            onClick={() => {
              setPoStock(true);
              handlePO("record");
            }}
          >
            RAISE PO FOR STOCK <PlusCircleOutlined />
          </Button>
          <Modal
            title="CREATE A PROJECT"
            onCancel={handleCancel}
            visible={visible3}
            onOk={handlePoTable}
            footer={[
              <Button key="back" onClick={handleCancel}>
                CANCEL
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleSubmit(handleCreateProject)}
              >
                ADD
              </Button>,
            ]}
          >
            <div style={{ width: "100% !important" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <label style={{ width: "25%" }}>Name </label>
                  <input
                    type="text"
                    value={createProject.name}
                    name="name"
                    {...register("name", { required: true })}
                    style={{ width: "70%" }}
                    onChange={(e) =>
                      setCreateProject({
                        ...createProject,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                {errors.name && (
                  <p style={{ marginLeft: "140px" }}>Name is required</p>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <label style={{ width: "25%" }}>City</label>
                  <input
                    type="text"
                    name="city"
                    value={createProject.city}
                    style={{ width: "70%" }}
                    {...register("city", { required: true })}
                    onChange={(e) =>
                      setCreateProject({
                        ...createProject,
                        city: e.target.value,
                      })
                    }
                  />
                </div>
                {errors.city && (
                  <p style={{ marginLeft: "140px" }}>City is required</p>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <label style={{ width: "25%" }}>Address</label>
                  <input
                    type="text"
                    value={createProject.address}
                    style={{ width: "70%" }}
                    {...register("address", { required: true })}
                    onChange={(e) =>
                      setCreateProject({
                        ...createProject,
                        address: e.target.value,
                      })
                    }
                  />
                </div>
                {errors.address && (
                  <p style={{ marginLeft: "140px" }}>Address is required</p>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <label style={{ width: "25%" }}>Start Date</label>
                  <input
                    type="date"
                    value={createProject.startDate}
                    id="project_startDate"
                    style={{ width: "70%" }}
                    name="start date"
                    {...register("start date", { required: true })}
                    onChange={(e) =>
                      setCreateProject({
                        ...createProject,
                        startDate: e.target.value,
                      })
                    }
                  />
                </div>
                {errors["start date"] && (
                  <p style={{ marginLeft: "140px" }}>Start date is required</p>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <label style={{ width: "25%" }}>End Date</label>
                  <input
                    type="date"
                    value={createProject.endDate}
                    style={{ width: "70%" }}
                    name="end date"
                    id="project_endDate"
                    {...register("end date", { required: true })}
                    onChange={(e) =>
                      setCreateProject({
                        ...createProject,
                        endDate: e.target.value,
                      })
                    }
                  />
                </div>
                {errors["end date"] && (
                  <p style={{ marginLeft: "140px" }}>end date is required</p>
                )}
              </div>
              {/* <div className="inputBox">
                <label>Latitude</label>
                <input
                  type="text"
                  value={createProject.latitude}
                  onChange={(e) =>
                    setCreateProject({
                      ...createProject,
                      latitude: e.target.value,
                    })
                  }
                />
              </div> */}
              {/* <div className="inputBox">
                <label>Longitude</label>
                <input
                  type="text"
                  value={createProject.longitude}
                  onChange={(e) =>
                    setCreateProject({
                      ...createProject,
                      longitude: e.target.value,
                    })
                  }
                />
              </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <label style={{ width: "25%" }}>SPOC Name</label>
                  <input
                    type="text"
                    value={createProject.SPOCname}
                    style={{ width: "70%" }}
                    name="SPOCname"
                    {...register("SPOCname", { required: true })}
                    onChange={(e) =>
                      setCreateProject({
                        ...createProject,
                        SPOCname: e.target.value,
                      })
                    }
                  />
                </div>
                {errors.SPOCname && (
                  <p style={{ marginLeft: "140px" }}>SPOC name is required</p>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <label style={{ width: "25%" }}>SPOC Contact</label>
                  <input
                    type="text"
                    value={createProject.SPOCcontact}
                    name="SPOCcontact"
                    style={{ width: "70%" }}
                    {...register("SPOCcontact", { required: true })}
                    onChange={(e) =>
                      setCreateProject({
                        ...createProject,
                        SPOCcontact: e.target.value,
                      })
                    }
                  />
                </div>
                {errors.SPOCcontact && (
                  <p style={{ marginLeft: "140px" }}>
                    SPOC contact is required
                  </p>
                )}
              </div>
            </div>
          </Modal>

          <Modal
            title="Purchase Orders"
            visible={visible2}
            onOk={handlePoTable}
            onCancel={handleCancel}
            width="920px"
          >
            <Table dataSource={purchaseOrders} columns={po_columns} />
          </Modal>
          <Table
            columns={columns}
            dataSource={projects}
            pagination={{ pageSize: 6 }}
          />
        </div>
      )}
    </div>
  );
};

export default Projects;
