import axios from "axios";
import { func } from "prop-types";
import { getToken } from "./helper";

// axios.defaults.baseURL = "http://localhost:3001/v1"; // LOCAL HOSTING
axios.defaults.baseURL = "https://api.getclairco.com/v1"; // PRODUCTION TESTING
function setHeader() {
  axios.defaults.headers.common["Authorization"] = getToken();
}
export async function getData() {
  setHeader();
  return await axios.get("data");
}
export async function typeOfLogin(email) {
  return await axios.post("typeOfLogin", { email });
}
export async function login(email, password) {
  return await axios.post("login", { email, password });
}
export async function changePassword(oldpassword, newpassword, email) {
  return await axios.post("changePassword", {
    oldpassword,
    newpassword,
    email,
  });
}
export async function adminLogin(email, password) {
  return await axios.post("adminLogin", { email, password });
}
export async function addCustomer(data) {
  return await axios.post("customer", data);
}
export async function addProperty(data) {
  return await axios.post("property", data);
}

export async function getPredictedAddress(input) {
  return await axios.get("/property/predictedAddresses/" + input);
}

export async function editProperty(data) {
  return await axios.put("/property/edit", data);
}

export async function deleteProperty(id) {
  return await axios.delete("/property/delete/" + id);
}

export async function getAllDevices() {
  return await axios.get("devices/all");
}

export async function getGsmDevice(id) {
  return await axios.get(`device/data/${id}`);
}

export async function searchByProperty(val) {
  return await axios.get(`/property/search?query=${val}`);
}

export async function searchBySpace(val) {
  return await axios.get(`/space/search?query=${val}`);
}

export async function editSpace(data) {
  return await axios.put("/space/edit", data);
}

export async function getItem() {
  return await axios.get("item");
}

export async function addItem(data) {
  return await axios.post("item", data);
}

export async function addItemWithFile(data) {
  return await axios.post("/item/add/file", data, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export async function findOneItem(name) {
  return await axios.get("item/" + name);
}

export async function updateItem(name, data) {
  // return await axios.post("item/" + name.replace(/%20/g, " "), data);
  return await axios.put("item/update", data);
}

export async function getAllCustomer() {
  return await axios.get("customer/list");
}

export async function getAllDisconnectedDevices() {
  return await axios.get("customer/devices/disconnected/list");
}

export async function getAllUsers() {
  return await axios.get("user/all");
}

export async function getAllCustomerUsers(id) {
  return await axios.get("customer", {
    params: {
      id: id,
    },
  });
}

export async function getCustomer(id, deepData) {
  return await axios.get("customer", {
    params: {
      id,
      deep_data: deepData,
    },
  });
}

export async function editCustomer(data) {
  return await axios.put("/customer/edit", data);
}

export async function updateCustomerSLA(data) {
  return await axios.post("customer/update", {
    data,
  });
}

export async function getSummaryAqData(id, deep_data, start_day) {
  return await axios.get("summary_aq_data", {
    params: {
      id,
      deep_data,
      start_day,
    },
  });
}

export async function getOutdoorSummaryAqData(id, deep_data, start_day) {
  return await axios.get("outdoor/summary_aq_data", {
    params: {
      id,
      deep_data,
      start_day,
    },
  });
}

export async function updateUser(id, update_label, update_data) {
  return await axios.post("update_user", {
    id,
    update_label,
    update_data,
  });
}

export async function updateCustomer(id, update_label, update_data) {
  return await axios.post("update_customer", {});
}

export async function getAqExcelFile(
  customerId,
  deepData,
  startDate,
  endDate,
  fileType,
  city,
  customerName,
) {
  return await axios
    .get("download_excel", {
      params: {
        id: customerId,
        deep_data: deepData,
        startDate: startDate,
        endDate: endDate,
        fileType,
        city,
      },
      responseType: "blob",
    })
    .then((response) => {
      // console.log("response:",response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        customerName +
          "_" +
          city +
          "_AirQuality_WeeklyReport_PoweredByClairco.xlsx",
      );
      document.body.appendChild(link);
      link.click();
    });
}

export async function getDptDataFile(
  devId,
  startDate,
  endDate,
) {
  return await axios
    .get("dpt/data", {
      params: {
        device: devId,
        startDate: startDate,
        endDate: endDate
      },
      responseType: "blob"
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
          "_DPT_Data_Report_PoweredByClairco.xlsx",
      );
      document.body.appendChild(link);
      link.click();
    });
}
export async function getExcelFile(sendData, fileType) {
  // console.log("in getExcelFileAPI");
  return await axios
    .get("download_excelFile", {
      params: {
        sendData,
        fileType,
      },
      responseType: "blob",
    })
    .then((response) => {
      // console.log("response:",response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "AirQuality_WeeklyReport_PoweredByClairco.xlsx",
      );
      document.body.appendChild(link);
      link.click();
    });
}

export async function getProperty(id) {
  return await axios.get("property", {
    params: {
      id,
    },
  });
}

export async function getSpaceFromProperty(id) {
  return await axios.get("property", {
    params: {
      id,
    },
  });
}

export async function getSpace(id) {
  return await axios.get("space", {
    params: {
      id,
    },
  });
}

export async function getAQ(spaceId, propId, insDevId, outDevId, dateRangeArr) {
  return await axios.get("aq_data", {
    params: {
      spaceId,
      propId,
      insDevId,
      outDevId,
      dateRangeArr,
    },
  });
}

export async function getAverageHistoryData(spaceId, dateRangeArr) {
  return await axios.get("/average_history_data", {
    params: {
      spaceId,
      dateRangeArr,
    },
  });
}

export async function getHistoryData(spaceId, dateRangeArr) {
  return await axios.get("/history_data", {
    params: {
      spaceId,
      dateRangeArr,
    },
  });
}

export async function calculte_aqi(pm25_value) {
  return await axios.get("/calculte_aqi", {
    params: {
      pm25_value,
    },
  });
}

export async function calculte_productivity(aqi_value) {
  return await axios.get("/calculte_productivity", {
    params: {
      aqi_value,
    },
  });
}

export async function searchCustomer(query) {
  return await axios.get("customer/search", {
    params: {
      query,
    },
  });
}
export async function addSpace(data) {
  return await axios.post("space", data);
}

export async function deleteSpace(id) {
  return await axios.delete("/space/delete/" + id);
}

export async function addDevice(data) {
  return await axios.post("device", data);
}

export async function editDevice(data) {
  return await axios.put("device/edit", data);
}

export async function deleteDevice(id) {
  return await axios.delete("device/" + id);
}
export async function addAC(data) {
  return await axios.post("space/ac", data);
}

export async function addFilter(data) {
  return await axios.post("/filter", data);
}

export async function addLog(data) {
  return await axios.post("/log", data);
}

export async function getFilters(id) {
  return await axios.get("/customer/filter", {
    params: {
      id,
    },
  });
}

export async function deleteFilter(id) {
  return await axios.delete("/filter/" + id);
}

export async function deleteLog(id) {
  return await axios.delete("/filter/log/" + id);
}

export async function getFilterData(id) {
  return await axios.get("/filter/" + id);
}

export async function getLogs(id) {
  return await axios.get("/logs", {
    params: {
      id,
    },
  });
}

export async function addStation(data) {
  return await axios.post("/outside/station/add", data);
}

export async function deleteStation(id) {
  return await axios.delete("/outside/station/delete/" + id);
}

export async function fetchLatLonData(data) {
  return await axios.get("/outside/property/add", {
    params: {
      data,
    },
  });
}

export async function addUVDevice(data) {
  return await axios.post("/filter/uv", data);
}

export async function addDPTController(data) {
  return await axios.post("/filter/dpt", data);
}

export async function getPurifiers(id) {
  return await axios.get("/purifier/all", {
    params: {
      id,
    },
  });
}

export async function addPurifier(data) {
  return await axios.post("/purifier/add", data);
}

export async function changePurifierState(data) {
  return await axios.post("/purifier/change", data);
}

export async function updatePurifier(data) {
  return await axios.post("/purifier/update", data);
}

export async function getPurifierExcelReport(customerId, startDate, endDate) {
  // console.log("in getAqExcelFile");
  return await axios
    .get("/purifier/report", {
      params: {
        id: customerId,
        startDate: startDate,
        endDate: endDate,
      },
      responseType: "blob",
    })
    .then((response) => {
      // console.log("response:",response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "Purifier_StateReport_PoweredByClairco.xlsx",
      );
      document.body.appendChild(link);
      link.click();
    });
}

export async function getUserData(email) {
  return await axios.get("user/data", {
    params: {
      email,
    },
  });
}

export async function sendHSMMessage(data) {
  return await axios.post("whatsapp/HSM/send-message", data);
}

export async function sendSessionMessage(data) {
  return await axios.post("/whatsapp/Session/send-message", data);
}

export async function getConversationHistory(data) {
  return await axios.get("/whatsapp/conversation/history?contact=" + data);
}

export async function addVendor(payload) {
  return await axios.post("/vendor", payload);
}

export async function deleteVendorData(id) {
  return await axios.delete(`/vendor/delete/${id}`);
}

export async function updateVendorDetails(id, payload) {
  return await axios.put(`/vendor/update/${id}`, payload);
}

export async function getVendors() {
  return await axios.get("/vendor/all");
}

export async function updateUserDetail(payload) {
  return await axios.post("/update_user", payload);
}

export async function deleteUser(id) {
  return await axios.delete(`/user/delete/${id}`);
}

export async function removeUserAssociationWithCustoner(payload) {
  return await axios.put("/user/remove/association", payload);
}

export async function addUserToCustomerWithRole(payload) {
  return await axios.post("user/customer/role", payload);
}

export async function getPOsForProject(id) {
  return await axios.get(`/admin/po/project/${id}/all`);
}

export async function getProjects() {
  return await axios.get("/admin/projects");
}

export async function uploadBOQ(payload) {
  return await axios.post("/admin/boq/upload", payload);
}

export async function addProject(payload) {
  return await axios.post("admin/project", payload);
}

export async function deleteBOQForProject(payload) {
  return await axios.put("/admin/boq/delete", payload);
}

export async function deletePOForProject(id) {
  return await axios.delete(`/admin/po/delete/${id}`);
}

export async function getAllItems() {
  return await axios.get("/item");
}

export async function uploadPO(payload) {
  return await axios.post("/admin/po/upload", payload);
}

export async function getAllVendors() {
  return await axios.get("/vendor/all");
}

export async function getAllApprovers() {
  return await axios.get("/user/role");
}

export async function getUvData(id) {  
  return await axios.get("/uv/data/all/"+id);
}

export async function makePostRequest (data) {
  let postrequestData = {
      "deviceId": data.id,
      "fetchType": "latest"
  }
  let headers = {
      'api-key': 'lcvhzec0ae4u20047s36mfexcjr2ebd3cpk28b9yca9c9f95d17dgeda',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJzdF9uYW1lIjoiQXNoaXNoIiwiZW1haWwiOiJhc2hpc2gua3VtYXJAY2xhaXJjby5pbiIsImlkIjoiY2xhaXJjby1mZGZkOTNlNC1lYTljLTQxZWItODIzZS0xMjUwMmE5MTQxNTcudnRkZXB3ODZvdyIsImRvbWFpbiI6ImNsYWlyY28iLCJyb2xlcyI6WyJmZDU1YTM2OS0yNjA2LTQyODEtYjgyNS0yODBiNzBmYWM4MjkiXSwiaWF0IjoxNzEzMTYzNTYxLCJleHAiOjE3NDQ2OTk1NjF9.coHIb8hZ09bQ4f7jISgJVNpjw2WAc2Yi5lQ7CzyWy9Y'
  }
  let dptDeviceData = await new Promise(async (resolve, reject) => {
      await axios({
          method: 'POST',
          url: '/device/fetch',
          baseURL: 'https://api.claircoair.com/api',
          headers: headers,
          data: postrequestData
      }).then((data) => {
          resolve(data);
      });
  });

  return dptDeviceData;
}

export async function getUvDataById(id, custid) {
  return await axios.get("/uv/data", {
    params: {
      id,
      custid
    },
  });
}
