import React, { Component } from "react";
import {
  getPurifiers,
  getCustomer,
  addPurifier,
  changePurifierState,
  updatePurifier,
} from "../api";
import {
  Spin,
  Button,
  PageHeader,
  Table,
  Modal,
  Icon,
  Popconfirm,
  Switch,
  notification,
  Alert,
  TreeSelect,
} from "antd";
import PurifierForm from "../components/PurifierForm";
import PurifierEditForm from "../components/PurifierEditForm";
import moment from "moment";
import _ from "lodash";
const dataSource = [
  {
    _id: "1",
    space_name: "purifier 1",
    iaq_device: "yes",
  },
];
export default class Purifier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      customer_id: this.props.id,
      visible: false,
      edit_visible: false,
      status_load: false,
      response: null,
      property_spaces_data: null,
    };
    this.columns = [
      { title: "Device ID", dataIndex: "_id", key: "_id" },
      { title: "Device Name", dataIndex: "space_name", key: "space_name" },
      {
        title: "Status",
        key: "_id",
        render: (text, record) => (
          <Switch
            loading={this.state.status_load}
            defaultChecked={record.status}
            onClick={(e) => this.handelChange(e, record._id)}
            checkedChildren="ON"
            unCheckedChildren="OFF"
          />
        ),
      },
      {
        title: "Action",
        children: [
          {
            title: "View Device Data",
            dataIndex: "iaq_device",
            key: "_id",
            render: (text, record) => (
              <Button onClick={this.showDataModal(record.iaq_device)}>
                <Icon type="eye" />
              </Button>
            ),
          },
          {
            title: "View Logs",
            dataIndex: "customer_id",
            key: "customer_id",
            render: (id) => (
              <Button>
                <Icon type="eye" />
              </Button>
            ),
          },
          {
            title: "Edit Purifier",
            dataIndex: "_id",
            key: "_id",
            render: (text, record) => (
              <Button
                onClick={() => {
                  this.handleEditDevice(record._id);
                }}
              >
                <Icon type="edit" />
              </Button>
            ),
          },
          {
            title: "Delete",
            dataIndex: "_id",
            key: "id",
            render: (id) => (
              <Popconfirm
                placement="topRight"
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
              >
                <Button>
                  <Icon type="delete" style={{ color: "#FF0000" }} />
                </Button>
              </Popconfirm>
            ),
          },
        ],
      },
    ];
  }

  async componentDidMount() {
    try {
      const { data } = (await getCustomer(this.state.customer_id, true)).data;
      let property_spaces_data = [];
      data.Properties &&
        data.Properties.map((property) => {
          let obj = { children: [], title: "", value: "", key: "" };
          obj.title = property.name;
          obj.value = property.name;
          obj.key = property.id;
          property.Spaces &&
            property.Spaces.map((space) => {
              obj.children = [
                {
                  title: space.name,
                  value: space.name,
                  key: space.id,
                },
              ];
            });
          property_spaces_data.push(obj);
        });
      this.setState({ property_spaces_data });
    } catch (error) {
      console.log(error);
    }
  }

  prevComponent = () => {
    this.props.onClick("Customer", this.state.customer_id, null);
  };

  savePurifierForm = (formRef) => {
    this.purifierRef = formRef;
  };

  editPurifierForm = (formRef) => {
    this.editPurifierRef = formRef;
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showDataModal = () => {};

  handleEditDevice = (model_id) => {
    // var defaultData = _.filter(this.state.devices, (x) => x._id === model_id);
    // defaultData[0].turn_on_time = moment(defaultData[0].turn_on_time, "HH:mm");
    // defaultData[0].turn_off_time = moment(
    //   defaultData[0].turn_off_time,
    //   "HH:mm",
    // );
    // this.setState({
    //   edit_visible: true,
    //   selected_model_id: model_id,
    //   deviceDefaultData: defaultData[0],
    // });
  };

  handelCancel = () => {
    this.setState({
      visible: false,
      edit_visible: false,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
      edit_visible: false,
    });
  };

  onSubmit = () => {
    const purifierForm = this.purifierRef.props.form;

    purifierForm.validateFields(async (err, formdetails) => {
    //   console.log(formdetails);
      formdetails["customer_id"] = this.state.customer_id;
      if (err) {
        return err;
      }
      try {
        const response = await addPurifier({
          ...formdetails,
        });
        purifierForm.resetFields();
        this.setState({ code: 200, response: "Device added", visible: false });
      } catch (e) {
        this.setState({ code: 400, response: "Error" });
      }
    });
  };

  onUpdate = () => {
    const editPurifierForm = this.editPurifierRef.props.form;

    editPurifierForm.validateFields(async (err, formdetails) => {
      var turn_on_time = moment(formdetails["turn_on_time"]._d).format("HH:mm");
      var turn_off_time = moment(formdetails["turn_off_time"]._d).format(
        "HH:mm",
      );
      formdetails["purifier_device_id"] = this.state.selected_model_id;
      formdetails["turn_on_time"] = turn_on_time;
      formdetails["turn_off_time"] = turn_off_time;
      formdetails["customer_id"] = this.state.customer_id;

      if (err) {
        return err;
      }
      try {
        const response = await updatePurifier({
          ...formdetails,
        });
        editPurifierForm.resetFields();
        this.setState({ code: 200, response: "Device Updated" });
      } catch (e) {
        this.setState({ code: 400, response: "Error Updating" });
      }
    });
  };

  handelChange = async (e, id) => {
    this.setState({ status_load: true });
    var data = {
      device_id: id,
      state: e == false ? "OFF" : "ON",
    };
    notification.warn({
      message: "Updating...",
      description:
        "The state of the Purifier is being Updated to " +
        data.state +
        ". Please wait for around 10 sec.",
      onClick: () => {
        //   console.log('Notification Clicked!');
      },
    });
    try {
      let result = (await changePurifierState(data)).data;
      if (result.data) {
        notification.open({
          message: "Purifier State Updated",
          description:
            "The state of the Purifier has been Updated to " + data.state,
          onClick: () => {
            //   console.log('Notification Clicked!');
          },
        });
      } else {
        notification.warn({
          message: "Unable to Update",
          description:
            "The state of the Purifier was NOT Updated to " + data.state,
          onClick: () => {
            //   console.log('Notification Clicked!');
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({ status_load: false });
  };

  render() {
    const {
      data,
      visible,
      devices,
      edit_visible,
      deviceDefaultData,
    } = this.state;

    return (
      <div style={style.container}>
        <Modal
          title="Add Purifier"
          centered
          onOk={this.handleOk}
          onCancel={this.handelCancel}
          visible={visible}
          footer={[
            <Button key="back" onClick={this.handelCancel}>
              Cancel
            </Button>,
          ]}
        >
          <div>
            <PurifierForm
              wrappedComponentRef={this.savePurifierForm}
              data={this.state.property_spaces_data}
            />
            <Button
              icon="plus"
              key="submit"
              type="primary"
              onClick={this.onSubmit}
            >
              Add Purifier
            </Button>
          </div>
        </Modal>
        <Modal
          title="Edit Purifier Details"
          centered
          onOk={this.handleOk}
          onCancel={this.handelCancel}
          visible={edit_visible}
          footer={[
            <Button key="cancel" onClick={this.handelCancel}>
              Cancel
            </Button>,
          ]}
        >
          <div>
            <PurifierEditForm
              wrappedComponentRef={this.editPurifierForm}
              defaultData={deviceDefaultData}
            />
            <Button
              icon="edit"
              key="submit"
              type="primary"
              onClick={this.onUpdate}
            >
              Update Purifier
            </Button>
            <br />
            {this.state.response !== null ? (
              <Alert message={this.state.response} type="info" />
            ) : null}
          </div>
        </Modal>
        <PageHeader
          className="site-page-header"
          onBack={() => this.prevComponent()}
          title="Customer Purifier"
        />
        <Button
          icon="plus"
          style={style.button}
          type="primary"
          onClick={() => this.showModal()}
        >
          Add Purifier
        </Button>
        <Table
          className="components-table-demo-nested"
          columns={this.columns}
          bordered
          dataSource={dataSource}
        />
      </div>
    );
  }
}

const style = {
  container: {
    margin: "auto",
    background: "#FFF",
    border: "3 px solid green",
    paddingTop: 30,
    maxwidth: 500,
  },
  button: {
    margin: 16,
  },
  saveBtn: {
    marginTop: 20,
    marginBottom: 20,
  },
  loader: {
    width: "100%",
    textAlign: "center",
    marginTop: 100,
  },
  listStyle: {
    marginTop: 20,
  },
};
