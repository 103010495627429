import React, { Component } from "react";
// import { loadSlim } from "tsparticles-slim";
import ReactCardFlip from "react-card-flip";
import {
  Avatar,
  Card,
  Row,
  Col,
  Tooltip,
  Modal,
  Spin,
  Button,
  Table,
} from "antd";
import {
  UserOutlined,
  RightOutlined,
  LeftOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  CheckCircleTwoTone,
  StarTwoTone,
  CloudOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";

import { removeToken, getCsrId, removeCsrId, getUsername } from "../../helper";
import AqiGraphDPT from "../../components/aqiGraph/AqiGraphDPT";
import "../../scenes/Dashboard/DashboardV2.less";
import GirlImage from "../../img/assets/5302.jpg";
import weatherIcon from "../../img/assets/cloudy.png";
import bg from "../../img/assets/property.png";
import ClockGauge from "../../components/dashboardContent/ClockGauge";
import UserManagement from "../UserManagement";
import MaintainenceLogs from "../MaintainenceLogs";
let aqPollutants = ["PM 2.5", "PM 10", "CO2", "VOC"];
const gaugeLabels = ["AQI", "PRO", "FL", "ENG"];
// const gaugeLabels = ["AQI"];

const dataSource = [
  {
    parameter: "AQI",
    unit: "No Units",
    excellent: "<50",
    good: "50-100",
    average: "100-200",
    poor: ">200",
  },
  {
    parameter: "PM 2.5",
    unit: "μg/m3",
    excellent: "<30",
    good: "30-50",
    average: "50-90",
    poor: ">90",
  },
  {
    parameter: "PM 10",
    unit: "μg/m3",
    excellent: "<50",
    good: "50-150",
    average: "150-200",
    poor: ">200",
  },
  {
    parameter: "CO2",
    unit: "ppm",
    excellent: "<600",
    good: "600-800",
    average: "800-1000",
    poor: ">1000",
  },
  {
    parameter: "VOC",
    unit: "ppb",
    excellent: "<300",
    good: "300-400",
    average: "400-500",
    poor: ">500",
  },
];

const columns = [
  {
    title: "Parameter",
    dataIndex: "parameter",
  },
  {
    title: "Unit",
    dataIndex: "unit",
  },
  {
    title: "Excellent",
    dataIndex: "excellent",
  },
  {
    title: "Good",
    dataIndex: "good",
  },
  {
    title: "Average",
    dataIndex: "average",
  },
  {
    title: "Poor",
    dataIndex: "poor",
  },
];

export default class SummaryPage extends Component {
  constructor(props) {
    super(props);
    if (this.props.showSixParameters == true) {
      aqPollutants = ["PM 2.5", "PM 10", "CO2", "VOC", "TEM", "HUM"];
    } else {
      aqPollutants = ["PM 2.5", "PM 10", "CO2", "VOC"];
    }
    this.state = {
      id: this.props.id,
      selectedCity: this.props.selectedCity,
      data: this.props.data,
      properties: this.props.properties,
      aqiRange: this.props.aqiRange,
      filterInfo: this.props.filterInfo,
      selectedCard: 0,
      defaultCard: 0,
      visible: false,
      isFlipped: false,
      userState: false,
      user_list: this.props.users,
      content: "summary",
      aqi_visible: false,
      pro_visible: false,
      fl_visible: false,
      iaq_visible: false,
      selectedCityIndex: 0,
      showSixParameters: this.props.showSixParameters,
    };
  }

  async fetchDataAndSetState(
    id,
    selectedCity,
    propDataForSummary,
    showSixParameters,
  ) {
    // console.log("property data summary ");
    // console.log(propDataForSummary);
    try {
      const dataforpm25 = [];
      const dataforpm10 = [];
      const dataforvoc = [];
      const dataforco2 = [];
      const tableData = [];
      for (let i = 0; i < propDataForSummary.SpaceData.length; i++) {
        // console.log('summary: ',propDataForSummary.SpaceData[i].spaceinfo);
        if (propDataForSummary.SpaceData[i].spaceinfo.inside) {
          var tempdatapm25 = {};
          var tempdatapm10 = {};
          var tempdatavoc = {};
          var tempdataco2 = {};
          tempdatapm25["name"] = propDataForSummary.SpaceData[i].name;
          tempdatapm25["insData"] = parseInt(
            propDataForSummary.SpaceData[i].spaceinfo.inside.pm25,
            10,
          );
          tempdatapm25["outData"] =
            propDataForSummary.SpaceData[i].spaceinfo.outside != undefined
              ? parseInt(
                  propDataForSummary.SpaceData[i].spaceinfo.outside.pm25,
                  10,
                )
              : "NA";

          tempdatapm10["name"] = propDataForSummary.SpaceData[i].name;
          tempdatapm10["insData"] = parseInt(
            propDataForSummary.SpaceData[i].spaceinfo.inside.pm10,
            10,
          );
          tempdatapm10["outData"] =
            propDataForSummary.SpaceData[i].spaceinfo.outside != undefined
              ? parseInt(
                  propDataForSummary.SpaceData[i].spaceinfo.outside.pm10,
                  10,
                )
              : "NA";

          tempdatavoc["name"] = propDataForSummary.SpaceData[i].name;
          if (
            propDataForSummary.SpaceData[i].spaceinfo.inside.voc != undefined
          ) {
            tempdatavoc["insData"] = parseInt(
              propDataForSummary.SpaceData[i].spaceinfo.inside.voc,
              10,
            );
          } else {
            tempdatavoc["insData"] = "NA";
          }
          tempdatavoc["outData"] =
            propDataForSummary.SpaceData[i].spaceinfo.outside != undefined
              ? propDataForSummary.SpaceData[i].spaceinfo.outside.voc !=
                undefined
                ? parseInt(
                    propDataForSummary.SpaceData[i].spaceinfo.outside.voc,
                    10,
                  )
                : "NA"
              : "NA";

          tempdataco2["name"] = propDataForSummary.SpaceData[i].name;
          if (
            propDataForSummary.SpaceData[i].spaceinfo.inside.co2 != undefined
          ) {
            tempdataco2["insData"] = parseInt(
              propDataForSummary.SpaceData[i].spaceinfo.inside.co2,
              10,
            );
          } else {
            tempdataco2["insData"] = "NA";
          }
          tempdataco2["outData"] =
            propDataForSummary.SpaceData[i].spaceinfo.outside != undefined
              ? propDataForSummary.SpaceData[i].spaceinfo.outside.co2 !=
                undefined
                ? parseInt(
                    propDataForSummary.SpaceData[i].spaceinfo.outside.co2,
                    10,
                  )
                : "NA"
              : "NA";

          dataforpm25.push(tempdatapm25);
          dataforpm10.push(tempdatapm10);
          dataforvoc.push(tempdatavoc);
          dataforco2.push(tempdataco2);
        }
      }
      var insAq = propDataForSummary.avgIns;
      if (showSixParameters) {
        insAq["pData"] = [
          insAq.pm2_5,
          insAq.pm10,
          insAq.co2,
          insAq.voc,
          insAq.temp,
          insAq.hum,
        ];
        // console.log(insAq["pData"]);
        var outAq = propDataForSummary.avgOut;
        outAq["pData"] = [
          outAq.pm2_5,
          outAq.pm10,
          outAq.co2,
          outAq.voc,
          outAq.aqi,
          outAq.temp,
          outAq.hum,
        ];
      } else {
        insAq["pData"] = [
          insAq.pm2_5,
          insAq.pm10,
          insAq.co2,
          insAq.voc,
          // insAq.aqi,
          // insAq.productivity,
        ];
        // console.log(insAq["pData"]);
        var outAq = propDataForSummary.avgOut;
        outAq["pData"] = [
          outAq.pm2_5,
          outAq.pm10,
          outAq.co2,
          outAq.voc,
          // outAq.aqi,
        ];
      }

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const currentDate = new Date().toLocaleDateString("en-US", options);

      let sla_flag = false;
      let aqi_text = "EXCELLENT";
      if (insAq.pData[0] > 90) {
        aqi_text = "POOR";
      } else if (insAq.pData[0] > 50) {
        aqi_text = "AVERAGE";
      } else if (insAq.pData[0] > 30) {
        aqi_text = "GOOD";
      }
      const selectedAqTrendIndex = 0;
      this.setState({
        id,
        selectedCity,
        data: propDataForSummary,
        dataIsLoaded: true,
        commentModalVisible: false,
        selectedAqTrendIndex,
        // dateRangeArr,
        insAq,
        outAq,
        dataforpm25,
        dataforpm10,
        dataforvoc,
        dataforco2,
        currentDate: currentDate,
        visible: false,
        // tableData,
        sla_flag,
        aqi_text,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async componentDidMount() {
    await this.fetchDataAndSetState(
      this.state.id,
      this.state.selectedCity,
      this.state.data,
      this.state.showSixParameters,
    );
    let intial_card = document.getElementsByClassName("slide:0");
    intial_card[0].classList.add("active");
    setInterval(() => {
      this.setState({
        isFlipped: !this.state.isFlipped,
      });
    }, 30000);

    setTimeout(() => {
      // console.log(this.props);
    }, 5000);
  }

  async componentDidUpdate(prevProps, prevState) {}

  handleClick = (label) => {
    if (label === "AQI") {
      this.setState({
        aqi_visible: true,
      });
    } else if (label === "PRO") {
      this.setState({
        pro_visible: true,
      });
    } else {
      this.setState({
        fl_visible: true,
      });
    }
  };

  changeCard = (dir) => {
    let selected_card = this.state.selectedCard;
    let current_card = document.getElementsByClassName(
      "slide:" + selected_card,
    );
    let next_card_num = null;
    let next_card = null;
    if (dir === "next" && this.state.selectedCard == 2) {
      next_card_num = this.state.defaultCard;
      next_card = document.getElementsByClassName("slide:" + next_card_num);
    } else if (
      dir === "next" &&
      this.state.selectedCard == 1 &&
      this.state.filterInfo == false
    ) {
      next_card_num = this.state.defaultCard;
      next_card = document.getElementsByClassName("slide:" + next_card_num);
    } else if (dir === "next") {
      next_card_num = selected_card + 1;
      next_card = document.getElementsByClassName("slide:" + next_card_num);
    } else if (dir === "prev" && this.state.selectedCard == 0) {
      next_card_num = 1;
      next_card = document.getElementsByClassName("slide:" + next_card_num);
    } else if (
      dir === "prev" &&
      this.state.selectedCard == 0 &&
      this.state.filterInfo == false
    ) {
      next_card_num = 1;
      next_card = document.getElementsByClassName("slide:" + next_card_num);
    } else {
      next_card_num = selected_card - 1;
      next_card = document.getElementsByClassName("slide:" + next_card_num);
    }
    current_card[0].classList.remove("active");
    next_card[0].classList.add("active");
    this.setState({
      selectedCard: next_card_num,
    });
  };

  setVisible = (e) => {
    this.setState({
      [e]: !this.state[e],
    });
  };

  handleClick() {
    this.setState((prevState) => ({ isFlipped: !prevState.isFlipped }));
  }

  showInfoModal = (label) => {
    // console.log(label);
  };

  addUser = () => {
    this.setState({
      content: "add_user",
    });
  };

  render() {
    const { data, id, selectedCityIndex, aqi_text } = this.state;
    var d = new Date();
    var n = d.getDay();
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    if (!this.state.id) {
      return (
        <div className="summaryContainer">
          <Spin size="large" />
        </div>
      );
    }
    if (!this.state.data) {
      return (
        <div className="summaryContainer">
          <Spin size="large" />
        </div>
      );
    }
    if (!this.state.dataIsLoaded) {
      return (
        <div className="summaryContainer">
          <Spin size="large" />
        </div>
      );
    }
    const { content, insAq, outAq } = this.state;

    // const user_group = user_list.map((item, i) => {
    //     return (
    //         <Tooltip title={item.first_name} placement="top">
    //             <Avatar style={{ backgroundColor: '#87d068', margin: '5px', cursor: 'pointer' }} >{item.first_name.charAt(0)}</Avatar>
    //         </Tooltip>
    //     )
    // });
    // console.log(this.state.insAq);

    let maintenanceLogCard;
    if (this.state.filterInfo == true) {
      maintenanceLogCard = (
        <div>
          <h1
            style={{
              float: "left",
              color: "#00695c",
              fontSize: "100%",
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: "bold",
              marginTop: "1%",
            }}
          >
            Maintenance Logs
          </h1>
          <MaintainenceLogs
            id={id}
            properties={this.state.properties}
            source={"summary"}
            logsCount={0}
          />
        </div>
      );
    }
    //  else {
    //   maintenanceLogCard = (
    //     <div style={{ border: "1px solid #e0e0e0", marginTop: "2%" }}>
    //       <h1
    //         style={{
    //           textAlign: "center",
    //           fontWeight: "bold",
    //           color: "#00695c",
    //           marginTop: "5px",
    //         }}
    //       >
    //         <a
    //           href="https://app.cpcbccr.com/ccr_docs/FINAL-REPORT_AQI_.pdf"
    //           style={{ textDecoration: "none" }}
    //           target="_blank"
    //         >
    //           IAQ Standards CPCB
    //         </a>
    //       </h1>
    //       {/* <h1
    //         style={{
    //           fontSize: "150%",
    //           textAlign: "center",
    //           fontFamily: "'Montserrat', sans-serif",
    //         }}
    //       >
    //         AQI Forecast
    //       </h1>
    //       <StarTwoTone
    //         spin="true"
    //         style={{ fontSize: "300%", textAlign: "center", marginLeft: "45%" }}
    //         twoToneColor="#ffc107"
    //       />
    //       <h3
    //         style={{
    //           fontSize: "150%",
    //           textAlign: "center",
    //           fontFamily: "'Montserrat', sans-serif",
    //           marginTop: "2%",
    //         }}
    //       >
    //         31.2<sup>o</sup>C
    //       </h3>
    //       <div>
    //         <Row>
    //           <Col span={8} align="middle">
    //             <div
    //               style={{
    //                 borderRadius: "15px",
    //                 backgroundImage:
    //                   "linear-gradient(to bottom, #ffe082, #ffd54f, #ffca28)",
    //                 minHeight: "20vh",
    //                 maxWidth: "8vw",
    //                 textAlign: "center",
    //               }}
    //             >
    //               <br></br>
    //               <h3
    //                 style={{
    //                   color: "white",
    //                   fontFamily: "'Montserrat', sans-serif",
    //                 }}
    //               >
    //                 Tomorrow
    //               </h3>
    //               <h4 style={{ color: "white", fontSize: "120%" }}>
    //                 <ThunderboltOutlined /> AQI: 140
    //               </h4>
    //               <h4 style={{ color: "white", fontSize: "120%" }}>
    //                 <CloudOutlined /> Temp: 20 <sup>o</sup>C
    //               </h4>
    //             </div>
    //           </Col>
    //           <Col span={8} align="middle">
    //             <div
    //               style={{
    //                 borderRadius: "15px",
    //                 backgroundImage:
    //                   "linear-gradient(to bottom,#90caf9,#64b5f6, #42a5f5 )",
    //                 minHeight: "20vh",
    //                 maxWidth: "8vw",
    //                 textAlign: "center",
    //               }}
    //             >
    //               <br></br>
    //               <h3
    //                 style={{
    //                   color: "white",
    //                   fontFamily: "'Montserrat', sans-serif",
    //                 }}
    //               >
    //                 {days[n + 2]}
    //               </h3>
    //               <h4 style={{ color: "white", fontSize: "120%" }}>
    //                 <ThunderboltOutlined /> AQI: 140
    //               </h4>
    //               <h4 style={{ color: "white", fontSize: "120%" }}>
    //                 <CloudOutlined /> Temp: 20 <sup>o</sup>C
    //               </h4>
    //             </div>
    //           </Col>
    //           <Col span={8} align="middle">
    //             <div
    //               style={{
    //                 borderRadius: "15px",
    //                 backgroundImage:
    //                   "linear-gradient(to bottom,#b388ff,#7c4dff, #651fff)",
    //                 minHeight: "20vh",
    //                 maxWidth: "8vw",
    //                 textAlign: "center",
    //               }}
    //             >
    //               <br></br>
    //               <h3
    //                 style={{
    //                   color: "white",
    //                   fontFamily: "'Montserrat', sans-serif",
    //                 }}
    //               >
    //                 {days[n + 3]}
    //               </h3>
    //               <h4 style={{ color: "white", fontSize: "120%" }}>
    //                 <ThunderboltOutlined /> AQI: 140
    //               </h4>
    //               <h4 style={{ color: "white", fontSize: "120%" }}>
    //                 <CloudOutlined /> Temp: 20 <sup>o</sup>C
    //               </h4>
    //             </div>
    //           </Col>
    //         </Row>
    //         <br></br>
    //       </div> */}
    //       <Table
    //         dataSource={dataSource}
    //         columns={columns}
    //         pagination={{ pageSize: 2 }}
    //       />
    //     </div>
    //   );
    // }

    const insRtAqItems = aqPollutants.map((item, i) => {
      // console.log("flip cards items ");
      // console.log(insAq.pData[i]);
      let p, v, aqi_color;
      if (insAq && insAq.pData[i] >= 0) {
        v = insAq.pData[i];
        p = outAq.pData[i];
      }
      if (item === "PM 2.5" || item === "PM 10") {
        if (item === "PM 2.5") {
          if (v > 90) {
            aqi_color = "bad";
          } else if (v > 50) {
            aqi_color = "moderate";
          } else if (v > 30) {
            aqi_color = "good";
          } else {
            aqi_color = "excellent";
          }
        } else {
          if (v > 200) {
            aqi_color = "bad";
          } else if (v > 150) {
            aqi_color = "moderate";
          } else if (v > 50) {
            aqi_color = "good";
          } else {
            aqi_color = "excellent";
          }
        }

        return (
          <Col span={10} key={item}>
            <ReactCardFlip
              isFlipped={this.state.isFlipped}
              flipDirection="vertical"
            >
              <Card
                className={"aqi_holder_card " + aqi_color}
                onClick={() => this.setVisible("iaq_visible")}
                title={
                  <div>
                    <span className="aqi_card_header_title indoor">Indoor</span>
                    {/* <Tooltip placement="bottom" text={text}>
                      <span
                        className="aqi_card_header"
                        onClick={() => this.setVisible(true)}
                      >
                        <InfoCircleOutlined style={{ color: "#FFF" }} />
                      </span>
                    </Tooltip> */}
                  </div>
                }
                bordered={true}
              >
                <span className="aqi_holder_card_name">{item}</span>
                <br />
                <span className="aqi_holer_card_value">{v}</span>
                <span className="aqi_holder_card_unit"> ug/m3</span>
              </Card>

              <Card
                className="aqi_holder_card_outside"
                onClick={() => this.setVisible("iaq_visible")}
                title={
                  <div>
                    <span className="aqi_card_header_title outdoor">
                      Outdoor
                    </span>
                    {/* <Tooltip placement="bottom" text={text}>
                      <span
                        className="aqi_card_header"
                        onClick={() => this.setVisible(true)}
                      >
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip> */}
                  </div>
                }
                bordered={true}
              >
                <span className="aqi_holder_card_name">{item}</span>
                <br />
                <span className="aqi_holer_card_value">{p}</span>
                <span className="aqi_holder_card_unit"> ug/m3</span>
              </Card>
            </ReactCardFlip>
          </Col>
        );
      } else {
        if (item === "CO2") {
          if (v > 1000) {
            aqi_color = "bad";
          } else if (v > 800) {
            aqi_color = "moderate";
          } else {
            aqi_color = "good";
          }
        } else {
          if (v > 500) {
            aqi_color = "bad";
          } else if (v > 300) {
            aqi_color = "moderate";
          } else {
            aqi_color = "good";
          }
        }
        return (
          <Col span={10}>
            {/* <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="vertical"> */}
            <Card
              className={"aqi_holder_card " + aqi_color}
              onClick={() => this.setVisible("iaq_visible")}
              title={
                <div>
                  <span className="aqi_card_header_title indoor">Indoor</span>
                  {/* <Tooltip placement="bottom" text={text}>
                    <span
                      className="aqi_card_header"
                      onClick={() => this.setVisible("true")}
                    >
                      <InfoCircleOutlined style={{ color: "#FFF" }} />
                    </span>
                  </Tooltip> */}
                </div>
              }
              bordered={true}
            >
              <span className="aqi_holder_card_name">{item}</span>
              <br />
              <span className="aqi_holer_card_value">{0 + v}</span>
              <span className="aqi_holder_card_unit">
                {item === "CO2"
                  ? " ppm"
                  : item === "TEM"
                  ? "C"
                  : item === "HUM"
                  ? "%"
                  : " ppb"}
              </span>
            </Card>
            {/* </ReactCardFlip> */}
          </Col>
        );
      }
    });

    const insRealtimeAqCard = (
      <div className="summary_aqi_holder">{insRtAqItems}</div>
    );

    const gaugeItems = gaugeLabels.map((item, i) => {
      let p, v;
      if (item === "AQI") {
        p = insAq.pData[0];
        v = outAq.pData[0];
      } else if (item === "PRO") {
        p = insAq.productivity;
        v = "";
        // console.log(p);
      }
      let label = gauge_labels[item].label;
      let className = "gauge_container slide:" + i;

      return (
        <div className={className} key={className}>
          {/* {item === "AQI" ? (
            <div className="gauge_card_desc">
              <div className="aqi_indicator green"></div>Indoor
              <div className="aqi_indicator red"></div>Outdoor
            </div>
          ) : null} */}
          <div className="gauge_card_icon_label">
            {label}{" "}
            <InfoCircleOutlined
              className="gauge_info_icon"
              onClick={() => this.handleClick(item)}
            />
          </div>
          <Col className="center" span={8} style={{ marginTop: "10%" }}>
            {/* <div className="gauge_card_icon_holder prev">
              <LeftOutlined
                onClick={() => this.changeCard("prev")}
                style={{ color: "#FFF", fontSize: "25px" }}
              />
            </div> */}
          </Col>
          <Col span={8}>
            <Card style={{ border: "none", backgroundColor: "transparent" }}>
              <ClockGauge
                strokeWidth="15"
                sqSize="200"
                percentage={p}
                item={item}
                v={v}
              />
            </Card>
          </Col>
          <Col className="center" span={8} style={{ marginTop: "10%" }}>
            {/* <div className="gauge_card_icon_holder next">
              <RightOutlined
                onClick={() => this.changeCard("next")}
                style={{ color: "#FFF", fontSize: "25px" }}
              />
            </div> */}
          </Col>
          {/* <div className="gauge_card_icon_info">
            <CheckCircleTwoTone twoToneColor="#52c41a" /> Indoor Air quality is
             {" "+ 100 - parseInt((insAq.aqi / outAq.aqi) * 100)}% better than outdoor
          </div> */}
        </div>
      );
    });
    const propertyGauge = (
      <Row className="user_gauge_card_carousel" gutter={[8, 8]}>
        <div className="image_container">
          <img className="gauge_card_bg" src={bg} />
        </div>
        {gaugeItems}
      </Row>
    );
    if (content === "add_user") {
      return <UserManagement id={this.state.id} />;
    } else {
      return (
        <Row>
          <Modal
            title="Air Quality Guidelines"
            footer={null}
            centered
            visible={this.state.aqi_visible}
            onCancel={() => this.setVisible("aqi_visible")}
            width={800}
          >
            <p>
              Air quality guideline is an annual mean concentration guideline
              for particulate matter from the World Health Organization, most
              recently updated in 2005.
            </p>
            <p>
              In terms of health effects, the guideline states that PM2.5
              concentration of 10 is the lowest level at which total,
              cardiopulmonary and lung cancer mortality have been shown to
              increase with more than 95% confidence in response to long-term
              exposure to PM2.5.
            </p>
            <p>
              Along with cardiopulmonary and lung cancer deaths, the chances of
              which an individual increases their risk of being diagnosed with
              these is highly coordinated to fine particulate matter and sulfur
              dioxide-related pollution. A 2002 study found that "Each 10 μg/m3
              elevation in fine particulate air pollution was associated with
              approximately a 4%, 6% and 8% increased risk of all-cause,
              cardiopulmonary, and lung cancer mortality, respectively."
            </p>
            <p>These guidelines are as follows:</p>
            <table id="customers">
              <tr>
                <th>Pollutant</th>
                <th>Good</th>
                <th>Moderate</th>
                <th>Severe</th>
              </tr>
              <tr>
                <td>
                  PM 2.5 (ug/m<sup>3</sup>)
                </td>
                <td>{"< " + this.state.aqiRange[0].min}</td>
                <td>
                  {parseInt(this.state.aqiRange[0].min) + 1} -{" "}
                  {this.state.aqiRange[0].max - 1}
                </td>
                <td>{"> " + this.state.aqiRange[0].max}</td>
              </tr>
              <tr>
                <td>
                  PM 10 (ug/m<sup>3</sup>)
                </td>
                <td>{"< " + this.state.aqiRange[1].min}</td>
                <td>
                  {parseInt(this.state.aqiRange[1].min) + 1} -{" "}
                  {this.state.aqiRange[1].max - 1}
                </td>
                <td>{"> " + this.state.aqiRange[1].max}</td>
              </tr>
              <tr>
                <td>
                  CO<sub>2</sub> (ppm)
                </td>
                <td>{"< " + this.state.aqiRange[2].min}</td>
                <td>
                  {parseInt(this.state.aqiRange[2].min) + 1} -{" "}
                  {this.state.aqiRange[2].max - 1}
                </td>
                <td>{"> " + this.state.aqiRange[2].max}</td>
              </tr>
              <tr>
                <td>VOC (ppb)</td>
                <td>{"< " + this.state.aqiRange[3].min}</td>
                <td>
                  {parseInt(this.state.aqiRange[3].min) + 1} -{" "}
                  {this.state.aqiRange[3].max - 1}
                </td>
                <td>{"> " + this.state.aqiRange[3].max}</td>
              </tr>
            </table>
            <br />
          </Modal>
          <Modal
            title="Productivity"
            footer={null}
            centered
            visible={this.state.pro_visible}
            onCancel={() => this.setVisible("pro_visible")}
            width={800}
          >
            <h1>Worker Productivity and Air Quality</h1>
            <p>
              A healthier workforce and worker productivity is enhanced by
              improved air quality. Reduced worker productivity occurs at levels
              of pollution well within current air quality standards and
              guidelines.
            </p>
            <p>
              Worker productivity captures more subtle effects of pollution,
              which are likely to be widespread.
            </p>
            <p>
              <ul>
                <li>
                  Most prior evidence that has looked at the effects of
                  pollution has focused on health outcomes, and typically rather
                  extreme events, such as mortality and hospitalizations.
                </li>
                <li>
                  Productivity is an outcome that is easily monetized, which
                  therefore makes it relatively straightforward to calculate its
                  cost to society and to include it in cost–benefit analyses of
                  air quality policies.
                </li>
              </ul>
            </p>

            <p>
              On average, a 10-unit increase in the Air Quality Index (AQI) led
              to a <b>0.35%</b> decline in the number of calls handled by a
              Ctrip worker. Findings suggest that workers are <b>5%–6%</b> more
              productive when air pollution levels are rated as good by the
              Environmental Protection Agency (AQI of 0–50) versus when they are
              rated as unhealthy (AQI of 150–200).
            </p>
          </Modal>
          <Modal
            footer={null}
            title="Filter Life"
            centered
            visible={this.state.fl_visible}
            onCancel={() => this.setVisible("fl_visible")}
            width={800}
          >
            <h1>AI based Filter Life Predictions</h1>
            <p>
              Predictive maintenance of filter life based on Artificial
              Intelligence. The AI technology will correlate external and
              internal parameters like PM 2.5 and PM 10. It will also take
              differential pressure across filter to predict the filter life and
              maintenance needed.
              <br />
              With Clairco AI technology, you can ensure to work in a healthy
              workspace environment.
            </p>
          </Modal>
          <Col span={14}>
            <Row>
              <Card className="user_aqi_info_card">
                <Col span={14}>
                  <Row style={{ marginTop: "10%" }}>
                    <span
                      style={{ fontFamily: "'Montserrat', sans-serif" }}
                      className="aqi_info_card_title"
                    >
                      Hi {getUsername()}
                    </span>
                    <br />
                    <span
                      style={{ fontFamily: "'Montserrat', sans-serif" }}
                      className="aqi_info_card_subtitle"
                    >
                      Air quality at your property is <b> {aqi_text} </b>
                    </span>
                    <br />
                  </Row>
                  {/* <Row style={{ marginTop: 30 }}>
                    <span
                      style={{
                        fontSize: "20px",
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      {insAq.aqi} - Indoor AQI
                    </span>
                    <br />
                    <span
                      style={{
                        fontSize: "20px",
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      {outAq.aqi} - Outdoor AQI
                    </span>
                    <br />
                    <br />
                    <img className="weather_icon" src={weatherIcon} />
                    <span className="temp_data">
                      {" "}
                      34<sup>o</sup>C
                    </span>
                  </Row> */}
                </Col>
                <Col span={10} lg={10}>
                  <img
                    className="aqi_info_card_image"
                    src={GirlImage}
                    style={{ position: "static" }}
                  />
                </Col>
              </Card>
            </Row>
            {propertyGauge}
          </Col>
          <Col span={10}>
            <Row>
              <Modal
                title="Air Quality Guidelines"
                footer={null}
                centered
                visible={this.state.iaq_visible}
                onCancel={() => this.setVisible("iaq_visible")}
                width={600}
              >
                <p>
                  Air quality guideline is an annual mean concentration
                  guideline for particulate matter from the World Health
                  Organization, most recently updated in 2005.
                </p>
                <p>
                  In terms of health effects, the guideline states that PM2.5
                  concentration of 10 is the lowest level at which total,
                  cardiopulmonary and lung cancer mortality have been shown to
                  increase with more than 95% confidence in response to
                  long-term exposure to PM2.5.
                </p>
                <p>
                  Along with cardiopulmonary and lung cancer deaths, the chances
                  of which an individual increases their risk of being diagnosed
                  with these is highly coordinated to fine particulate matter
                  and sulfur dioxide-related pollution. A 2002 study found that
                  "Each 10 μg/m3 elevation in fine particulate air pollution was
                  associated with approximately a 4%, 6% and 8% increased risk
                  of all-cause, cardiopulmonary, and lung cancer mortality,
                  respectively."
                </p>
                <p>These guidelines are as follows:</p>
                <table id="customers">
                  <tr>
                    <th>Pollutant</th>
                    <th>Good</th>
                    <th>Moderate</th>
                    <th>Severe</th>
                  </tr>
                  <tr>
                    <td>
                      PM 2.5 (ug/m<sup>3</sup>)
                    </td>
                    <td>{"< " + this.state.aqiRange[0].min}</td>
                    <td>
                      {parseInt(this.state.aqiRange[0].min) + 1} -{" "}
                      {this.state.aqiRange[0].max - 1}
                    </td>
                    <td>{"> " + this.state.aqiRange[0].max}</td>
                  </tr>
                  <tr>
                    <td>
                      PM 10 (ug/m<sup>3</sup>)
                    </td>
                    <td>{"< " + this.state.aqiRange[1].min}</td>
                    <td>
                      {parseInt(this.state.aqiRange[1].min) + 1} -{" "}
                      {this.state.aqiRange[1].max - 1}
                    </td>
                    <td>{"> " + this.state.aqiRange[1].max}</td>
                  </tr>
                  <tr>
                    <td>
                      CO<sub>2</sub> (ppm)
                    </td>
                    <td>{"< " + this.state.aqiRange[2].min}</td>
                    <td>
                      {parseInt(this.state.aqiRange[2].min) + 1} -{" "}
                      {this.state.aqiRange[2].max - 1}
                    </td>
                    <td>{"> " + this.state.aqiRange[2].max}</td>
                  </tr>
                  <tr>
                    <td>VOC (ppb)</td>
                    <td>{"< " + this.state.aqiRange[3].min}</td>
                    <td>
                      {parseInt(this.state.aqiRange[3].min) + 1} -{" "}
                      {this.state.aqiRange[3].max - 1}
                    </td>
                    <td>{"> " + this.state.aqiRange[3].max}</td>
                  </tr>
                </table>
                <br />
              </Modal>
              {insRealtimeAqCard}
            </Row>
            <Row>
              {
                // <Card className="user_list_holder">
                //     {user_group}
                //     <Tooltip placement="bottom" text='Add User'>
                //         <Button className="add_user"><PlusOutlined /></Button>
                //     </Tooltip>
                // </Card>
              }
            </Row>
            <Row style={{ width: "100%", padding: "30px" }}>
              <div
                style={{
                  maxHeight: "42vh",
                  overflow: "hidden",
                  // marginTop: "-6%",
                }}
              >
                {maintenanceLogCard}
              </div>
            </Row>
          </Col>
        </Row>
      );
    }
  }
}

const text = <span>Info</span>;
const gauge_labels = {
  AQI: {
    label: (
      <span style={{ fontFamily: "'Montserrat', sans-serif" }}>
        Air Quality (PM 2.5)
      </span>
    ),
  },
  PRO: {
    label: (
      <span style={{ fontFamily: "'Montserrat', sans-serif" }}>
        Productivity
      </span>
    ),
  },
  FL: {
    label: (
      <span style={{ fontFamily: "'Montserrat', sans-serif" }}>
        Filter Life
      </span>
    ),
  },
  ENG: {
    label: (
      <span style={{ fontFamily: "'Montserrat', sans-serif" }}>
        Energy Saved
      </span>
    ),
  },
};
