import React, { Component } from "react";
import { Button, Select, Form, Input, Radio, AutoComplete } from "antd";
import axios from "axios";
import { getPredictedAddress } from "../api";
const FormItem = Form.Item;
const Option = Select.Option;

const PropertyForm = Form.create()(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.autocompleteInput = React.createRef();
      this.state = {
        address: "",
        value: "",
        dataSource: [],
        predictedData: [],
      };
      this.onSelect = this.onSelect.bind(this);
      this.onSearch = this.onSearch.bind(this);
    }

    onSelect(value) {
      // console.log("onSelect", value);
      const places = this.state.predictedData;

      const other_data = places.find((place) => place.address === value);
      // console.log(other_data);

      this.props.form.setFieldsValue({
        city: other_data.city.value,
        state: other_data.state.value,
        pin_code: other_data.location.postal_code,
      });
    }
    onSearch = async (searchText) => {
      try {
        const { data } = await (await getPredictedAddress(searchText)).data;
        const addresses = data.map((info) => info.address);
        this.setState({
          dataSource: !searchText ? [] : addresses,
          predictedData: data,
        });
      } catch (error) {
        console.log(error);
      }
    };
    onChange = (value) => {
      this.setState({ value });
    };
    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Form layout="vertical">
          <FormItem label="Property name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please fill property name!",
                },
              ],
            })(
              <Input placeholder="Ex: Cult BTM 2nd stage, Oyo Koramangala 5th block" />,
            )}
          </FormItem>
          {/* <FormItem label="Address">
            {getFieldDecorator("address", {
              rules: [
                {
                  required: true,
                  message: "Please fill address",
                },
              ],
            })(<Input placeholder="Please write complete address" />)}
          </FormItem> */}

          <FormItem label="Address">
            {getFieldDecorator("address", {
              rules: [
                {
                  required: true,
                  message: "Please fill address",
                },
              ],
            })(
              <AutoComplete
                dataSource={this.state.dataSource}
                onSelect={this.onSelect}
                onSearch={this.onSearch}
                placeholder="Please write complete address"
              />,
            )}
          </FormItem>

          <FormItem label="City">
            {getFieldDecorator("city", {
              rules: [
                {
                  required: true,
                  message: "Please fill city",
                },
              ],
            })(
              <Select showSearch placeholder="Please choose city">
                {this.props.cities.map((city) => {
                  return <Option value={city}>{city}</Option>;
                })}
              </Select>,
            )}
          </FormItem>
          <FormItem label="State">
            {getFieldDecorator("state", {
              rules: [
                {
                  required: true,
                  message: "Please fill state",
                },
              ],
            })(<Input placeholder="Please write state" />)}
          </FormItem>
          <FormItem label="Pin code">
            {getFieldDecorator("pin_code", {
              rules: [
                {
                  required: true,
                  message: "Please fill pin code",
                },
              ],
            })(<Input placeholder="Please write pin code" />)}
          </FormItem>
          {/* <FormItem label="Sla">
            {getFieldDecorator("sla", {
              rules: [
                {
                  required: true,
                  message: "Please add SLA"
                }
              ]
            })(<Input/>)}
          </FormItem> */}
        </Form>
      );
    }
  },
);

export default PropertyForm;
