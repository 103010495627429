import React, {Component} from "react";

var PM2_5_LVL_MIN_VAL = {
  excellentMin: 0,
  goodMin: 25,
  poorMin: 35,
  poorMax: 500
};
export var PM2_5_LVL = PM2_5_LVL_MIN_VAL;

var PM10_LVL_MIN_VAL = {
  excellentMin: 0,
  goodMin: 50,
  poorMin: 150,
  poorMax: 556
};
export var PM10_LVL = PM10_LVL_MIN_VAL;

var CO_LVL_MIN_VAL = {
  excellentMin: 0,
  goodMin: 30,
  poorMin: 50,
  poorMax: 100
};
export var CO_LVL = CO_LVL_MIN_VAL;

var CO2_LVL_MIN_VAL = {
  excellentMin: 0,
  goodMin: 800,
  poorMin: 1000,
  poorMax: 5000
};
export var CO2_LVL = CO2_LVL_MIN_VAL;

var VOC_LVL_MIN_VAL = {
  excellentMin: 0,
  goodMin: 300,
  poorMin: 500,
  poorMax: 1000
};
export var VOC_LVL = VOC_LVL_MIN_VAL;

var NOISE_LVL_MIN_VAL = {
  excellentMin: 30,
  goodMin: 40,
  poorMin: 60,
  poorMax: 120
}
export var NOISE_LVL = NOISE_LVL_MIN_VAL;

var UV_LVL_MIN_VAL = {
  excellentMin: 0.1,
  goodMin: 2,
  poorMin: 14,
  poorMax: 40
}
export var UV_LVL = UV_LVL_MIN_VAL;

var LIGHT_LVL_MIN_VAL = {
  excellentMin: 0,
  goodMin: 500,
  poorMin: 800,
  poorMax: 2000
}
export var LIGHT_LVL = LIGHT_LVL_MIN_VAL;


var PERCENT_RANGES_FILL = {
  excellentMin: 5,
  goodMin: 30,
  poorMin: 35,
  poorMax: 98
};

export const P_LVL_TXT = {
  excellent: 'Excellent',
  good: 'Good',
  poor: 'Poor'
};

export const P_LVL_KEY = {
  excellent: 'excellent',
  good: 'good',
  poor: 'poor'
};

export const GET_P_LVL_MIN_VAL_DICT = function(pollutantLabel) {
  return pollutantLabel === 'PM2.5'
          ? PM2_5_LVL_MIN_VAL
          : pollutantLabel === 'PM10'
            ? PM10_LVL_MIN_VAL
            : pollutantLabel === 'CO'
              ? CO_LVL_MIN_VAL
              : pollutantLabel === 'CO2'
                ? CO2_LVL_MIN_VAL
                : pollutantLabel === 'VOC'
                  ? VOC_LVL_MIN_VAL
                  : pollutantLabel === 'LUX'
                    ? LIGHT_LVL_MIN_VAL
                    : pollutantLabel === 'UV'
                      ? UV_LVL_MIN_VAL
                      : pollutantLabel = 'NOISE'
                        ? NOISE_LVL_MIN_VAL
                        : null;
};

export const GET_P_UNIT_HTML = function(pollutantLabel) {
  return ((pollutantLabel === 'PM2.5') || (pollutantLabel === 'PM10'))
          ?<span> ug/m<sup>3</sup></span>
          : pollutantLabel === 'CO2' || pollutantLabel === 'CO' 
            ? ' ppm'
            : pollutantLabel === 'VOC'
              ? ' ppb'
              : pollutantLabel === 'LUX'
                ? ' Lux'
                  : pollutantLabel ==='UV'
                    ? <span> uW/cm<sub>2</sub></span>
                      : pollutantLabel === 'NOISE'
                        ? ' dB'
                          : ' ';
};

export const GET_P_LVL_PERCENT_VALUE = function(value, pollutantLabel, range){
  const minValDict = GET_P_LVL_MIN_VAL_DICT(pollutantLabel);
  // console.log(pollutantLabel)
  // console.log(range);
  if(range!==undefined){
    var label_min = range[0].min;
    var label_max = range[0].max;
    minValDict.goodMin = label_min;
    minValDict.poorMin = label_max;
  }
  // console.log('minValDict: ',minValDict);
  const floorPercentValue = (value === undefined) || (value === 0)
                            ? 0
                            : value < minValDict.goodMin
                              ? PERCENT_RANGES_FILL.excellentMin
                              : value < minValDict.poorMin
                                ? PERCENT_RANGES_FILL.goodMin
                                : PERCENT_RANGES_FILL.poorMin;
  // console.log('floorPercentValue: ',floorPercentValue);
  const ceilingPercentValue = (value === undefined) || (value === 0)
                              ? 0
                              : value < minValDict.goodMin
                                ? PERCENT_RANGES_FILL.goodMin
                                : value < minValDict.poorMin
                                  ? PERCENT_RANGES_FILL.poorMin
                                  : PERCENT_RANGES_FILL.poorMax;
  // console.log('ceilingPercentValue: ',ceilingPercentValue);
  const ceilingRangeValue = (value === undefined) || (value === 0)
                        ? 0
                        : value < minValDict.goodMin
                        ? minValDict.goodMin
                        : value < minValDict.poorMin
                          ? minValDict.poorMin
                          : minValDict.poorMax;
  // console.log('ceilingRangeValue: ',ceilingRangeValue);
  const floorRangeValue = (value === undefined) || (value === 0)
                        ? 0
                        : value < minValDict.goodMin
                        ? minValDict.excellentMin
                        : value < minValDict.poorMin
                          ? minValDict.goodMin
                          : minValDict.poorMin;
  // console.log('floorRangeValue: ',floorRangeValue);
  const percentValue = (value === undefined) || (value === 0)
                        ? 0
                        : floorPercentValue 
                          + parseInt(
                              (ceilingPercentValue-floorPercentValue)
                              *((value-floorRangeValue) / (ceilingRangeValue-floorRangeValue))
                          );

  return (percentValue < PERCENT_RANGES_FILL.poorMax
          ? percentValue
          : PERCENT_RANGES_FILL.poorMax);           
}