import React, { Component } from "react";
import { Input, Col, Form } from "antd";
import { render } from "react-dom";

const FormItem = Form.Item;

const SLAEditForm = Form.create()(
  class extends React.Component {
    render() {
      const { form, defaultData } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Form layout="inline" style={{ marginTop: "15px" }}>
          <FormItem label={defaultData[0].label}>
            {getFieldDecorator("pm25_range_min", {
              initialValue: defaultData[0].min,
              rules: [
                {
                  required: true,
                  message: "Important",
                },
              ],
            })(
              <Input
                type="number"
                addonBefore="min"
                style={{ width: 180, marginRight: "0px", marginLeft: "0px" }}
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("pm25_range_max", {
              initialValue: defaultData[0].max,
              rules: [
                {
                  required: true,
                  message: "Important",
                },
              ],
            })(
              <Input
                type="number"
                addonBefore="max"
                style={{ width: 180, marginRight: "10px", marginLeft: "15px" }}
              />,
            )}
          </FormItem>
          <FormItem label={defaultData[1].label}>
            {getFieldDecorator("pm10_range_min", {
              initialValue: defaultData[1].min,
              rules: [
                {
                  required: true,
                  message: "Important",
                },
              ],
            })(
              <Input
                type="number"
                addonBefore="min"
                style={{ width: 180, marginRight: "0px", marginLeft: "3px" }}
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("pm10_range_max", {
              initialValue: defaultData[1].max,
              rules: [
                {
                  required: true,
                  message: "Important",
                },
              ],
            })(
              <Input
                type="number"
                addonBefore="max"
                style={{ width: 180, marginRight: "10px", marginLeft: "15px" }}
              />,
            )}
          </FormItem>
          <FormItem label={defaultData[2].label}>
            {getFieldDecorator("co2_range_min", {
              initialValue: defaultData[2].min,
              rules: [
                {
                  required: true,
                  message: "Important",
                },
              ],
            })(
              <Input
                type="number"
                addonBefore="min"
                style={{ width: 180, marginRight: "10px", marginLeft: "10px" }}
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("co2_range_max", {
              initialValue: defaultData[2].max,
              rules: [
                {
                  required: true,
                  message: "Important",
                },
              ],
            })(
              <Input
                type="number"
                addonBefore="max"
                style={{ width: 180, marginRight: "10px", marginLeft: "6px" }}
              />,
            )}
          </FormItem>
          <FormItem label={defaultData[3].label}>
            {getFieldDecorator("voc_range_min", {
              initialValue: defaultData[3].min,
              rules: [
                {
                  required: true,
                  message: "Important",
                },
              ],
            })(
              <Input
                type="number"
                addonBefore="min"
                style={{ width: 180, marginRight: "10px", marginLeft: "10px" }}
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("voc_range_max", {
              initialValue: defaultData[3].max,
              rules: [
                {
                  required: true,
                  message: "Important",
                },
              ],
            })(
              <Input
                type="number"
                addonBefore="max"
                style={{ width: 180, marginRight: "10px", marginLeft: "5px" }}
              />,
            )}
          </FormItem>
        </Form>
      );
    }
  },
);

export default SLAEditForm;
