import React, { useState, useEffect } from "react";
import { Table, message } from "antd";
import { isNull, isUndefined } from "lodash";
import { Button, Input, Icon, Spin } from "antd";
import Highlighter from "react-highlight-words";
import moment from "moment";
import GSM from "./GSM";
const DisconnectedDevices = ({
  disconnected_devices,
  handleDisconnectedDeviceInput,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showDeviceData, setShowDeviceData] = useState(false);
  const [disconnectedDevicesData, setDisconnectedDevicesData] = useState([]);
  const [deviceID, setDeviceID] = useState("");
  const [deviceData, setDeviceData] = useState([]);
  const [totalDisconnectedDevices, setTotalDisconnectedDevices] = useState(0);
  useEffect(() => {
    let sum = 0
     if(disconnected_devices.length > 0) {
      disconnected_devices.map(device => sum += device.devices_disconnected)
        // console.log("DISCONNECTED DEVICES COMPONENT " + sum)
        message.success(`Total disconnected Devices ${sum}`,5)
         setTotalDisconnectedDevices(sum)
      }
       
    },[disconnected_devices]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const data = [];
  let devices_data = [];

  disconnected_devices.map((device, i) => {
    const ob = {
      name: device.name,
      last_login: moment(device.last_login).fromNow(),
      devices_disconnected: device.devices_disconnected,
      disconnected_device_lisst: [],
    };
    disconnected_devices.map((device) =>
      isUndefined(device.disconnected_device)
        ? ob.disconnected_device_lisst.push("")
        : ob.disconnected_device_lisst.push(device)
    );
    device.disconnected_device_list.map((dev) =>
      ob.disconnected_device_lisst.push(dev)
    );
    device.device_data.map((dev) => devices_data.push(dev));

    if (!isNull(ob.name)) {
      data.push(ob);
    }
  });

  useEffect(() => {
    let sum = 0
    if(disconnected_devices.length > 0) {
      disconnected_devices.map(device => sum += device.devices_disconnected)
      // console.log("DISCONNECTED DEVICES COMPONENT " + sum)
      message.success(`Total disconnected Devices ${sum}`,5)
      setTotalDisconnectedDevices(sum)
    }
   
  },[disconnected_devices])

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },

    {
      title: "Last login",
      dataIndex: "last_login",
      render: (last_login) => {
        return last_login === "Invalid date" ? "NA" : last_login;
      },
    },
    {
      title: `Disconnected devices  (${totalDisconnectedDevices})`,
      dataIndex: "devices_disconnected",
   
    },
    {
      title: "Disconnected device list",
      dataIndex: "disconnected_device_lisst",
      render: (disconnected_device_lisst) => {
        return disconnected_device_lisst.map((device) => (
          <div>
            <p
              style={{
                color: "#00ad9a",
                marginBottom: "1px",
                cursor: "pointer",
                display: "block",
              }}
              onClick={(e) => {
                findDeviceData(e.target.textContent);
              }}
            >
              {" "}
              {device}
            </p>
          </div>
        ));
      },
    },
  
  ];
  const findDeviceData = (deviceID) => {
    const deviceData = devices_data.find(
      (device) => device.id === deviceID.trim()
    );
    setDeviceData([
      deviceData.pm25,
      deviceData.pm10,
      deviceData.co,
      deviceData.co2,
      deviceData.voc,
      typeof(deviceData.time)  === 'object' ? deviceData.time.dateTime : deviceData.time
    ]);
    setDeviceID(deviceData.id);
    setShowDeviceData(true);
  };

  return data.length > 0 ? (
    <div>
      {showDeviceData && <GSM gsm_data={deviceData} id={deviceID} />}
      <Table
        columns={columns.filter((column) => column.dataIndex !== "last_login")}
        dataSource={data}
        pagination={{ pageSize: 5 }}
      />
    </div>
  ) : (
    <div
      style={{
        margin: "150px 0 0 0",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large" tip="fetching disconnected devices"/>
    </div>
  );
};

export default DisconnectedDevices;
