import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    Spin,    
    Row,
    Col,   
    Card, 
    Button,
    Icon
} from "antd";
import {
    getAQ,
    getCustomer,
    getSummaryAqData
  } from "../api";
import { CreateAqData } from "../utils/createAqData";
import _ from "lodash";
import "../components/kiosk/Kiosk.less"
import background from "../img/Indoor.png";
import logo from "../img/clairco.png";
import orionMall_logo from "../img/customerLogos/orionMall.png";
import {COLOR, GET_CLR_POLLUTANT_LVL} from "../colors";
import { GetDateRangesTxt, GetDateRangeArrForMenuIndex } from "../utils/dateRange";
import { getCsrId } from "../helper";

const aqPollutants = ['PM2.5','PM10'];

export default class DashboardReception extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: props.match.params.id,
            selectedDateRangeIndex: 0,
            dataIsLoaded: true,
            currentIndex: 0,
            selectedCityIndex: 0
        } 
    }

    async componentDidUpdate() {
    }

    async componentDidMount() {
        try{
            let {data} = (await getCustomer(getCsrId(this.state.id), true)).data;
            const customerName = data.name;
            if(data.Properties.length>0){
                data = _(data.Properties)
                .groupBy(property => property.city)
                .map((value, key) => ({name: key, properties: value}))
                .sortBy(cityRegion => cityRegion.name)
                .value();
            }
            const city = data[0].name;
            let cityNamesList = [];
                for(let i=0,l=data.length;i<l;i++){
                    cityNamesList.push(data[i].name);
                }
            
            let propertyId = data[0].properties[0].id;        
            let spaceName = data[0].properties[0].Spaces[this.state.currentIndex].name;
            let propertyName = null;

            data.map(city=>{
                city.properties.map(
                    property => (
                        property.Spaces.map(
                            space => (
                                propertyName = property.name
                            )
                        )
                    )
                )
            });
            
            
            const dateRangeArr = GetDateRangeArrForMenuIndex(this.state.selectedDateRangeIndex);
            let summaryData = (await getSummaryAqData(getCsrId(this.state.id),true,'Yesterday')).data;
            
            let selectedCityIndex = 0;
            let propDataForSummary = {};
            for(let i=0,l=summaryData.data.length;i<l;i++) {
                if(summaryData.data[i].cityName === cityNamesList[selectedCityIndex]) {
                  propDataForSummary = summaryData.data[i].propsDataInsLowestPm2_5;
                  break;
                }
            }

            let spaceDevId = propDataForSummary.spacesDataInsLowestPm2_5
                        ? propDataForSummary.spacesDataInsLowestPm2_5.devId
                        : undefined;
            let propDevId = propDataForSummary.devId;

            const spaceAqData = (await getAQ(undefined, spaceDevId, propDevId, dateRangeArr)).data;
            const {aqTrendData,avgAq,selectedAqTrendIndex,insAq,outAq}
            = CreateAqData(spaceAqData,spaceDevId,dateRangeArr);
            this.setState({
                insAq,
                outAq,
                customerName,
                city,
                propDataForSummary
            })
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        if (!this.state.insAq) {
            return (
              <div className="dashLoaderContainer">
                <Spin size="large"/>
              </div>
            );
          }
        const {customerName, city, insAq, outAq, propDataForSummary} = this.state;
        
        const aqLevels = ['Good', 'Satisfactory', 'Moderate', 'Poor', 'Very Poor', 'Severe'];

        const ftLvlItemsMapped = aqLevels.map ((item,i) => {
            return (
                <Col 
                    className="ftlvlItems" 
                    span={4}
                    style={{backgroundColor: 
                                            item=='Good'
                                            ?COLOR.good:
                                            item=='Satisfactory'
                                            ?COLOR.satisfactory:
                                            item=='Moderate'
                                            ?COLOR.moderate:
                                            item=='Poor'
                                            ?COLOR.poor:
                                            item=='Very Poor'
                                            ?COLOR.veryPoor:
                                            COLOR.severe}}>
                    <Col className="ftlvlItemsContainer" justify="center" align="middle">
                        {item}
                    </Col>
                </Col>
            );
        });

        const rtSideBlocks = aqPollutants.map ((item, i) => {
            let v;
            if(insAq && insAq.pData[i]){
                v = insAq.pData[i];
            }
            return(
                <Row gutter={16}>
                <Col span={4}className="shape" style={{
                    backgroundColor: GET_CLR_POLLUTANT_LVL(v, item)
                    }}></Col>{item}</Row>
            );
        });

        const rtsideItems = (
            <Row gutter={16}>
                {rtSideBlocks}
            </Row>
        );

        const mainLabel = (
            <Col className="aqValueHolder" justify="center" align="middle" style={{
                backgroundColor: GET_CLR_POLLUTANT_LVL(insAq.pData[0], 'PM2.5')}}>
                {insAq.pData[0]}<span className="aqValueUnits">ug/m<sup>3</sup></span>
            </Col>
        )

        const ftLvlItems = (
            <Row gutter={16}>
                {ftLvlItemsMapped}
            </Row>
        );
        const width = window.innerWidth;
        const imageHeightConfig = {
        minHeight: width <= 1366 ? 450 : 450,
        rowHeighImage: width <= 1366 ? 550 : 550,
        rowHeightAward: width <= 1366 ? 120 : 120,
        };
        return (
            !this.state.dataIsLoaded
            ? <div className="propertiesLoaderContainer"><Spin size="large"/></div>
            : <div style={{ minHeight: 600 }}>
                <Row type="flex" justify='center' align='middle'>
                    <div className="AqiLabel">
                        <span>Inside Air Quality</span>
                    </div>
                </Row>
                <Row type="flex" justify='center' align='middle'>
                    <div className="SpaceNameLabel">
                        <span>{customerName},{city}</span>
                    </div>
                </Row>
                <Row gutter={[16, 32]} type="flex" justify="center" align="middle" style={{marginBottom: 10}}>
                    <Col span={4}>
                        <img height={95} src={orionMall_logo}/>
                    </Col>
                    <Col span={12} justify="center" align="middle">
                        <img height={imageHeightConfig.rowHeighImage} 
                         src={background}/>
                    </Col>
                    <Col span={4}>
                        <Card style={{ width: 200 }} bordered={false}>
                            {rtsideItems}
                        </Card>
                    </Col>
                </Row>
                <Row type="flex" justify="center" align="middle">
                    <div className="valulesContainer">
                        <Col className="aqLabelHolder" justify="center" align="middle">PM 2.5</Col>
                        {mainLabel}
                    </div>
                </Row>
                <Row className="footerHeader" type="flex" justify="center" align="middle" style={{marginTop: 50}}>
                    <div className="footerInside">
                        Air Quality Index
                    </div>
                </Row>
                <Row gutter={[16, 16]} className="footerContainer" type="flex" justify="center" align="middle">
                    <img className="footerLogo" height={120} src={logo}/>
                    {ftLvlItems}
                </Row>
            </div>
        );
    }
}
