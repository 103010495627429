import React, { Component } from "react";
import {
  getItem,
  addItem,
  findOneItem,
  updateItem,
  addItemWithFile,
} from "../../api";
import {
  Form,
  Input,
  Button,
  Table,
  Row,
  Col,
  Layout,
  Modal,
  message,
  Radio,
  Divider,
} from "antd";
import qs from "qs";
// import './Admin.less'
import axios from "axios";

const { Search } = Input;
const { Header, Footer, Sider, Content } = Layout;

export default class ItemControl extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Trace Stock",
        dataIndex: "traceStock",
        key: "traceStock",
      },

      {
        title: "Base UOM",
        dataIndex: "baseUOM",
        key: "baseUOM",
      },

      {
        title: "Purchase UOM",
        key: "purchaseUOM",
        dataIndex: "purchaseUOM",
      },
      {
        title: "In Stock",
        dataIndex: "qty",
        key: "qty",
      },
      {
        title: "Cost per unit",
        dataIndex: "cost_per_unit",
        key: "cost_per_unit",
      },

      {
        title: "Remarks",
        dataIndex: "remarks",
        key: "remarks",
      },
    ];
    this.state = {
      name: "",
      traceStock: "yes",
      qty: 0,
      baseUOM: "",
      cost_per_unit: 0,
      purchaseUOM: "",
      remarks: "",
      itemName: "",
      updateCheck: 0,
      isModalVisible: false,
      isModalVisibleUpdate: false,
      getData: {},
      value: "",
      updateData: {},
      file: null,
      alert: false,
      fileKey: "",
    };
  }

  async componentDidMount() {
    try {
      let data = await getItem();
      data = data.data;

      this.setState({
        data: data,
      });
    } catch (error) {
      console.log("Error: ", error);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.alert !== this.state.alert) {
      try {
        let data = await getItem();
        data = data.data;
        this.setState({
          data: data,
          alert: false,
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    }
  }

  showModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  showModalUpdate = async () => {
    const getDatas = (await findOneItem(this.state.value)).data;
    this.setState({
      isModalVisibleUpdate: true,
      updateData: getDatas,
      baseUOM: getDatas.baseUOM,
      name: getDatas.name,
      traceStock: getDatas.traceStock,
      qty: getDatas.qty,
      cost_per_unit: getDatas.cost_per_unit,
      purchaseUOM: getDatas.purchaseUOM,
      remarks: getDatas.remarks,
    });
  };

  handleCancelUpdate = () => {
    this.setState({
      isModalVisibleUpdate: false,
    });
  };

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  searchItem = async (value) => {
    if (value == "") {
      try {
        let data = await getItem();
        data = data.data;
        this.setState({
          data: data,
          updateCheck: 0,
          value: value,
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    } else {
      try {
        const getDatas = await findOneItem(value);
        this.setState({
          data: [getDatas.data],
          updateCheck: 1,
          value: value,
        });
      } catch (err) {
        console.log("ERROR: ====", err);
      }
    }
  };

  updateClick = (e) => {
    // this.setState({
    //     updateCheck:1
    // })
  };

  submitHandler = async (e) => {
    e.preventDefault();

    const postData = qs.stringify({
      name: this.state.name,
      traceStock: this.state.traceStock,
      qty: this.state.qty,
      baseUOM: this.state.baseUOM,
      cost_per_unit: this.state.cost_per_unit,
      remarks: this.state.remarks,
      purchaseUOM: this.state.purchaseUOM,
    });

    // this.setState({
    //   name: "",
    //   qty: 0,
    //   traceStock: 0,
    //   cost_per_unit: 0,
    //   remarks: "",
    //   baseUOM: "",
    //   purchaseUOM: "",
    // });

    try {
      let data = undefined;
      data = await addItem(postData);
      if (data !== undefined) {
        this.setState({
          response: true,
        });
        message.success(data.data.message);
        this.handleCancel();
        this.setState({
          name: "",
          qty: 0,
          traceStock: 0,
          cost_per_unit: 0,
          remarks: "",
          baseUOM: "",
          purchaseUOM: "",
        });
      }
    } catch (error) {
      console.log("Error: ", error);
      message.error(error.response.data.message);
    }

    try {
      let data = await getItem();
      data = data.data;
      this.setState({
        data: data,
      });
    } catch (error) {
      console.log("Error: ", error);
    }

    // this.handleCancel(); // to close add vendor modal
  };

  updateHandler = async (e) => {
    e.preventDefault();
    const postData = qs.stringify({
      name: this.state.name,
      traceStock: this.state.traceStock,
      qty: this.state.qty,
      baseUOM: this.state.baseUOM,
      cost_per_unit: this.state.cost_per_unit,
      remarks: this.state.remarks,
      purchaseUOM: this.state.purchaseUOM,
    });
    try {
      let data = undefined;
      data = await updateItem(this.state.value, postData);
      if (data !== undefined) {
        this.setState({
          response: true,
        });
        message.success(data.data.message);
        this.setState({
          name: "",
          qty: 0,
          cost_per_unit: 0,
          remarks: "",
          baseUOM: "",
          purchaseUOM: "",
        });
        this.handleCancelUpdate();
      }
    } catch (error) {
      message.error(error.response.data.message);
    }

    const getDatas = (await findOneItem(this.state.value)).data;
    this.setState({
      data: [getDatas],
    });
  };

  submitFile = async (e) => {
    e.preventDefault();
    // console.log("submit file");
    const formData = new FormData();
    formData.append("myfile", this.state.file);
    try {
      const res = await addItemWithFile(formData);
      // console.log(res);
      if (res.status == 200) {
        message.success("File uploaded successfully");
        this.setState({
          file: null,
          alert: true,
        });
      }
      // console.log(this.myRef.current.state);
      this.myRef.current.state.value = null;
    } catch (error) {
      console.log(error);
      message.error("Could not upload file. Please try again later");
    }
  };
  render() {
    const { data, updateCheck, updateData } = this.state;
    return (
      <div style={{ maxHeight: "74.5vh", minHeight: "74.5vh" }}>
        <Layout>
          <Header style={{ backgroundColor: "white" }}>
            <div>
              <h1
                style={{
                  float: "left",
                  color: "#00695c",
                  fontSize: "250%",
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: "bold",
                }}
              >
                Item Master
              </h1>
            </div>
          </Header>
          <Layout>
            <Row>
              <Col span={5}>
                <Button
                  type="primary"
                  onClick={this.showModal}
                  style={{
                    width: "120px",
                    marginTop: "10%",
                    fontSize: "20px",
                    height: "40px",
                    fontFamily: "'Montserrat', sans-serif",
                    textAlign: "center",
                    marginLeft: "20%",
                  }}
                >
                  Add Item
                </Button>
              </Col>
              <Col span={19}>
                <Search
                  placeholder="Enter Item Name"
                  allowClear
                  enterButton="Search"
                  size="default"
                  onSearch={this.searchItem}
                  style={{ marginTop: "3%", width: "900px" }}
                />
              </Col>
            </Row>
            <Table
              columns={this.columns}
              dataSource={data}
              pagination={{ pageSize: 6 }}
              size="large"
              style={{
                marginTop: "1%",
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            {updateCheck === 1 ? (
              <div>
                <Button
                  onClick={this.showModalUpdate}
                  type="primary"
                  style={{
                    width: "120px",
                    fontSize: "15px",
                    height: "30px",
                    fontFamily: "'Montserrat', sans-serif",
                    textAlign: "center",
                    marginLeft: "5%",
                  }}
                >
                  Update
                </Button>
              </div>
            ) : null}
            <Modal
              title="Add Items"
              style={{ marginLeft: "35%" }}
              width={600}
              footer={null}
              visible={this.state.isModalVisible}
              onCancel={this.handleCancel}
            >
              <Form
                name="basic"
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{
                  span: 15,
                }}
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item label="Upload excel file" name="myfile">
                  <Input
                    type="file"
                    name="myfile"
                    ref={this.myRef}
                    onChange={(e) => this.setState({ file: e.target.files[0] })}
                  />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    onClick={this.submitFile}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
              <Divider>or Add Single Item</Divider>
              <Form
                name="basic"
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{
                  span: 15,
                }}
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item
                  label="** Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input item name!",
                    },
                  ]}
                >
                  <Input
                    name="name"
                    value={this.state.name}
                    onChange={this.changeHandler}
                  />
                </Form.Item>
                <Form.Item
                  label="Trace Stock"
                  name="traceStock"
                  rules={[
                    {
                      required: true,
                      message: "Please input traceStock!",
                    },
                  ]}
                >
                  {/* <Input
                    name="traceStock"
                    value={this.state.traceStock}
                    onChange={this.changeHandler}
                  /> */}
                  <Radio.Group
                    onChange={this.changeHandler}
                    value={this.state.traceStock}
                    name="traceStock"
                  >
                    <Radio value="yes">YES</Radio>
                    <Radio value="no">NO</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="** Base UOM"
                  className="base"
                  name="baseUOM"
                  rules={[
                    {
                      required: true,
                      message: "Please input item Base UOM!",
                    },
                  ]}
                >
                  <Input
                    name="baseUOM"
                    value={this.state.baseUOM}
                    onChange={this.changeHandler}
                  />
                </Form.Item>

                <Form.Item
                  label="** In Stock"
                  name="qty"
                  rules={[
                    {
                      required: true,
                      message: "Please input quantity!",
                    },
                  ]}
                >
                  <Input
                    name="qty"
                    value={this.state.qty}
                    onChange={this.changeHandler}
                    type="number"
                  />
                </Form.Item>

                <Form.Item
                  label="** Cost per unit"
                  name="cost_per_unit"
                  rules={[
                    {
                      required: true,
                      message: "Please input Cost per unit!",
                    },
                  ]}
                >
                  <Input
                    name="cost_per_unit"
                    type="number"
                    value={this.state.cost_per_unit}
                    onChange={this.changeHandler}
                  />
                </Form.Item>

                <Form.Item
                  label="** Purchase UOM"
                  name="purchaseUOM"
                  rules={[
                    {
                      required: true,
                      message: "Please input item Purchase UOM!",
                    },
                  ]}
                >
                  <Input
                    name="purchaseUOM"
                    value={this.state.purchaseUOM}
                    onChange={this.changeHandler}
                  />
                </Form.Item>

                <Form.Item
                  label="** Remarks"
                  name="remarks"
                  rules={[
                    {
                      required: true,
                      message: "Please input remarks",
                    },
                  ]}
                >
                  <Input
                    name="remarks"
                    value={this.state.remarks}
                    onChange={this.changeHandler}
                  />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    onClick={this.submitHandler}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>

            <Modal
              title="Update Items"
              style={{ marginLeft: "35%" }}
              width={600}
              footer={null}
              visible={this.state.isModalVisibleUpdate}
              onCancel={this.handleCancelUpdate}
            >
              <Form
                name="update"
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{
                  span: 15,
                }}
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item label="Name" name="name">
                  {this.state.name}
                </Form.Item>
                <Form.Item label="traceStock" name="traceStock">
                  {/* <Input
                    name="traceStock"
                    value={this.state.traceStock}
                    onChange={this.changeHandler}
                  /> */}
                  <Radio.Group
                    onChange={this.changeHandler}
                    value={this.state.traceStock}
                    name="traceStock"
                  >
                    <Radio value="yes">YES</Radio>
                    <Radio value="no">NO</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="** Base UOM" name="baseUOM">
                  <Input
                    name="baseUOM"
                    placeholder={updateData.baseUOM}
                    value={this.state.baseUOM}
                    onChange={this.changeHandler}
                  />
                </Form.Item>

                <Form.Item label="** In Stock" name="qty">
                  <Input
                    name="qty"
                    placeholder={updateData.qty}
                    value={this.state.qty}
                    onChange={this.changeHandler}
                  />
                </Form.Item>

                <Form.Item label="** Cost per unit" name="cost_per_unit">
                  <Input
                    name="cost_per_unit"
                    type="number"
                    value={this.state.cost_per_unit}
                    onChange={this.changeHandler}
                  />
                </Form.Item>
                <Form.Item label="** Purchase UOM" name="purchaseUOM">
                  <Input
                    name="purchaseUOM"
                    placeholder={updateData.purchaseUOM}
                    value={this.state.purchaseUOM}
                    onChange={this.changeHandler}
                  />
                </Form.Item>

                <Form.Item label="** Remarks" name="remarks">
                  <Input
                    name="remarks"
                    placeholder={updateData.remarks}
                    value={this.state.remarks}
                    onChange={this.changeHandler}
                  />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    onClick={this.updateHandler}
                    type="primary"
                    htmlType="submit"
                  >
                    Update Data
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </Layout>
        </Layout>
      </div>
    );
  }
}
