import React, { Component } from "react";
import { Button, PageHeader, Form, Alert, Select } from "antd";
import { addLog, getFilterData } from "../api";
import LogForm from "../components/LogForm";

export default class AddLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingData : true,
            response: ""
        }
    }

    async componentDidMount () {
        var filter_id = this.props.id;
        var filter_Data = (await getFilterData(filter_id)).data;
        var filter_name = filter_Data.data.name;
        var customer_id = filter_Data.data.customer_id;
        this.setState({
            filter_id,
            filter_name,
            customer_id
        })
    };
    
    onSubmit = () => {
        const logForm = this.logFormRef.props.form;
        logForm.validateFields(async(err, logDetails) => {
            logDetails['filter_id'] = this.state.filter_id;
            logDetails['filter_name'] = this.state.filter_name;
            if(err){
                return;
            }
            try {
                const response = await addLog({
                    ...logDetails
                });
                logForm.resetFields();
                this.setState({code: 200, response: "Update log added successfully."})
            }catch (e) {                
                this.setState({ code: 400, response: 'Something went wrong'})
            }
        });
      };    

    saveLogFormRef = (formRef) => {
        this.logFormRef = formRef;
    };
    prevComponent = () => {
        this.props.onClick('Filter', this.state.customer_id, null);
    };

    render() {
        const { filter_id } = this.state;
        return(
            <div style={{background: '#FFF'}}>
                <div style={style.container}>
                    <PageHeader
                    className="site-page-header"
                    onBack={() => this.prevComponent()}
                    title={<h1>Add AHU  Update Record</h1>}
                    />
                    <LogForm wrappedComponentRef={this.saveLogFormRef}/>
                    {this.state.response && <Alert
                        message={this.state.response}
                        type={this.state.code == 200
                        ? "success"
                        : "error"}
                        showIcon/>}
                    <Button
                    style={style.saveBtn}
                    type="primary"
                    onClick={this.onSubmit}>
                        Save
                    </Button>
                </div>
            </div>
        );
    }
};

const style = {
    container: {
      margin: "auto",
      maxWidth: 500,
      border: "3 px solid green",
      paddingTop: 30
    },
    saveBtn: {
      marginTop: 20,
      marginBottom: 20
    }
  };