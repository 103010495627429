import React, { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import { Select } from "antd";
import { useForm } from "react-hook-form";
import { ArrowLeftOutlined } from "@ant-design/icons";

import "./PO.less";
import PurchaseOrder from "./PurchaseOrder";
import { isNull } from "lodash";
import {
  getAllItems,
  uploadPO,
  getAllVendors,
  getAllApprovers,
} from "../../api";
const { Option } = Select;
const MAX_PAGE = 4;

const POform = ({
  record,
  showProjectList,
  poForStock,
  handleShowProjectList,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    setValue,
    control,
    watch,
  } = useForm({ mode: "all" });
  const [page, setPage] = useState(1);
  const [vendorList, setVendorList] = useState([]);
  const [orderDate, setOrderDate] = useState("");
  const [POID, setPOID] = useState("");
  const [approversList, setApproversList] = useState([]);
  const [supplierData, setSupplierData] = useState({
    address: "",
    phone: "",
    name: "",
    email: "",
    website: "",
    gst: "",
    eta: "",
    billingAddress: "",
    billingGST: "",
    shippingAddress: "",
  });
  const [billingAddress, setBillingAddress] = useState({
    address: "",
    phone: "",
    gst: "",
  });
  const [shippingAddress, setShippingAddress] = useState({
    address: "",
    phone: "",
  });

  const [terms, setTerms] = useState("");
  const [approvername, setApprovername] = useState("");
  const [visible, setVisible] = useState(false);
  //item state begin

  const [options, setOptions] = useState([]);
  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [uom, setUom] = useState("");
  const [cpu, setCpu] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [purchaseOrder, setPurchaseOrder] = useState([]);
  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [gst, setGst] = useState(0);
  const [selected, setSelected] = useState("");

  // item state end

  const getVendors = async () => {
    try {
      const { data } = await getAllVendors();
      setVendorList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getApproversList = async () => {
    const result = await getAllApprovers();
    let arr = [];
    if (result.data != null) {
      result.data.Users.map((approver) => {
        arr.push({
          email: approver.email,
          name: approver.first_name + "  " + approver.last_name,
        });
      });
    }
    setApproversList(arr);
  };

  useEffect(() => {
    !isNull(JSON.parse(localStorage.getItem("po"))) &&
      setPurchaseOrder(JSON.parse(localStorage.getItem("po")));

    getVendors();

    getApproversList();

    const fetchData = async () => {
      const result = await getAllItems();

      localStorage.setItem("items", JSON.stringify(result.data));
      let arr = [...options];
      result.data.map((item, i) => {
        arr.push({
          value: item.name,
          label: item.name,
        });
      });
      setOptions(arr);
      setItems(result.data);
    };
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = dd + "" + mm + "" + yyyy + "/" + today.getMilliseconds();
    setPOID(today);
    fetchData();
  }, []);

  // fillVendorDetails() is used to fill the vendor related data while creating purchase order

  const fillVendorDetails = (inp) => {
    const vendor = vendorList.find((vendor) => vendor.businessName === inp);
    setSupplierData({
      name: vendor.businessName,
      email: vendor.email,
      address: vendor.address,
      gst: vendor.GSTnumber,
      phone: vendor.POCcontact,
      billingAddress: vendor.address,
      billingGST: vendor.GSTnumber,
      shippingAddress: vendor.address,
    });
  };

  useEffect(() => {
    localStorage.setItem("po", JSON.stringify(purchaseOrder));
  }, [purchaseOrder]);

  function onChange(value) {
    setSelected(value);
    items.map((item) => {
      if (item.name === value) {
        setName(item.name);
        setUom(item.purchaseUOM);
        setCpu(item.cost_per_unit);
        setQuantity(item.qty);
      }
    });
  }

  const deleteOrder = (inp) => {
    setPurchaseOrder(purchaseOrder.filter((item) => item.name !== inp.name));
    if (purchaseOrder.length <= 1) {
      setShow(false);
    }
  };
  const sendPo = async () => {
    const payload = {
      poForStock,
      id: record.id,
      PO_ID: "#PO" + POID,
      purchaseOrder,
      supplierData,
      billingAddress,
      shippingAddress,
      orderDate,
      approvername,
      terms,
      totalpricewithgst: new Intl.NumberFormat("en-IN").format(
        purchaseOrder.reduce((accumulator, item) => {
          return accumulator + item.totalpricewithgst;
        }, 0),
      ),
      totalpricewithoutgst: new Intl.NumberFormat("en-IN").format(
        purchaseOrder.reduce((accumulator, item) => {
          return accumulator + item.totalprice;
        }, 0),
      ),
    };

    try {
      const result = await uploadPO(payload);
      if (result) {
        // setShowInfo(true);
        handleShowProjectList(false);
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.message);
      } else {
        console.log(error.message);
        message.error(error.message);
      }
    }

    setPurchaseOrder([]);
    setPage(1);
    setPOID("");
    setOrderDate("");
    setSupplierData({
      address: "",
      phone: "",
      name: "",
      email: "",
      website: "",
      gst: "",
      eta: "",
      billingAddress: "",
      billingGST: "",
      shippingAddress: "",
    });
    setBillingAddress({
      address: "",
      phone: "",
      gst: "",
    });
    setShippingAddress({
      address: "",
      phone: "",
    });
    setOrderDate("");
    setShow(false);
  };

  // addPO() is used to make a list of items to be purchased while creating Purchase Order

  const addPO = async () => {
    if (name) {
      let arr = [...purchaseOrder];
      const ob = {};
      ob.name = name;
      ob.uom = uom;
      ob.cpu = Number(cpu);
      ob.quantity = Number(quantity);
      ob.totalprice = Number(quantity * cpu);
      ob.totalpricewithgst =
        Number(cpu * quantity) + Number((gst / 100) * (cpu * quantity));
      ob.gst = gst;
      arr.push(ob);
      setPurchaseOrder([...arr]);

      setShow(true);

      setName("");
      setQuantity("");
      setUom("");
      setCpu("");
    }
  };

  function handleChange(value) {
    setGst(value);
  }
  return (
    <div>
      <Modal
        visible={visible}
        title="Purchase Order Confirmation"
        onOk={() => {
          setVisible(false);
          sendPo();
        }}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            No{" "}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setVisible(false);
              sendPo();
            }}
          >
            Yes{" "}
          </Button>,
        ]}
      >
        <p>
          Terms and Conditions are not stated. Do you still want to send PO?
        </p>
      </Modal>

      <div className="invoice-details">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // border: "1px solid red",
          }}
        >
          <Button
            onClick={showProjectList}
            style={{
              marginLeft: "0px",

              color: "white",
              // background: "#36454F",
              background: "none",
              fontWeight: 600,
              border: "none",
            }}
          >
            <ArrowLeftOutlined
              style={{ fontSize: "30px", color: "#36454F", fontWeight: 700 }}
            />
          </Button>
          <span
            style={{
              fontSize: "25px",
              color: "#36454F",
              fontWeight: 700,
              marginLeft: "20px",
            }}
          >
            PO ID. {POID}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // border: "1px solid red",
            margin: "20px  0 0 0",
          }}
        >
          {page !== 4 && (
            <div>
              <div
                style={{
                  fontSize: "16px",
                  color: "#36454F",
                  margin: "0px 0 0 0",
                  fontWeight: 500,
                  // border: "1px solid red",
                  letterSpacing: "1px",
                }}
              >
                Project Name :{" "}
                <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {" "}
                  {record.project_name}
                </span>
              </div>
              <div>
                <label
                  style={{
                    fontSize: "16px",
                    color: "#36454F",
                    fontWeight: 500,
                    letterSpacing: "1px",
                  }}
                >
                  Order Date
                </label>
                <input
                  type="date"
                  name="date"
                  {...register("date", { required: true })}
                  onChange={(e) => setOrderDate(e.target.value)}
                  style={{ width: "200px", marginLeft: "20px" }}
                />
                {errors.date && (
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "red",
                      marginLeft: "112px",
                      marginTop: "5px",
                    }}
                  >
                    Order date is required
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* {showInfo && (
        <Alert
          message="Success"
          description="Purchase Order was successully generated"
          type="success"
          closable
          onClose={(e) => setShowInfo(false)}
          style={{ width: 400, margin: "0 auto " }}
        />
      )} */}
      {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
      <form>
        {page === 4 && (
          <section>
            <div
              className="flex-container"
              style={{
                width: "580px",
                margin: "50px auto",
              }}
            >
              <p>Purchase Order ID : {POID}</p>
              <p>Order date : {orderDate}</p>
              <p>Vendor : {supplierData.name}</p>
              <p>Vendor gst : {supplierData.gst}</p>
              <p>Billing GST : {billingAddress.gst}</p>
              <p>Billing Address : {billingAddress.address}</p>
              <p>Shipping Address : {shippingAddress.address}</p>
              <div>
                <p>Purchase Orders</p>

                <ol>
                  {purchaseOrder.map((po) => (
                    <li>
                      {po.name}, <span>Quantity : {po.quantity}</span>,{" "}
                      <span>
                        Amount : Rs.{" "}
                        {new Intl.NumberFormat("en-IN").format(
                          po.totalpricewithgst,
                        )}
                      </span>
                    </li>
                  ))}
                </ol>
                <p>
                  Total Amount to be Paid with gst: Rs.
                  {new Intl.NumberFormat("en-IN").format(
                    purchaseOrder.reduce((accumulator, item) => {
                      return accumulator + item.totalpricewithgst;
                    }, 0),
                  )}
                  {/* {purchaseOrder.reduce((accumulator, item) => {
                    return accumulator + item.totalpricewithgst;
                  }, 0)} */}
                </p>
                <p>
                  Total Amount to be Paid without gst: Rs.
                  {new Intl.NumberFormat("en-IN").format(
                    purchaseOrder.reduce((accumulator, item) => {
                      return accumulator + item.totalprice;
                    }, 0),
                  )}
                  {/* {purchaseOrder.reduce((accumulator, item) => {
                    return accumulator + item.totalprice;
                  }, 0)} */}
                </p>
              </div>
              <div className="flex-child">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <label htmlFor="">Approver Name</label>
                  <select
                    {...register("approver name", { required: true })}
                    value={approvername}
                    onChange={(e) => {
                      // console.log(e.target);
                      setApprovername(e.target.value);
                    }}
                  >
                    <option value="" disabled selected>
                      Select Approver{" "}
                    </option>
                    {approversList.map((user, i) => (
                      <option value={user.email} key={i}>
                        {user.name}
                      </option>
                    ))}
                    <option value="iamshaiksha17@gmail.com">
                      Shaiksha Vali
                    </option>
                    <option value="ashish.kumar@clairco.in">
                      Ashish Kumar
                    </option>
                  </select>{" "}
                  {/* <input
                    type="text"
                    name="approver"
                    {...register("approver", { required: true })}
                    value={approvername}
                    onChange={(e) => setApprovername(e.target.value)}
                  /> */}
                </div>
                {errors["approver name"] && (
                  <p style={{ marginLeft: "168px" }}>
                    Approver name is required
                  </p>
                )}
              </div>
              <div className="flex-child ">
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <label htmlFor="">Terms & conditions</label>
                  <textarea
                    name="termsnconditions"
                    id=""
                    cols="30"
                    rows="5"
                    value={terms}
                    onChange={(e) => setTerms(e.target.value)}
                  ></textarea>
                </div>
              </div>

              {terms.length !== 0 ? (
                <Button
                  type="button"
                  style={{ background: "#ffa940" }}
                  onClick={sendPo}
                >
                  SEND PO
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  SEND PO
                </Button>
              )}
            </div>
          </section>
        )}
        {page === 1 && (
          <section className="step1-form">
            <div className="supplier-details">
              <div className="title">Supplier order details</div>
              <div className="flex-container">
                <div className="flex-child">
                  <div>
                    <label> Name</label>
                    <select
                      name="supplier name"
                      {...register("supplier name", { required: true })}
                      style={{ width: "408px" }}
                      placeholder="select vendor"
                      onChange={(e) => fillVendorDetails(e.target.value)}
                      // onMouseEnter={e => fillVendorDetails(e.target.value)}
                    >
                      <option value="" disabled selected>
                        choose a supplier
                      </option>
                      {vendorList.length > 0 &&
                        vendorList.map((vendor, i) => (
                          <div>
                            <option value={vendor.businessName} key={i}>
                              {vendor.businessName}
                            </option>
                          </div>
                        ))}
                    </select>
                  </div>

                  {errors["supplier name"] && <p>Name is required</p>}
                </div>
                <div className="flex-child">
                  <div>
                    <label> Email</label>
                    <input
                      type="email"
                      name="email"
                      value={supplierData.email}
                      readOnly
                    />
                  </div>
                </div>
                <div className="flex-child">
                  <div>
                    <label>Website</label>
                    <input
                      type="text"
                      // {...register("website", { required: true })}
                      value={supplierData.website}
                      onChange={(e) =>
                        setSupplierData({
                          ...supplierData,
                          website: e.target.value,
                        })
                      }
                      style={{ width: "403px" }}
                    />
                  </div>
                  {/* {errors["website"] && <p>Website is required</p>} */}
                </div>
                <div className="flex-child">
                  <div>
                    <label>GST</label>
                    <input
                      type="text"
                      name="gst"
                      // {...register("gst", { required: true })}
                      value={supplierData.gst}
                      readOnly
                    />
                  </div>
                  {/* {errors["gst"] && <p>GST is required</p>} */}
                </div>
                <div className="flex-child">
                  <div>
                    <label>ETA</label>
                    <input
                      type="date"
                      name="eta"
                      style={{ width: "403px" }}
                      min={orderDate}
                      {...register("eta", { required: true })}
                      value={supplierData.eta}
                      onChange={(e) => {
                        setSupplierData({
                          ...supplierData,
                          eta: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors["eta"] && (
                    <p style={{ fontWeight: "bold", color: "red" }}>
                      ETA is required
                    </p>
                  )}
                </div>
                <div className="flex-child">
                  <div>
                    <label> Phone</label>
                    <input
                      type="text"
                      name="phone"
                      value={supplierData.phone}
                      readOnly
                      // {...register("phone", { required: true })}
                    />
                    {/* {errors["phone"] && <p>Phone number is required</p>} */}
                  </div>
                </div>
                <div className="flex-child">
                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <label> Address</label>
                    <textarea
                      rows="4"
                      cols="61"
                      name="address"
                      value={supplierData.address}
                      readOnly
                      // {...register("address", { required: true })}
                      style={{ marginLeft: "130px" }}
                    ></textarea>
                  </div>
                  {/* {errors["address"] && <p>Address is required</p>} */}
                </div>
              </div>
            </div>
          </section>
        )}
        {page === 2 && (
          <section className="step2-form">
            <div className="billing-address">
              <div className="title">Billing order details</div>
              <div className="flex-container">
                <div className="flex-child">
                  <div>
                    <label>Phone</label>
                    <input
                      type="text"
                      name="biller phone"
                      value={billingAddress.phone}
                      {...register("biller phone", { required: true })}
                      onChange={(e) =>
                        setBillingAddress({
                          ...billingAddress,
                          phone: e.target.value,
                        })
                      }
                    />
                  </div>
                  {errors["biller phone"] && (
                    <p style={{ fontWeight: "bold", color: "red" }}>
                      Phone number is required
                    </p>
                  )}
                </div>
                <div className="flex-child">
                  <div>
                    <label>GST</label>
                    <input
                      type="text"
                      name="billing gst"
                      {...register("billing gst", {
                        required: true,
                        pattern: /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i,
                      })}
                      value={billingAddress.gst}
                      onChange={(e) =>
                        setBillingAddress({
                          ...billingAddress,
                          gst: e.target.value,
                        })
                      }
                    />
                  </div>
                  {errors["billing gst"] && (
                    <p style={{ fontWeight: "bold", color: "red" }}>
                      GST is required. If entered, type valid gst number
                    </p>
                  )}
                </div>
                <div className="flex-child">
                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <label>Address</label>
                    <textarea
                      rows="4"
                      cols="61"
                      name="billing address"
                      style={{ marginLeft: "60px" }}
                      {...register("billing address", { required: true })}
                      value={billingAddress.address}
                      onChange={(e) =>
                        setBillingAddress({
                          ...billingAddress,
                          address: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                  {errors["billing address"] && (
                    <p
                      style={{
                        marginTop: "105px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      Address is required
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="shipping-address">
              <div className="title">Shipping Details</div>
              <div className="flex-container">
                <div className="flex-child">
                  <div>
                    <label>contact</label>
                    <input
                      type="text"
                      name="shipping phone"
                      {...register("shipping phone", { required: true })}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          phone: e.target.value,
                        })
                      }
                    />
                  </div>
                  {errors["shipping phone"] && (
                    <p style={{ fontWeight: "bold", color: "red" }}>
                      Phone number is required
                    </p>
                  )}
                </div>
                <div className="flex-child">
                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <label>Address</label>
                    <textarea
                      rows="4"
                      cols="55"
                      name="shipping address"
                      style={{ marginLeft: "90px" }}
                      value={shippingAddress.address}
                      {...register("shipping address", { required: true })}
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          address: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                  {errors["shipping address"] && (
                    <p
                      style={{
                        marginTop: "105px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      Address is required
                    </p>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}

        {page === 3 && (
          <div>
            <div className="form-container">
              <div className="select-container" style={{ margin: "50px 0" }}>
                <label className="select-label">Items</label>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select an item"
                  optionFilterProp="children"
                  onChange={onChange}
                  className="sele"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {options.map((item, i) => (
                    <Option
                      value={item.value}
                      key={item.value}
                      disabled={item.value === selected ? true : false}
                    >
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </div>
              <div>
                <div className="inputBox">
                  <label>Name </label>
                  <input type="text" value={name} readOnly />
                </div>
                <div className="inputBox">
                  <label>UOM</label>
                  <input type="text" value={uom} readOnly />
                </div>
                <div className="inputBox">
                  <label>Cost per unit</label>
                  <input
                    type="text"
                    value={cpu}
                    onChange={(e) => {
                      setCpu(e.target.value);
                    }}
                  />
                </div>
                <div className="inputBox">
                  <label>Quantity to purchase</label>
                  <input
                    type="text"
                    value={quantity}
                    onChange={(e) => {
                      setQuantity(e.target.value);
                    }}
                  />
                </div>

                <div className="inputBox">
                  <label>Total</label>
                  <input type="text" value={cpu * quantity} readOnly />
                </div>
                <div className="inputBox">
                  <label>GST type</label>

                  <Select
                    style={{ width: 409 }}
                    onChange={(e) => handleChange(e)}
                    placeholder="select GST"
                  >
                    <Option value={18}> 18.0% GST(18%)</Option>
                    <Option value={12}> 12.0% GST(12%)</Option>
                    <Option value={6}>6.0% GST(6%)</Option>
                    <Option value={5}>5.0% GST(5%)</Option>
                    <Option value={0.25}>0.25 GST(0.25%)</Option>
                  </Select>
                </div>
                <div className="inputBox">
                  <label>Total + GST</label>
                  <input
                    type="text"
                    value={
                      cpu * quantity + Number((gst / 100) * (cpu * quantity))
                    }
                    readOnly
                  />
                </div>
                <button type="button" onClick={addPO} className="order_button">
                  ORDER
                </button>
              </div>
            </div>

            {purchaseOrder.length !== 0 && (
              <PurchaseOrder po={purchaseOrder} deleteOrder={deleteOrder} />
            )}
          </div>
        )}

        {page > 1 && (
          <Button
            style={{ margin: "0 8px", color: "#00ad9a" }}
            onClick={() => setPage(page - 1)}
          >
            Previous
          </Button>
        )}
        {page < MAX_PAGE && (
          <Button
            disabled={!isValid}
            onClick={() => setPage(page + 1)}
            style={{ color: "white", background: "#00ad9a" }}
          >
            Next
          </Button>
        )}
      </form>
    </div>
  );
};

export default POform;
