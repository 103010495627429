import React, {Component} from "react";
import {Row, Col} from "antd";
import './ColorInfo.less';
const ColorInfo = (props) => {
    return (
        <Row className="colorInfoContainer" type="flex" justify="center">
            <Col lg={3} xs={8} sm={4} className="boxContainer">
              <div className="colorInfoGood colorInfoBar"></div>
              Good
            </Col>
            <Col lg={3} xs={8} sm={4} className="boxContainer">
              <div className="colorInfoSatisfactory colorInfoBar"></div>
              Satisfactory
            </Col>
            <Col lg={3} xs={8} sm={4} className="boxContainer">
              <div className="colorInfoModerate colorInfoBar"></div>
              Moderate
            </Col>
            <Col lg={3} xs={8}sm={4} className="boxContainer">
              <div className="colorInfoPoor colorInfoBar"></div>
              Poor
            </Col>
            <Col lg={3} xs={8}sm={4} className="boxContainer">
              <div className="colorInfoVeryPoor colorInfoBar"></div>
              Very Poor
            </Col>
            <Col lg={3} xs={8} sm={4} className="boxContainer">
              <div className="colorInfoSevere colorInfoBar"></div>
              Severe
            </Col>
        </Row>
    )
}

export default ColorInfo;