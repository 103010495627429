import React, { Component } from "react";
import {
  Button,
  Divider,
  Spin,
  Popconfirm,
  message,
  Form,
  PageHeader,
  List,
  Modal,
  Input,
  Select,
  TimePicker,
} from "antd";
import { getSpace, deleteDevice, editDevice, editSpace } from "../api";
import moment from "moment";
const FormItem = Form.Item;
const { Option } = Select;

const space_operational_hours = [
  {
    key: "1",
    value: "All 24 hours",
  },
  {
    key: "2",
    value: "6am to 11pm",
  },
  {
    key: "3",
    value: "10am to 8pm",
  },
  {
    key: "4",
    value: "6am to 12pm - 4pm to 10pm",
  },
  {
    key: "5",
    value: "7am to 7pm",
  },
];

const space_non_operational_days = [
  {
    key: "1",
    value: "None",
  },
  {
    key: "2",
    value: "Saturday - Sunday",
  },
];

const Space = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        data: null,
        spaceId: "",
        visible: false,
        new_device_id: "",
        alert: false,
        isSpaceModalVisible: false,
        operationalHours: "",
        nonOperationalDays: "",
        time: null,
      };
    }
    async componentDidMount() {      
      this.spaceId = this.props.id;

      try {
        const { data } = (await getSpace(this.spaceId)).data;
        // console.log(data);
        const opHours = space_operational_hours.find(
          (space) => space.key === data.opHours,
        );

        const non_op_days = space_non_operational_days.find(
          (space) => space.key === data.NonOpDays,
        );
        // console.log(non_op_days);
        var propertyId = this.props.referrer;
        this.setState({
          data,
          propertyId,
          spaceId: data.id,
          operationalHours:
            opHours !== undefined ? opHours.value : data.opHours,
          nonOperationalDays:
            non_op_days !== undefined ? non_op_days.value : data.NonOpDays,
        });
      } catch (e) {
        console.log(e);
      }
    }

    async componentDidUpdate(prevProps, prevState) {
      if (prevState.alert !== this.state.alert) {
        this.spaceId = this.props.id;

        try {
          const { data } = (await getSpace(this.spaceId)).data;
          // console.log(data);
          var propertyId = this.props.referrer;
          const opHours = space_operational_hours.find(
            (space) => space.key === data.opHours,
          );
          const non_op_days = space_non_operational_days.find(
            (space) => space.key === data.NonOpDays,
          );
          // console.log(non_op_days);
          this.setState({
            data,
            propertyId,
            spaceId: this.props.id,
            operationalHours:
              opHours !== undefined ? opHours.value : data.opHours,
            nonOperationalDays:
              non_op_days !== undefined ? non_op_days.value : data.NonOpDays,
            alert: false,
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
    confirm = async () => {
      try {
        await deleteDevice(this.state.data.Device.id);
        message.success("Deleted");
        this.state.data.Device = null;
        this.setState({ data: this.state.data });
      } catch (er) {
        console.log(er);
        message.error("Operation failed");
      }
    };
    addDevice = () => {
      if (this.state.data.Device) {
        return message.error("A space can have only one device");
      }
      this.props.history.push("/admin/adddevice", {
        id: this.spaceId,
        isOutSide: false,
      });
      //this.props.onClick("AddDevice", this.spaceId, null);
    };

    editDeviceId = async () => {
      try {
        const payload = {
          new_device_id: this.state.new_device_id,
          parent_id: this.state.spaceId,
          is_outside: false,
          old_device_id: this.state.data.Device.id,
        };
        // console.log(payload);
        const { data } = await editDevice(payload);
        // console.log(data);
        message.success(data.message);
        this.setState({
          alert: true,
          new_device_id: "",
          visible: false,
        });
      } catch (error) {
        console.log(error);
        if (error.response) {
          message.error(error.response.data.message, 7);
        }
        this.setState({ visible: true });
      }
    };

    editSpaceData = () => {
      this.props.form.validateFields(
        [
          "space_name",
          "space_id",
          "NonOpDays",
          "space_opHours",

          "fromOpHours",
          "toOpHours",
          // "space_NonOpDays",
        ],
        async (err, values) => {
          if (!err) {
            try {
              const {
                space_name,
                space_id,
                space_NonOpDays,
                // space_opHours,
              } = values;
              // const opHours =
              //   values.fromOpHours.format("ha") +
              //   " - " +
              //   values.toOpHours.format("ha");
              const payload = {
                id: space_id,
                name: space_name
              };
              const { data } = await editSpace(payload);
              message.success(data.message);
              this.setState({
                isSpaceModalVisible: false,
                alert: true,
              });
            } catch (error) {
              console.log(error);
              message.error(error.response.data.message);
            }
          }
        },
      );
    };

    addAC = () => {
      this.props.history.push("/admin/addac", { id: this.spaceId });
    };
    prevComponent = () => {
      this.props.onClick("Property", this.state.propertyId, null);
    };

    render() {
      const data = this.state.data;
      const { getFieldDecorator } = this.props.form;
      console.log(getFieldDecorator);
      console.log(data);
      if (!data) {
        return (
          <div style={style.loader}>
            <Spin size="large" tip="Loading space data.." />
          </div>
        );
      }
      return (
        <div style={{ background: "#FFF" }}>
          <div style={style.container}>
            <PageHeader
              className="site-page-header"
              onBack={() => this.prevComponent()}
              title={<h1>{data.name}</h1>}
              subTitle={
                <div>
                  <span>
                    Op Hours{" "}
                    <b style={{ padding: " 0 5px" }}>
                      {this.state.operationalHours}
                    </b>
                  </span>
                  ,
                  <span style={{ paddingLeft: "5px" }}>
                    Non op days <b>{this.state.nonOperationalDays}</b>
                  </span>
                  <Button
                    style={{
                      marginLeft: 16,
                    }}
                    shape="circle"
                    icon="edit"
                    type="primary"
                    onClick={() => {
                      this.setState({ isSpaceModalVisible: true });
                      this.props.form.setFieldsValue({
                        space_id: data.id,
                        space_name: data.name,
                        space_opHours: data.opHours,
                        space_NonOpDays: data.NonOpDays,
                      });
                    }}
                  />
                </div>
              }
            />
            <Button
              icon="plus"
              style={style.button}
              onClick={this.addDevice}
              type="primary"
            >
              Add Device
            </Button>
            <Modal
              title="Edit Space Details"
              visible={this.state.isSpaceModalVisible}
              onOk={() => this.setState({ isSpaceModalVisible: false })}
              onCancel={() => this.setState({ isSpaceModalVisible: false })}
              footer={[
                <Button
                  key="back"
                  onClick={() => this.setState({ isSpaceModalVisible: false })}
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    this.editSpaceData();
                  }}
                >
                  Update{" "}
                </Button>,
              ]}
            >
              <Form layout="vertical">
                <FormItem label="Space ID" style={{ display: "none" }}>
                  {getFieldDecorator("space_id", {})}
                  <Input placeholder="ID" />
                </FormItem>
                <FormItem label="Space name">
                  {getFieldDecorator("space_name", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill space name!",
                      },
                    ],
                  })(<Input placeholder="Ground floor AHU" />)}
                </FormItem>
                {/* <FormItem label="Space Operational hours">
                  {getFieldDecorator("space_opHours", {
                    rules: [
                      {
                        required: true,
                        message: "Please select operational hours!",
                      },
                    ],
                  })(
                    <Select placeholder="Please select Operational Hours of the space!">
                      <Option value="All 24 hours">All 24 hours</Option>
                      <Option value="6am to 11pm">6am to 11pm</Option>
                      <Option value="10am to 8pm">10am to 8pm</Option>
                      <Option value="6am to 12pm - 4pm to 10pm">
                        6am to 12pm - 4pm to 10pm
                      </Option>
                      <Option value="7am to 7pm">7am to 7pm</Option>
                    </Select>,
                  )}
                </FormItem> */}
                {/* <FormItem label="Space Operational hours">
                  {getFieldDecorator("fromOpHours", {
                    rules: [
                      {
                        required: true,
                        message: "Please select operational hours!",
                      },
                    ],
                  })(
                    <TimePicker
                      use12Hours={true}
                      // onChange={(time, timestring) => console.log(timestring)}
                      format="h A"
                      placeholder="start time"
                      style={{ width: "100%", marginBottom: "5px" }}
                    />,
                  )}
                  {getFieldDecorator("toOpHours", {
                    rules: [
                      {
                        required: true,
                        message:
                          "Please select Operational Hours of the space!",
                      },
                    ],
                  })(
                    <TimePicker
                      use12Hours={true}
                      // onChange={(time, timestring) => console.log(timestring)}
                      format="h A"
                      placeholder="end time"
                      style={{ width: "100%" }}
                    />,
                  )}
                </FormItem>
                <FormItem label="Space Non Operational Days">
                  {getFieldDecorator("space_NonOpDays", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill non operational days!",
                      },
                    ],
                  })(
                    // <Select placeholder="Please select Non-Operational Days of the space!">
                    //   <Option value="1">None</Option>
                    //   <Option value="2">Saturday - Sunday</Option>
                    // </Select>,
                    <Select placeholder="Please select Non-Operational Days of the space!">
                      <Option value="None">None</Option>
                      <Option value="Saturday - Sunday">
                        Saturday - Sunday
                      </Option>
                    </Select>,
                  )}
                </FormItem> */}
              </Form>
            </Modal>
            <Modal
              title="Edit Device ID"
              visible={this.state.visible}
              // onOk={() => {
              //   this.editDeviceId();
              //   this.setState({ visible: false });
              // }}
              onCancel={() => this.setState({ visible: false })}
              footer={[
                <Button
                  key="back"
                  onClick={() => this.setState({ visible: false })}
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    this.editDeviceId();
                    // this.setState({ visible: false });
                  }}
                >
                  Edit
                </Button>,
              ]}
            >
              <Input
                placeholder="New Device ID"
                value={this.state.new_device_id}
                onChange={(e) =>
                  this.setState({ new_device_id: e.target.value })
                }
              />
            </Modal>
            {data.Device && (
              <div>
                <b> Device ID </b> : {data.Device.id}
                <Button
                  style={{
                    marginLeft: 16,
                  }}
                  shape="circle"
                  icon="edit"
                  type="primary"
                  onClick={() => this.setState({ visible: true })}
                />
                <Popconfirm
                  title="Are you sure delete this device?"
                  onConfirm={this.confirm}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{
                      marginLeft: 16,
                    }}
                    shape="circle"
                    icon="delete"
                    type="primary"
                  />
                </Popconfirm>
              </div>
            )}
            <Divider
              style={{
                marginTop: 32,
              }}
            />
            <Button
              icon="plus"
              style={style.button}
              onClick={this.addAC}
              type="primary"
            >
              Add AC and Filter
            </Button>

            <List
              style={style.listStyle}
              bordered
              dataSource={data.ACs}
              renderItem={(item) => (
                <List.Item>
                  <p href={"customer/space/" + item.id}>
                    {item.brand +
                      " AC with filter installed on " +
                      moment(item.Filter.installed_on).format("DD-MM-YYYY")}
                  </p>
                </List.Item>
              )}
            />
          </div>
        </div>
      );
    }
  },
);

const style = {
  container: {
    margin: "auto",
    maxWidth: 500,
    border: "3 px solid green",
    paddingTop: 30,
  },
  button: {
    margin: 16,
  },
  saveBtn: {
    marginTop: 20,
    marginBottom: 20,
  },
  loader: {
    width: "100%",
    textAlign: "center",
    marginTop: 100,
  },
  listStyle: {
    marginTop: 20,
  },
};

export default Space;
