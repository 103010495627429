import React, { useRef, Component, createRef } from "react";
import {Button, Spin, Icon, Form, Tooltip, notification} from "antd";
import S3 from "react-aws-s3";
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};
const base_url = 'https://customers-asset.s3-ap-south-1.amazonaws.com/';
var config = {
    bucketName: 'customers-asset',
    region: 'ap-south-1',
    accessKeyId: 'AKIARC4ZKUPX3AMBYC4O',
    // dirName: 'max-okhla/',
    secretAccessKey: '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',
    Delimiter: '/',
};
var AWS = require('aws-sdk');

var s3;
export default class KioskManagement extends Component {    
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        // config['dirName'] = props.dir;
        AWS.config.update(config);
        s3 = new AWS.S3();
        this.state = {
          show_item: false,
          id: props.id
        }
    }

    // async componentDidMount() {
    //   AWS.config.update(config);
    //   var s3 = new AWS.S3();
    //   var params = {
    //       Bucket: config.bucketName, /* required */
    //       Prefix: config.dirName  // Can be your folder name
    //     };
    //   var temp = new Promise((resolve, reject) => {
    //     s3.listObjectsV2(params, function(err, data) {
    //       if (err) console.log(err, err.stack); // an error occurred
    //       else{
    //         setTimeout(function(){
    //           resolve(data)
    //         }, 1000)
    //           // successful response
    //           // console.log(data);
              
    //           // for(var i=0; i <data.Contents.length; i++){
    //           //     temp.push({
    //           //         key: data.Contents[i].Key,
    //           //         url: base_url+data.Contents[i].Key,
    //           //     });
    //           // }
    //           // this.setState({
    //           //   images: temp
    //           // })
    //       }
    //     });
    //   })
    //   console.log(temp);          
    // }

    handleClick = (event) => {
      let prefix;
      if(this.state.id === 'fd6a8e6d-a0c1-4387-8b84-a6a20b48559e'){
        prefix = 'max-okhla'
      } else if(this.state.id === "e0bd2d59-1f36-44a0-9167-fe9c03fc4d0b") {
        prefix = 'max-estates'
      }
      event.preventDefault();
      if(this.fileInput.current.files[0]){
        let file = this.fileInput.current.files[0];
        let newFileName = prefix+'/'+this.fileInput.current.files[0].name.replace(/\..+$/, "");
        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(file, newFileName).then((data) => {
          // console.log(data);
          if (data.status === 204) {
            notification.success({
              message: `Notification`,
              description: 'Slide Uploaded Successfully',
              placement: 'topRight',
            });
            this.getObjects();
          } else {
            console.log("failed");
          }
        });
      } else {
        notification.error({
          message: `Notification`,
          description: 'Please Select Image to Upload',
          placement: 'topRight',
        });
      }
    };

    deleteSlide = (e) => {
      var params = {  Bucket: config.bucketName, Key: e };
      s3.deleteObject(params, function(err, data) {
        if (err) console.log(err, err.stack);  // error
        else{
          notification.warn({
            message: `Notification`,
            description: 'Slide Deleted',
            placement: 'topRight',
          });
        }
      });
    }

    getObjects = () => {
      let prefix;
      if(this.state.id === 'fd6a8e6d-a0c1-4387-8b84-a6a20b48559e'){
        prefix = 'max-okhla'
      } else if(this.state.id === "e0bd2d59-1f36-44a0-9167-fe9c03fc4d0b"){
        prefix = 'max-estates'
      }
      var params = {
          Bucket: config.bucketName, /* required */
          Prefix: prefix  // Can be your folder name
      };
      var temp = s3.listObjectsV2(params, function(err, data) {
          if (err) console.log(err, err.stack); // an error occurred
          else{
            // console.log(data);
            return data; // successful response              
          }
      });
      setTimeout(()=>{ 
        // This timeout is set because S3 sends delayed response and promise was not working
        // Find a better approach in near future for a better function.
        this.setState({
          images_list: temp.response.data.Contents,
          show_item: true
        })
      }, 2000)
    }

    render() {
        var renderItems = () => {
          var items = [];
          var temp = this.state.images_list;
          {temp.map((pic) => {
            items.push(<div className="kiosk_image_card_container" key={pic.Key}>
                        <Tooltip placement="bottom" title="Delete Slide">
                          <Icon type="delete" key={pic.Key} data-key={pic.Key} onClick={() => this.deleteSlide(pic.Key)}/>
                        </Tooltip>
                      <img className="kiosk_image_card" src={base_url+pic.Key}/>
                    </div>)
          })}
          items.shift();
          return items;
        }
          return (
              <div>
                <div className='body-card-header-pop' style={{marginTop: '0px', marginBottom: '10px'}}>
                    Uploaded Slides
                  </div>
                <div className='image_list_container'>
                  {this.state.show_item?renderItems():null}
                    {/* <Button type="primary">
                      Refresh <Icon type="sync"/>
                    </Button> */}
                </div>
                <Form className='upload-steps' onSubmit={this.handleClick}  {...formItemLayout}>
                    <label>
                        Add more slides:
                        <input className='image_upload' type='file' ref={this.fileInput} />
                    </label>
                    <button type='submit'>Upload</button>
                </Form>
                <Button onClick={this.getObjects}>Get Objects</Button>
              </div>
            )
    }
}