import React, { Component } from "react";
import { Form, Input, Button, Select, Checkbox, Row, Col, message } from "antd";
import User from "./addUser.svg";
import { addCustomer } from "../api";
import { getCsrId } from "../helper";
import UserControl from "./UserControl";
import { getUserId } from "../helper";
const { Option } = Select;
const FormItem = Form.Item;
class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
      designation: "",
      response: false,
      changemenu: 0,
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  saveCustomer = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        values["customer_id"] = this.state.id;
        values["user_id"] = getUserId();
        try {
          //   console.log(values);
          const response = await addCustomer(values);
          this.setState({
            response: true,
            changemenu: 1,
          });
        } catch (e) {
          console.log(e);
          //   this.setState({ showAlert: true, error: e.response.data.message });
          message.warning(e.response.data.message);
        }
      }
    });
  };

  render() {
    const { response, changemenu, id } = this.state;
    const success = (
      <div>
        <br></br>
        {response ? (
          <div
            align="middle"
            style={{
              backgroundColor: "#009688",
              color: "white",
              padding: "8px",
            }}
          >
            <h3 style={{ color: "white" }}>Succesful Addition of User</h3>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
    const { getFieldDecorator } = this.props.form;
    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
    };
    const tailLayout = {
      wrapperCol: { offset: 11, span: 15 },
    };
    return (
      <div>
        {changemenu === 1 ? (
          <UserControl id={id} />
        ) : (
          <div style={{ minHeight: "85vh", maxHeight: "85vh" }}>
            <Row>
              <Col span={13}>
                <h1
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    marginTop: "10%",
                    color: "#1a237e",
                    fontSize: "200%",
                  }}
                  align="middle"
                >
                  Add your teammates for an enhanced experience!
                </h1>
                <Form
                  name="newUserForm"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "5%",
                  }}
                  {...layout}
                  name="basic"
                  onSubmit={this.saveCustomer}
                >
                  <FormItem
                    label="Firstname"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    {getFieldDecorator("first_name", {
                      rules: [
                        {
                          required: true,
                          message: "Required Field",
                        },
                      ],
                    })(<Input name="first_name" />)}
                  </FormItem>
                  <FormItem
                    label="Lastname"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    {getFieldDecorator("last_name", {
                      rules: [
                        {
                          required: true,
                          message: "Required Field",
                        },
                      ],
                    })(<Input name="last_name" />)}
                  </FormItem>
                  <FormItem
                    label="Contact Number"
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Contact Number!",
                      },
                    ]}
                  >
                    {getFieldDecorator("phone_number", {
                      rules: [
                        {
                          required: true,
                          message: "Required Field",
                        },
                      ],
                    })(<Input name="phone_number" />)}
                  </FormItem>
                  <FormItem
                    label="Email ID"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Email ID!",
                      },
                    ]}
                  >
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          message: "Required Field",
                        },
                      ],
                    })(<Input name="email" />)}
                  </FormItem>
                  <FormItem
                    label="Designation"
                    name="designation"
                    rules={[
                      {
                        required: true,
                        message: "Please input your designation!",
                      },
                    ]}
                  >
                    {getFieldDecorator("designation", {
                      validateTrigger: ["onChange", "onBlur"],
                      initialValue: "Customer",
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message:
                            "Please input passenger's name or delete this field.",
                        },
                      ],
                    })(
                      <Select name="designation" style={{ width: 200 }}>
                        <Option value="Customer">Park Admin</Option>
                        <Option value="Supervisor">India Admin</Option>
                      </Select>,
                    )}
                    {/* <Select name="designation" defaultValue="supervisor" style={{ width: 200 }}>
                                        <Option value="supervisor">Supervisior</Option>
                                        <Option value="maintenance">Maintenance</Option>
                                        <Option value="bms">BMS</Option>
                                    </Select> */}
                  </FormItem>
                  <FormItem {...tailLayout}>
                    <Button
                      type="primary"
                      style={{ backgroundColor: "#1a237e" }}
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </FormItem>
                </Form>
                {success}
              </Col>
              <Col span={11}>
                <div style={{ marginTop: "15%", marginLeft: "3%" }}>
                  <img src={User} width="90%" />
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default Form.create()(UserManagement);
