import React, { Component } from "react";
import {
  Chart,
  Guide
} from 'bizcharts';
const {Html} = Guide;

const HumidityBox = (props)=>{ 
    const {data,label} = props
    return (
        <Chart
          forceFit
          height={70}
          padding={[16,10,16,10]}
          data={data}>
          <Guide>
            <Html
              position={['50%', '60%']}
              html={() => (`<div style="width: 100px;text-align: right;font-size: 8px!important;"><p style="font-size: 1.75em; color: rgba(0,0,0,0.43);margin: 0;">${label}</p><p style="font-size: 3em;color: rgba(0,0,0,0.85);margin: 0;">   ${data[0].value?data[0].value:"NA"} ${data[0].value?"%":""}</p></div>`)}/>
          </Guide>
        </Chart>
    )
  }

export default HumidityBox;