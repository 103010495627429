import React, { Component } from "react";
import {
  Layout,
  Spin,
  Menu,
  Button,
  Steps,
  Icon,
  Dropdown,
  Avatar,
  Modal,
  DatePicker,
  Alert,
  Badge,
  Result,
  Switch,
} from "antd";
import "./Dashboard.less";
import {
  getCustomer,
  getSummaryAqData,
  getAqExcelFile,
  getPurifierExcelReport,
} from "../../api";
import _ from "lodash";
import {
  removeToken,
  getCsrId,
  removeCsrId,
  getSupervisorData,
} from "../../helper";
import claircologo from "../../img/Claircofinallogo.png";
import clairco_logo from "../../img/customerLogos/clairco.png";
import cureFit_logo from "../../img/customerLogos/cureFit.png";
import blueTokai_logo from "../../img/customerLogos/blueTokai.png";
import wtc_logo from "../../img/customerLogos/wtcBangalore.jpg";
import dlf_logo from "../../img/customerLogos/dlf.png";
import maxEstates_logo from "../../img/customerLogos/maxEstates.png";
import buzzworks_logo from "../../img/customerLogos/buzzworks.png";
import cowrks_logo from "../../img/customerLogos/cowrks.png";
import sarva_logo from "../../img/customerLogos/sarva.png";
import capitaland_logo from "../../img/customerLogos/capitaland.png";
import orionMall_logo from "../../img/customerLogos/orionMall.png";
import wework_logo from "../../img/customerLogos/wework.png";
import emaar_logo from "../../img/customerLogos/emaar.png";
import candorTechSpace_logo from "../../img/customerLogos/candorTechSpace.jpeg";
import knightFrank_logo from "../../img/customerLogos/knightFrank.png";
import mgfMetropolitan_logo from "../../img/customerLogos/mgfMetropolitan.png";
import suncityProjects_logo from "../../img/customerLogos/suncityProjects.png";
import aviva_logo from "../../img/customerLogos/aviva.png";
import { COLOR } from "../../colors";
import moment from "moment";
import SummaryCapitaLand from "../../components/dashboardContentCapitaLand/SummaryCapitaLand";
import PropertiesCapitaLand from "../../components/dashboardContentCapitaLand/PropertiesCapitaLand";
import MaintainenceLogs from "../../components/MaintainenceLogs";
import PurifierView from "../../components/PurifierView";
import KioskManagement from "../../components/KioskManagement";
import UserManagement from "../../components/UserManagement";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const { Step } = Steps;

// Change below customer ids, then change in server also (customer_controller)
const cureFit_customerId = "827e9644-2de5-42e8-836e-3073afb3d8fe";
const blueTokai_customerId = "15ce89ec-7c1e-4d01-be22-0c9d867c5b2d";
const wtc_customerId = "b38f6c08-dcaf-4139-aea0-d30ee75d4ee1";
const wework_32nd = "3b0c8b10-8dac-4466-a728-0937239baefe";
const berger_wework = "a2b4c6e4-e9f0-4f3e-82cd-d4d1bf47387c";
const vijohn_wework = "a5ac3d08-851e-4c3e-85c1-63be20054d21";
const platina_wework = "527c32d4-140f-4501-a9ef-1cdd284d5bcf";
const dlf_customerId = "f0bd9405-61d7-4edf-8e59-1707155d7e84";
const maxEstates_customerId = "e0bd2d59-1f36-44a0-9167-fe9c03fc4d0b";
const axis_house = "eb53efcd-d4e8-47f8-970c-8938145eabd7";
const buzzworks_customerId = "3a119b92-0322-45f5-97a0-fa7ba20f0088";
const cowrks_customerId = "11e4ec5b-a7f0-4fd5-89f5-314f329c9ec2";
const sarva_customerId = "1493ab1e-a9ea-4496-9318-4e003918f562";
const capitaland_customerId = "9fb2d83a-7db7-475e-84ad-d26c56727366";
const capitaland_gurgaon_customerId = "5b541311-4e27-4785-8067-e8fc7586cdbc";
const capitaland_chennai_customerId = "46a5ef08-4a0f-4858-8c3f-9fe257771fc7";
const capitaland_vitp_customerId = "5b541311-4e27-4785-8067-e8fc7586cdbc";
const capitaland_aitpp_customerId = "d50be409-c9b9-4353-b22f-e221e7319225";
const orionMall_customerId = "5133df6a-fe15-4b05-a4ff-4c64bac8307d";
const wework_customerId = "4c3af777-5509-4ef3-bdd5-cb57ead81502";
const emaar_customerId = "1192008a-f73b-4929-a963-d024f1b3fe18";
const candorTechSpace_customerId = "5edf44a3-9c1b-452a-94be-c7968feea87a";
const knightFrank_customerId = "61edd950-3e5a-401d-bd2f-46ed77cdd765";
const mgfMetropolitan_customerId = "099b5cc0-bed6-4a3c-aa09-7d818941bff9";
const suncityProjects_customerId = "c82556c4-93cc-4ebd-a9ed-3a3f957a3685";
const aviva_customerId = "6e8c6026-4212-419d-9fa6-ccb3ea7c21d5";
const orionAvenue_customerId = "619c559c-eca3-4293-ac5a-9fa2ab2e3fab";

let lastDate = new Date();
lastDate.setDate(lastDate.getDate() - 1);
lastDate = lastDate.toLocaleDateString();

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      id: getCsrId(props.match.params.id) || this.props.location.state.id,
      loading: false,
      loadingData: true,
      startValue: null,
      endValue: null,
      endOpen: false,
      showAlert: false,
      error: "Please enter a Valid Date",
      show: false,
      fileType: true,
      reportType: true,
      report_flag_disable: true,
    };
  }
  async componentDidMount() {
    try {
      let { data } = (await getCustomer(this.state.id, true)).data;
      let summaryData = (
        await getSummaryAqData(this.state.id, true, "Yesterday")
      ).data;
      const dashboard_active = data.Users[0].dashboard_active;
      const outsideDataShow = data.Users[0].OutdoorDataShow;
      // console.log('Dashboard.data: ',data);
      const purifier_status = data.purifier_status;
      const aqi_ranges = {};
      aqi_ranges["pm25_range"] = data.pm25_range;
      aqi_ranges["pm10_range"] = data.pm10_range;
      aqi_ranges["co2_range"] = data.co2_range;
      aqi_ranges["voc_range"] = data.voc_range;
      // console.log('summaryData: ',summaryData.data);
      var aqi_value_obj = [
        { key: "pm25_range", min: "", max: "", label: "PM2.5" },
        { key: "pm10_range", min: "", max: "", label: "PM10" },
        { key: "co2_range", min: "", max: "", label: "CO2" },
        { key: "voc_range", min: "", max: "", label: "VOC" },
      ];
      for (var label in aqi_ranges) {
        var label_data = data[label].split(",");
        var select_data = _.filter(aqi_value_obj, (x) => x.key === label);
        select_data[0].min = label_data[0];
        select_data[0].max = label_data[1];
      }
      this.setState({
        loadingData: false,
      });
      const customerName = data.name;
      if (data.Properties.length > 0) {
        data = _(data.Properties)
          .groupBy((property) => property.city)
          .map((value, key) => ({ name: key, properties: value }))
          .sortBy((cityRegion) => cityRegion.name)
          .value();

        // console.log('data: ',data);
        let cityNamesList = [];
        for (let i = 0, l = data.length; i < l; i++) {
          cityNamesList.push(data[i].name);
        }
        let selectedCityIndex = 0;
        let selectedCity = cityNamesList[selectedCityIndex];
        let propDataForSummary = {};
        // propDataForSummary=summaryData.data[0];
        for (let i = 0, l = summaryData.data.length; i < l; i++) {
          if (
            summaryData.data[i].cityName === cityNamesList[selectedCityIndex]
          ) {
            propDataForSummary = summaryData.data[i];
            break;
          }
        }

        let uv_data_obj = summaryData.data[2 + cityNamesList.length][0];
        // console.log(uv_data_obj);
        // console.log(uv_data_obj.uv_data);
        propDataForSummary["uv_device_data"] =
          uv_data_obj != undefined || uv_data_obj != null
            ? uv_data_obj.uv_device_data
            : null;

        // console.log("propDataForSummary", propDataForSummary);
        var report_flag_disable = null;
        this.state.id === capitaland_customerId ||
        capitaland_gurgaon_customerId ||
        capitaland_chennai_customerId ||
        capitaland_vitp_customerId ||
        capitaland_vitp_customerId ||
        capitaland_aitpp_customerId
          ? (report_flag_disable = false)
          : (report_flag_disable = true);

        this.setState({
          data,
          summaryData,
          dashboard_active,
          outsideDataShow,
          defaultSelectedKeys: ["summary"],
          selectedMenu: "summary",
          defaultOpenKeys: ["summary"],
          customerName,
          cityNamesList,
          selectedCityIndex,
          selectedCity,
          propDataForSummary,
          report_flag_disable: report_flag_disable,
          aqi_ranges: aqi_value_obj,
          purifier_status: purifier_status,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleClick = (e) => {
    if (this.state.selectedMenu !== e.keyPath[0]) {
      this.setState({ selectedMenu: e.keyPath[0] });
    }
  };

  handleLogoutClick = (e) => {
    removeToken();
    removeCsrId();
    this.props.history.push("/");
  };

  handleLogView = (e) => {
    this.setState({
      selectedMenu: "logs",
    });
  };
  handleReferer = (e) => {
    var data = getSupervisorData();
    this.props.history.push("/customer/admin", { data: data });
  };
  handleChangeClick = (e) => {
    this.props.history.push("/changePassword");
  };

  kioskDisplay = (e) => {
    this.props.history.push("/kiosk", { refferer: "dashboard" });
  };

  handleDownloadClick = async (e) => {
    const deepData = true;
    const { startValue, endValue, reportType } = this.state;
    if (startValue === null || endValue === null) {
      this.setState({
        showAlert: true,
      });
    } else {
      this.setState({ confirmLoading: true, showAlert: false });
      try {
        if (reportType) {
          await getAqExcelFile(
            this.state.id,
            deepData,
            startValue.utc().format("YYYY-MM-DD"),
            endValue.utc().format("YYYY-MM-DD"),
            this.state.fileType,
            this.state.selectedCity,
          );
        } else {
          await getPurifierExcelReport(
            this.state.id,
            startValue.utc().format("YYYY-MM-DD"),
            endValue.utc().format("YYYY-MM-DD"),
          );
        }
      } catch (err) {
        console.log(err);
      }
      this.setState({ confirmLoading: false, visible: false });
    }
  };

  handleDropdownButtonClickForCity = (e) => {
    let index = parseInt(e.key);
    if (index !== this.state.selectedCityIndex) {
      for (let i = 0, l = this.state.summaryData.data.length; i < l; i++) {
        if (
          this.state.summaryData.data[i].cityName ===
          this.state.cityNamesList[index]
        ) {
          this.setState({
            selectedCityIndex: index,
            selectedCity: this.state.cityNamesList[index],
            selectedMenu: "summary",
            propDataForSummary: this.state.summaryData.data[i],
          });
          break;
        }
      }
    }
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      ModalText: "The modal will be closed after two seconds",
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  };
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  disabledStartDate = (current) => {
    let customDate = "2019-11-01";
    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  ontypeChange = (e) => {
    this.setState({
      fileType: e.target.value,
    });
  };
  switchChange = (checked, e) => {
    if (e === "report_type") {
      if (checked) {
        this.setState({
          reportType: true,
        });
      } else {
        this.setState({
          reportType: false,
        });
      }
    } else if (e === "data_type") {
      if (checked) {
        this.setState({
          fileType: true,
        });
      } else {
        this.setState({
          fileType: false,
        });
      }
    }
  };
  render() {
    const {
      visible,
      confirmLoading,
      show,
      endValue,
      endOpen,
      dashboard_active,
      outsideDataShow,
      fileType,
      report_flag_disable,
      aqi_ranges,
      purifier_status,
    } = this.state;
    if (this.state.loadingData) {
      return (
        <div className="dashLoaderContainer">
          <Spin size="large" />
        </div>
      );
    }
    if (!this.state.data) {
      return (
        <Layout className="homeLayout">
          <Header className="homeHeader">
            <div className="homeHeaderContainer">
              <div className="logoContainer">
                <a href="/">
                  <img className="logoHome" src={claircologo} />
                </a>
              </div>
            </div>
          </Header>
          <Content className="homeContentContainer">
            <div className="contentContainer">
              <Steps className="dashDownload1" direction="vertical" current={1}>
                <Step
                  title="User Registered"
                  description="You have been registered."
                />
                <Step
                  title="Verification Pending"
                  description="Your account verification is pending.
                  Contact Udayan Banerjee at +91-9898036463 or udayan@clairco.in"
                />
                <Step title="User Verfied" description="Dashboard is live." />
              </Steps>
              <Button
                icon="logout"
                className="dashDownload1"
                onClick={this.handleLogoutClick}
                loading={this.state.loading}
                type="primary"
                shape="round"
                style={{}}
              >
                Log Out
              </Button>
            </div>
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            Aliferous Technologies pvt. ltd. ©2018
          </Footer>
        </Layout>
      );
    }
    if (!dashboard_active) {
      return (
        <Layout className="homeLayout">
          <Header className="homeHeader">
            <div className="homeHeaderContainer">
              <div className="logoContainer">
                <a href="/">
                  <img className="logoHome" src={claircologo} />
                </a>
              </div>
            </div>
          </Header>
          <Result
            style={{ marginTop: 100 }}
            status="500"
            title=""
            subTitle="Sorry, you are not authorized to access this page. 
              Please, contact Udayan Banerjee at +91-9898036463 or udayan@clairco.in"
            extra={<Button type="primary">Back Home</Button>}
          />
        </Layout>
      );
    }
    // console.log('Loading:', this.state.loading);
    const {
      data,
      id,
      defaultSelectedKeys,
      selectedMenu,
      collapsed,
      customerName,
      cityNamesList,
      selectedCityIndex,
      selectedCity,
      propDataForSummary,
    } = this.state;
    const opHourType =
      id === cureFit_customerId || id === sarva_customerId
        ? 2
        : id === cowrks_customerId
        ? 1
        : id === capitaland_customerId
        ? 3
        : 0;

    const citiesSubmenu = data.map((city) => {
      const propSubmenu =
        city.name === selectedCity
          ? city.properties.map((property) => {
              const spacesMenuItem = property.Spaces.map((space) => {
                return (
                  <Menu.Item
                    key={
                      property.id +
                      ":" +
                      space.Device.id +
                      ":" +
                      (property.OutsideDevice
                        ? property.OutsideDevice.id
                        : property.NeighbourProperty &&
                          property.NeighbourProperty.OutsideDevice
                        ? property.NeighbourProperty.OutsideDevice.id
                        : property.station_id
                        ? property.station_id
                        : null) +
                      ":" +
                      space.name +
                      ":" +
                      property.name +
                      ":" +
                      space.id +
                      ":" +
                      this.state.id
                    }
                  >
                    {space.name}
                  </Menu.Item>
                );
              });
              return (
                <SubMenu
                  key={property.id}
                  title={property.name}
                  children={spacesMenuItem}
                />
              );
            })
          : null;
      return propSubmenu != null ? (
        <SubMenu
          key={"properties"}
          title={
            <span>
              <Icon type="bank" style={{ color: "#FFF" }} />
              <span>Properties</span>
            </span>
          }
          children={propSubmenu}
        />
      ) : null;
    });

    const logo =
      id === cureFit_customerId
        ? cureFit_logo
        : id === blueTokai_customerId
        ? blueTokai_logo
        : id === wtc_customerId
        ? wtc_logo
        : id === dlf_customerId
        ? dlf_logo
        : id === maxEstates_customerId
        ? maxEstates_logo
        : id === buzzworks_customerId
        ? buzzworks_logo
        : id === cowrks_customerId
        ? cowrks_logo
        : id === sarva_customerId
        ? sarva_logo
        : id === capitaland_customerId
        ? capitaland_logo
        : id === orionMall_customerId || id === orionAvenue_customerId
        ? orionMall_logo
        : id === wework_customerId
        ? wework_logo
        : id === emaar_customerId
        ? emaar_logo
        : id === candorTechSpace_customerId
        ? candorTechSpace_logo
        : id === knightFrank_customerId
        ? knightFrank_logo
        : id === mgfMetropolitan_customerId
        ? mgfMetropolitan_logo
        : id === suncityProjects_customerId
        ? suncityProjects_logo
        : id === aviva_customerId
        ? aviva_logo
        : clairco_logo;

    const cityMenuItems = cityNamesList.map((item, i) => (
      <Menu.Item key={i}>
        <Icon type="environment" />
        {item}
      </Menu.Item>
    ));
    // console.log('cityMenuItems: ',cityMenuItems);

    const dashboard = id === capitaland_customerId ? capitaland_customerId : id;

    const cityNamesDropdownButton = (
      <Menu onClick={this.handleDropdownButtonClickForCity}>
        {cityMenuItems}
      </Menu>
    );
    return (
      <Layout>
        <Sider
          breakpoint="md"
          collapsedWidth="0"
          collapsible
          className="sider"
          collapsed={collapsed}
          trigger={null}
          width={250}
          style={{
            position: "relative",
            height: "100vh",
          }}
          onBreakpoint={(broken) => {
            this.setState({ collapsed: broken });
            // console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            // console.log(collapsed, type);
          }}
        >
          {
            <div className="customerLogoContainer">
              <img src={logo} className="customerLogo" />
            </div>
          }
          <Button
            icon="download"
            className="dashDownload"
            // onClick={this.handleDownloadClick}
            onClick={this.showModal}
            // loading={this.state.loading}
            type="primary"
            shape="round"
            style={{}}
          >
            Download Excel Report
          </Button>
          <Modal
            title="Download Excel Report"
            visible={visible}
            width={480}
            onOk={this.handleDownloadClick}
            confirmLoading={confirmLoading}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button
                icon="download"
                key="submit"
                type="primary"
                loading={confirmLoading}
                onClick={this.handleDownloadClick}
              >
                Download
              </Button>,
            ]}
          >
            <div>
              {this.state.showAlert && (
                <Alert
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  message={this.state.error}
                  type="error"
                  showIcon
                />
              )}
              <DatePicker
                disabledDate={this.disabledDate}
                defaultValue={moment(lastDate, "DD-MM-YYYY")}
                format="YYYY-MM-DD"
                // value={startValue}
                placeholder="Start Date"
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
              />
              <DatePicker
                className="endDatePicker"
                disabledDate={this.disabledDate}
                format="YYYY-MM-DD"
                value={endValue}
                placeholder="End Date"
                onChange={this.onEndChange}
                open={endOpen}
                onOpenChange={this.handleEndOpenChange}
              />
            </div>
            <div
              style={{
                marginTop: 20,
                marginLeft: 20,
              }}
            >
              {id === capitaland_customerId || wtc_customerId ? (
                <div>
                  <div className="small_margin">
                    <b>Report Type:</b>
                    <Switch
                      disabled={report_flag_disable}
                      className="small_margin"
                      defaultChecked={this.state.reportType}
                      onClick={(checked, e) =>
                        this.switchChange(checked, "report_type")
                      }
                      checkedChildren="Data Report"
                      unCheckedChildren="Purifier Report"
                    />
                    <b>Data Type:</b>
                    <Switch
                      className="small_margin"
                      defaultChecked={this.state.fileType}
                      onClick={(checked, e) =>
                        this.switchChange(checked, "data_type")
                      }
                      checkedChildren="Daily Average"
                      unCheckedChildren="Hourly Average"
                    />
                    <br />
                  </div>
                  <div className="small_margin margin-top-15p">
                    <b>NOTE:</b> Toggle the switch to change the Report Type.
                  </div>
                  {/* Report Type: <br/>
                    <Radio.Group onChange={this.ontypeChange} value={fileType}>
                      <Radio value={1}>Purifier Report</Radio>
                      <Radio value={2}>Data Report</Radio>
                    </Radio.Group>
                    <br/>
                    Data Type: <br/>
                    <Radio.Group onChange={this.ontypeChange} value={fileType}>
                      <Radio value={1}>Daily Average Report</Radio>
                      <Radio value={2}>Hourly Data Report</Radio>
                    </Radio.Group> */}
                </div>
              ) : null}
            </div>
          </Modal>
          <Dropdown overlay={cityNamesDropdownButton}>
            <Button className="buttonCityNamesList">
              <Icon
                className="locationIcon"
                type="environment"
                style={{ color: COLOR.good }}
              />
              {cityNamesList[selectedCityIndex]}
              <Icon type="down" />
            </Button>
          </Dropdown>
          <Sider
            breakpoint="md"
            className="siderMenuItems"
            trigger={null}
            width="100%"
            style={{
              background: "none",
            }}
          >
            <Menu
              className="innerMenuItems"
              onClick={this.handleClick}
              defaultSelectedKeys={defaultSelectedKeys}
              mode="inline"
            >
              <Menu.Item key="summary">
                <Icon type="snippets" />
                <span>Summary</span>
              </Menu.Item>
              {citiesSubmenu}
              <Menu.Item key="logs">
                <Icon type="setting" />
                Maintainence
              </Menu.Item>
              {purifier_status ? (
                <Menu.Item key="purifiers">
                  <Icon type="tool" />
                  Air Purifiers
                </Menu.Item>
              ) : null}
              {id === maxEstates_customerId ? (
                <Menu.Item key="kiosk">
                  <Icon type="desktop" />
                  Kiosk Management
                </Menu.Item>
              ) : null}
            </Menu>
          </Sider>
        </Sider>
        <Layout
          style={{
            width: "100%",
            position: "relative",
            overflow: "auto",
            height: "100vh",
          }}
        >
          <Header
            className="dashHeader"
            style={{
              position: "fixed",
              zIndex: 10, // so that labels in PmCard don't come on top of dashHeader
              width: "100%",
            }}
          >
            <div className="headerLogo">
              {/* Powered by <img src={clairco_logo} className="claircoLogo" /> */}
              <Avatar
                size={{
                  xs: 24,
                  sm: 32,
                  md: 40,
                  lg: 64,
                  xl: 80,
                  xxl: 100,
                }}
                style={{
                  marginRight: "10px",
                }}
              >
                {customerName.charAt(0)}
              </Avatar>
              {customerName}
            </div>
            <div className="userMenuHolder">
              <div>
                <Menu mode="horizontal">
                  <SubMenu
                    title={
                      <Badge dot={show}>
                        <Icon type="bell" style={{ fontSize: "26px" }} />
                      </Badge>
                    }
                  >
                    <div className="notificationShade">
                      No New Notifications
                    </div>
                  </SubMenu>
                </Menu>
              </div>
              <Menu mode="horizontal">
                <SubMenu
                  title={
                    <div>
                      <Icon type="bars" style={{ fontSize: "26px" }} />
                    </div>
                  }
                >
                  {this.props.location.state !== undefined &&
                  this.props.location.state.referer === "supervisor" ? (
                    <Menu.Item key="setting:1" onClick={this.handleReferer}>
                      Change Dashboard
                    </Menu.Item>
                  ) : (
                    [
                      <Menu.Item
                        key="setting:1"
                        onClick={this.handleChangeClick}
                      >
                        Change Password
                      </Menu.Item>,
                      <Menu.Item
                        key="setting:2"
                        onClick={this.handleLogoutClick}
                      >
                        Log Out
                      </Menu.Item>,
                    ]
                  )}
                  {id == maxEstates_customerId || axis_house ? (
                    <Menu.Item key="setting:3" onClick={this.kioskDisplay}>
                      Kiosk
                    </Menu.Item>
                  ) : null}
                </SubMenu>
              </Menu>
            </div>
          </Header>
          <Content
            style={{
              paddingTop: "60px",
              margin: "24px 16px 0",
            }}
          >
            {" "}
            {selectedMenu === "kiosk" ? (
              <KioskManagement />
            ) : selectedMenu === "purifiers" ? (
              <PurifierView id={id} />
            ) : selectedMenu === "logs" ? (
              <MaintainenceLogs
                id={id}
                properties={data[selectedCityIndex].properties}
              />
            ) : selectedMenu === "summary" ? (
              <SummaryCapitaLand
                id={id}
                selectedCity={selectedCity}
                propDataForSummary={propDataForSummary}
                outsideDataShow={outsideDataShow}
                aqiRange={aqi_ranges}
              />
            ) : selectedMenu === "addUser" ? (
              <UserManagement />
            ) : (
              <PropertiesCapitaLand
                selectedMenu={selectedMenu}
                dateRangeIndex={1}
                outsideDataShow={outsideDataShow}
                aqiRange={aqi_ranges}
                {...this.props}
                customerId={id}
              />
            )}
            <Footer
              style={{
                textAlign: "center",
              }}
            >
              <p>
                In case of any queries, please reach out to Clairco at
                +91-9898036463
              </p>
              Aliferous Technologies pvt. ltd. ©2018
            </Footer>
          </Content>
        </Layout>
      </Layout>
    );
  }
}
const style = {
  welcome: {
    margin: 20,
  },
  container: {
    padding: 16,
  },
  button: {
    margin: 10,
  },
};
