import React, { Component } from "react";
import {
  Button,
  Form,
  InputNumber,
  Checkbox,
  Select,
  Input,
  Radio,
} from "antd";
const FormItem = Form.Item;
const Option = Select.Option;

const DeviceForm = Form.create()(
  class extends React.Component {
    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Form layout="vertical">
          <FormItem label="Serial Number">
            {getFieldDecorator("id", {
              rules: [
                {
                  required: true,
                  message: "Please fill device serial number!",
                },
              ],
            })(<Input placeholder="Ex: 10000001,10000002" />)}
          </FormItem>
          <FormItem label="Info">
            {getFieldDecorator("info")(
              <Input placeholder="Ex: This device doesn't have screen" />,
            )}
          </FormItem>
        </Form>
      );
    }
  },
);

export default DeviceForm;
