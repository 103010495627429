import React, { Component } from "react";
import {
  Button,
  Popconfirm,
  message,
  PageHeader,
  Spin,
  Divider,
  Form,
  Alert,
  List,
  Modal,
  Input,
  Select,
} from "antd";
import CustomerForm from "../components/CustomerForm";
import UserForm from "../components/UserForm";
import {
  getProperty,
  deleteDevice,
  deleteStation,
  editProperty,
  editSpace,
  editDevice,
  deleteSpace,
} from "../api";
import { isNull } from "lodash";
import axios from "axios";

const FormItem = Form.Item;
const { Option } = Select;
const Property = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        data: null,
        disable_station_outside_btns: false,
        isPropertyModalVisible: false,
        isSpaceModalVisible: false,
        isOutsideDeviceModalVisible: false,
        isStationDeviceModalVisible: false,
        alert: false,
      };
    }
    checkOutsideorStationID() {
      if (
        isNull(this.state.data.OutsideDevice) &&
        isNull(this.state.data.station_id)
      ) {
        this.setState({
          disable_station_outside_btns: false,
        });
      } else if (
        isNull(this.state.data.OutsideDevice) ||
        !isNull(this.state.data.station_id)
      ) {
        this.setState({
          disable_station_outside_btns: true,
        });
      } else if (
        !isNull(this.state.data.OutsideDevice) ||
        isNull(this.state.data.station_id)
      ) {
        this.setState({
          disable_station_outside_btns: true,
        });
      }
    }
    async componentDidMount() {
      this.propertyId = this.props.id;
      try {
        var neighbour_flag = false;
        const { data } = (await getProperty(this.propertyId)).data;
        // console.log(data);
        //  data.neighbour_id? neighbour_flag = true : null;
        // Setting neighbour_flag for properties using neighbour property for outside data.
        var customer = this.props.referrer;
        this.setState({
          data,
          customer,
          neighbour_flag,
        });
        this.checkOutsideorStationID();
      } catch (e) {
        console.log(e);
      }
    }

    async componentDidUpdate(prevProps, prevState) {
      if (prevState.alert !== this.state.alert) {
        try {
          var neighbour_flag = false;
          const { data } = (await getProperty(this.propertyId)).data;

          //  data.neighbour_id? neighbour_flag = true : null;
          // Setting neighbour_flag for properties using neighbour property for outside data.
          var customer = this.props.referrer;
          this.setState({
            data,
            customer,
            neighbour_flag,
            alert: false,
          });
          this.checkOutsideorStationID();
        } catch (e) {
          console.log(e);
        }
      }
    }

    prevComponent = () => {
      this.props.onClick("Customer", this.state.customer, null);
    };

    confirm = async (id) => {
      try {
        if (id == 4) {
          await deleteDevice(this.state.data.OutsideDevice.id);
          message.success("Deleted");
          this.state.data.OutsideDevice = null;
          this.setState({ data: this.state.data });
        } else {
          await deleteStation(this.state.data.station_id);
          message.success("Deleted");
          this.state.data.station_id = null;
          this.setState({ data: this.state.data });
        }
        this.checkOutsideorStationID();
      } catch (er) {
        console.log(er);
        message.error("Operation failed");
      }
    };

    editProperty = () => {
      this.props.form.validateFields(
        ["id", "name", "city", "state", "address", "pin_code"],
        async (err, values) => {
          if (!err) {
            try {
              const { name, address, city, state, pin_code } = values;
              const payload = {
                id: this.state.data.id,
                name,
                city,
                state,
                address,
                pin_code,
              };
              // console.log(payload);
              const { data } = await editProperty(payload);
              message.success(data.message);
              this.setState({
                isPropertyModalVisible: false,
                alert: true,
              });
            } catch (error) {
              console.log(error);
              message.error(error.response.data.message);
            }
          }
        },
      );
    };

    openSpace = (id) => {
      this.props.onClick("Space", id, this.propertyId);
    };

    addSpace = () => {
      // this.props.history.push("/admin/addspace", { id: this.propertyId });
      this.props.onClick("AddSpace", this.propertyId, null);
    };

    deleteSpaceData = async (id) => {
      try {
        const { Device } = this.state.data.Spaces.find(
          (space) => space.id == id,
        );
        if (Device) {
          message.info(
            "Cannot delete space. A Device is installed at this space",
          );
        } else {
          const { data } = await deleteSpace(id);
          message.success(data.message);
          this.setState({
            alert: true,
          });
        }
      } catch (error) {
        if (error.response) {
          message.error(error.response.data.message);
        } else {
          console.log(error);
          message.error("Space deletion failed");
        }
      }
    };

    editSpaceData = () => {
      this.props.form.validateFields(
        ["space_name", "space_id"],
        async (err, values) => {
          if (!err) {
            try {
              const { space_name, space_id } = values;
              const payload = {
                id: space_id,
                name: space_name,
              };
              const { data } = await editSpace(payload);
              message.success(data.message);
              this.setState({
                isSpaceModalVisible: false,
                alert: true,
              });
            } catch (error) {
              console.log(error);
              message.error(error.response.data.message);
            }
          }
        },
      );
    };

    editOutsideDeviceID = () => {
      this.props.form.validateFields(
        ["outside_device_id"],
        async (err, values) => {
          if (!err) {
            try {
              const { outside_device_id } = values;

              const payload = {
                new_device_id: outside_device_id,
                parent_id: this.state.data.id,
                is_outside: true,
                old_device_id: this.state.data.OutsideDevice.id,
              };
              const { data } = await editDevice(payload);
              message.success(data.message);
              this.setState({
                isOutsideDeviceModalVisible: false,
                alert: true,
              });
            } catch (error) {
              console.log(error);
              if (error.response) message.error(error.response.data.message);
            }
          }
        },
      );
    };

    addDevice = () => {
      if (this.state.data.OutsideDevice) {
        return message.error("A property can have only one device outside");
      }
      // this.props.onClick(
      //   "AddDevice",
      //   { isOutSide: true, id: this.propertyId },
      //   null,
      // );
      this.props.history.push("/admin/adddevice", {
        id: this.propertyId,
        isOutSide: true,
      });
    };
    addStation = () => {
      //this.props.history.push("/admin/addStation", { id: this.propertyId });
      this.props.onClick("AddStation", this.propertyId, null);
    };
    saveCustomerFormRef = (formRef) => {
      this.customerFormRef = formRef;
    };
    saveUserFormRef = (formRef) => {
      this.userFormRef = formRef;
    };

    render() {
      let { data, neighbour_flag } = this.state;
      const { form } = this.props;
      const { getFieldDecorator } = form;
      // console.log(data);
      if (!data) {
        return (
          <div style={style.loader}>
            <Spin size="large" tip="Loading property data.." />
          </div>
        );
      }
      return (
        <div style={{ background: "#FFF" }}>
          <div style={style.container}>
            <PageHeader
              className="site-page-header"
              onBack={() => this.prevComponent()}
              title={<h1>{data.name}</h1>}
              subTitle={
                <h4>
                  {data.address}
                  <Button
                    style={{
                      marginLeft: 16,
                    }}
                    shape="circle"
                    icon="edit"
                    type="primary"
                    onClick={() => {
                      this.setState({ isPropertyModalVisible: true });
                      form.setFieldsValue({
                        name: data.name,
                        address: data.address,
                        pin_code: data.pin_code,
                        city: data.city,
                        state: data.state,
                      });
                    }}
                  />
                </h4>
              }
            />
            {/* <Button
              type="primary"
              onClick={() => {
                this.setState({ isPropertyModalVisible: true });
                form.setFieldsValue({
                  name: data.name,
                  address: data.address,
                  pin_code: data.pin_code,
                  city: data.city,
                  state: data.state,
                });
              }}
            >
              Edit Property{" "}
            </Button> */}
            <Modal
              visible={this.state.isPropertyModalVisible}
              title="Update Property Details"
              onOk={() => this.setState({ isPropertyModalVisible: false })}
              onCancel={() => this.setState({ isPropertyModalVisible: false })}
              footer={[
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({ isPropertyModalVisible: false })
                  }
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    this.editProperty();
                  }}
                >
                  Update{" "}
                </Button>,
              ]}
            >
              <Form layout="vertical">
                <FormItem label="Property name">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill property name!",
                      },
                    ],
                  })(<Input placeholder="Ex: Oyo Koramangala 5th block" />)}
                </FormItem>
                <FormItem label="Address">
                  {getFieldDecorator("address", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill address",
                      },
                    ],
                  })(<Input placeholder="Please write complete address" />)}
                </FormItem>
                <FormItem label="City">
                  {getFieldDecorator("city", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill city",
                      },
                    ],
                  })(<Input placeholder="Please write city name" />)}
                </FormItem>
                <FormItem label="State">
                  {getFieldDecorator("state", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill state",
                      },
                    ],
                  })(<Input placeholder="Please write state" />)}
                </FormItem>
                <FormItem label="Pin code">
                  {getFieldDecorator("pin_code", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill pin code",
                      },
                    ],
                  })(<Input placeholder="Please write pin code" />)}
                </FormItem>
              </Form>
            </Modal>
            <Modal
              title="Edit Space Details"
              visible={this.state.isSpaceModalVisible}
              onOk={() => this.setState({ isSpaceModalVisible: false })}
              onCancel={() => this.setState({ isSpaceModalVisible: false })}
              footer={[
                <Button
                  key="back"
                  onClick={() => this.setState({ isSpaceModalVisible: false })}
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    this.editSpaceData();
                  }}
                >
                  Update{" "}
                </Button>,
              ]}
            >
              <Form layout="vertical">
                <FormItem label="Space ID" style={{ display: "none" }}>
                  {getFieldDecorator("space_id", {})}
                  <Input placeholder="ID" />
                </FormItem>
                <FormItem label="Space name">
                  {getFieldDecorator("space_name", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill space name!",
                      },
                    ],
                  })(<Input placeholder="Ground floor AHU" />)}
                </FormItem>
              </Form>
            </Modal>
            <Modal
              title="Edit Outside Device ID"
              visible={this.state.isOutsideDeviceModalVisible}
              onOk={() => this.setState({ isOutsideDeviceModalVisible: false })}
              onCancel={() =>
                this.setState({ isOutsideDeviceModalVisible: false })
              }
              footer={[
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({ isOutsideDeviceModalVisible: false })
                  }
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    this.editOutsideDeviceID();
                  }}
                >
                  Update{" "}
                </Button>,
              ]}
            >
              <Form layout="vertical">
                <FormItem label="Device ID">
                  {getFieldDecorator("outside_device_id", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill Device ID!",
                      },
                    ],
                  })(<Input placeholder="123456" />)}
                </FormItem>
              </Form>
            </Modal>

            {neighbour_flag ? (
              <Alert
                message="Warning"
                description="This property is using Neighbour Property for Outside data."
                type="warning"
                showIcon
                closable
              />
            ) : null}
            <Button
              icon="plus"
              style={style.button}
              onClick={this.addDevice}
              type="primary"
              disabled={this.state.disable_station_outside_btns}
            >
              Add Outside device
            </Button>
            <Button
              icon="plus"
              style={style.button}
              onClick={this.addStation}
              type="primary"
              disabled={this.state.disable_station_outside_btns}
            >
              Add Outside station
            </Button>
            {data.OutsideDevice && (
              <div>
                Outside Device ID : {data.OutsideDevice.id}
                <Button
                  style={{
                    marginLeft: 16,
                  }}
                  shape="circle"
                  icon="edit"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      isOutsideDeviceModalVisible: true,
                    });
                    form.setFieldsValue({
                      outside_device_id: data.OutsideDevice.id,
                    });
                  }}
                />
                <Popconfirm
                  title="Are you sure delete this device?"
                  onConfirm={() => this.confirm(4)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{
                      marginLeft: 16,
                    }}
                    shape="circle"
                    icon="delete"
                    type="primary"
                  />
                </Popconfirm>
              </div>
            )}
            {data.station_id && (
              <div>
                Station ID : {data.station_id} (
                {data.StationProperty && data.StationProperty.name})
                <Popconfirm
                  title="Are you sure delete this device?"
                  onConfirm={() => this.confirm(5)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{
                      marginLeft: 16,
                    }}
                    shape="circle"
                    icon="delete"
                    type="primary"
                  />
                </Popconfirm>
              </div>
            )}
            <Divider
              style={{
                marginTop: 32,
              }}
            />
            <Button
              icon="plus"
              style={style.button}
              onClick={this.addSpace}
              type="primary"
            >
              Add Space
            </Button>
            <List
              style={style.listStyle}
              bordered
              dataSource={data.Spaces}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <a
                        onClick={() => this.openSpace(item.id)}
                        style={{ color: "#006d86" }}
                      >
                        {item.name}
                      </a>
                    }
                  />
                  <Popconfirm
                    title="Are you sure to delete this space?"
                    onConfirm={() => this.deleteSpaceData(item.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{
                        marginLeft: 16,
                      }}
                      shape="circle"
                      icon="delete"
                      type="primary"
                    />
                  </Popconfirm>
                </List.Item>
              )}
            />
          </div>
        </div>
      );
    }
  },
);

const style = {
  container: {
    margin: "auto",
    maxWidth: 500,
    border: "3 px solid green",
    paddingTop: 30,
  },
  saveBtn: {
    marginTop: 20,
    marginBottom: 20,
  },
  button: {
    margin: 32,
  },
  loader: {
    width: "100%",
    textAlign: "center",
    marginTop: 100,
  },
  listStyle: {
    marginTop: 20,
  },
};

export default Property;
