import React, {Component} from "react";
import {Row, Col} from "antd";
import './ColorInfoOps.less'
const ColorInfoOps = (props) => {
    return (
        <Row className="colorInfoContainerOps">
            <Col lg={5} xs={8} sm={4} className="boxContainerOps">
              <div className="colorInfoGood colorInfoBarOps"></div>
              Good
            </Col>
            <Col lg={5} xs={8} sm={4} className="boxContainerOps">
              <div className="colorInfoSatisfactory colorInfoBarOps"></div>
              Satisfactory
            </Col>
            <Col lg={5} xs={8} sm={4} className="boxContainerOps">
              <div className="colorInfoModerate colorInfoBarOps"></div>
              Moderate
            </Col>
            <Col lg={5} xs={8}sm={4} className="boxContainerOps">
              <div className="colorInfoPoor colorInfoBarOps"></div>
              Poor
            </Col>
            <Col lg={5} xs={8}sm={4} className="boxContainerOps">
              <div className="colorInfoVeryPoor colorInfoBarOps"></div>
              Very Poor
            </Col>
            <Col lg={5} xs={8} sm={4} className="boxContainerOps">
              <div className="colorInfoSevere colorInfoBarOps"></div>
              Severe
            </Col>
        </Row>
    )
}

export default ColorInfoOps;