import React, { Component } from "react";
import { Chart, Axis, Geom, Tooltip } from "bizcharts";
import DataSet from "@antv/data-set";
import moment from "moment";
import { COLOR } from "./colorsCapitaLand";
import {
  GET_P_LVL_MIN_VAL_DICT,
  P_LVL_KEY,
  PM2_5_LVL,
} from "./pLevelsCapitaLand";
import CHECK_DEVICE_CONFIG from "../../utils/checkDeviceConfig";
import {
  GetUtcDay,
  CheckIfCurrDayIsPresentDay,
} from "../../../src/utils/createAqData";
import mometTimezone from "moment-timezone";
const DEV_MODE = false;

const WinWidth = window.innerWidth;
const ChartConfig =
  WinWidth <= 1280
    ? {
        chartHeight: 210,
      }
    : WinWidth <= 1366
    ? {
        chartHeight: 220,
      }
    : {
        chartHeight: 250,
      };

function aqLevelsToShow(maxAqValue, minValDict) {
  let showAqLevelLines = {
    good: false,
    satisfactory: false,
    moderate: false,
    poor: false,
    veryPoor: false,
    severe: false,
  };

  // true: default show, false: only if needed show
  if (maxAqValue >= minValDict.severeMin) {
    showAqLevelLines.severe = true;
    showAqLevelLines.veryPoor = true;
    showAqLevelLines.poor = true;
    showAqLevelLines.moderate = true;
    showAqLevelLines.satisfactory = true;
    showAqLevelLines.good = true;
  } else if (maxAqValue >= minValDict.veryPoorMin) {
    showAqLevelLines.veryPoor = true;
    showAqLevelLines.poor = true;
    showAqLevelLines.moderate = true;
    showAqLevelLines.satisfactory = true;
    showAqLevelLines.good = true;
  } else if (maxAqValue >= minValDict.poorMin) {
    showAqLevelLines.poor = true;
    showAqLevelLines.moderate = true;
    showAqLevelLines.satisfactory = true;
    showAqLevelLines.good = true;
  } else if (maxAqValue >= minValDict.moderateMin) {
    showAqLevelLines.moderate = true;
    showAqLevelLines.satisfactory = true;
    showAqLevelLines.good = true;
  } else if (maxAqValue >= minValDict.satisfactoryMin) {
    showAqLevelLines.satisfactory = true;
    showAqLevelLines.good = true;
  } else if (maxAqValue >= 0) {
    showAqLevelLines.good = true;
  }

  return showAqLevelLines;
}

function addAqLevels(data, dataVisFields, label, maxAqValue) {
  const minValDict = GET_P_LVL_MIN_VAL_DICT(label);
  // console.log('label: ',label);
  // console.log('maxAqValue: ',maxAqValue);
  // console.log('minValDict: ',minValDict);
  const showAqLevelLines = aqLevelsToShow(maxAqValue, minValDict);
  // console.log('showAqLevelLines: ',showAqLevelLines);

  for (let i = 0, l = data.length; i < l; i++) {
    if (showAqLevelLines.good) {
      data[i][P_LVL_KEY.good] = minValDict.satisfactoryMin - 1;
      if (!dataVisFields.includes(P_LVL_KEY.good)) {
        dataVisFields.push(P_LVL_KEY.good);
      }
    }
    if (showAqLevelLines.satisfactory) {
      data[i][P_LVL_KEY.satisfactory] = minValDict.moderateMin - 1;
      if (!dataVisFields.includes(P_LVL_KEY.satisfactory)) {
        dataVisFields.push(P_LVL_KEY.satisfactory);
      }
    }
    if (showAqLevelLines.moderate) {
      data[i][P_LVL_KEY.moderate] = minValDict.poorMin - 1;
      if (!dataVisFields.includes(P_LVL_KEY.moderate)) {
        dataVisFields.push(P_LVL_KEY.moderate);
      }
    }
    if (showAqLevelLines.poor) {
      data[i][P_LVL_KEY.poor] = minValDict.veryPoorMin - 1;
      if (!dataVisFields.includes(P_LVL_KEY.poor)) {
        dataVisFields.push(P_LVL_KEY.poor);
      }
    }
    if (showAqLevelLines.veryPoor) {
      data[i][P_LVL_KEY.veryPoor] = minValDict.severeMin - 1;
      if (!dataVisFields.includes(P_LVL_KEY.veryPoor)) {
        dataVisFields.push(P_LVL_KEY.veryPoor);
      }
    }
    if (showAqLevelLines.severe) {
      data[i][P_LVL_KEY.severe] = minValDict.severeMax;
      if (!dataVisFields.includes(P_LVL_KEY.severe)) {
        dataVisFields.push(P_LVL_KEY.severe);
      }
    }
  }
  // console.log('dataVisFields: ',dataVisFields);

  return dataVisFields;
}

function structureOpHourData(
  deviceId,
  aqData,
  dateRangeArr,
  opHours,
  outsideDataShow,
) {
  // Structure the time series data from DynamoDB correctly for logic
  let aqDataT = aqData.slice();

  aqData = [].splice();
  // if(aqDataT.length % 24 !== 0) {
  //   const numOfHoursToAdd = 24 - (aqDataT.length % 24);
  //   for(var i=0;i<numOfHoursToAdd;i++) {
  //     aqDataT.push({});
  //   }
  // }
  // if(aqDataT.length !== 24) {
  //   for(let i=aqDataT.length,l=24;i<l;i++) {
  //     aqDataT.push({});
  //   }
  // }
  for (let i = 0, l = aqDataT.length; i < l; i++) {
    // for(let i=0,l=24;i<l;i++) {
    let newD;
    // console.log('i: ',i);
    // console.log('aqDataT[i]: ',aqDataT[i]);

    if (aqDataT[i].hour !== undefined) {
      newD = new Date(aqDataT[i].hour);
      // console.log('newD: ',newD);
    } else {
      // console.log('aqDataT[i].hour: ',undefined);
      newD = new Date();
      // console.log('newD: ',newD);
      newD.setHours(i);
      // console.log('newD: ',newD);
      aqDataT[i]["hour"] = newD.toString();
    }

    // Data sent at 6.30am (IST) is stored in DynamoDB as data of 12.00am
    // But, data at 6.31(IST) is stored as 1.00am. This and above is due to
    // Dynamodb automatically converting data timestamp to UTC before storing.
    // Similarly, while reading, the data is converted back to local time.
    // Timezones with GMT+5 has no problems.
    // But, Timezones with GMT+530 has problems. IST is GMT+530.
    //Example:
    // (1) if requested at 6.00am-6.30am (IST), data is 0.00am(UTC) (12.00am)
    // (2) if requested at 6.31am-6.59am (IST), data is 1.00am(UTC)
    // Below correction is to overcome the problem:
    // (1) if requested at 6.00am-6.29am (IST), data is 0.00am(UTC) (this is the 1st hour of the day, 12.00am)
    // (2) if requested at 6.30am-6.59am (IST), data is 0.00am(UTC)
    let correctionFactor = new Date().getMinutes() < 30 ? 0 : 1;

    // console.log('aqDataT[i].hour: ',aqDataT[i].hour);
    if (correctionFactor > 0) {
      if (!CHECK_DEVICE_CONFIG.devIdIsInEnggList(deviceId)) {
        aqDataT[i].hour = new Date(
          new Date(aqDataT[i].hour).getTime() + // epoch time
            30 * 60 * 1000, // adding 30 minutes worth milliseconds to epoch time
        ).toString();
      }

      // From this hour's 30 mins onwards, the aq graph shows point in next hour also
      // Example: 8.29pm, aq graph shows only till 8pm
      // 8.30pm (upto 8.59pm), aq graph shows till 9pm
      // 9.00pm (upto 9.29pm), aq graph shows till 9pm
      // 9.30pm, aq graph shows till 10pm
      // JUGAAD FIX: from this hour's 30th minute, set next hours data to null
      if (CheckIfCurrDayIsPresentDay(new Date(aqDataT[i].hour))) {
        // console.log('IS present day: '+aqDataT[i].hour);
        if (new Date(aqDataT[i].hour).getHours() - new Date().getHours() > 0) {
          aqDataT[i]["inside"] = null;
          aqDataT[i]["outside"] = null;
        }
      } else {
        // console.log('NOT present day: '+aqDataT[i].hour);
      }
    }
    // console.log('aqDataT[i].hour: ',aqDataT[i].hour);
  }
  // console.log("aqDataT:", aqDataT);
  // console.log("First hour ", new Date(aqDataT[0].hour).getHours());

  // For customers whose operational hours are split into two parts
  // 6am-12pm and 4pm-10pm
  let aqData2 = [];
  if (opHours.length === 0) {
    aqData = aqDataT.slice();
  } else {
    // console.log("opHours: ", opHours);
    for (let i = 0, l = aqDataT.length; i < l; i++) {
      // for(let i=0,l=24;i<l;i++) {

      for (let j = 0, m = opHours.length; j < m; j++) {
        if (
          new Date(aqDataT[i].hour).getHours() >= opHours[j][0] &&
          new Date(aqDataT[i].hour).getHours() <= opHours[j][1]
        ) {
          if (aqDataT[i] === undefined) {
            aqDataT[i] = aqDataT[0].slice();
            aqDataT[i]["inside"] = null;
            aqDataT[i]["outside"] = null;
          }
          // console.log('PmChart.outsideDataShow: ',outsideDataShow);
          if (!outsideDataShow) {
            aqDataT[i]["outside"] = null;
          }
          if (j === 0) {
            aqData.push(aqDataT[i]);
          } else {
            aqData2.push(aqDataT[i]);
          }
        }
      }
    }
  }
  // console.log("before addding hour aqdata is");
  // console.log(aqData);

  // addHourNum() shows the data in the graph on first render. However, after the first render, it stops the diplay and this function only formats the hour property of aqData object. Since addHourNum() is causing error and the role of addHourNum() is only to structure hour property in aqData object, this funcion call is commented out and moment-timezone is used instead as it displays the date only in IST format irrespective of what the host's date-time format is.

  // addHourNum(aqData);
  // console.log("aqData: ", aqData);
  for (let i = 0; i < aqData.length; i++) {
    aqData[i].hour = mometTimezone(aqData[i].hour)
      .tz("Asia/Kolkata")
      .format("lll");
  }
  return aqData;
}

function addHourNum(aqData) {
  for (var i = 0, l = aqData.length; i < l; i++) {
    const dateObj = new Date(aqData[i].hour);

    const hourInfo =
      dateObj.getHours() === 0
        ? "12am"
        : dateObj.getHours() < 12
        ? dateObj.getHours() + "am"
        : dateObj.getHours() === 12
        ? "12pm"
        : dateObj.getHours() - 12 + "pm";
    const dateInfo = GetUtcDay(dateObj);
    aqData[i].hour = dateInfo + ", " + hourInfo;
  }

  return aqData;
}

const PmChartCapitaLand = (props) => {
  const {
    aqTrendData,
    dateRangeArr,
    label,
    spaceDevId,
    opHours,
    outsideDataShow,
  } = props;
  // console.log(label);
  // console.log('outsideDataShow: ',outsideDataShow);
  // console.log("PmChart.aqTrendData: ", JSON.stringify(aqTrendData, null, 2));
  // console.log("pM chart aq trend data");
  // console.log(aqTrendData);
  // console.log({
  //   aqTrendData,
  //   dateRangeArr,
  //   spaceDevId,
  //   opHours,
  //   outsideDataShow,
  // });
  const data = structureOpHourData(
    spaceDevId,
    aqTrendData,
    dateRangeArr,
    opHours,
    outsideDataShow,
  );
  // console.log('PmChart.data: ',JSON.stringify(data,null,2));
  // console.log("structured data");
  // console.log(data);
  let maxAqValue = 0;
  for (let i = 0, l = data.length; i < l; i++) {
    if (DEV_MODE) {
      // DEV CODE
      let compareValue = GET_P_LVL_MIN_VAL_DICT(label).severeMin - 1;
      if (data[i].inside >= compareValue) {
        data[i].inside = compareValue;
      }
      if (data[i].outside >= compareValue) {
        data[i].outside = compareValue;
      }
    }
    if (data[i].inside > maxAqValue) {
      maxAqValue = data[i].inside;
    }
    if (data[i].outside > maxAqValue) {
      maxAqValue = data[i].outside;
    }
  }
  // console.log('maxAqValue: ',maxAqValue);

  // console.log('showAqLevelLines: ',showAqLevelLines);
  // let dataVisFields = [];
  let dataVisFields = ["inside", "outside"];
  // outsideDataShow ? dataVisFields = ['inside','outside'] : dataVisFields = ['inside'];
  // console.log('dataVisFields: ',dataVisFields);
  // dataVisFields = addAqLevels(data,dataVisFields,label,maxAqValue);
  // console.log('dataVisFields: ',dataVisFields);
  // console.log('data: ',JSON.stringify(data,null,2));
  const ds = new DataSet();
  const dv = ds.createView().source(data);
  dv.transform({
    type: "fold",
    fields: dataVisFields,
    key: "space",
    value: "pollutant",
  });
  const width = window.innerWidth;
  let tickCount;
  if (width < 600) tickCount = 8;
  else if (width < 800) tickCount = 10;
  const cols = {
    hour: {
      range: [0, 1],
      alias: "Hour of the day",
      tickCount,
    },
    pollutant: {
      min: 0,
      nice: false,
      alias:
        label === "PM 2.5"
          ? "PM2.5 (ug/m3)"
          : label === "PM 10"
          ? "PM10 (ug/m3)"
          : label === "CO"
          ? "CO (ppm)"
          : label === "CO2"
          ? "CO2 (ppm)"
          : label === "VOC"
          ? "VOC (ppb)"
          : "",
    },
  };

  return (
    <div>
      <Chart height={ChartConfig.chartHeight} data={dv} scale={cols} forceFit>
        <Axis name="hour" label="" />
        <Axis
          title={{ textStyle: { fontSize: "14" } }}
          label={{ textStyle: { fontSize: "11" } }}
          name="pollutant"
        />
        <Tooltip
          showTitle={true}
          crosshairs={{
            type: "cross",
          }}
        />
        <Geom
          type="point"
          position="hour*pollutant"
          size={1}
          shape={"circle"}
          color={[
            "space",
            [COLOR.insideAir, outsideDataShow ? COLOR.outsideAir : null],
          ]}
        >
          <Geom
            type="area"
            position="hour*pollutant"
            size={2}
            color={[
              "space",
              [COLOR.insideAir, outsideDataShow ? COLOR.outsideAir : null],
            ]}
            shape={"smooth"}
          />
        </Geom>
      </Chart>
    </div>
  );
};

export default PmChartCapitaLand;
