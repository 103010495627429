import React, { Component } from "react";
import { Button, Alert, Form, Input, Radio, PageHeader } from "antd";
import SpaceForm from "../components/SpaceForm";
import moment from "moment";
import { addSpace } from "../api";
import { resolve } from "url";

const FormItem = Form.Item;

export default class AddSpace extends Component {
  constructor(props) {
    super(props);
    //  this.propertyId = this.props.location.state.id;
    this.state = {
      loading: false,
      response: "",
      propertyId: "",
    };
  }

  componentDidMount() {
    this.setState({ propertyId: this.props.id });
  }
  prevComponent = () => {
    this.props.onClick("Property", this.state.propertyId, null);
  };
  saveSpace = () => {
    const spaceForm = this.spaceFormRef.props.form;
    spaceForm.validateFields(
      // [
      //   // "name",
      //   // "area",
      //   // "exhaust_fan",
      //   // "vent_wih_filter",
      //   // "vent_wihtout_filter",
      //   // "opHours",
      //   // "NonOpDays",
      //   // "fromOpHours",
      //   // "toOpHours",
      // ],
      async (err, values) => {
        if (err) {
          return;
        }
        let opHours;
        if (values.opHours) {
          opHours = values.opHours;
        } else {
          opHours =
            values.fromOpHours.format("ha") +
            " - " +
            values.toOpHours.format("ha");
        }
        // console.log(values);
        // console.log(values.opHours.format("h A"));c
        // console.log(
        //   values.fromOpHours.format("ha") +
        //     " - " +
        //     values.toOpHours.format("ha"),
        // );
        // const opHours =
        //   values.fromOpHours.format("ha") +
        //   " - " +
        //   values.toOpHours.format("ha");
        this.setState({ loading: true });
        try {
          const response = await addSpace({
            name: values.name,
            area: values.area,
            exhaust_fan: values.exhaust_fan,
            vent_wih_filter: values.vent_wih_filter,
            vent_wihtout_filter: values.vent_wihtout_filter,
            opHours,
            NonOpDays: values.NonOpDays,
            property_id: this.state.propertyId,
          });
          // console.log(response.data);
          spaceForm.resetFields();
          this.setState({ code: 200, response: "Data created successfully" });
        } catch (e) {
          console.log(e);
          this.setState({ code: 400, response: e.response.data.message });
        }
        this.setState({ loading: false });
      },
    );
  };

  saveSpaceFormRef = (formRef) => {
    this.spaceFormRef = formRef;
  };

  render() {
    return (
      <div style={style.container}>
        {/* <h1>Add Space</h1> */}
        <PageHeader
          className="site-page-header"
          onBack={() => this.prevComponent()}
          title={<h1>{"Add Space"}</h1>}
        />
        <SpaceForm wrappedComponentRef={this.saveSpaceFormRef} />
        {this.state.response && (
          <Alert
            message={this.state.response}
            type={this.state.code == 200 ? "success" : "error"}
            showIcon
          />
        )}
        <Button
          style={style.saveBtn}
          type="primary"
          loading={this.state.loading}
          onClick={this.saveSpace}
        >
          Save
        </Button>
      </div>
    );
  }
}

const style = {
  container: {
    margin: "auto",
    maxWidth: 500,
    border: "3 px solid green",
    paddingTop: 30,
  },
  saveBtn: {
    marginTop: 20,
    marginBottom: 20,
  },
};
