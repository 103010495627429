import React, {Component} from "react";
import {makePostRequest} from "../api";
import axios from "axios";
import {Spin, Card, Row, Col, Divider, Result } from "antd";

const fracta_gurgaon_id = 'f55022f0-64ff-40f8-807c-0043ef640a17';
const fracta_bangalore_id = "b060fb6b-f40f-4e70-a03a-249d04d6f032";
const fracta_mumbai_id = "55a74949-e121-44ee-b30b-a1e34a3c836c";
const fractal_Ids = [
    "55a74949-e121-44ee-b30b-a1e34a3c836c",
    "b060fb6b-f40f-4e70-a03a-249d04d6f032",
    "f55022f0-64ff-40f8-807c-0043ef640a17"
]

export default class FFUView extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            customer_id: this.props.id,
            edit_visible: false,
            response: null
        }
    }

    async componentDidMount() {
        try {
            let queryCon;
            let dataArray;
            let responseData = [];
            if(this.state.customer_id === fracta_gurgaon_id) {
                queryCon = "frac/gur/"
            } else if (this.state.customer_id === fracta_mumbai_id) {
                queryCon = "frac/mub/"
            } else if (this.state.customer_id === fracta_bangalore_id) {
                queryCon = "frac/bglr/"
            }
            let requestData = {    
                "queryCol": "details",
                "queryCon": queryCon
            }
            let Devices = await axios({
                method: 'POST',
                url: '/device',
                baseURL: 'https://api.claircoair.com/api',
                headers: {
                    'api-key': 'lcvhzec0ae4u20047s36mfexcjr2ebd3cpk28b9yca9c9f95d17dgeda',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJzdF9uYW1lIjoiQXNoaXNoIiwiZW1haWwiOiJhc2hpc2gua3VtYXJAY2xhaXJjby5pbiIsImlkIjoiY2xhaXJjby1mZGZkOTNlNC1lYTljLTQxZWItODIzZS0xMjUwMmE5MTQxNTcudnRkZXB3ODZvdyIsImRvbWFpbiI6ImNsYWlyY28iLCJyb2xlcyI6WyJmZDU1YTM2OS0yNjA2LTQyODEtYjgyNS0yODBiNzBmYWM4MjkiXSwiaWF0IjoxNzEzMTYzNTYxLCJleHAiOjE3NDQ2OTk1NjF9.coHIb8hZ09bQ4f7jISgJVNpjw2WAc2Yi5lQ7CzyWy9Y'
                },
                data: requestData
            }).then((response) => {
                let devices = response.data.msg;
                let ffcDevices = devices.filter((item) => {
                    return item['id'].includes('FFC')
                })
                dataArray = ffcDevices;
            });
            for(var i=0; i<dataArray.length; i++){
                let data = await new Promise(async (resolve, reject) => {
                    let incomingResponse = await makePostRequest(dataArray[i]);
                    resolve(incomingResponse);
                });
                responseData.push({
                    key: dataArray[i].id,
                    metaData: dataArray[i].metaData,
                    data:data.data.msg
                });
            }            
            this.setState({
                responseData
            });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        let { responseData} = this.state;
        if(this.state.customer_id === fracta_gurgaon_id || this.state.customer_id === fracta_mumbai_id || this.state.customer_id === fracta_bangalore_id){
            if(!responseData)
                return <Spin></Spin>
        }
        if(responseData.length == 0){
            return (
                <Result
                    status="warning"
                    title="No Purifiers Have been added."
                />
            )
        }
        let filterCardItems, filterItems;
        if(this.state.customer_id === fracta_gurgaon_id || this.state.customer_id === fracta_mumbai_id || this.state.customer_id === fracta_bangalore_id){
            filterItems = responseData.map((item, i) => {
                return (<Col span={4} offset={1} style={{marginBottom: '20px'}}>
                <Card style={{boxShadow:'3px 3px 3px #e0e0e0'}}>
                    <Row style={{minHeight:'5vh'}}>
                        <Col className={
                            typeof item.data == 'string' || item.data.metaData.RELAY_STATE < 1
                            ?"block_card_icon fail"
                            :"block_card_icon success"}
                            span={12}>
                            <div>
                                {typeof item.data == 'string' || item.data.metaData.RELAY_STATE < 1
                                    ?'OFF'
                                    :'ON'}
                            </div>
                        </Col>
                    </Row>
                    <Divider className="custom_divider"/>
                    <Row style={{textAlign: "center", minHeight:'2vh'}}>
                        <span><b>{item.metaData.name+' - '+item.key}</b></span>
                    </Row>
                </Card>
            </Col>)
            });
        }
        filterCardItems = ( filterItems );
        return(
            <div style={{ minHeight: 600 }}>
                {(fractal_Ids.indexOf(this.state.customer_id) > -1)? filterCardItems:null}
            </div>
        );
    }
}