import React, {Component} from 'react'
import "./Home.less"
import {
    Layout,
    Icon,
    Row,
    Input,
    Col,
    Carousel,
    Popover,
    Button
} from 'antd';
import logo from '../../img/Claircofinallogo.png'
import work from '../../img/work.svg'
import pic1 from '../../img/aayush.png'
import pic2 from '../../img/Udayan.png'
import pic3 from '../../img/Melvin.png'
import pic4 from '../../img/Ashish.jpg'
import screen from '../../img/dash.png'
import illu from '../../img/hello.svg'
import illu2 from '../../img/client.png'
import bloomberg from '../../img/media/bloomberg-quint-vector-logo.png'
import businesstoday from '../../img/media/BusinessToday.png'
import e27 from '../../img/media/e27-logo.png'
import financial from '../../img/media/financialexpres.png'
import techcircle from '../../img/media/techcircle.png'
import theweek from '../../img/media/the-week-logo.png'
import yourstory from '../../img/media/yourstory.png'
import business from '../../img/media/businessstandardlogo.png'
import {sha256} from 'js-sha256';

const {Header, Content, Footer} = Layout;
const Search = Input.Search;

export default class Home extends Component {
    state = {
        scrolled: false
    }

    scrollListner = (e) => {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            this.setState({scrolled: true})
        } else {
            this.setState({scrolled: false})
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.scrollListner, true);
    }
    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.scrollListner, true);
    }

    demoClicked = (e) => {
        window
            .Tawk_API
            .toggle()
    }

    loginClicked=(e)=>{
        this.props.history.push("/login");
    }
    onSearch = (email) => {
        window
            .Tawk_API
            .setAttributes({
                'name': 'Name',
                email,
                'hash': sha256.hmac('dbc6a6bacfa832c9983b322d46bb5ba8433f467c', email)
            }, function (error) {
                console.log(error);
            });
        window
            .Tawk_API
            .toggle()
    }

    render() {

        const menu = (
            <div className="homeSMMenu">
                <div>
                    <a href="#home">Home</a>
                </div>
                <div>
                    <a href="#features">Why Us?</a>
                </div>
                <div>
                    <a href="#overview">Dashboard</a>
                </div>
                <div>
                    <a href="#ourteam">Our Team</a>
                </div>
                <div>
                    <a href="#client">Our Clients</a>
                </div>
                <div>
                    <a href="#media">Media</a>
                </div>
                <div>
                    <a href="#contact">Contact</a>
                </div>
                <div className="homeSMMenuAction">
                    <Button type="ghost" className="homeLogin" onClick={this.loginClicked}>Login</Button>
                    <Button
                        className="homeDemo"
                        onClick={this.demoClicked}
                        type="primary"
                        color="#000000"
                        style={{}}>Request a demo</Button>
                </div>
            </div>
        );
        return (
            <Layout className="homeLayout" id="home">
                <Header
                    className={this.state.scrolled
                    ? "homeHeader shadow"
                    : "homeHeader"}>
                    <div className="homeHeaderContainer">
                        <div className="logoContainer"><img className="logoHome" src={logo}/>
                        </div>
                        <span className="homeNav">
                            <a href="#home">Home</a>
                            <a href="#client">Our Clients</a>
                            <a href="#featuers">Why Us?</a>
                            <a href="#overview">Dashboard</a>
                            <a href="#ourteam">Our Team</a>
                            <a href="#media">Media</a>
                            <a href="#contact">Contact</a>
                            {/* <a href="airqualityreport/AirQualityIndexinMajorLocalitiesofBangalore.html" target="_blank">Blog</a> */}
                        </span>
                        <div className="homeActionContainer">
                            <Button type="ghost" onClick={this.loginClicked} className="homeLogin">Login</Button>
                            <Button
                                className="homeDemo"
                                onClick={this.demoClicked}
                                type="primary"
                                style={{}}>Request a demo</Button>
                        </div>
                        <div className="smScreenMenuContainer">
                            <Popover placement="bottomRight" content={menu} trigger="click" style={{backgroundColor: "#022F8E"}}>
                                <Button type="ghost" icon="menu-fold"/>
                            </Popover>
                        </div>
                    </div>
                </Header>
                <Content className="homeContentContainer">
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <div className="homeMainContentContainer">
                                <span className="homeMainContentText">
                                    Clean air Guaranteed | Zero CapeX | Huge Savings | Better Productivity
                                </span>
                                <br/>
                                <span className="homeMainContentSubText">
                                    We are experts at indoor air quality management.
                                    Improve your customer experience by providing measurable cleaner air for your business.
                                </span>
                                <Search
                                    placeholder="Enter your email"
                                    enterButton="Get started"
                                    size="large"
                                    className="homeEmail"
                                    onSearch={this.onSearch}/>
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={12} lg={12}>
                            <div className="homeMainContentContainer">
                                <img className="homeImg" src={work}/>
                                {/* <img className="homeImg" src={illu2}/> */}
                            </div>
                        </Col>
                    </Row>

                    <div className="homeChooseRow" id="client">
                        <p className="homeChooseTitle">Our Clients</p>
                    </div>
                    <div className="homeClientScreenRow" >
                        <img className="homeDashboardScreen" src={illu2}/>
                    </div>

                    <div className="homeChooseRow" id="featuers">
                        <p className="homeChooseTitle">Why Choose Us?</p>
                    </div>
                    <Row
                        className="homeFeatureContainer"
                        type="flex"
                        justify="center"
                        gutter={16}>
                        <Col lg={12} md={12} sm={24}>
                            <div className="homeCard homeFirstCard">
                                <div className="homeIconContainer homeIconContainerFirst">
                                    <Icon className="homeIcon" type="area-chart" theme="outlined"/>
                                </div>
                                <div className="homeCardTextContainer">
                                    <p className="homeCardTitle">Measure and Show Air Quality Data</p>
                                    <p className="homeCardSubTitle">
                                        Real time visibility of air borne pollutants critical for customer and employee health.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={24}>
                            <div className="homeCard homeSecondCard">
                                <div className="homeIconContainer homeIconContainerSecond">
                                    <Icon className="homeIcon" type="exception" theme="outlined"/>
                                </div>
                                <div className="homeCardTextContainer">
                                    <p className="homeCardTitle">Analyze Data</p>
                                    <p className="homeCardSubTitle">
                                        Detailed analysis of air quality trends through automated periodic reports.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={24}>
                            <div className="homeCard homeThirdCard">
                                <div className="homeIconContainer homeIconContainerThird">
                                    <Icon className="homeIcon" type="clock-circle" theme="outlined"/>
                                </div>
                                <div className="homeCardTextContainer">
                                    <p className="homeCardTitle">Clean Ambience</p>
                                    <p className="homeCardSubTitle">
                                        Guaranteed clean air at all times | More productive employees | Happier customers
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={24}>
                            <div className="homeCard homeThirdCard">
                                <div className="homeIconContainer homeIconContainerFour">
                                    <Icon className="homeIcon" type="check-square" theme="outlined" /> 
                                </div>
                                <div className="homeCardTextContainer">
                                    <p className="homeCardTitle">Better Workspace Experience</p>
                                    <p className="homeCardSubTitle">
                                        Display air quality data on any screen/app | Better brand image | More footfalls.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="homeDashboardScreenRow" id="overview">
                        <p className="homeDashboardScreenTitle">Simple Dashboard, Deep Insights</p>
                        <img className="homeDashboardScreen" src={screen}/>
                    </div>
                    <div className="homeChooseRow" id="ourteam">
                        <p className="homeChooseTitle">Our Team</p>
                    </div>
                    <Row
                        className="homeFeatureContainer"
                        type="flex"
                        justify="center"
                        gutter={16}>
                            <Col lg={6} md={6} sm={24}>
                                <div className="homeCardTextContainer">
                                    <img className="logoProfile" src={pic1}/>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <span className="homeProfileNameText">
                                        Aayush Jha
                                    </span>
                                    <p className="homeProfileContentText">
                                        Co-Founder & CEO
                                    </p>
                                    <a href="https://www.linkedin.com/in/aayush-jha/" target="_blank">
                                        <Icon className="homeProfileLogo" type="linkedin" theme="outlined"/> 
                                    </a>
                                    </div>
                            </Col>
                            <Col lg={6} md={6} sm={24}>
                                <div>
                                    <img className="logoProfile" src={pic2}/>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <span className="homeProfileNameText">
                                        Udayan Banerjee
                                    </span>
                                    <p className="homeProfileContentText">
                                        Co-Founder & Operations Head
                                    </p>
                                    <a href="https://www.linkedin.com/in/udayan-banerjee-16407432/" target="_blank">
                                        <Icon className="homeProfileLogo" type="linkedin" theme="outlined"/> 
                                    </a>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={24}>
                                <div >
                                    <img className="logoProfile" src={pic3}/>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <span className="homeProfileNameText">
                                        Melvin George
                                    </span>
                                    <p className="homeProfileContentText">
                                        Co-Founder & Product Head
                                    </p>
                                    <a href="https://www.linkedin.com/in/melvin-george-1092b092/" target="_blank">
                                        <Icon className="homeProfileLogo" type="linkedin" theme="filled"/> 
                                    </a>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={24}>
                                <div >
                                    <img className="logoProfile" src={pic4}/>
                                </div>
                                <div style={{textAlign: "center"}}> 
                                    <span className="homeProfileNameText">
                                        Ashish Jindal
                                    </span>
                                    <p className="homeProfileContentText">
                                        Director Sales, BD &    Expansion
                                    </p>
                                    <a href="https://www.linkedin.com/in/ashish-jindal-1345431//" target="_blank">
                                        <Icon className="homeProfileLogo" type="linkedin" theme="filled"/> 
                                    </a>
                                </div>
                            </Col>
                    </Row>

                    <div className="homeChooseRow" id="media">
                        <p className="homeChooseTitle">In The Media</p>
                    </div>
                    <div className="homeMediaScreenRow">
                    <Row
                        className="homeFeatureContainer"
                        type="flex"
                        justify="center"
                        gutter={16}>
                        <Col lg={6} md={6} sm={24}>
                        <a href="https://yourstory.com/2019/05/clean-air-startup-clairco-funding-angellist-india"
                           target="_blank">
                            <img className="homeDashboardScreen" src={yourstory}/>
                        </a>
                        </Col>
                        <Col lg={6} md={6} sm={24}>
                        <a href="https://www.financialexpress.com/industry/this-start-up-develops-technology-to-modify-any-kind-of-ac-units-in-air-purifiers/1442563/"
                           target="_blank">
                            <img className="homeDashboardScreen" src={financial}/>
                        </a>
                        </Col>
                        <Col lg={6} md={6} sm={24}>
                        <a href="https://www.bloombergquint.com/technology/deal-street-startup-that-turns-air-conditioner-into-purifier-gets-angel-funding-for-growth"
                           target="_blank">
                            <img className="homeDashboardScreen" src={bloomberg}/>
                        </a>
                        </Col>
                        <Col lg={6} md={6} sm={24}>
                        <a href="https://www.theweek.in/wire-updates/business/2018/11/14/pwr6--clairco.html"
                           target="_blank">
                            <img className="homeDashboardScreen" src={theweek}/>
                        </a>
                        </Col>
                        <Col lg={6} md={6} sm={24}>
                        <a href="https://www.techcircle.in/2019/05/27/exclusive-iot-startup-clairco-closes-funding-round-from-angellist-india"
                           target="_blank">
                            <img className="homeDashboardScreen" src={techcircle}/>
                        </a>
                        </Col>
                        <Col lg={6} md={6} sm={24}>
                        <a href="https://e27.co/indian-iot-startup-clairco-can-convert-your-ac-unit-into-a-smart-air-purifier-20190117/"
                           target="_blank">
                            <img className="homeDashboardScreen" src={e27}/>
                        </a>
                        </Col>
                        <Col lg={6} md={6} sm={24}>
                        <a href="https://www.businesstoday.in/magazine/coolest-start-ups-2019/clearing-the-air/story/358478.html"
                           target="_blank">
                            <img className="homeDashboardScreen" src={businesstoday}/>
                        </a>
                        </Col>
                        <Col lg={6} md={6} sm={24}>
                        <a href="https://www.business-standard.com/article/pti-stories/clairco-brings-guaranteed-pure-air-to-new-delhi-as-toxic-smog-engulfs-the-city-118111400820_1.html"
                           target="_blank">
                            <img className="homeDashboardScreen" src={business}/>
                        </a>
                        </Col>
                    </Row>
                    </div>
                    <Row className="homeFooterRow" id="contact">
                        <Col sm={24} md={12} className="homeFooterRowItem">
                            <div className="logoContainer"><img className="logoHomeFooter" src={logo}/>
                            </div>
                            <p className="homeFooterCopyText">Copyright © 2018 Aliferous Technologies pvt. ltd.
                                <br/>
                                <a href="tel:+91-9910012334">+91-9910012334/+91-9898036463</a>
                                <br/>
                                <a href="mailto:contact@clairco.in">contact@clairco.in</a>
                            </p>
                        </Col>
                        <Col sm={0} md={4}></Col>
                        <Col sm={24} md={4} className="homeFooterRowItem">
                            <p className="homeFooterHeader">Company</p>
                            <div className="homeFooterSubText">
                                <a href="#home">Home</a><br/>
                                <a href="#featuers">Why Us?</a><br/>
                                <a href="#overview">Overview</a><br/>
                                <a href="https://www.medium.com/clairco" target="_blank">Blogs</a><br/>
                                <a href="https://angel.co/company/clairco-1" target="_blank">Careers</a>
                            </div>
                        </Col>
                        <Col sm={24} md={4} className="homeFooterRowItem">
                            <p className="homeFooterHeader">Support</p>
                            <div className="homeFooterSubText">
                                <a target="_blank" href="https://www.getclairco.com/privacy.html">Privacy Policy</a><br/>
                                <a target="_blank" href="https://www.getclairco.com/terms.html">Terms and Services</a>
                            </div>
                        </Col>
                    </Row>
                </Content>

            </Layout>
        )
    }
}
