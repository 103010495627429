import React, {Component} from "react";
import {Layout, Avatar, Select, Button, Icon, Modal, Alert, Badge, Input, Card, Spin} from "antd";
import {sendHSMMessage, sendSessionMessage, getConversationHistory} from "../api";
const {Header,  Content, Footer} = Layout;
const {Option} = Select;
export default class ChatView extends Component {
    constructor(props){
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.template_values = {}
        this.state = {
            customerName: this.props.name,
            contact: this.props.contact,
            visible: false,
            title: "Send HSM to "+ this.props.name+" ( "+this.props.contact+" )",
            show_input: false,
            message: null
        }
    }

    async componentDidMount() {
      let message_data = (await getConversationHistory("91"+this.state.contact)).data;
      let conversations = [];
      if(message_data.result!==null){
        conversations = message_data.result.conversations;
      }
      // console.log('converstaions: ',conversations);
      this.setState({
        messages: conversations
      })
    }

    async componentDidUpdate(prevProps) {    
        const prevContact = prevProps.contact;
        const contact = this.props.contact;
        const customerName = this.props.name;        
        this.customerName = customerName;
        this.contact = contact;
        let message_data = (await getConversationHistory("91"+this.contact)).data;
        // console.log(message_data);
        let conversations = [];
        if(message_data.result!==null){
          conversations = message_data.result.conversations;
        }
        let title = "Send HSM to "+ customerName + " ( "+contact+" )";
        // console.log('converstaions: ',conversations);
        if(contact !== prevContact){
            this.setState({
                customerName: customerName,
                contact: contact,
                title: title,
                messages: conversations
            })
        }
    }

    showHSMModal = () => {
        this.setState({
            visible: true,
          });
    }

    handleCancel = () => {
        this.setState({
          visible: false,
        });
    };

    onSubmit = async (e) => {
      let data = {
        to: this.state.contact,
        type: "text",
        template: this.state.template,
        value: this.template_values
      };
      try {
        let resp = (await sendHSMMessage(data)).data;
        if(resp.response.messageId){
          this.setState({
            message: "Sent Successfully"
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    handleChange = (value) => {
        var render_input = 1;
        if(value === 'account_live'){
            render_input = 2;
        } else if (value === 'weekly_report'){
          render_input = 11;
        }
        this.setState({
            template: value,
            show_input: true,
            render_input: render_input
        })        
    };

    sendSessionMessage = async () => {
      var to = this.state.contact;
      var type = "text";
      if(this.state.session_message) {
        var data = {
          to: to,
          type: type,
          message: this.state.session_message
        }
        var resp = await sendSessionMessage(data);
      }
    }

    handelSessionInput = (evt) => {
      this.setState({
        session_message: evt.target.value
      })
    }

    updateInputValue = (evt) => {
      this.template_values[evt.target.name] = evt.target.value
    }

    renderInputItems = () => {
        var items = []
        for(var i=0; i<this.state.render_input; i++ ){         
            items.push(<div>
                        <span>Var {i+1}</span>
                        <br/>
                        <Input name={"var"+(parseInt(i)+1)} type="text" onChange={this.updateInputValue}/>
                      </div>);
        };
        return items;
    }

    renderConversationMessages = () => {
      if(this.state.messages){
        var messages = this.state.messages;
        var items = [];
        for(var i=0; i < messages.length; i++) {
          items.push(
            <div key={messages[i].messageId} style={{display: 'flow-root'}}>
              {messages[i].sender_name==='Clairco'
                ?<span className="message_card float_right">
                  {messages[i].message}
                 </span>
                :<span className="message_card float_left">
                  {messages[i].message}
                 </span>
              }
            </div>
          )
        }
        return items;
      } else {
        return (<Spin size="default"/>)
      }
    }
    render() {
        let {customerName, contact, title, visible, show_input} = this.state;
        
        return(
            <Layout style={{height: '102%'}}>
                <Header className="chatViewHeader">
                    <div className="headerLogo">
                        {/* Powered by <img src={clairco_logo} className="claircoLogo"/> */}
                        <Avatar
                        size={{
                            xs: 24,
                            sm: 32,
                            md: 40,
                            lg: 64,
                            xl: 80,
                            xxl: 100,
                        }}
                        style={{
                            marginRight: '10px'
                        }}
                        >{customerName.charAt(0)}</Avatar> {customerName}
                    </div>
                    <div className="hsmButtonContainer">
                        <Button 
                            icon="message"
                            onClick={this.showHSMModal}>
                            Send HSM
                        </Button>
                        <Modal
                            title={title}
                            visible={visible}
                            onOk={this.handelSend}
                            onCancel={this.handleCancel}
                            footer={[
                                <Button key="back" onClick={this.handleCancel}>
                                  Cancel
                                </Button>,
                                <Button icon="email" key="submit" type="primary" className="btn" onClick={this.onSubmit}>
                                  Send
                                </Button>
                            ]}>
                                Template
                                <br/>
                                <form id="hsmForm" className="form-horizontal">
                                  <Select
                                      placeholder="Select a Template"
                                      style={{ width: 200 }}
                                      onChange={this.handleChange}>
                                      <Option value="clairco_onboarding">clairco_onboarding</Option>
                                      <Option value="account_live">account_live</Option>
                                      <Option value="weekly_report">weekly_report</Option>
                                  </Select>
                                <br/>
                                {
                                    show_input?
                                     this.renderInputItems()
                                     :<div></div>
                                }
                                </form>
                                <br/>
                                {this.state.message?<Alert type="success" message={this.state.message}/>: null}
                                {/* {this.state.inputValue?
                                 <span>Template Message: {this.state.inputValue}, Welcome to Clairco!<br/>
                                 We'd love to help you breathe better, and are gere to help.</span>
                                :null
                                } */}
                        </Modal>
                    </div>
                </Header>
                <Content>
                    <div className="conversationContainer">
                        {this.renderConversationMessages()}
                    </div>
                </Content>
                <Footer  className="chatViewFooter">
                    <Input type="text" onChange={this.handelSessionInput} allowClear addonAfter={<Button type="primary" onClick={this.sendSessionMessage}>Send Message</Button>}/>
                </Footer>
            </Layout>
        )
    }
}