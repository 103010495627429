import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Icon,
  Input,
  Tooltip,
  Badge,
  message,
} from "antd";
import Highlighter from "react-highlight-words";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import "./Admin.less";
import {
  addVendor,
  updateVendorDetails,
  getVendors,
  deleteVendorData,
} from "../../api";

const Vendor = () => {
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const deleteVendor = async (record) => {
    await deleteVendorData(record.id)
      .then((response) => {
        setAlert(true);
        message.success(`${record.businessName} is successfully deleted`);
      })
      .catch((error) => message.error("Cannot delete now. Please try later"));
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      className: "hide",
    },
    {
      title: "Name",
      dataIndex: "businessName",
      ...getColumnSearchProps("businessName"),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Field of Business",
      dataIndex: "business_field",
      ...getColumnSearchProps("business_field"),
    },
    {
      title: "Pan Card",
      dataIndex: "panCard",
    },
    {
      title: "GST",
      dataIndex: "gst",
    },
    {
      title: "Updated",
      dataIndex: "updated",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (actions, record) => {
        return (
          <div>
            <a
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setVisible2(true);
                getVendorDetail(record);
              }}
            >
              <Tooltip placement="top" title="Update Vendor">
                <Icon type="edit" style={{ color: "green" }} />
              </Tooltip>
            </a>
            <a
              style={{ marginLeft: "10px" }}
              onClick={() => {
                window.confirm(
                  "Are you sure you want to delete vendor details?",
                ) == true && deleteVendor(record);
              }}
            >
              <Tooltip placement="top" title="Delete Vendor">
                <Icon type="delete" style={{ color: "red" }} />
              </Tooltip>
            </a>
          </div>
        );
      },
    },
  ];
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [alert, setAlert] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    setValue,
    control,
    watch,
  } = useForm();
  const [vendor, setVendor] = useState({
    vendorFirstName: "",
    vendorLastName: "",
    businessName: "",
    business_field: "",
    address: "",
    email: "",
    panCard: "",
    GSTnumber: "",
    POCname: "",
    POCcontact: "",
    referredBy: "",
  });
  const [vendors, setVendors] = useState([]);
  const [vendorID, setVendorID] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const showModal = () => {
    setVisible(true);
  };

  // add a new vendor

  const addNewVendor = async (e) => {
    try {
      const response = await addVendor(vendor);
      setVisible(false);
      setAlert(true);
      message.success(response.data.message);
      setVendor({
        vendorFirstName: "",
        vendorLastName: "",
        businessName: "",
        business_field: "",
        address: "",
        email: "",
        panCard: "",
        GSTnumber: "",
        POCname: "",
        POCcontact: "",
        referredBy: "",
      });
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.message, 10);
      } else {
        console.log(error.message);
        message.error("Network error. Please try again later");
      }
    }
  };
  const getAllVendors = async () => {
    try {
      const res = await getVendors();
      let arr = [];
      res.data.map((vendor) => {
        arr.push({
          id: vendor.id,
          businessName: vendor.businessName,
          business_field: vendor.business_field,
          email: vendor.email,
          panCard: vendor.panCard,
          gst: vendor.GSTnumber,
          updated: vendor.updated_at.slice(0, 10),
        });
      });
      setVendors(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVendors();
  }, []);

  useEffect(() => {
    if (!alert) return;
    getAllVendors();
  }, [alert]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false);
      }, 1000);
    }
  }, [alert]);

  const handleCancel = (e) => {
    setVisible(false);
    setVisible2(false);
  };

  const updateVendor = async () => {
    const payload = {
      businessName: vendor.businessName,
      business_field: vendor.business_field,
      GSTnumber: vendor.GSTnumber,
      panCard: vendor.panCard,
      email: vendor.email,
    };

    try {
      const response = await updateVendorDetails(vendorID, payload);
      setVisible2(false);
      const res = await getVendors();
      setAlert(true);
      message.success("Vendor Details is successfully updated", 5);
      setVendor({
        businessName: "",
        email: "",
        panCard: "",
        GSTnumber: "",
      });
    } catch (error) {
      if (error.response) {
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.map((err) => {
            message.error(err, 5);
          });
        } else {
          message.error(error.response.data.message);
        }
      } else {
        console.log(error.message);
        message.error("Network error. Please try again later", 10);
      }
    }
  };

  const onError = (e) => {
    console.log(e);
  };

  //getVendorDetail() is used to get the vendor data which has to be updated.
  //so that when update icon is clicked, the input input fields will be filled by the respective vendor details.
  //This functionality is used for updating the vendor details.
  // This function comes before updating vendor function

  const getVendorDetail = async (record) => {
    setVendorID(record.id);
    setVendor({
      businessName: record.businessName,
      email: record.email,
      panCard: record.panCard,
      GSTnumber: record.gst,
    });
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={showModal}
        style={{
          marginBottom: "20px",
          fontSize: "20px",
          padding: "8px 16px",
          height: "42px",
        }}
      >
        ADD VENDOR <PlusCircleOutlined />
      </Button>
      <Modal
        title="Add Vendor"
        visible={visible}
        onOk={handleSubmit(addNewVendor, onError)}
        onCancel={handleCancel}
      >
        <div style={{ width: "100% !important" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }}>First Name </label>
              <input
                type="text"
                value={vendor.vendorFirstName}
                name="name"
                style={{ width: "70%" }}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    vendorFirstName: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }}>Last Name</label>
              <input
                type="text"
                name="city"
                value={vendor.vendorLastName}
                style={{ width: "70%" }}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    vendorLastName: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }} className="important-mark">
                Business Name
              </label>
              <input
                type="text"
                name="businessName"
                value={vendor.businessName}
                style={{ width: "70%" }}
                {...register("businessName", { required: true })}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    businessName: e.target.value,
                  })
                }
              />
            </div>
            {errors.businessName && (
              <p style={{ marginLeft: "140px" }}>Business name is required</p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }} className="important-mark">
                Business Field{" "}
              </label>
              <input
                type="text"
                name="businessName"
                value={vendor.business_field}
                style={{ width: "70%" }}
                {...register("business_field", { required: true })}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    business_field: e.target.value,
                  })
                }
              />
            </div>
            {errors.business_field && (
              <p style={{ marginLeft: "140px" }}>Business field is required</p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }} className="important-mark">
                Email
              </label>
              <input
                type="text"
                value={vendor.email}
                style={{ width: "70%" }}
                name="email"
                {...register("email", { required: true })}
                onChange={(e) =>
                  setVendor({ ...vendor, email: e.target.value })
                }
              />
            </div>
            {errors.email && (
              <p style={{ marginLeft: "140px" }}>Email is required</p>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }} className="important-mark">
                Address
              </label>
              <input
                type="text"
                value={vendor.address}
                style={{ width: "70%" }}
                name="address"
                {...register("address", { required: true })}
                onChange={(e) =>
                  setVendor({ ...vendor, address: e.target.value })
                }
              />
            </div>
            {errors.address && (
              <p style={{ marginLeft: "140px" }}>Address is required</p>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }} className="important-mark">
                Pan Card
              </label>
              <input
                type="text"
                value={vendor.panCard}
                name="pancard"
                style={{ width: "70%" }}
                {...register("pancard", {
                  required: true,
                  pattern: {
                    value: /[A-Z]{5}[0-9]{4}[A-Z]{1}$/i,
                    message: "invalid pan number",
                  },
                })}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    panCard: e.target.value,
                  })
                }
              />
            </div>
            {errors.pancard && (
              <p style={{ marginLeft: "140px" }}>
                {errors.pancard.message
                  ? errors.pancard.message
                  : "Pan card number is required"}
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }} className="important-mark">
                GST
              </label>
              <input
                type="text"
                value={vendor.GSTnumber}
                name="gst"
                style={{ width: "70%" }}
                {...register("gst", {
                  required: true,
                  pattern: {
                    value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i,
                    message: "Invalid GST number",
                  },
                })}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    GSTnumber: e.target.value,
                  })
                }
              />
            </div>
            {errors.gst && (
              <p style={{ marginLeft: "140px" }}>
                {errors.gst.message
                  ? errors.gst.message
                  : "GST number is required"}
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }} className="important-mark">
                POC Name
              </label>
              <input
                type="text"
                value={vendor.POCname}
                name="poc_name"
                style={{ width: "70%" }}
                {...register("poc_name", { required: true })}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    POCname: e.target.value,
                  })
                }
              />
            </div>
            {errors.poc_name && (
              <p style={{ marginLeft: "140px" }}>POC name is required</p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }} className="important-mark">
                POC Contact
              </label>
              <input
                type="text"
                value={vendor.POCcontact}
                name="POCcontact"
                style={{ width: "70%" }}
                {...register("POCcontact", { required: true })}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    POCcontact: e.target.value,
                  })
                }
              />
            </div>
            {errors.POCcontact && (
              <p style={{ marginLeft: "140px" }}>POC contact is required</p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }}>Referred by</label>
              <input
                type="text"
                value={vendor.referredBy}
                style={{ width: "70%" }}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    referredBy: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Upadte Vendor Details"
        visible={visible2}
        onOk={updateVendor}
        onCancel={handleCancel}
      >
        <div style={{ width: "100% !important" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }} className="important-mark">
                Business Name
              </label>
              <input
                type="text"
                name="businessNameUpdate"
                value={vendor.businessName}
                style={{ width: "70%" }}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    businessName: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }} className="important-mark">
                Business Field{" "}
              </label>
              <input
                type="text"
                name="businessNameUpdate"
                value={vendor.business_field}
                style={{ width: "70%" }}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    business_field: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }} className="important-mark">
                Email
              </label>
              <input
                type="text"
                value={vendor.email}
                style={{ width: "70%" }}
                name="emailUpdate"
                onChange={(e) =>
                  setVendor({ ...vendor, email: e.target.value })
                }
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }} className="important-mark">
                Pan Card
              </label>
              <input
                type="text"
                value={vendor.panCard}
                name="pancardUpdate"
                style={{ width: "70%" }}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    panCard: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label style={{ width: "25%" }} className="important-mark">
                GST
              </label>
              <input
                type="text"
                value={vendor.GSTnumber}
                name="gstUpdate"
                style={{ width: "70%" }}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    GSTnumber: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </Modal>
      <Table
        columns={columns}
        dataSource={vendors}
        pagination={{ pageSize: 6 }}
      />
    </div>
  );
};

export default Vendor;
