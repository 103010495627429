import React, {Component} from 'react'
import {
  Spin,
  Card,
  Row,
  Col,
  Modal,
  Typography,
  Menu,
  Button,
  Icon,
  Layout,
  Table,
  Tag,
  Divider
} from 'antd';
import {
  G2,
  Chart, 
  Geom,
  Axis,
  Tooltip, 
} from "bizcharts";
import _ from "lodash";
import './SummaryCapitaLand.less';
import AqProgressBar from './AqProgressBar';
import AqiTableCapitaLand from './AqiTableCapitaLand';
import Indoor1 from '../../../src/img/assets/summaryInDashboard/Indoor1.png';
import { COLOR, GET_CLR_POLLUTANT_LVL } from './colorsCapitaLand';
import {getAQ} from "../../api";
import PmChartCapitaLand from "./PmChartCapitaLand";
import CHECK_DEVICE_CONFIG from '../../utils/checkDeviceConfig'; // Change it to direct call to function
import { CreateAqData } from '../../utils/createAqData';
import {GetDateRangesTxt, GetDateRangeArrForMenuIndex} from '../../utils/dateRange';
import { GET_P_UNIT_HTML, GET_P_LVL_BASE_PERCENT_VALUE } from './pLevelsCapitaLand';

const aqPollutants = ['PM2.5','PM10','CO2','VOC'];
const dateRanges = GetDateRangesTxt();

const {Header} = Layout;

let chartIns = null;
const scale1 = {
  name: {
    min: 0,
  },
  insData: {
    min: 0,
    alias: 'Inside PM2.5 (ug/m3)'
  },
  outData: {
    min: 0,
    alias: 'Outside PM2.5 (ug/m3)'
  }
};const scale2 = {
  name: {
    min: 0
  },
  insData: {
    min: 0,
    alias: 'PM 10 (ug/m3)'
  },
  outData: {
    min: 0,
    alias: 'PM10 (ug/m3)'
  }
};const scale3 = {
  name: {
    min: 0
  },
  insData: {
    min: 0,
    alias: 'VOC (ppb)'
  },
  outData: {
    min: 0,
    alias: 'VOC (ppb)'
  }
};const scale4 = {
  name: {
    min: 0
  },
  insData: {
    min: 0,
    alias: 'CO2 (ppm)'
  },
  outData: {
    min: 0,
    alias: 'CO2 (ppm)'
  }
};


const columnsForTable = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 200,
    fixed: 'left',
    render: text => <a>{text}</a>,
  },
  {
    title: 'Inside PM 2.5',
    dataIndex: 'inspm25',
    key: 'inspm25',
  },
  {
    title: 'Outside PM 2.5',
    dataIndex: 'outpm25',
    key: 'outpm25',
  },
  {
    title: 'Inside PM 10',
    dataIndex: 'inspm10',
    key: 'inspm10',
  },
  {
    title: 'Outside PM 10',
    dataIndex: 'outpm10',
    key: 'outpm10',
  },
  {
    title: 'Inside CO2',
    dataIndex: 'insco2',
    key: 'insco2',
  },
  {
    title: 'Outside CO2',
    dataIndex: 'outco2',
    key: 'outco2',
  },
  {
    title: 'Inside VOC',
    dataIndex: 'insvoc',
    key: 'insvoc',
  },
  {
    title: 'Outside VOC',
    dataIndex: 'outvoc',
    key: 'outvoc',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    width: 100,
    fixed: 'right',
    render: tags => (
      <span>
        {tags.map(tag => {
          let color= 'yellow';
          if (tag === "Excellent") {
            color = '#00940F';
          }
          else if(tag=== "Good"){
            color = '#62C94B';
          }
          else {
            color = '#FF0000';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    ),
  }
];


export default class SummaryCapitaLand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      selectedCity: this.props.selectedCity,
      data: this.props.propDataForSummary,
      uv_device_data: this.props.propDataForSummary.uv_device_data,
      dataIsLoaded: false,
      commentModalVisible: false,
      selectedDateRangeIndex: 1,
      outsideDataShow: this.props.outsideDataShow,
      visible: false,
      aqiRange: this.props.aqiRange
    };
  }



  async fetchDataAndSetState(id, selectedCity, propDataForSummary) {
    // console.log('Summary.propDataForSummary: ',propDataForSummary);
    try {
      const dateRangeArr = GetDateRangeArrForMenuIndex(this.state.selectedDateRangeIndex);
      
      const dataforpm25 = [];
      const dataforpm10=[];
      const dataforvoc=[];
      const dataforco2=[];
      const tableData=[];
      for(let i=0;i<propDataForSummary.SpaceData.length;i++){
        var tempdatapm25={};
        var tempdatapm10={};
        var tempdatavoc={};
        var tempdataco2={};
        tempdatapm25['name'] = propDataForSummary.SpaceData[i].name;
        tempdatapm25['insData']=parseInt(propDataForSummary.SpaceData[i].spaceinfo.inside.pm25,10);
        tempdatapm25['outData']= propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                  ? parseInt(propDataForSummary.SpaceData[i].spaceinfo.outside.pm25,10)
                                  : 'NA';

        tempdatapm10['name'] = propDataForSummary.SpaceData[i].name;
        tempdatapm10['insData']=parseInt(propDataForSummary.SpaceData[i].spaceinfo.inside.pm10,10);
        tempdatapm10['outData']=propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                ? parseInt(propDataForSummary.SpaceData[i].spaceinfo.outside.pm10,10)
                                : 'NA';

        tempdatavoc['name'] = propDataForSummary.SpaceData[i].name;
        if(propDataForSummary.SpaceData[i].spaceinfo.inside.voc!=undefined){
          tempdatavoc['insData']=parseInt(propDataForSummary.SpaceData[i].spaceinfo.inside.voc,10);
        } else{
          tempdatavoc['insData']="NA";
        }
        tempdatavoc['outData']=propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                ? propDataForSummary.SpaceData[i].spaceinfo.outside.voc!=undefined
                                  ? parseInt(propDataForSummary.SpaceData[i].spaceinfo.outside.voc,10)
                                  : 'NA'
                                : 'NA';

        tempdataco2['name'] = propDataForSummary.SpaceData[i].name;
        if(propDataForSummary.SpaceData[i].spaceinfo.inside.co2!=undefined){
          tempdataco2['insData']=parseInt(propDataForSummary.SpaceData[i].spaceinfo.inside.co2,10);
        } else{
          tempdataco2['insData']="NA";
        }
        tempdataco2['outData']=propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                ? propDataForSummary.SpaceData[i].spaceinfo.outside.co2!=undefined
                                  ? parseInt(propDataForSummary.SpaceData[i].spaceinfo.outside.co2,10)
                                  : 'NA'
                                : 'NA';

        
        dataforpm25.push(tempdatapm25);
        dataforpm10.push(tempdatapm10);
        dataforvoc.push(tempdatavoc);
        dataforco2.push(tempdataco2);
      }
      var insAq=propDataForSummary.avgIns;
      insAq['pData'] = [insAq.pm2_5,insAq.pm10,insAq.co2,insAq.voc];
      var outAq=propDataForSummary.avgOut;
      outAq['pData'] = [outAq.pm2_5,outAq.pm10,outAq.co2,outAq.voc];
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const currentDate = (new Date()).toLocaleDateString('en-US',options);
      for(let i=0;i<propDataForSummary.SpaceData.length;i++){
        var tableobj = {};
        var tagArr=[];
        tableobj['key']=i+1;
        tableobj['name']= propDataForSummary.SpaceData[i].name;
        tableobj['inspm25']= parseInt(propDataForSummary.SpaceData[i].spaceinfo.inside.pm25,10);
        tableobj['inspm10']= parseInt(propDataForSummary.SpaceData[i].spaceinfo.inside.pm10,10);


        tableobj['outpm25']= propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                  ? parseInt(propDataForSummary.SpaceData[i].spaceinfo.outside.pm25,10)
                                  : 'NA';

        tableobj['outpm10']= propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                  ? parseInt(propDataForSummary.SpaceData[i].spaceinfo.outside.pm10,10)
                                  : 'NA';

        if(propDataForSummary.SpaceData[i].spaceinfo.inside.voc!=undefined){
          tableobj['insvoc']=parseInt(propDataForSummary.SpaceData[i].spaceinfo.inside.voc,10);
        } else{
          tableobj['insvoc']="NA";
        }
        tableobj['outvoc']=propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                ? propDataForSummary.SpaceData[i].spaceinfo.outside.voc!=undefined
                                  ? parseInt(propDataForSummary.SpaceData[i].spaceinfo.outside.voc,10)
                                  : 'NA'
                                : 'NA';

        if(propDataForSummary.SpaceData[i].spaceinfo.inside.co2!=undefined){
          tableobj['insco2']=parseInt(propDataForSummary.SpaceData[i].spaceinfo.inside.co2,10);
        } else{
          tableobj['insco2']="NA";
        }
        tableobj['outco2']=propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                ? propDataForSummary.SpaceData[i].spaceinfo.outside.co2!=undefined
                                  ? parseInt(propDataForSummary.SpaceData[i].spaceinfo.outside.co2,10)
                                  : 'NA'
                                : 'NA';

        var tagVar = tableobj['inspm25']<=25 
                      ? 'Excellent'
                        : tableobj['inspm25']>35
                          ? 'Poor'
                            : tableobj['inspm25']>25 && tableobj['inspm25']<=35
                              ? 'Good'
                                :'NA';
        tagArr.push(tagVar);
        tableobj['tags'] = tagArr;
        tableData.push(tableobj);
      }
      let sla_flag = false;
        // id === '3b0c8b10-8dac-4466-a728-0937239baefe'
        //   ? sla_flag = true     
        //     : id === 'a2b4c6e4-e9f0-4f3e-82cd-d4d1bf47387c'
        //       ? sla_flag = true
        //         : id === 'a5ac3d08-851e-4c3e-85c1-63be20054d21'
        //           ? sla_flag = true
        //             : id === '527c32d4-140f-4501-a9ef-1cdd284d5bcf';

      const selectedAqTrendIndex = 0;
      this.setState({
        id,
        selectedCity,
        data: propDataForSummary,
        dataIsLoaded: true,
        commentModalVisible: false,
        selectedAqTrendIndex,
        dateRangeArr,
        insAq,
        outAq,
        dataforpm25,
        dataforpm10,
        dataforvoc,
        dataforco2,
        currentDate: currentDate,
        visible: false,
        tableData,
        sla_flag
      });
    } catch (err) {
      console.log(err);
    }
  }

  async componentDidMount() {
    // console.log('summary: ',this.state.data);
    await this.fetchDataAndSetState(
      this.state.id,
      this.state.selectedCity,
      this.state.data
    );


  };

  async componentDidUpdate(prevProps, prevState) {
    if(prevProps.selectedCity !== this.props.selectedCity) {
      this.setState({
        dataIsLoaded: false
      });
      try {
        const dateRangeArr = GetDateRangeArrForMenuIndex(this.state.selectedDateRangeIndex);
        
        const insAq=this.props.propDataForSummary.avgIns;
        insAq['pData'] = [insAq.pm2_5,insAq.pm10,insAq.co2,insAq.voc];
        const outAq=this.props.propDataForSummary.avgOut;
        outAq['pData'] = [outAq.pm2_5,outAq.pm10,outAq.co2,outAq.voc];
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const currentDate = (new Date()).toLocaleDateString('en-US',options);
        const selectedAqTrendIndex = 0;
        
        const dataforpm25 = [];
        const dataforpm10=[];
        const dataforvoc=[];
        const dataforco2=[];
        const tableData=[];
        for(let i=0;i<this.props.propDataForSummary.SpaceData.length;i++){
          var tempdatapm25={};
          var tempdatapm10={};
          var tempdatavoc={};
          var tempdataco2={};
          tempdatapm25['name'] = this.props.propDataForSummary.SpaceData[i].name;
          tempdatapm25['insData']=parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.inside.pm25,10);
          tempdatapm25['outData']= this.props.propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                    ? parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.outside.pm25,10)
                                    : 'NA';
  
          tempdatapm10['name'] = this.props.propDataForSummary.SpaceData[i].name;
          tempdatapm10['insData']=parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.inside.pm10,10);
          tempdatapm10['outData']=this.props.propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                  ? parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.outside.pm10,10)
                                  : 'NA';
  
          tempdatavoc['name'] = this.props.propDataForSummary.SpaceData[i].name;
          if(this.props.propDataForSummary.SpaceData[i].spaceinfo.inside.voc!=undefined){
            tempdatavoc['insData']=parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.inside.voc,10);
          } else{
            tempdatavoc['insData']="NA";
          }
          tempdatavoc['outData']=this.props.propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                  ? this.props.propDataForSummary.SpaceData[i].spaceinfo.outside.voc!=undefined
                                    ? parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.outside.voc,10)
                                    : 'NA'
                                  : 'NA';
  
          tempdataco2['name'] = this.props.propDataForSummary.SpaceData[i].name;
          if(this.props.propDataForSummary.SpaceData[i].spaceinfo.inside.co2!=undefined){
            tempdataco2['insData']=parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.inside.co2,10);
          } else{
            tempdataco2['insData']="NA";
          }
          tempdataco2['outData']=this.props.propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                  ? this.props.propDataForSummary.SpaceData[i].spaceinfo.outside.co2!=undefined
                                    ? parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.outside.co2,10)
                                    : 'NA'
                                  : 'NA';
  
          
          dataforpm25.push(tempdatapm25);
          dataforpm10.push(tempdatapm10);
          dataforvoc.push(tempdatavoc);
          dataforco2.push(tempdataco2);
        }
        for(let i=0;i<this.props.propDataForSummary.SpaceData.length;i++){
          var tableobj = {};
          var tagArr=[];
          tableobj['key']=i+1;
          tableobj['name']= this.props.propDataForSummary.SpaceData[i].name;
          tableobj['inspm25']= parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.inside.pm25,10);
          tableobj['inspm10']= parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.inside.pm10,10);
  
  
          tableobj['outpm25']= this.props.propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                    ? parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.outside.pm25,10)
                                    : 'NA';
   
          tableobj['outpm10']= this.props.propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                    ? parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.outside.pm10,10)
                                    : 'NA';
  
          if(this.props.propDataForSummary.SpaceData[i].spaceinfo.inside.voc!=undefined){
            tableobj['insvoc']=parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.inside.voc,10);
          } else{
            tableobj['insvoc']="NA";
          }
          tableobj['outvoc']=this.props.propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                  ? this.props.propDataForSummary.SpaceData[i].spaceinfo.outside.voc!=undefined
                                    ? parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.outside.voc,10)
                                    : 'NA'
                                  : 'NA';
  
          if(this.props.propDataForSummary.SpaceData[i].spaceinfo.inside.co2!=undefined){
            tableobj['insco2']=parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.inside.co2,10);
          } else{
            tableobj['insco2']="NA";
          }
          tableobj['outco2']=this.props.propDataForSummary.SpaceData[i].spaceinfo.outside!=undefined 
                                  ? this.props.propDataForSummary.SpaceData[i].spaceinfo.outside.co2!=undefined
                                    ? parseInt(this.props.propDataForSummary.SpaceData[i].spaceinfo.outside.co2,10)
                                    : 'NA'
                                  : 'NA';
  
          var tagVar = tableobj['inspm25']<=25 
                        ? 'Excellent'
                          : tableobj['inspm25']>35
                            ? 'Poor'
                              : tableobj['inspm25']>25 && tableobj['inspm25']<=35
                                ? 'Good'
                                  :'NA';
          tagArr.push(tagVar);
          tableobj['tags'] = tagArr;
          tableData.push(tableobj);
        }
        // console.log('Updated prop: ', this.props.propDataForSummary);
        this.setState({
          id:this.props.id,
          selectedCity:this.props.selectedCity,
          data: this.props.propDataForSummary,
          dataIsLoaded: true,
          commentModalVisible: false,
          selectedAqTrendIndex,
          dateRangeArr,
          insAq,
          outAq,
          dataforpm25,
          dataforpm10,
          dataforvoc,
          dataforco2,
          tableData,
          currentDate: currentDate,
          visible: false
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  handleAqTrendDropdownClick = (e) => {
    let keyIndex = parseInt(e.key.toString()
                                  .substr(('aqTrendDropdownItem').length));
    this.setState({
      selectedAqTrendIndex: keyIndex,
    });
  };

  handleDateRangeDropdownClick = (e) => {
    // console.log('e of handleClick: ',e.key.toString().substr(('dateRangeDropdownItem').length));
    let keyIndex = parseInt(e.key.toString()
                                  .substr(('dateRangeDropdownItem').length));
    this.setState(
      {
        selectedDateRangeIndex: keyIndex,
        dataIsLoaded: false
      },
      () => {this.fetchDataAndSetState(this.state.id,
                              this.state.selectedCity,
                              this.state.data)}
    );
  };

  showCommentModal = () => { this.setState({commentModalVisible: true}); };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCommentModalOk = (e) => { this.setState({commentModalVisible: false}); }

  handleOk = e => {
    // console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    if (!this.state.id) {
      return (
        <div className='summaryContainer'>
          <Spin size='large'/>
        </div>
      );
    }

    if (!this.state.data) {
      return <div className='summaryContainer'><Spin size='large'/></div>;
    }
    if(!this.state.dataIsLoaded){
      return <div className='summaryContainer'><Spin size='large'/></div>;
    }
    const {dataIsLoaded,data,sinceDay,opHours,nonOpDays,
      insAq,outAq,dataforpm25,dataforpm10,dataforvoc,dataforco2,tableData,
      aqTrendData,avgAq,dateRangeArr,currentDate,outsideDataShow, uv_device_data, aqiRange, sla_flag} = this.state;
    let {selectedAqTrendIndex,selectedDateRangeIndex} = this.state;    
    
    const insCardSpan = outsideDataShow
                        ? {
                            xs: 24, sm: 24, md: 24,
                            lg: 10, xl: 10, xxl: 10
                          }
                        : {
                          xs: 24, sm: 24, md: 24,
                          lg: 14, xl: 14, xxl: 14
                        };
    const outCardSpan = outsideDataShow
                        ? {
                            xs: 24, sm: 24, md: 24,
                            lg: 7, xl: 7, xxl: 7
                          }
                        : {
                            xs: 0, sm: 0, md: 0,
                            lg: 0, xl: 0, xxl: 0
                        };
    const aqiTableCardSpan = outsideDataShow
                              ? {
                                  xs: 24, sm: 24, md: 24,
                                  lg: 7, xl: 7, xxl: 7
                                }
                              : {
                                  xs: 24, sm: 24, md: 24,
                                  lg: 10, xl: 10, xxl: 10
                              };

    const WinWidth = window.innerWidth;
    const awardCardConfig = WinWidth <= 1280
                            ? {
                                minHeight: 300,
                                headerFontSize: 14,
                                bodyFontSize: 14,
                                rowHeightAward: 80
                              }
                            : WinWidth <= 1366
                              ? {
                                  minHeight: 300,
                                  headerFontSize: 15,
                                  bodyFontSize: 15,
                                  rowHeightAward: 90
                                }
                              : {
                                  minHeight: 300,
                                  headerFontSize: 16,
                                  bodyFontSize: 16,
                                  rowHeightAward: 100
                                };
    const insCardConfig = WinWidth <= 1366
                            ? {
                                rowHeightGoodAq: 100,
                                span: insCardSpan
                              }
                            : {
                                rowHeightGoodAq: 105,
                                span: insCardSpan
                            };
    const outCardConfig = { span: outCardSpan };
    const aqiTableCardConfig = { span: aqiTableCardSpan };
    const aqTrendsCardConfig = WinWidth <= 1280
                                ? {
                                    chartMarginBottom: '-2%',
                                    chartMarginTop: '-0.5%'
                                  }
                                : WinWidth <= 1366
                                  ? {
                                      chartMarginBottom: '-1%',
                                      chartMarginTop: '-1%'
                                    }
                                  : WinWidth <= 1440
                                    ? {
                                        chartMarginBottom: '-1%',
                                        chartMarginTop: '-1%'
                                      }
                                    : {
                                        chartMarginBottom: '-1%',
                                        chartMarginTop: '-1%'
                                    };

    const outRtAqItems = outAq
                          ? aqPollutants.map((item, i) => {
                              let p, v;
                              var select_range = _.filter(aqiRange, x => x.label === item);
                              if(outAq && outAq.pData[i]) {
                                v = outAq.pData[i];
                                
                                return (
                                  <AqProgressBar key={'aqOutProgressBar'+i}
                                    label={item} data={[{value: v}]} range={select_range}/>
                                );
                              }
                            })
                          : null;

    
    const insRtAqItems = aqPollutants.map ((item,i) => {
      let p, v;
      var select_range = _.filter(aqiRange, x => x.label === item);
      if(insAq && insAq.pData[i]) {
          v = insAq.pData[i];
      }
      return (
        <AqProgressBar key={'aqInsProgressBar'+i}
          label={item} data={[{value: v}]} range={select_range}/>
      );
    });
    const rtAqItemsOutside = outAq && outsideDataShow
                              ? aqPollutants.map ((item, i) => {
                                  let p, v;
                                  if(outAq && outAq.pData[i]) {
                                    v = outAq.pData[i];
                                  }
                                  return (
                                      <Col xs={12} sm={12} md={12} lg={6}
                                                className="rtAqOutside" 
                                                span={4}>
                                                <Col className="rtAqOutsideContainer" justify="center" align="middle"
                                                      style={{backgroundColor: GET_CLR_POLLUTANT_LVL(v, item)}}>
                                                    {v}
                                                </Col>
                                      </Col>
                                  );
                                })
                              : null;

    const commentModalFooter = [(
      <Button key="submit" type="primary" onClick={this.handleCommentModalOk}>
        OK
      </Button>
    )];
    const CommentModal = () => (
      <Modal
        title="Possible reasons for not seeing real time data"
        visible={this.state.commentModalVisible} footer={commentModalFooter}
        onOk={this.handleCommentModalOk} closable={false}>
        <div>
          <ul>
            <li>Air quality monitor not installed.</li>
            <li>No internet connection.</li>
            <li>Air quality monitor damaged.</li>
          </ul>
          <h4>Note:</h4>
          <span>Please check whether the above causes exist at your side.</span>
          <span>If the issue persists even after doing that, kindly inform clairco support team.</span>
          <br/>
          <span>Incase of any enquiry please reach out to:
            <br/>Udayan Banerjee
            <br/>udayan@clairco.in
            <br/>+919898036463
          </span>
        </div>
      </Modal>
    );

    const modalFooter = [(
      <Button key="submit" type="primary" onClick={this.handleOk}>
        OK
      </Button>
    )];
    const TableModal = () => (
      <Modal
        title= "Overall Space Pollutant Table"
        visible={this.state.visible} 
        onOk={this.handleOk} width={800} closable={false} footer={modalFooter}>
        <Table columns={columnsForTable} dataSource={tableData} scroll={{ x: 1300 }} />
        </Modal>

    );
    const CommentMark = () => (
      <Button
        type="danger" onClick={this.showCommentModal}
        ghost size="small" icon="question" shape="circle"
        style={{ height: 18, fontSize: 12, width: 18 }}
      />
    );
    const rtCardExtra = (time) => {
      return (
        <span className='extraCardHeader'>
          Updated every 2 minutes
        </span>
      );
    };

    const cardsTitleFontSize = 14;
    const cardsTitleFontWeight = 500;
    const uv_card = uv_device_data
          ?(
          <Card 
            className='header_block_card' style={{width: '100%', marginTop: 0, marginBottom: 0}}>
            <Row gutter={[16, 16]}>
              <Col className={
                uv_device_data.status==0
                ?"block_card_icon fail"
                :"block_card_icon success"
              } span={12}>
                {uv_device_data.status==0
                  ?<Icon type="warning"/>
                  :<Icon type="check"/>}
              </Col>
              <Col span={12}>
                <div className='block_card_info_header'>UV dosage</div>
                <div className='card_info'>{uv_device_data.status==0?'NA':uv_device_data.energy}</div>
                <div className="info_units">uj/cm<sup>2</sup></div>
              </Col>
            </Row>
            <Divider className='custom_divider'/>
            <Row>
              <span><Icon type="history" style={{marginRight: '10px'}}/>Refreshed 5 min ago</span>
            </Row>
          </Card>)
          :null;

    const insRealtimeAqCard = (
      <Card style={{height: '100%', marginBottom: '-20px' }} bordered={false}
        title={<span
            style={{ fontSize: cardsTitleFontSize, fontWeight: cardsTitleFontWeight }}>
            Average Indoor AQ
          </span>}>
        <Row>
          <Col span={15}>
            {insRtAqItems}
          </Col>
          <Col span={9}>
            <Row type='flex' justify='center' align='middle'>
              <Col>
                {this.state.id=='d89cc76c-04a2-4563-a4ee-9403375f8c77' || 
                 this.state.id == '282c7c97-72a3-4e0d-9742-f87552334332'
                  ?uv_card
                  :<img height={insCardConfig.rowHeightGoodAq}
                  style={{ paddingLeft: 25 }}
                  src={Indoor1}/>}
              </Col>
            </Row>
          </Col>
          </Row>
          <br/>
          <Row type='flex' justify='center' align='middle'>
          <Button type="primary" onClick={this.showModal} size="small">
            All Space Data
          </Button>
          </Row>
      </Card>
    );

    const outRealtimeAqCard = (
      <Card style={{height: '100%', marginBottom: '-20px' }} bordered={false}
        title={<span
            style={{ fontSize: cardsTitleFontSize, fontWeight: cardsTitleFontWeight }}>
            Average Outdoor AQ
          </span>}>
        { outAq
          ? outRtAqItems
          : (
              <Row type='flex' justify='center' align='middle'>
                <Col>
                  <Typography.Text style={{ fontSize: 14 }}>
                    Outdoor device is yet to be assigned.
                    In case of delays to scheduled date of adding this device,
                    please contact Clairco. Thank you.
                  </Typography.Text>
                </Col>
              </Row>
            )
        }
      </Card>
    );

    const aqiTableCard = (
      <Card title={<span
            style={{ fontSize: cardsTitleFontSize, fontWeight: 500 }}>
            Indoor AQ Guidelines
          </span>}
        style={{height: '100%', marginBottom: '-20px' }}
        bordered={false}>
        <AqiTableCapitaLand aqiRange={aqiRange} sla_flag={sla_flag}/>
      </Card>
    );

    const dateRangeDropdownItems = dateRanges.map((item,i) => {
      return (
      <Menu.Item key={'dateRangeDropdownItem'+i}>
        {item}
      </Menu.Item>
    )});

    const dateRangeDropdownMenu = () => {
      return (
        <Menu onClick={(e) => this.handleDateRangeDropdownClick(e)}>
          {dateRangeDropdownItems}
        </Menu>
      );
    };

    const aqTrendsCard = (
      <Card style={{height: '100%'}} bordered={false}
        title={ <span style={{ fontSize: cardsTitleFontSize,
                  fontWeight: cardsTitleFontWeight}}
                >
                  Average Pollutant wise Space Split  
                </span>}
      >
        <div>
          <div style={{ display: 'flex',
            marginBottom: aqTrendsCardConfig.chartMarginBottom,
            marginTop: aqTrendsCardConfig.chartMarginTop }}>
            <div style={{ width: '50%' ,height: '100px'}}>
              {(dataforpm25 !== undefined)
              ? 
              
        <Chart
        data={dataforpm25}
        height={300}
        scale={scale1}
        forceFit
      >
        <Axis
          name="name"
          label={{
          textStyle: {
            rotate: 45
          }}}
        />
        <Axis
          name="insData"
          label="PM 2.5"
          title={{
            offset: 50
          }}
        />
        <Geom
          type="interval"
          position="name*insData"
          color={["insData",
                   function(insData){
                       if(insData <= aqiRange[0].min )
                          return ["#00940F"];
                        else if(insData > aqiRange[0].max)
                          return ["#FF0000"];
                        else
                          return ["#62C94B"];
                   }   
                      ]}
        />
        <Tooltip
          showTitle={true}
          crosshairs={{
            type: 'cross'
          }}
        />
        </Chart>
              : <div/>}
            </div>
            <div style={{ width: '50%' }}>
            {(dataforpm10 !== undefined)
            ? <Chart
            data={dataforpm10}
            height={300}
            scale={scale2}
            forceFit
          >
          <Axis
            name="name"
            label={{
            textStyle: {
              rotate: 45
            }}}
          />
          <Axis
            name="insData"
            label="PM 10"
            title={{
              offset: 50
            }}
          />
            <Geom
              type="interval"
              position="name*insData"
              color={["insData",
                       function(insData){
                           if(insData <= aqiRange[1].min )
                              return ["#00940F"];
                            else if(insData > aqiRange[1].max)
                              return ["#FF0000"];
                            else
                              return ["#62C94B"];
                       }   
                          ]}
            />
            <Tooltip
              showTitle={true}
              crosshairs={{
                type: 'cross'
              }}
            />
          </Chart>
            : <div/>}
          </div>
          </div>
          <br/>
          <br/>
          <div style={{ display: 'flex',
            marginBottom: aqTrendsCardConfig.chartMarginBottom,
            marginTop: aqTrendsCardConfig.chartMarginTop }}>
            <div style={{ width: '50%' }}>
              {(dataforvoc !== undefined)
              ? 
              <Chart
        data={dataforvoc}
        height={300}
        scale={scale3}
        forceFit
      >
      <Axis
        name="name"
        label={{
        textStyle: {
          rotate: 45
        }}}
      />
      <Axis
        name="insData"
        label="VOC"
        title={{
          offset: 50
        }}
      />
        <Geom
          type="interval"
          position="name*insData"
          color={["insData",
                   function(insData){
                       if(insData <= aqiRange[3].min )
                          return ["#00940F"];
                        else if(insData > aqiRange[3].max)
                          return ["#FF0000"];
                        else
                          return ["#62C94B"];
                   }   
                      ]}
        />
        <Tooltip />
      </Chart>
              : <div/>}
            </div>
            <div style={{ width: '50%' }}>
              {(dataforco2 !== undefined)
              ? <Chart
              data={dataforco2}
              height={300}
              scale={scale4}
              forceFit
            >
            <Axis
              name="name"
              label={{
              textStyle: {
                rotate: 45
              }}}
            />
            <Axis
              name="insData"
              label="CO2"
              title={{
                offset: 50
              }}
            />
              <Geom
                type="interval"
                position="name*insData"
                color={["insData",
                         function(insData){
                             if(insData <= aqiRange[2].min )
                                return ["#00940F"];
                              else if(insData > aqiRange[2].max)
                                return ["#FF0000"];
                              else
                                return ["#62C94B"];
                         }   
                            ]}
              />
              <Tooltip />
            </Chart>
              : <div/>}
            </div>
          </div>
        </div>
      </Card>
    );

    return (
      !dataIsLoaded
        ? <div className='summaryLoaderContainer'><Spin size='large'/></div>
        : <div style={{ minHeight: 600 }}
            // className='summaryContainer'
          >
            <Header className='summaryHeaderContainer'>
              <div className='summaryHeader'>
                <b>{'Summary : '}</b>{currentDate}
              </div>
              <div className='summaryDate'/>
            </Header>
            <Row gutter={[8, 16]} type='flex'>
              <Col xs={outCardConfig.span.xs} sm={outCardConfig.span.sm}
                md={outCardConfig.span.md} lg={outCardConfig.span.lg}
                xl={outCardConfig.span.xl} xxl={outCardConfig.span.xxl}>
                {outRealtimeAqCard}
              </Col>
              <Col xs={insCardConfig.span.xs} sm={insCardConfig.span.sm}
                md={insCardConfig.span.md} lg={insCardConfig.span.lg}
                xl={insCardConfig.span.xl} xxl={insCardConfig.span.xxl}>
                {insRealtimeAqCard}
              </Col>
              <Col xs={aqiTableCardConfig.span.xs} sm={aqiTableCardConfig.span.sm}
                md={aqiTableCardConfig.span.md} lg={aqiTableCardConfig.span.lg}
                xl={aqiTableCardConfig.span.xl} xxl={aqiTableCardConfig.span.xxl}>
                {aqiTableCard}
              </Col>
            </Row>
            <br/>
            <br/>
            <Row gutter={[8, 16]} type='flex'>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                {aqTrendsCard}
              </Col>          
            </Row>
            < CommentModal />
            < TableModal />
          </div>
    )
  }
}