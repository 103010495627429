import React, { Component } from "react";
import GoogleMap from "google-map-react";
import { AcUnit, Opacity } from "@material-ui/icons";
import { Chart, Area, Line, Axis, Geom, Coordinate } from "bizcharts";
import {
  Layout,
  Menu,
  Badge,
  Icon,
  Modal,
  DatePicker,
  Alert,
  Button,
  Tooltip,
  Steps,
  Row,
  Col,
  Dropdown,
} from "antd";
import {
  DownloadOutlined,
  WindowsOutlined,
  ExportOutlined,
  EditOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "./DashboardV2.less";
import { getAqExcelFile, getPurifierExcelReport } from "../../api";
import {
  removeToken,
  getCsrId,
  removeCsrId,
  getUsername,
  getSupervisorData,
  getUserRole,
  removeCache,
  getAdminToken,
} from "../../helper";
import moment from "moment";
import AqProgressBar from "../../components/dashboardContentCapitaLand/AqProgressBar";
import clairco_logo from "../../img/customerLogos/clairco.png";
// import UvDashboard from "./UvDashboard";
const { Header, Sider, Content, Footer } = Layout;
const SubMenu = Menu.SubMenu;
const { Step } = Steps;
let lastDate = new Date();
lastDate.setDate(lastDate.getDate() - 1);
lastDate = lastDate.toLocaleDateString();
const aqPollutants = ["PM2.5", "PM10", "CO2", "VOC"];

const menu = (
  <Menu>
    <Menu.Item key="1" icon={<UserOutlined />}>
      Device ID 2
    </Menu.Item>
    <Menu.Item key="2" icon={<UserOutlined />}>
      Device ID 3
    </Menu.Item>
    <Menu.Item key="3" icon={<UserOutlined />}>
      Device ID 4
    </Menu.Item>
  </Menu>
);

const data = [
  { year: "1991", value: 15468 },
  { year: "1992", value: 16100 },
  { year: "1993", value: 15900 },
  { year: "1994", value: 17409 },
  { year: "1995", value: 17000 },
  { year: "1996", value: 31056 },
  { year: "1997", value: 31982 },
  { year: "1998", value: 32040 },
  { year: "1999", value: 33233 },
  { year: "2000", value: 15468 },
  { year: "2001", value: 16100 },
  { year: "2002", value: 15900 },
  { year: "2003", value: 17409 },
  { year: "2004", value: 17000 },
  { year: "2005", value: 31056 },
  { year: "2006", value: 31982 },
  { year: "2007", value: 32040 },
  { year: "2008", value: 33233 },
];

const data2 = [
  { year: "1991", value: 12 },
  { year: "1992", value: 13 },
  { year: "1993", value: 11 },
  { year: "1994", value: 20 },
  { year: "1995", value: 30 },
  { year: "1996", value: 23 },
  { year: "1997", value: 25 },
  { year: "1998", value: 32 },
  { year: "1999", value: 33 },
  { year: "2000", value: 15 },
  { year: "2001", value: 16 },
  { year: "2002", value: 15 },
  { year: "2003", value: 17 },
  { year: "2004", value: 17 },
  { year: "2005", value: 31 },
  { year: "2006", value: 31 },
  { year: "2007", value: 32 },
  { year: "2008", value: 33 },
];

const scale = {
  value: {
    min: 10000,
    nice: true,
  },
  year: {
    range: [0, 1],
  },
};

const scale2 = {
  value: {
    min: 10,
    nice: true,
  },
  year: {
    range: [0, 1],
  },
};
export default class DashboardHPE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // id: !isUndefined(this.props.location.state)
      //   ? this.props.location.state.id
      // : getCsrId(),
      loading: false,
      loadingData: true,
      startValue: null,
      endValue: null,
      endOpen: false,
      showAlert: false,
      error: "Please enter a Valid Date",
      show: false,
      fileType: true,
      reportType: true,
      report_flag_disable: true,
      selectedCard: 0,
      collapsed: false,
      visible: false,
      isFlipped: false,
      addUser: false,
      selectedCityIndex: 0,
      filterInfo: false,
      selectedCity: "",
      customerName: "",
    };
  }
  async componentDidMount() {
    try {
      // let { data } = (await getCustomer(this.state.id, true)).data;
    } catch (error) {
      console.log(error);
    }
  }

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  handleLogoutClick = (e) => {
    removeToken();
    removeCsrId();
    removeCache();
    this.props.history.push("/");
  };

  handleChangeClick = (e) => {
    this.props.history.push("/changePassword");
  };

  handleClick = (e) => {
    if (this.state.selectedMenu !== e.keyPath[0]) {
      this.setState({ selectedMenu: e.keyPath[0] });
    }
  };

  handleReferer = (e) => {
    var data = getSupervisorData();
    this.props.history.push("/customer/admin", { data: data });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleDownloadClick = async (e) => {
    const deepData = true;
    const { startValue, endValue, reportType } = this.state;
    if (startValue === null || endValue === null) {
      this.setState({
        showAlert: true,
      });
    } else {
      this.setState({ confirmLoading: true, showAlert: false });
      try {
        if (reportType) {
          await getAqExcelFile(
            this.state.id,
            deepData,
            startValue.utc().format("YYYY-MM-DD"),
            endValue.utc().format("YYYY-MM-DD"),
            this.state.fileType,
            this.state.selectedCity,
            this.state.customerName,
          );
        } else {
          await getPurifierExcelReport(
            this.state.id,
            startValue.utc().format("YYYY-MM-DD"),
            endValue.utc().format("YYYY-MM-DD"),
          );
        }
      } catch (err) {
        console.log(err);
      }
      this.setState({ confirmLoading: false, visible: false });
    }
  };

  handleDropdownButtonClickForCity = (e) => {
    let index = parseInt(e.key);
    if (index !== this.state.selectedCityIndex) {
      for (let i = 0, l = this.state.summaryData.data.length; i < l; i++) {
        if (
          this.state.summaryData.data[i].cityName ===
          this.state.cityNamesList[index]
        ) {
          this.setState({
            selectedCityIndex: index,
            selectedCity: this.state.cityNamesList[index],
            selectedMenu: "summary",
            propDataForSummary: this.state.summaryData.data[i],
          });
          break;
        }
      }
    }
  };
  handleOk = () => {
    this.setState({
      ModalText: "The modal will be closed after two seconds",
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  };
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  disabledStartDate = (current) => {
    let customDate = "2019-11-01";
    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  ontypeChange = (e) => {
    this.setState({
      fileType: e.target.value,
    });
  };
  switchChange = (checked, e) => {
    if (e === "report_type") {
      if (checked) {
        this.setState({
          reportType: true,
        });
      } else {
        this.setState({
          reportType: false,
        });
      }
    } else if (e === "data_type") {
      if (checked) {
        this.setState({
          fileType: true,
        });
      } else {
        this.setState({
          fileType: false,
        });
      }
    }
  };

  kioskDisplay = (e) => {
    this.props.history.push("/kiosk", { refferer: "dashboard" });
  };

  renderMarkers(map, maps) {
    let marker = new maps.Marker({
      position: { lat: 25.2690491, lng: 82.9910372 },
      map,
      title: "Hello World!",
    });
    return marker;
  }

  render() {
    const {
      visible,
      confirmLoading,
      show,
      startValue,
      endValue,
      endOpen,
    } = this.state;

    const insRtAqItems = aqPollutants.map((item, i) => {
      let p, v;
      // var select_range = _.filter(aqiRange, x => x.label === item);
      // if(insAq && insAq.pData[i]) {
      //     v = insAq.pData[i];
      // }
      var select_range = [{ min: 0, max: 40 }];
      return (
        <AqProgressBar
          key={"aqInsProgressBar" + i}
          label={item}
          data={[{ value: 12 }]}
          range={select_range}
        />
      );
    });

    return (
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
          style={{ position: "relative", height: "100vh" }}
          collapsedWidth={0}
        >
          <div className="customerLogoContainer">
            <img src={clairco_logo} className="customerLogo" />
          </div>
          <div className="customer_name_container">BHU</div>
          <Menu
            class="sidermenu"
            style={{ minHeight: "40vh" }}
            theme="dark"
            onClick={this.handleClick}
            mode="inline"
            defaultSelectedKeys={["summary"]}
          >
            <Menu.Item
              style={{ marginTop: "20px" }}
              key="summary"
              title="Dashboard"
            >
              <WindowsOutlined style={{ fontSize: "21px" }} />
              <span style={{ fontFamily: "'Montserrat', sans-serif" }}>
                Dashboard
              </span>
            </Menu.Item>
          </Menu>
          <Tooltip placement="right" text="Download Reports">
            <Button
              className="dashDownload"
              title="Download Reports"
              onClick={this.showModal}
            >
              <DownloadOutlined style={{ fontSize: "21px" }} />
              Download Reports
              {/* nav 3 */}
            </Button>
          </Tooltip>
          <Modal
            title="Download Excel Report"
            visible={visible}
            width={480}
            onOk={this.handleDownloadClick}
            confirmLoading={confirmLoading}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button
                icon="download"
                key="submit"
                type="primary"
                loading={confirmLoading}
                onClick={this.handleDownloadClick}
              >
                Download
              </Button>,
            ]}
          >
            <div>
              {this.state.showAlert && (
                <Alert
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  message={this.state.error}
                  type="error"
                  showIcon
                />
              )}
              <DatePicker
                disabledDate={this.disabledDate}
                // defaultValue={moment(lastDate, "DD-MM-YYYY")}
                format="YYYY-MM-DD"
                value={startValue}
                placeholder="Start Date"
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
              />
              <DatePicker
                className="endDatePicker"
                disabledDate={this.disabledDate}
                format="YYYY-MM-DD"
                value={endValue}
                placeholder="End Date"
                onChange={this.onEndChange}
                open={endOpen}
                onOpenChange={this.handleEndOpenChange}
              />
            </div>
            <div
              style={{
                marginTop: 20,
                marginLeft: 20,
              }}
            ></div>
          </Modal>
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-sub-header-background"
            style={{ padding: 0 }}
          >
            <div className="headerLogo">
              <Icon
                className="trigger"
                type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                onClick={this.toggle}
              />
              {/* Powered by <img src={clairco_logo} className="claircoLogo"/> */}
              <span style={{ fontSize: 25 }}>Welcome Admin</span>
            </div>
            <div
              className="adminMenuHolder"
              style={{ backgroundColor: "#FFF" }}
            >
              <div>
                <Menu mode="horizontal" style={{ backgroundColor: "#FFF" }}>
                  <SubMenu
                    title={
                      <Badge dot={show}>
                        <Icon type="bell" style={{ fontSize: "26px" }} />
                      </Badge>
                    }
                  >
                    <div className="notificationShade">
                      No New Notifications
                    </div>
                  </SubMenu>
                </Menu>
              </div>
              <Menu mode="horizontal" style={{ backgroundColor: "#FFF" }}>
                <SubMenu
                  title={
                    <div>
                      <Icon type="menu" style={{ fontSize: "26px" }} />
                    </div>
                  }
                  style={{ backgroundColor: "#FFF !important" }}
                >
                  <Menu.Item
                    key="setting:1"
                    onClick={this.handleChangeClick}
                    style={{
                      color: "#FFF",
                    }}
                  >
                    <EditOutlined
                      style={{
                        fontSize: "15px",
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    />
                    Change Password
                  </Menu.Item>
                  <Menu.Item
                    style={{
                      color: "#FFF",
                    }}
                    onClick={() => this.handleLogoutClick()}
                  >
                    <ExportOutlined
                      style={{
                        fontSize: "15px",
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    />
                    <span>Logout</span>
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </div>
          </Header>
          <Content
            className="homeContentContainer site-layout-background"
            // style={{
            //   minHeight: 700,
            // }}
          >
            <Row>
              <Dropdown overlay={menu}>
                <Button>
                  Device ID 1 <DownOutlined />
                </Button>
              </Dropdown>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <div className="col_header_custom">Real Time Parameters</div>
                <Row gutter={[8, 16]} className="outdoor_data_main">
                  <Col span={12} className="outdoor_main_row">
                    <Row className="outdoor_main_label">PM 2.5</Row>
                    <Row className="outdoor_main_value">90</Row>
                    <Row className="outdoor_main_description">MODERATE</Row>
                    <Row gutter={[8, 8]}>
                      <Col span={4}></Col>
                      <Col span={8}>
                        <AcUnit /> <span className="inner_card_value">34</span>
                        <sup>o</sup>c
                      </Col>
                      <Col span={8}>
                        <Opacity /> <span className="inner_card_value">56</span>
                        <sup>%</sup>
                      </Col>
                      <Col span={4}></Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={24}>
                        <span className="inner_card_label">Last Updated: </span>
                        <span className="inner_card_timestamp">
                          {" "}
                          5 mins ago
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>{insRtAqItems}</Col>
                </Row>
              </Col>
              <Col span={12}>
                <div className="col_header_custom">Device Location</div>
                <div style={{ height: "40vh", width: "100%" }}>
                  <GoogleMap
                    bootstrapURLKeys={{
                      key: "AIzaSyCz3-IfGt6NuK8tEAyR1yION1SaLATJ6pw",
                    }}
                    defaultZoom={13}
                    defaultCenter={{
                      lat: 25.2690491,
                      lng: 82.9910372,
                    }}
                    onGoogleApiLoaded={({ map, maps }) =>
                      this.renderMarkers(map, maps)
                    }
                  ></GoogleMap>
                </div>
              </Col>
              <Col span={12}>
                <div className="col_header_custom">
                  Noise Levels (LIVE Data)
                </div>
                <Chart
                  height={400}
                  width={800}
                  data={data}
                  scale={scale}
                  autoFit
                >
                  <Axis name="value" label="Value" />
                  <Axis
                    title={{ textStyle: { fontSize: "14" } }}
                    label={("Year", { textStyle: { fontSize: "11" } })}
                    name="year"
                  />
                  <Tooltip shared />
                  <Geom
                    type="point"
                    position="year*value"
                    size={1}
                    shape={"circle"}
                    color={["space", ["#1638D7"]]}
                  >
                    <Geom
                      type="area"
                      position="year*value"
                      size={2}
                      shape={"smooth"}
                      color="#1638D7"
                    />
                  </Geom>
                </Chart>
              </Col>
              <Col span={12}>
                <div className="col_header_custom">UV Radiation</div>
                <Chart
                  height={400}
                  width={800}
                  data={data2}
                  scale={scale2}
                  autoFit
                >
                  <Axis name="value" label="Value" />
                  <Axis
                    title={{ textStyle: { fontSize: "14" } }}
                    label={("Year", { textStyle: { fontSize: "11" } })}
                    name="year"
                  />
                  <Tooltip shared />
                  <Geom
                    type="point"
                    position="year*value"
                    size={1}
                    shape={"circle"}
                    color={["space", ["#1638D7"]]}
                  >
                    <Geom
                      type="area"
                      position="year*value"
                      size={2}
                      shape={"smooth"}
                      color={("value", ["red", "#5225ab"])}
                    />
                  </Geom>
                </Chart>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}
