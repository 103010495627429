import React, {Component} from 'react'
import {Table, Typography, Row, Col, Spin } from 'antd';
import { P_LVL_TXT, PM2_5_LVL, PM10_LVL, CO2_LVL, VOC_LVL } from './pLevelsCapitaLand';
import './ColorInfoCapitaLand.less';
import sla_wework from "../../img/assets/sla_guideline_wework.jpeg";
import _ from "lodash";
const {Column} = Table;
const COL_WIDTHS = {
  category: 7,
  pm2_5: 4,
  pm10: 4,
  co2: 5,
  voc: 4
};
const WinWidth = window.innerWidth;
const ROW_MARGINS_TOP = {
  tableHeader: WinWidth <= 1280 ? -25 : -25,
  row1: WinWidth <= 1280 ? 0 : 0,
  row2: WinWidth <= 1280 ? 0 : 0,
  row3: WinWidth <= 1280 ? 0 : 0,
  row4: WinWidth <= 1280 ? 0 : 0
}

const width = window.innerWidth;
let colWidth, cellFontSize, headerFontSize;
if(width <= 1366) {
  colWidth = {
    'pm2_5': 50,
    'pm10': 50,
    'co2': 60,
    'voc': 55
  };
  headerFontSize = 11;
  cellFontSize = 11;
} else if (width <= 1440) {
  colWidth = {
    'pm2_5': 55,
    'pm10': 55,
    'co2': 62,
    'voc': 62
  };
  headerFontSize = 12;
  cellFontSize = 12;
} else {
  colWidth = {
    'pm2_5': 70,
    'pm10': 70,
    'co2': 75,
    'voc': 75
  };
  headerFontSize = 14;
  cellFontSize = 16;
}
window.colWidth = colWidth;

const tableHeader = (
  <Row gutter={[0, 4]}>
    <Col span={5}></Col>
    <Col span={6} justify="center" align="middle">
      <div>
        <div className='colorInfoExcellentCapitaLand colorInfoCircle'></div>
        <Typography.Text style={{ fontSize: headerFontSize }}>
          Excellent
        </Typography.Text>
      </div>
    </Col>
    <Col span={8} justify="center" align="middle">
      <div>
        <div className='colorInfoGoodCapitaLand colorInfoCircle'></div>
        <Typography.Text style={{ fontSize: headerFontSize }}>
          Good
        </Typography.Text>
      </div>
    </Col>
    <Col span={5} justify="center" align="middle">
      <div>
        <div className='colorInfoPoorCapitaLand colorInfoCircle'></div>
        <Typography.Text style={{ fontSize: headerFontSize }}>
          Poor
        </Typography.Text>
      </div>
    </Col>
  </Row>
);

export default class AqiTableCapitaLand extends Component {
  constructor(props){
    super(props);
    this.state = {
      aqiRange: this.props.aqiRange,
      sla_flag: this.props.sla_flag
    }
  }

  render() {
    let {aqiRange, sla_flag} = this.state;
    if(!aqiRange){
      return <Spin size='small'></Spin>
    }
    const aqLabels = ['PM2.5','PM10', 'CO2', 'VOC'];
    const aqi_rows = aqLabels.map((item, i) => {
      var select_range = _.filter(aqiRange, x => x.label === item);
      var min_value = select_range[0].min;
      var max_value = select_range[0].max;
      return (
        <div style={{marginTop: ROW_MARGINS_TOP.row1}}>
          <Row gutter={[0, 8]}>
            <Col span={5}>
              <Typography.Text style={{ fontSize: cellFontSize }}>
                {item}
              </Typography.Text>
            </Col>
            <Col span={6} justify="center" align="middle">
              <Typography.Text style={{ fontSize: cellFontSize }}>
                {'< '+min_value}
              </Typography.Text>
            </Col>
            <Col span={8} justify="center" align="middle">
              <Typography.Text style={{ fontSize: cellFontSize }}>
                {min_value+' - '+max_value}
              </Typography.Text>
            </Col>
            <Col span={5} justify="center" align="middle">
              <Typography.Text style={{ fontSize: cellFontSize }}>
                {'> '+max_value}
              </Typography.Text>
            </Col>
          </Row>
      </div>
      )
    });
    const aqi_table_data = (aqi_rows);
    return (
      <div>
        <div style={{marginTop: ROW_MARGINS_TOP.tableHeader}}>{tableHeader}</div>
        {sla_flag?
        <img src={sla_wework} width='102%' />:aqi_table_data}
      </div>
    )
  }
}