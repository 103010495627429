import React, { useState, useEffect } from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Select,
  Steps,
  PageHeader,
  Typography,
  Card,
  Table,
  Tooltip,
  message,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  getAllItems,
  uploadPO,
  getAllVendors,
  getAllApprovers,
} from "../../api";
import { isNaN, values } from "lodash";
import moment, { locale } from "moment";
import PurchaseOrder from "./PurchaseOrder";

const MAX_PAGE = 5;
const { TextArea } = Input;
const { Option, OptGroup } = Select;
const { Step } = Steps;
const { Title, Text } = Typography;
const PO = (props) => {
  const { getFieldDecorator } = props.form;
  // const { record, showProjectList, poForStock, handleShowProjectList } = props;
  const [page, setPage] = useState(1);
  const [orderDate, setOrderDate] = useState("");
  const [POID, setPOID] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [vendorListOptions, setVendorListOptions] = useState([]);
  const [approversList, setApproversList] = useState([]);
  const [supplierData, setSupplierData] = useState({
    address: "",
    phone: "",
    name: "",
    email: "",
    website: "",
    gst: "",
    eta: "",
  });
  const [billingAddress, setBillingAddress] = useState({
    address: "",
    phone: "",
    gst: "",
  });
  const [shippingAddress, setShippingAddress] = useState({
    address: "",
    phone: "",
  });
  const [item, setItem] = useState({
    name: "",
    uom: "",
    cpu: "",
    quantity: "",
  });
  const [items, setItems] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [currentpage, setCurrentpage] = useState(0);
  const [terms, setTerms] = useState("");
  const [approvername, setApprovername] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (purchaseOrder.length == 0 && page == 3) {
      return message.warning("Please add items for PO");
    }

    props.form.validateFields(
      [
        "name",
        "email",
        "phone",
        "gst",
        "address",
        "eta",
        "order_date",
        "billing_phone",
        "billing_gst",
        "billing_address",
        "shipping_phone",
        "shipping_address",
        "approverEmail",
      ],
      (err, values) => {
        // console.log(values);
        if (!err) {
          setPage(page + 1);
          setCurrentpage(currentpage + 1);
          if (page <= MAX_PAGE) {
            setOrderDate(values.order_date.format("DD-MM-YYYY"));
          }
          switch (page) {
            case 1:
              setSupplierData({
                email: values.email,
                name: values.name,
                eta: values.eta.format("DD-MM-YYYY"),
                phone: values.phone,
                gst: values.gst,
                address: values.address,
                website: values.website,
              });

              break;
            case 2:
              setBillingAddress({
                phone: values.billing_phone,
                gst: values.billing_gst,
                address: values.billing_address,
              });
              setShippingAddress({
                phone: values.shipping_phone,
                address: values.shipping_address,
              });
              break;
            case 4:
              setApprovername(values.approverEmail);

              break;

            default:
              break;
          }
        }
      },
    );
  };

  const handelePreviousPage = () => {
    setPage(page - 1);
    setCurrentpage(currentpage - 1);

    switch (page) {
      case 1:
        props.form.setFieldsValue({
          email: supplierData.email,
          name: supplierData.name,
          eta: supplierData.eta,
          phone: supplierData.phone,
          gst: supplierData.gst,
          address: supplierData.address,
          website: supplierData.website,
        });
        break;
      case 2:
        props.form.setFieldsValue({
          billing_phone: billingAddress.phone,
          billing_gst: billingAddress.gst,
          billing_address: billingAddress.address,
        });
        props.form.setFieldsValue({
          shipping_phone: shippingAddress.phone,
          shipping_address: shippingAddress.address,
        });

      case 4:
        props.form.setFieldsValue({
          approverEmail: approvername,
          terms,
        });
        break;
      default:
        break;
    }
  };

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const formItemMasterLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Total Price + GST",
      dataIndex: "totalpricewithgst",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (actions, record) => {
        return (
          <div>
            <Tooltip placement="top" title="Delete Order">
              <Icon
                type="delete"
                style={{ color: "red" }}
                onClick={() => {
                  deleteOrder(record.name);
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getVendors();
    getApproversList();
    fetchItems();
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = dd + "" + mm + "" + yyyy + "/" + today.getMilliseconds();
    setPOID(today);
  }, []);

  function setItemFields(value) {
    items.map((item) => {
      if (item.name === value) {
        props.form.setFieldsValue({
          item_name: item.name,
          item_uom: item.purchaseUOM,
          item_cpu: item.cost_per_unit,
          item_quantity: item.quantity,
        });
      }
    });
  }

  const fetchItems = async () => {
    const { data } = await getAllItems();
    let arr = [];
    data.map((item, i) => {
      arr.push({
        value: item.name,
        label: item.name,
      });
    });
    setItemOptions(arr);
    setItems(data);
  };

  const getVendors = async () => {
    try {
      const { data } = await getAllVendors();
      let arr = [];
      data.map((vendor) => {
        arr.push({
          label: vendor.businessName,
          value: vendor.businessName,
          key: vendor.businessName,
        });
      });

      setVendorList(data);
      setVendorListOptions(arr);
    } catch (error) {
      console.log(error);
    }
  };

  // fillVendorDetails() is used to fill the vendor related data while creating purchase order

  const fillVendorDetails = (inp) => {
    const vendor = vendorList.find((vendor) => vendor.businessName === inp);
    props.form.setFieldsValue({
      email: vendor.email,
      name: vendor.name,

      phone: vendor.POCcontact,
      gst: vendor.GSTnumber,
      address: vendor.address,
    });
    setSupplierData({
      name: vendor.businessName,
      email: vendor.email,
      address: vendor.address,
      gst: vendor.GSTnumber,
      phone: vendor.POCcontact,
    });
  };

  const getApproversList = async () => {
    const result = await getAllApprovers();

    let arr = [];

    result.data.Users.map((approver) => {
      arr.push({
        value: approver.email,
        label: approver.first_name + "  " + approver.last_name,
      });
    });
    setApproversList(arr);
  };

  // addPO() is used to make a list of items to be purchased while creating Purchase Order

  const addPO = async () => {
    const {
      item_name,
      item_quantity,
      item_cpu,
      item_uom,
      totalpricewithgst,
      totalprice,
      gst,
    } = props.form.getFieldsValue();
    props.form.validateFields(["item_quantity", "item_list"], (err, values) => {
      if (!err) {
        let arr = [...purchaseOrder];
        const ob = {};
        ob.name = item_name;
        ob.uom = item_uom;
        ob.cpu = Number(item_cpu);
        ob.quantity = isNaN(Number(item_quantity)) ? 0 : Number(item_quantity);
        ob.totalprice = Number(item_cpu * item_quantity);
        ob.totalpricewithgst = isNaN(
          Number(item_cpu * item_quantity) +
            Number((gst / 100) * (item_cpu * item_quantity)),
        )
          ? 0
          : Number(item_cpu * item_quantity) +
            Number((gst / 100) * (item_cpu * item_quantity));
        ob.gst = gst;
        arr.push(ob);
        setPurchaseOrder([...arr]);
        props.form.resetFields([
          "item_name",
          "item_cpu",
          "item_uom",
          "item_quantity",
          "item_list",
        ]);
        //setItemOptions(itemOptions.filter((item) => item.value !== item_name));
      }
      console.log(err);
    });
  };

  // deleteOrder() is used to delete items from purchasing list

  const deleteOrder = (inp) => {
    setPurchaseOrder(purchaseOrder.filter((item) => item.name !== inp));
  };

  const sendPo = async () => {
    const payload = {
      id: props.record.id,
      PO_ID: "#PO" + POID,
      poForStock: props.poForStock,
      purchaseOrder,
      supplierData,
      billingAddress,
      shippingAddress,
      orderDate,
      approvername,
      terms,
      totalpricewithgst: new Intl.NumberFormat("en-IN").format(
        purchaseOrder.reduce((accumulator, item) => {
          return accumulator + item.totalpricewithgst;
        }, 0),
      ),
      totalpricewithoutgst: new Intl.NumberFormat("en-IN").format(
        purchaseOrder.reduce((accumulator, item) => {
          return accumulator + item.totalprice;
        }, 0),
      ),
    };
    // console.log(payload);
    try {
      const result = await uploadPO(payload);
      if (result) {
        props.handleShowProjectList(false);
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.message);
      } else {
        console.log(error.message);
        message.error(error.message);
      }
    }

    setPurchaseOrder([]);
    setPage(1);
    setPOID("");
    setOrderDate("");
    setSupplierData({
      address: "",
      phone: "",
      name: "",
      email: "",
      website: "",
      gst: "",
      eta: "",
      billingAddress: "",
      billingGST: "",
      shippingAddress: "",
    });
    setBillingAddress({
      address: "",
      phone: "",
      gst: "",
    });
    setShippingAddress({
      address: "",
      phone: "",
    });
  };

  return (
    <div>
      <Steps
        size="small"
        current={currentpage}
        style={{ marginBottom: "15px" }}
      >
        <Step title="Vendor" />
        <Step title="Billing & Shipping" />
        <Step title="Items" />
        <Step title="Terms & Conditions" />
        <Step title="Confirm PO" />
      </Steps>

      <Form
        onSubmit={handleSubmit}
        layout="horizontal"
        style={{ marginTop: "50px" }}
      >
        {page < MAX_PAGE && (
          <Row type="flex" justify="space-around">
            <Col lg={10}>
              <Title level={4} style={{ fontWeight: "600" }}>
                <span style={{ opacity: "0.9", marginLeft: "-32px" }}>
                  {" "}
                  <Button
                    onClick={props.showProjectList}
                    style={{
                      marginLeft: "0px",

                      color: "white",
                      // background: "#36454F",
                      background: "none",
                      fontWeight: 600,
                      border: "none",
                    }}
                  >
                    <ArrowLeftOutlined
                      style={{
                        fontSize: "20px",
                        color: "#36454F",
                        fontWeight: 700,
                      }}
                    />
                  </Button>
                  PROJECT :{" "}
                  {props.record.project_name ? props.record.project_name : "NA"}
                </span>
              </Title>
            </Col>
            <Col lg={10}>
              <Form.Item label="Order Date" {...formItemLayout}>
                {getFieldDecorator("order_date", {
                  rules: [
                    {
                      required: true,
                      message: "Purchase Order date is required",
                    },
                  ],
                  initialValue: moment(),
                })(
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />,
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        {page == 1 && (
          <div>
            <Row type="flex" justify="space-around" align="middle">
              <Col lg={10}>
                <Form.Item label="Vendor Name" {...formItemLayout}>
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Please select vendor name",
                      },
                    ],
                    initialValue: supplierData.name,
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select a person"
                      optionFilterProp="children"
                      onChange={(name) => fillVendorDetails(name)}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="" disabled>
                        Choose a Supplier
                      </Option>
                      {vendorListOptions.map((item, i) => (
                        <Option value={item.value} key={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col lg={10}>
                <Form.Item label="Vendor E-mail" {...formItemLayout}>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ],
                    initialValue: supplierData.email,
                  })(
                    <Input
                      suffix={<Icon type="mail" style={{ color: "#D3D3D3" }} />}
                      readOnly
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col lg={10}>
                <Form.Item label="Vendor Website" {...formItemLayout}>
                  {getFieldDecorator("website", {
                    initialValue: supplierData.website,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col lg={10}>
                <Form.Item label="Vendor GST" {...formItemLayout}>
                  {getFieldDecorator("gst", {
                    rules: [
                      {
                        required: true,
                        message: "Please input GST number",
                      },
                    ],
                    initialValue: supplierData.gst,
                  })(<Input readOnly />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col lg={10}>
                <Form.Item label="ETA" {...formItemLayout}>
                  {getFieldDecorator("eta", {
                    rules: [
                      {
                        required: true,
                        message: "Please input ETA",
                      },
                    ],
                  })(
                    <DatePicker
                      format="DD-MM-YYYY"
                      style={{ width: "100%" }}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col lg={10}>
                <Form.Item label="Vendor Contact No:" {...formItemLayout}>
                  {getFieldDecorator("phone", {
                    rules: [
                      {
                        required: true,
                        message: "Please input vendor's contact number",
                      },
                    ],
                    initialValue: supplierData.phone,
                  })(
                    <Input
                      suffix={
                        <Icon type="phone" style={{ color: "#D3D3D3" }} />
                      }
                      readOnly
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col lg={10}>
                <Form.Item label="Vendor Address" {...formItemLayout}>
                  {getFieldDecorator("address", {
                    rules: [
                      {
                        required: true,
                        message: "Please type vendor address",
                      },
                    ],
                    initialValue: supplierData.address,
                  })(<TextArea rows={4} readOnly />)}
                </Form.Item>
              </Col>
              <Col lg={10}></Col>
            </Row>
          </div>
        )}

        {page == 2 && (
          <div>
            <PageHeader
              title="Billing Details"
              subTitle="Fill the billing details below  for PO"
            />
            <Row type="flex" justify="space-around" align="middle">
              <Col lg={10}>
                <Form.Item label="Phone" {...formItemLayout}>
                  {getFieldDecorator("billing_phone", {
                    rules: [
                      {
                        required: true,
                        message: "Phone number is required",
                      },
                    ],
                    initialValue: billingAddress.phone,
                  })(
                    <Input
                      suffix={
                        <Icon type="phone" style={{ color: "#D3D3D3" }} />
                      }
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col lg={10}>
                <Form.Item label="GST" {...formItemLayout}>
                  {getFieldDecorator("billing_gst", {
                    rules: [
                      {
                        required: true,
                        message: "GST number is required",
                      },
                      {
                        pattern: /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                        message: `GST number is invalid`,
                      },
                    ],
                    initialValue: billingAddress.gst,
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col lg={10}>
                <Form.Item label="Address" {...formItemLayout}>
                  {getFieldDecorator("billing_address", {
                    rules: [
                      {
                        required: true,
                        message: "Billing address is required",
                      },
                    ],
                    initialValue: billingAddress.address,
                  })(<TextArea rows={4} />)}
                </Form.Item>
              </Col>
              <Col lg={10}></Col>
            </Row>
            <PageHeader
              title="Shipping Details"
              subTitle="Fill the shipping details below  for PO"
            />
            <Row type="flex" justify="space-around" align="top">
              <Col lg={10}>
                <Form.Item label="Address" {...formItemLayout}>
                  {getFieldDecorator("shipping_address", {
                    rules: [
                      {
                        required: true,
                        message: "Shipping address is required",
                      },
                    ],
                    initialValue: shippingAddress.address,
                  })(<TextArea rows={4} />)}
                </Form.Item>
              </Col>
              <Col lg={10}>
                <Form.Item label="Phone" {...formItemLayout}>
                  {getFieldDecorator("shipping_phone", {
                    rules: [
                      {
                        required: true,
                        message: "Phone number is required",
                      },
                    ],
                    initialValue: shippingAddress.phone,
                  })(
                    <Input
                      suffix={
                        <Icon type="phone" style={{ color: "#D3D3D3" }} />
                      }
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
        {page == 3 && (
          <div>
            <Row type="flex" justify="space-around" align="top">
              <Col lg={10} order={1}>
                <Form.Item label="Items" {...formItemMasterLayout}>
                  {getFieldDecorator("item_list", {
                    rules: [
                      {
                        required: true,
                        message: "Please select item",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      onChange={setItemFields}
                      placeholder="Select Item to purchase"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="" disabled>
                        Select Item to purchase
                      </Option>
                      {/* {itemOptions.map((item, i) => (

                        <Option value={item.value} key={item.value}>
                          {item.label}
                        </Option>
                      ))} */}
                      {itemOptions.map((item, i) => (
                        <Option value={item.value} key={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Name" {...formItemMasterLayout}>
                  {getFieldDecorator("item_name", {
                    initialValue: item.name,
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="UOM" {...formItemMasterLayout}>
                  {getFieldDecorator("item_uom", {
                    initialValue: item.uom,
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="Cost Per Unit" {...formItemMasterLayout}>
                  {getFieldDecorator("item_cpu", {
                    initialValue: item.cpu,
                  })(<Input type="number" min={0} />)}
                </Form.Item>
                <Form.Item
                  label="Quantity to Purchase"
                  {...formItemMasterLayout}
                >
                  {getFieldDecorator("item_quantity", {
                    rules: [
                      {
                        required: true,
                        message: "Please select Quantity to purchase",
                      },
                    ],
                    initialValue: item.quantity,
                  })(<Input type="number" min={0} />)}
                </Form.Item>
                <Form.Item label="Total Amount" {...formItemMasterLayout}>
                  {getFieldDecorator("item_total", {
                    initialValue: isNaN(
                      props.form.getFieldsValue().item_cpu *
                        props.form.getFieldsValue().item_quantity,
                    )
                      ? 0
                      : props.form.getFieldsValue().item_cpu *
                        props.form.getFieldsValue().item_quantity,
                  })(<Input readOnly />)}
                </Form.Item>

                <Form.Item label="GST Type" {...formItemMasterLayout}>
                  {getFieldDecorator("gst", {
                    rules: [
                      {
                        required: true,
                        message: "Please select type of gst",
                      },
                    ],
                    initialValue: 18,
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      onChange={(value) =>
                        setSupplierData({
                          ...supplierData,
                          name: supplierData.name,
                        })
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={18}> 18.0% GST(18%)</Option>
                      <Option value={12}> 12.0% GST(12%)</Option>
                      <Option value={6}>6.0% GST(6%)</Option>
                      <Option value={5}>5.0% GST(5%)</Option>
                      <Option value={0.25}>0.25 GST(0.25%)</Option>
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Total + GST" {...formItemMasterLayout}>
                  {getFieldDecorator("totalWithGST", {
                    initialValue: isNaN(
                      props.form.getFieldsValue().item_cpu *
                        props.form.getFieldsValue().item_quantity +
                        Number(
                          (props.form.getFieldsValue().gst / 100) *
                            (props.form.getFieldsValue().item_cpu *
                              props.form.getFieldsValue().item_quantity),
                        ),
                    )
                      ? 0
                      : (
                          props.form.getFieldsValue().item_cpu *
                            props.form.getFieldsValue().item_quantity +
                          Number(
                            (props.form.getFieldsValue().gst / 100) *
                              (props.form.getFieldsValue().item_cpu *
                                props.form.getFieldsValue().item_quantity),
                          )
                        ).toFixed(2),
                  })(<Input readOnly />)}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="reset" onClick={addPO}>
                    Place Order{" "}
                  </Button>
                </Form.Item>
              </Col>
              <Col lg={4} order={2}></Col>

              <Col lg={10} order={3}>
                <Table
                  dataSource={purchaseOrder}
                  columns={columns}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </div>
        )}
        {page == 4 && (
          <div>
            <PageHeader title="" subTitle="" />

            <Row>
              <Col lg={7}></Col>
              <Col lg={10}>
                <Form.Item
                  label="Approver Name"
                  {...formItemMasterLayout}
                  extra="PO will be sent to the selected Approver's email"
                >
                  {getFieldDecorator("approverEmail", {
                    rules: [
                      {
                        required: true,
                        message: "Please select Approver name",
                      },
                    ],
                    initialValue: approvername,
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      placeholder="Select Approver"
                      onChange={(val) => {
                        setApprovername(val);
                      }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="iamshaiksha17@gmail.com">
                        Shaiksha Vali{" "}
                      </Option>
                      <Option value="ashish.kumar@clairco.in">
                        Ashish Kumar
                      </Option>

                      {approversList.map((item, i) => (
                        <Option value={item.value} key={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col lg={7}></Col>
            </Row>
            <Row>
              <Col lg={7}></Col>
              <Col lg={10}>
                <Form.Item label="Terms & Conditions" {...formItemMasterLayout}>
                  {getFieldDecorator("terms", {
                    rules: [
                      {
                        required: true,
                        message: "Please select Approver name",
                      },
                    ],
                    initialValue: terms,
                  })(
                    <TextArea
                      rows={4}
                      onChange={(e) => setTerms(e.target.value)}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col lg={7}></Col>
            </Row>
          </div>
        )}
        {page == 5 && (
          <div>
            <PageHeader
              title="Order Summary"
              subTitle="Confirm below details to create PO"
            />
            <Row>
              <Col lg={2}></Col>
              <Col lg={20}>
                <Card>
                  <Row>
                    <Col lg={6}>
                      <p>Purchase Order ID</p>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={17}>
                      <p>{POID}</p>
                    </Col>
                    <Col lg={6}>
                      <p>Purchase Order Date</p>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={17}>
                      <p>{orderDate}</p>
                    </Col>
                    <Col lg={6}>
                      <p>Vendor Name</p>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={17}>
                      <p>{supplierData.name}</p>
                    </Col>
                    <Col lg={6}>
                      <p>Vendor Contact No</p>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={17}>
                      <p>{supplierData.phone}</p>
                    </Col>
                    <Col lg={6}>
                      <p>Vendor Address</p>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={17}>
                      <p>{supplierData.address}</p>
                    </Col>
                    <Col lg={6}>
                      <p>Billing GST</p>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={17}>
                      <p>{billingAddress.gst}</p>
                    </Col>
                    <Col lg={6}>
                      <p>Billing Contact No </p>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={17}>
                      <p>{billingAddress.phone}</p>
                    </Col>
                    <Col lg={6}>
                      <p>Billing Address</p>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={17}>
                      <p>{billingAddress.address}</p>
                    </Col>
                    <Col lg={6}>
                      <p>Shipping Contact No</p>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={17}>
                      <p>{shippingAddress.phone}</p>
                    </Col>
                    <Col lg={6}>
                      <p>Shipping Address</p>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={17}>
                      <p>{shippingAddress.address}</p>
                    </Col>
                    <Col lg={6}>
                      <p>Items</p>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={17}>
                      <ol>
                        {purchaseOrder.map((item, i) => (
                          <li key={i}>
                            {item.name}, Quantity : {item.quantity} ,
                            Amount(including GST) : {item.totalpricewithgst}
                          </li>
                        ))}
                      </ol>
                    </Col>
                    <Col lg={2}>
                      <Button type="primary" htmlType="button" onClick={sendPo}>
                        Create PO
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg={2}></Col>
            </Row>
          </div>
        )}
        <Form.Item>
          {page > 1 && (
            <Button
              type="primary"
              htmlType="button"
              className="login-form-button"
              onClick={handelePreviousPage}
              style={{
                background: "none",
                color: "#36454F",
                border: "1px solid #006d86",
                marginRight: "10px",
              }}
            >
              PREV
            </Button>
          )}
          {page < MAX_PAGE && (
            <Button
              type="primary"
              htmlType="button"
              className="login-form-button"
              onClick={handleSubmit}
              // disabled={purchaseOrder.length == 0 ? page == 1 : false}
            >
              NEXT{" "}
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default Form.create({ name: "PO FORM" })(PO);
