import React, { Component } from "react";
import {
  Button,
  Form,
  InputNumber,
  Checkbox,
  Select,
  Input,
  Radio,
  Col,
  Row,
  Divider,
  TimePicker,
} from "antd";
const FormItem = Form.Item;
const Option = Select.Option;
const OptGroup = Select.OptGroup;

const SpaceForm = Form.create()(
  class extends React.Component {
    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Form layout="vertical">
          <FormItem label="Space name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please fill space name!",
                },
              ],
            })(
              <Input placeholder="Ex: Room1,Outside, Hall, Floor2, BedRoom" />,
            )}
          </FormItem>
          <FormItem label="Area in square feet">
            {getFieldDecorator("area", {
              rules: [
                {
                  required: true,
                  message: "Please fill area",
                },
              ],
            })(
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Ex:100,200"
              />,
            )}
          </FormItem>
          <FormItem label="No. of exhaust fans">
            {getFieldDecorator("exhaust_fan", {
              initialValue: "0",
              rules: [
                {
                  required: true,
                  message: "Please fill No. of exhaust fans",
                },
              ],
            })(<InputNumber style={{ width: "100%" }} placeholder="0" />)}
          </FormItem>
          <FormItem label="No. of vent with filter">
            {getFieldDecorator("vent_wih_filter", {
              initialValue: "0",
              rules: [
                {
                  required: true,
                  message: "Please fill No. of vent with filter",
                },
              ],
            })(<InputNumber style={{ width: "100%" }} placeholder="0" />)}
          </FormItem>
          <FormItem label="No. of vent without filter">
            {getFieldDecorator("vent_wihtout_filter", {
              initialValue: "0",
              rules: [
                {
                  required: true,
                  message: "Please fill No. of vent without filter",
                },
              ],
            })(<InputNumber style={{ width: "100%" }} placeholder="0" />)}
          </FormItem>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label="Default Operational Hours" hasFeedback>
                {getFieldDecorator("opHours", {
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: "Please select Operational Hours of the space!",
                  //   },
                  // ],
                })(
                  <Select placeholder="Please select Operational Hours of the space!">
                    <Option value="1">All 24 hours</Option>
                    <Option value="2">6am to 11pm</Option>
                    <Option value="3">10am to 8pm</Option>
                    <Option value="4">6am to 12pm - 4pm to 10pm</Option>
                    <Option value="5">7am to 7pm</Option>
                  </Select>,
                )}
              </Form.Item>
            </Col>
            {/* <Divider type="vertical" /> */}

            <Col span={12}>
              <Form.Item label="Custom Operational Hours">
                {getFieldDecorator("fromOpHours", {
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: "Please select Operational Hours of the space!",
                  //   },
                  // ],
                })(
                  <TimePicker
                    use12Hours={true}
                    // onChange={(time, timestring) => console.log(timestring)}
                    format="h A"
                    placeholder="start time"
                    style={{ width: "100%", marginBottom: "5px" }}
                  />,
                )}

                {getFieldDecorator("toOpHours", {
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: "Please select Operational Hours of the space!",
                  //   },
                  // ],
                })(
                  <TimePicker
                    use12Hours={true}
                    // onChange={(time, timestring) => console.log(timestring)}
                    format="h A"
                    placeholder="end time"
                    style={{ width: "100%" }}
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Non-Operational Days" hasFeedback>
                {getFieldDecorator("NonOpDays", {
                  rules: [
                    {
                      required: true,
                      message:
                        "Please select Non-Operational Days of the space!",
                    },
                  ],
                })(
                  // <Select placeholder="Please select Non-Operational Days of the space!">
                  //   <Option value="1">None</Option>
                  //   <Option value="2">Saturday - Sunday</Option>
                  // </Select>,
                  <Select placeholder="Please select Non-Operational Days of the space!">
                    <Option value="None">None</Option>
                    <Option value="Saturday - Sunday">Saturday - Sunday</Option>
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      );
    }
  },
);

export default SpaceForm;
