import React, { Component } from "react";
import { Button, PageHeader, Form, Input, DatePicker, Select } from "antd";
import { render } from "react-dom";

const FormItem = Form.Item;
const Option = Select.Option;

const LogForm = Form.create()(
  class extends React.Component {
    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Form layout="vertical">
          <FormItem label="Updated By">
            {getFieldDecorator("updated_by", {
              rules: [
                {
                  required: true,
                  message: "Important",
                },
              ],
            })(<Input placeholder="e.g. AHU Number 1" />)}
          </FormItem>
          <FormItem label="Updated on">
            {getFieldDecorator("updated_on", {
              rules: [
                {
                  type: "object",
                  required: true,
                  message: "Important",
                },
              ],
            })(<DatePicker />)}
          </FormItem>
          <FormItem label="Action Taken">
            {getFieldDecorator("action", {
              rules: [
                {
                  required: true,
                  message: "Important",
                },
              ],
            })(
              <Select placeholder="Choose...">
                <Option value="0">Fresh Installation</Option>
                <Option value="1">Cleaned</Option>
                <Option value="2">Changed</Option>
              </Select>,
            )}
          </FormItem>
          <FormItem label="Customer Supervisor Name">
            {getFieldDecorator("supervised_by", {
              rules: [
                {
                  required: true,
                  message: "Important",
                },
              ],
            })(<Input placeholder="e.g. Jhon Doe" />)}
          </FormItem>
        </Form>
      );
    }
  },
);

export default LogForm;
