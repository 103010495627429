import {
  GET_P_LVL_MIN_VAL_DICT
} from "./pLevels";

export const COLOR = {
    primaryColor: '#4CAF50',
    primaryDark:'#388E3C',
    secondaryColor:'#7C4DFF',
    darkText:'#212121',
    secondaryText:'#757575',
    deviderColor:'#BDBDBD',
    insideAir: '#2E039E',
    outsideAir: '#00e5ff',
    good:"#00940F",
    good_light:'#b6ffbd',
    satisfactory:"#62C94B",
    satisfactory_light:'#dff4db',
    moderate:"#F7E22A",
    moderate_light:'#fdf9d4',
    poor:"#FF6600",
    poor_light:'#fee0cc',
    veryPoor:"#FF0000",
    veryPoor_light:'#fecccc',
    severe:"#870003",
    severe_light:'#ffb4b5'
  };

export const GET_CLR_POLLUTANT_LVL = function (value, pollutantLabel) {
  // console.log(value);
  // console.log(pollutantLabel);
  const minValDict = GET_P_LVL_MIN_VAL_DICT(pollutantLabel);
  return value ===undefined || value === 'NA'
          ? '#FFFF'
          :value < minValDict.satisfactoryMin
          ? COLOR.good
          : value < minValDict.moderateMin
            ? COLOR.satisfactory
            : value < minValDict.poorMin
              ? COLOR.moderate
              : value < minValDict.veryPoorMin
                ? COLOR.poor
                : value < minValDict.severeMin
                  ? COLOR.veryPoor
                  : COLOR.severe;
}