import React, { Component } from 'react';
import { Row, Col, Card, Menu, Button, Modal, Checkbox  } from 'antd';
import {getAverageHistoryData, getHistoryData, getSpaceFromProperty, calculte_aqi, calculte_productivity} from "../api";
import {Radar, Doughnut, HorizontalBar, Bar, Line, Polar} from 'react-chartjs-2';
import '../scenes/Reports.less'
import { node } from 'prop-types';

export default class Report extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            data: this.props.data,
            labels:[],
            pm25:[],
            pm10:[],
            co2:[],
            voc:[],
            aqi:[],
            menuVar: 0,
            dateVar : 2,
            selectedCharts: [],
            emptyDataCheck:0
        }
    }    

    async componentDidMount() {
        const property_id = this.state.data[0].properties[0].id;
        try {
            let spaces_list = (await getSpaceFromProperty(property_id)).data;
            spaces_list = spaces_list.data.Spaces;
            console.log('splst ', spaces_list)
            var today = (new Date());
            var year = (today.getFullYear()).toString();
            var month = (today.getMonth()+1).toString();
            var day = (today.getDate()).toString();
            var firstDay = new Date(year+"/"+"0"+month+"/"+"0"+day);

            var previousmonth= this.getDays((new Date(firstDay.getTime() - 15 * 24 * 60 * 60 * 1000)), 'prev');
            var previousweek= this.getDays((new Date(firstDay.getTime() - 7 * 24 * 60 * 60 * 1000)), 'prev');
            var previousday= this.getDays(today, 'now');
            var todayDate = this.getDays(today, 'now');
            
            var spacesHistoryDataMonth=[];
            var spacesHistoryDataWeek=[];
            var spacesHistoryDataDaily=[];
            var noDataVar = [] 

            for(let i=0; i<spaces_list.length; i++){
                var historyData = (await getAverageHistoryData(spaces_list[i].id, [previousmonth, todayDate])).data;
                if(historyData==[] || historyData==""){
                    noDataVar.push(i)
                }
                spacesHistoryDataMonth.push(historyData)
    
            }
            console.log('')

            for(let i=0; i<spaces_list.length; i++){
                var historyData = (await getAverageHistoryData(spaces_list[i].id, [previousweek, todayDate])).data;
                if(historyData==[] || historyData==""){
                    noDataVar.push(i)
                }
                spacesHistoryDataWeek.push(historyData)
                
            }

            console.log('')

            // for(let i=0; i<spaces_list.length; i++){
            //     var historyData = (await getHistoryData(spaces_list[i].id, [previousday, todayDate])).data;
            //     if(historyData==[] || historyData==""){
            //         noDataVar.push(i)
            //     }
            //     else{
            //         console.log(spaces_list[i].name)
            //         spacesHistoryDataDaily.push(historyData)
            //     }
            // }

            console.log(spacesHistoryDataMonth)
            console.log(spacesHistoryDataWeek)
            console.log(spacesHistoryDataDaily)

            var changeData = {
                label: [],
                pm25: [], 
                pm10: [],
                co2: [],
                voc: []
            } 

            for(let i=0; i<spacesHistoryDataWeek[0].length; i++){
                changeData.label.push(spacesHistoryDataWeek[0][i].day);
                changeData.pm25.push(spacesHistoryDataWeek[0][i]['PM25']);
                changeData.pm10.push(spacesHistoryDataWeek[0][i]['PM10']);
                changeData.co2.push(spacesHistoryDataWeek[0][i]['CO2']);
                changeData.voc.push(spacesHistoryDataWeek[0][i]['VOC']);
            }

            var tempAqi = [];
            var tempProd = []
            for(let i=0; i<changeData.pm25.length; i++){ 
                if(changeData.pm25[i] != null){
                    var fetchAqiData = (await calculte_aqi(changeData.pm25[i])).data;
                    tempAqi.push(fetchAqiData.data)
                }               
            }

            for(let i=0; i<tempAqi.length; i++){ 
                var fetchProdData = (await calculte_productivity(tempAqi[i])).data;
                tempProd.push(100 - fetchProdData.data - 2)             
            }

            let tempSpacesList = []
            for(let i=0; i<spaces_list.length; i++){
                if(noDataVar.includes(i) == false){
                    tempSpacesList.push(spaces_list[i])
                }
            }
            

            let oldSpacesList = [];
            for(let i=0; i<spaces_list.length; i++){
                oldSpacesList.push(spaces_list[i].name)
            }
            // spaces_list = tempSpacesList;

            this.setState({
                oldSpacesList,
                spaces_list,
                spacesHistoryDataWeek,
                spacesHistoryDataMonth,
                spacesHistoryDataDaily,
                labels: changeData.label,
                pm25: changeData.pm25,
                pm10: changeData.pm10,
                co2: changeData.co2,
                voc: changeData.voc,
                aqi: tempAqi,
                tempProd
            })

        } catch (err) {
            console.log("ERROR in spacelist");
        }
    }

    calculate_values = async (dateVar) => {

        var changeData = {
            label: [],
            pm25: [],
            pm10: [],
            co2: [],
            voc: []
        }

        if(dateVar === 1){
            for(let i=0; i<this.state.spacesHistoryDataDaily[this.state.menuVar][0]['CO2'].length; i++){  
                    changeData.label.push(i);
                    changeData.pm25.push(this.state.spacesHistoryDataDaily[this.state.menuVar][0]['PM25'][i]);
                    changeData.pm10.push(this.state.spacesHistoryDataDaily[this.state.menuVar][0]['PM10'][i]);
                    changeData.co2.push(this.state.spacesHistoryDataDaily[this.state.menuVar][0]['CO2'][i]);
                    changeData.voc.push(this.state.spacesHistoryDataDaily[this.state.menuVar][0]['VOC'][i]);
            }
            console.log('in 1', changeData)
        }
        else if(dateVar === 2){
            for(let i=0; i<this.state.spacesHistoryDataWeek[this.state.menuVar].length; i++){
                changeData.label.push(this.state.spacesHistoryDataWeek[this.state.menuVar][i].day);
                changeData.pm25.push(this.state.spacesHistoryDataWeek[this.state.menuVar][i]['PM25']);
                changeData.pm10.push(this.state.spacesHistoryDataWeek[this.state.menuVar][i]['PM10']);
                changeData.co2.push(this.state.spacesHistoryDataWeek[this.state.menuVar][i]['CO2']);
                changeData.voc.push(this.state.spacesHistoryDataWeek[this.state.menuVar][i]['VOC']);
            }
        }
        else if(dateVar === 3){  
            for(let i=0; i<this.state.spacesHistoryDataMonth[this.state.menuVar].length; i++){
                changeData.label.push(this.state.spacesHistoryDataMonth[this.state.menuVar][i].day);
                changeData.pm25.push(this.state.spacesHistoryDataMonth[this.state.menuVar][i]['PM25']);
                changeData.pm10.push(this.state.spacesHistoryDataMonth[this.state.menuVar][i]['PM10']);
                changeData.co2.push(this.state.spacesHistoryDataMonth[this.state.menuVar][i]['CO2']);
                changeData.voc.push(this.state.spacesHistoryDataMonth[this.state.menuVar][i]['VOC']);
            }
        }

        
        var tempAqi = [];
        var tempProd = [];
        for(let i=0; i<changeData.pm25.length; i++){ 
            if(changeData.pm25[i] != null){
                var fetchAqiData = (await calculte_aqi(changeData.pm25[i])).data;
                tempAqi.push(fetchAqiData.data)
            }               
        }

        for(let i=0; i<tempAqi.length; i++){ 
            var fetchProdData = (await calculte_productivity(tempAqi[i])).data;
            tempProd.push(100 - fetchProdData.data - 2)      
        }

        this.setState({
            labels: changeData.label,
            pm25: changeData.pm25,
            pm10: changeData.pm10,
            co2: changeData.co2,
            voc: changeData.voc,
            aqi: tempAqi,
            tempProd
        })

        console.log("this.state.spacesHistoryDataMonth ", this.state.spacesHistoryDataMonth);
        console.log("this.state.spacesHistoryDataWeek ", this.state.spacesHistoryDataWeek);
        console.log("this.state.spacesHistoryDataDaily ", this.state.spacesHistoryDataDaily);
    }

    handleClick = async(e) => {
        console.log('selected menu ', e.key);
        console.log("this.state.oldSpacesList ", this.state.oldSpacesList)
        let menuVar = parseInt(e.key);
        let menuname = this.state.spaces_list[menuVar];
        console.log('menuname ', menuname.name)
        menuVar = this.state.oldSpacesList.indexOf(menuname.name);
        console.log("menuVar ", menuVar)

        var changeData = {
            label: [],
            pm25: [],
            pm10: [],
            co2: [],
            voc: []
        }

        for(let i=0; i<this.state.spacesHistoryDataWeek[menuVar].length; i++){
                changeData.label.push(this.state.spacesHistoryDataWeek[menuVar][i].day);
                changeData.pm25.push(this.state.spacesHistoryDataWeek[menuVar][i]['PM25']);
                changeData.pm10.push(this.state.spacesHistoryDataWeek[menuVar][i]['PM10']);
                changeData.co2.push(this.state.spacesHistoryDataWeek[menuVar][i]['CO2']);
                changeData.voc.push(this.state.spacesHistoryDataWeek[menuVar][i]['VOC']);
        }


        this.setState({
            labels: changeData.label,
            pm25: changeData.pm25,
            pm10: changeData.pm10,
            co2: changeData.co2,
            voc: changeData.voc,
            menuVar: menuVar,
            dateVar: 2,
        })
    }

    datesClick = async(e) => {
        console.log('clicked ', e.key)
        var dateVar = parseInt(e.key)
        var today = (new Date());
        var year = (today.getFullYear()).toString();
        var month = (today.getMonth()+1).toString();
        var day = (today.getDate()).toString();
        var firstDay = new Date(year+"/"+"0"+month+"/"+"0"+day);

        this.setState({
            dateVar:dateVar
        })

        var calculateData = this.calculate_values(dateVar)
    }

    getDays (dateObj, timing)  {
        const month = dateObj.getUTCMonth() + 1; 
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        const hour = dateObj.getUTCHours();
        if(timing === 'now'){
            return (year + "-" + (month < 10?('0'+month):month) + "-" + (day < 10?('0'+day-1):(day-1)))
        }
        else{
            return (year + "-" + (month < 10?('0'+month):month) + "-" + (day < 10?('0'+day):(day)))
        }
        
    }    

    onChange = (checkedValues) => {
        for(let i=0; i<checkedValues.length; i++){
            checkedValues[i] = checkedValues[i].toLowerCase()
        }
        this.setState({
            selectedCharts : checkedValues
        })
    }

    render() {
        const {oldSpacesList, labels, pm25, pm10, co2, voc, dateVar, aqi, tempProd} = this.state;
        const style = { background: '#fafafa', minHeight:'37vh', border:'1px solid #e0e0e0'};
        const renderSpaces = (
            <div>
                <Menu
                className="scrollMenu"
                defaultSelectedKeys={['0']}
                style={{ overflowY:'scroll', maxHeight:'37vh', maxWidth:'20vw', overflowX:'hidden'}}
                onClick={this.handleClick}
                mode="inline"
                >
                {oldSpacesList!==undefined ? (oldSpacesList.map((item,index) => (
                    <Menu.Item className="report-menu" key={index}>
                        <span style={{fontFamily:"'Montserrat', sans-serif"}}>{item}</span>
                    </Menu.Item>
                ))) :
                (
                    <div></div>
                )
                }
                </Menu>
            </div>
        )
        var lineData = {
                labels: labels,
                datasets:[
                    {
                        label:'PM2.5',
                        data: pm25,
                        borderColor : 'rgba(255, 99, 132, 0.5)',
                        backgroundColor:'#fafafa'
                    },
                    {
                        label:'PM10',
                        data: pm10,
                        borderColor : 'rgba(54, 162, 235, 0.5)',
                        backgroundColor:'#fafafa'
                    },
                    {
                        label:'CO2',
                        data: co2,
                        borderColor : 'rgba(255, 206, 86, 0.5)',
                        backgroundColor:'#fafafa'
                    },
                    {
                        label:'VOC',
                        data: voc,
                        borderColor : 'rgba(75, 192, 192, 0.5)',
                        backgroundColor:'#fafafa'
                    },
                ]
            };

        var aqiData = {
            labels: labels,
            datasets:[
                {
                    label:'AQI',
                    data: aqi,
                    backgroundColor: 'rgba(255, 205, 86, 0.5)'
                },
            ]
        };

        // 'rgba(255, 99, 132, 0.5)',
        // 'rgba(75, 192, 192, 0.5)',
        // 'rgba(255, 205, 86, 0.5)',
        // 'rgba(201, 203, 207, 0.5)',
        // 'rgba(54, 162, 235, 0.5)',
        // 'rgba(111, 44, 82, 0.5)',
        // 'rgba(99, 31, 200, 0.5)',
        // 'rgba(12, 37, 11, 0.5)'

        var prodData = {
            labels: labels,
            datasets:[
                {
                    label:'PRODUCTIVITY',
                    data: tempProd,
                    borderColor : 'rgba(255, 99, 132, 0.5)',
                    backgroundColor:'rgba(200, 122, 82, 0.5)'
                },
            ]
        };

        var filterData = {
            labels: labels,
            datasets:[
                {
                    label:'FILTER',
                    data: pm25,
                    borderColor : 'rgba(255, 99, 132, 0.5)',
                    backgroundColor:'#fafafa'
                },
            ]
        };
        
        return (
            <div style={{minHeight:'85vh'}}>
                <Row className="dates-menu">
                    <Menu onClick={this.datesClick}
                    theme="light" className="dates-menu" mode="horizontal" selectedKeys={dateVar.toString()} defaultSelectedKeys={['2']} style={{backgroundColor:'white'}}>
                        <Menu.Item key="1" style={{float:'right', border:'0.5px solid #bdbdbd', borderRadius: '20px', marginRight:'5px'}}>Today</Menu.Item>
                        <Menu.Item key="2" style={{float:'right', border:'0.5px solid #bdbdbd', borderRadius: '20px', marginRight:'5px'}}>Last Week</Menu.Item>
                        <Menu.Item key="3" style={{float:'right', border:'0.5px solid #bdbdbd', borderRadius: '20px', marginRight:'5px'}}>Last 30 days</Menu.Item>
                    </Menu>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{minHeight:'39vh', marginTop:'1%'}}>
                    <Col span={14}>
                        <div style={style}>
                            <Row>    
                                <Col span={6} className="report-menu" style={{borderLeft:'1px solid #bdbdbd', minHeight:'37vh'}}>
                                    {renderSpaces}
                                </Col>                      
                                <Col span={17}>
                                    <div style={{marginTop:'3%', marginLeft:'3%'}}>
                                    <Line
                                            data={lineData}
                                            width={500}
                                            height={250}
                                            options={
                                                {
                                                    legend: {
                                                        display: true,
                                                    },
                                                    maintainAspectRatio:false,
                                                    responsive: false,
                                                    scales: {
                                                        xAxes: [{
                                                            gridLines: {
                                                                color: "rgba(0,0,0,0)",
                                                            },
                                                            ticks: {
                                                                fontSize: 13
                                                            },
                                                        }],
                                                        yAxes: [{
                                                            gridLines: {
                                                                color: "rgba(0,0,0,0)",
                                                            },
                                                            ticks: {
                                                                stepSize: 80,
                                                                fontSize: 13
                                                            },
                                                        }]
                                                        }
                                                }
                                            }
                                            />
                                    </div>
                                </Col>
                            </Row>
                            
                        </div>
                    </Col>
                    <Col span={10}>
                        <div style={style}>
                            <Row>                          
                                <Col span={23}>
                                    <div style={{marginTop:'3%', marginLeft:'3%'}}>
                                    <Bar
                                        data={aqiData}
                                        width={500}
                                        height={250}
                                        options={
                                            {
                                                legend: {
                                                    display: true,
                                                },
                                                maintainAspectRatio:false,
                                                responsive: false,
                                                scales: {
                                                    xAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,0)",
                                                        },
                                                        ticks: {
                                                            fontSize: 13
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        gridLines: {
                                                            color: "rgba(0,0,0,0)",
                                                        },
                                                        ticks: {
                                                            stepSize: 20,
                                                            fontSize: 13
                                                        },
                                                    }]
                                                    }
                                            }
                                        }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{minHeight:'39vh'}}>
                    <Col span={12}>
                        <div style={style}>
                            <Row>                          
                                <Col span={23}>
                                    <div style={{marginTop:'3%', marginLeft:'3%'}}>
                                    <Bar
                                    data={prodData}
                                    width={500}
                                    height={250}
                                    options={
                                        {
                                            legend: {
                                                display: true,
                                            },
                                            maintainAspectRatio:false,
                                            responsive: false,
                                            scales: {
                                                xAxes: [{
                                                    gridLines: {
                                                        color: "rgba(0,0,0,0)",
                                                    },
                                                    ticks: {
                                                        fontSize: 13
                                                    },
                                                }],
                                                yAxes: [{
                                                    gridLines: {
                                                        color: "rgba(0,0,0,0)",
                                                    },
                                                    ticks: {
                                                        stepSize: 80,
                                                        fontSize: 13
                                                    },
                                                }]
                                                }
                                        }
                                    }
                                    />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={style}>
                        <Row>                          
                            <Col span={23}>
                                <div style={{marginTop:'3%', marginLeft:'3%'}}>
                                <Line
                                    data={filterData}
                                    width={500}
                                    height={250}
                                    options={
                                        {
                                            legend: {
                                                display: true,
                                            },
                                            maintainAspectRatio:false,
                                            responsive: false,
                                            scales: {
                                                xAxes: [{
                                                    gridLines: {
                                                        color: "rgba(0,0,0,0)",
                                                        drawBorder: false,
                                                    },
                                                    ticks: {
                                                        fontSize: 13
                                                    },
                                                }],
                                                yAxes: [{
                                                    gridLines: {
                                                        color: "rgba(0,0,0,0)",
                                                        drawBorder: false
                                                    },
                                                    ticks: {
                                                        stepSize: 80,
                                                        fontSize: 13
                                                    },
                                                }]
                                                }
                                        }
                                    }
                                    />
                                </div>
                            </Col>
                        </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
