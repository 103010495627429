import React, { Component } from "react";
import { Spin } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import _ from "lodash";
import { getCsrId } from "../helper";
import { getCustomer, getSummaryAqData, getSpace, getAQ } from "../api";
import Kiosk from "./kiosk/Kiosk";
import { GetRealtimeAQI } from "../utils/createAqData";
const base_url = "https://customers-asset.s3-ap-south-1.amazonaws.com/";
const config = {
  bucketName: "customers-asset",
  region: "ap-south-1",
  // accessKeyId: "AKIAJMZSCT7YBJ5HVEDA",
  accessKeyId: "AKIARC4ZKUPX3AMBYC4O",

  // dirName: 'max-estates',
  // secretAccessKey: "idSBNKceeEmnX0VMC3kPU4CpcukiRs9DOMA2ItF1",
  secretAccessKey: "J8AQjvokSx+FIW4tstnG2vv42+VSZKu8MfC3vLLH",
};
var AWS = require("aws-sdk");

var s3;

const axis_house = "eb53efcd-d4e8-47f8-970c-8938145eabd7";
const max_house_okhla = "fd6a8e6d-a0c1-4387-8b84-a6a20b48559e";
const maxEstates_customerId = "e0bd2d59-1f36-44a0-9167-fe9c03fc4d0b";
const brookfield_customerId = "159f6b0a-33ee-4722-9a16-23241cb5e218";
const rmz_id = "bb930af8-eb2c-444a-8fc9-bbf5faeb6531";
const carrier_id = "526ed84b-757c-4381-a921-e568c1c53026";
const fractal_mub_id = "55a74949-e121-44ee-b30b-a1e34a3c836c";

const filterIds = ["eb53efcd-d4e8-47f8-970c-8938145eabd7", "fd6a8e6d-a0c1-4387-8b84-a6a20b48559e", "e0bd2d59-1f36-44a0-9167-fe9c03fc4d0b", "159f6b0a-33ee-4722-9a16-23241cb5e218", "bb930af8-eb2c-444a-8fc9-bbf5faeb6531", "526ed84b-757c-4381-a921-e568c1c53026", "55a74949-e121-44ee-b30b-a1e34a3c836c"];

export default class KioskSlider extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props);
    // config["dirName"] =
    //   props.location.state != undefined ? props.location.state.dir : null;
    AWS.config.update(config);
    s3 = new AWS.S3();
    this.state = {
      data: undefined,
      propDataForSummary: undefined,
    };
  }
  async componentDidMount() {
    if (this.props.location.state) {
      var counter = 0;
      let prefix;
      if(this.props.location.state.customer === 'fd6a8e6d-a0c1-4387-8b84-a6a20b48559e'){
        prefix = 'max-okhla'
      } else if(this.props.location.state.customer === "bb930af8-eb2c-444a-8fc9-bbf5faeb6531"){
        prefix = 'rmz'
      } else if (this.props.location.state.customer === "526ed84b-757c-4381-a921-e568c1c53026"){
        prefix = 'carrier'
      } else if (this.props.location.state.customer === "55a74949-e121-44ee-b30b-a1e34a3c836c") {
        prefix = 'fractal'
      } else if(this.props.location.state.customer === "e0bd2d59-1f36-44a0-9167-fe9c03fc4d0b") {
        prefix = 'max-estates'
      }
      
      if(prefix !== undefined){
        var params = {
          Bucket: config.bucketName /* required */,
          Prefix: prefix, // Can be your folder name
        };
        var temp = s3.listObjectsV2(params, function(err, data) {
          if (err) console.log(err, err.stack);
          // an error occurred
          else {
            return data;
          }
        });
        setTimeout(() => {
          // This timeout is set because S3 sends delayed response and promise was not working
          // Find a better approach in near future for a better function.
          this.setState({
            images_list: temp.response.data.Contents,
            show_item: true,
          });
        }, 2000);
        setInterval(() => {
          if (counter < this.state.images_list.length) {
            let button = document.getElementsByClassName(
              "control-arrow control-next",
            )[0];
            if (button) {
              button.click();
              counter++;
            }
          } else {
            let slide = document.getElementsByClassName("thumb ")[0];
            if (slide) {
              slide.click();
              counter = 0;
            }
            // window.location.reload(false);
          }
        }, 8000);
      }
    }
    this.fetchDataAndSetState('mount');
    let interval;
    clearInterval(interval);
    interval = setInterval(() => {
      this.fetchDataAndSetState('update');
    }, 600000);
  }

  fetchDataAndSetState = async (text) => {
    if (
      this.props.refferrer === "space" ||
      this.props.location.state["refferrer"] === "space"
    ) {
      try {
        let id = this.props.match.params.id || this.props.location.state.data;
        let data = (await getSpace(id)).data;
        let propertyId = data.property_id;
        let devId = data.data["Device"].id;
        let outDevId = data.data["Property"].OutsideDevice
          ? data.data["Property"].OutsideDevice.id
          : data.data["Property"].NeighbourProperty
          ? data.data["Property"].NeighbourProperty.OutsideDevice.id
          : data.data["Property"].station_id
          ? data.data["Property"].station_id
          : "NA";
        let date = new Date();
        let space_data = (
          await getAQ(id, propertyId, devId, outDevId, [
            date,
            date.setDate(date.getDate() - 1),
          ])
        ).data;
        const { insAq, outAq } = GetRealtimeAQI(space_data);
        let kiosk_Data = {
          customerName: data.data.name,
          customerCity: data.data["Property"].name,
          avgIns: insAq,
          avgOut: outAq,
          outSpaceName: "RajajiNagar",
          outPropertyName: "Bangalore",
          type: "space",
          logo: this.props.location.state? this.props.location.state.logo : null
        };
        this.setState({
          id: id,
          propDataForSummary: kiosk_Data,
          customerId: this.props.location.state
            ? this.props.location.state.customer
            : null,
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    } else {
      try {
        let { data } = (await getCustomer(getCsrId(this.state.id), true)).data;
        const customer_id = data.id;

        let summaryData = (
          await getSummaryAqData(getCsrId(this.state.id), true, "Yesterday")
        ).data;
        this.setState({
          loadingData: false,
        });
        const customerName = data.name;
        if (data.Properties.length > 0) {
          data = _(data.Properties)
            .groupBy((property) => property.city)
            .map((value, key) => ({ name: key, properties: value }))
            .sortBy((cityRegion) => cityRegion.name)
            .value();

          let cityNamesList = [];
          for (let i = 0, l = data.length; i < l; i++) {
            cityNamesList.push(data[i].name);
          }
          let selectedCityIndex = 0;
          let propDataForSummary = {};
          for (let i = 0, l = summaryData.data.length; i < l; i++) {
            if (
              summaryData.data[i].cityName === cityNamesList[selectedCityIndex]
            ) {
              propDataForSummary = summaryData.data[i];
              break;
            }
          }

          propDataForSummary.customerName = customerName;
          propDataForSummary.customerCity = "Uttar Pradesh";
          propDataForSummary.id = customer_id;

          this.setState({
            propDataForSummary,
            id: customer_id,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  render() {
    let { propDataForSummary } = this.state;
    if (!propDataForSummary) {
      return (
        <div className="dashLoaderContainer">
          <Spin size="large" />
        </div>
      );
    }
    var renderSlides = (start, end) => {
      var items = [];
      for (var i = start; i < end; i++) {
        var key = "slide:" + i + 1;
        var url = this.state.images_list[i].Key;
        items.push(
          <div key={key}>
            <img className="slide_image" src={base_url + url}/>
          </div>,
        );
      }
      return items;
    };
    if (filterIds.indexOf(this.state.customerId) > -1 ) {
      if (!this.state.images_list) {
        return (
          <div className="dashLoaderContainer">
            <Spin size="large" />
          </div>
        );
      }
      return (
        <Carousel style={{ background: "#FFF" }}>
          {renderSlides(1,parseInt(this.state.images_list.length))}
          <div key="slide:1" style={{ background: "#FFF" }}>
            <Kiosk
              data={propDataForSummary}
              id={this.state.id}
              customer={this.state.customerId}
            />
          </div>
          {/* {renderSlides(parseInt(this.state.images_list.length/3),parseInt(this.state.images_list.length-this.state.images_list.length/3))}
          <div key="slide:1" style={{ background: "#FFF" }}>
            <Kiosk
              data={propDataForSummary}
              id={this.state.id}
              customer={this.state.customerId}
            />
          </div>
          {renderSlides(parseInt(this.state.images_list.length-this.state.images_list.length/3),this.state.images_list.length)}
          <div key="slide:1" style={{ background: "#FFF" }}>
            <Kiosk
              data={propDataForSummary}
              id={this.state.id}
              customer={this.state.customerId}
            />
          </div> */}
        </Carousel>
      );
    } else {
      return (
        <Carousel style={{ background: "#FFF" }}>
          <div key="slide:1" style={{ background: "#FFF" }}>
            <Kiosk
              data={propDataForSummary}
              id={this.state.id}
              customerId={this.state.customerId}
            />
          </div>
        </Carousel>
      );
    }
  }
}
