import React, { Component } from "react";
import { Button, Alert, Form, PageHeader, Radio } from "antd";
import CustomerForm from "../components/CustomerForm";
import UserForm from "../components/UserForm";
import { addCustomer } from "../api";

const FormItem = Form.Item;
export default class AddCustomer extends Component {
  state = {
    loading: false,
    response: "",
  };

  saveCustomer = () => {
    const customerForm = this.customerFormRef.props.form;
    const userForm = this.userFormRef.props.form;
    customerForm.validateFields(async (err, customerDetails) => {
      if (err) {
        return;
      }
      const userDetails = userForm.getFieldsValue();
      if (Object.values(userDetails).every((x) => !x)) {
        this.setState({ loading: true });
      } else {
        userForm.validateFields(async (err, userDetails) => {
          if (err) {
            return;
          }
          this.setState({ loading: true });
        });
      }
      // console.log(customerDetails);
      // console.log(userDetails);
      try {
        const response = await addCustomer({
          ...customerDetails,
          ...userDetails,
        });
        userForm.resetFields();
        customerForm.resetFields();
        this.setState({ code: 200, response: "Data created successfully" });
      } catch (e) {
        console.log(e);

        this.setState({ code: 400, response: e.response.data.message });
      }
      this.setState({ loading: false });
    });
  };

  prevComponent = () => {
    this.props.onClick("Home", null, null);
  };

  saveCustomerFormRef = (formRef) => {
    this.customerFormRef = formRef;
  };
  saveUserFormRef = (formRef) => {
    this.userFormRef = formRef;
  };

  render() {
    return (
      <div style={style.outer}>
        <div style={style.container}>
          <PageHeader
            className="site-page-header"
            onBack={() => this.prevComponent()}
            title={<h1>Add Customer</h1>}
            subTitle="All fields are required."
          />
          <CustomerForm wrappedComponentRef={this.saveCustomerFormRef} />
          <h1>User/Owner/Admin details(Optional)</h1>
          <h4>This person later can see all the details about this company</h4>
          <UserForm wrappedComponentRef={this.saveUserFormRef} />{" "}
          {this.state.response && (
            <Alert
              message={this.state.response}
              type={this.state.code == 200 ? "success" : "error"}
              showIcon
            />
          )}
          <Button
            style={style.saveBtn}
            type="primary"
            loading={this.state.loading}
            onClick={this.saveCustomer}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

const style = {
  outer: {
    background: "#FFF",
  },
  container: {
    margin: "auto",
    maxWidth: 500,
    border: "3 px solid green",
    paddingTop: 30,
    background: "#FFF",
  },
  saveBtn: {
    marginTop: 20,
    marginBottom: 20,
  },
};
