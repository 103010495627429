import React, { Component } from "react";
import { Row, Col, DatePicker, Form, Input, Select, TimePicker } from "antd";
import { render } from "react-dom";
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;
const format = "HH:mm";

const PurifierEditForm = Form.create()(
class PurifierEditForm extends React.Component {
  render() {
    const { form, defaultData } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form layout="horizontal">
        <FormItem label="Enter IAQ Device ID">
          {getFieldDecorator("iaq_device", {
            initialValue: defaultData["iaq_device"],
            rules: [
              {
                required: true,
                message: "Important",
              },
            ],
          })(<Input placeholder="e.g. 30001166" />)}
        </FormItem>
        <FormItem label="Enter Device Name">
          {getFieldDecorator("space_name", {
            initialValue: defaultData["space_name"],
            rules: [
              {
                required: true,
                message: "Important",
              },
            ],
          })(<Input placeholder="e.g. HR 2" />)}
        </FormItem>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <FormItem label="Device Auto Turn ON Time">
              {getFieldDecorator("turn_on_time", {
                initialValue: defaultData["turn_on_time"],
                rules: [
                  {
                    type: "object",
                    required: true,
                    message: "Please select correct time!",
                  },
                ],
              })(<TimePicker format={format} />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Device Auto Turn OFF Time">
              {getFieldDecorator("turn_off_time", {
                initialValue: defaultData["turn_off_time"],
                rules: [
                  {
                    type: "object",
                    required: true,
                    message: "Please select correct time!",
                  },
                ],
              })(<TimePicker format={format} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <FormItem label="PM2.5 highest value">
              {getFieldDecorator("high_pm2_5", {
                initialValue: defaultData["high_pm2_5"],
                rules: [
                  {
                    required: true,
                    message: "Please enter correct value!",
                  },
                ],
              })(<Input type="number" placeholder="30" />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="PM2.5 lowest value">
              {getFieldDecorator("low_pm2_5", {
                initialValue: defaultData["low_pm2_5"],
                rules: [
                  {
                    required: true,
                    message: "Please enter correct value!",
                  },
                ],
              })(<Input type="number" placeholder="20" />)}
            </FormItem>
          </Col>
        </Row>
      </Form>
    );
  }
}
);

export default PurifierEditForm;
