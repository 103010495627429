import {
  GET_P_LVL_MIN_VAL_DICT
} from "./pLevelsCapitaLand";

export const COLOR = {
  primaryColor: '#4CAF50',
  primaryDark:'#388E3C',
  secondaryColor:'#7C4DFF',
  darkText:'#212121',
  secondaryText:'#757575',
  deviderColor:'#BDBDBD',
  insideAir: '#1638D7',
  outsideAir: '#00e5ff',
  excellent:"#00940F",
  excellent_light:'#b6ffbd',
  good:"#62C94B",
  good_light:'#dff4db',
  poor:"#FF0000",
  poor_light:'#fecccc',
  black: '#000000',
  darkGrey: '#a9a9a9',
  white: '#ffffff'
};

export const GET_CLR_POLLUTANT_LVL = function (value, pollutantLabel) {
  const minValDict = GET_P_LVL_MIN_VAL_DICT(pollutantLabel);
  return value ===undefined || value === 'NA'
          ? '#FFFF'
          :value < minValDict.goodMin
            ? COLOR.excellent
            : value < minValDict.poorMin
              ? COLOR.good
              : COLOR.poor;
}