import React, { Component } from "react";
import { Form, Icon, Alert, Input, Card, Button, Checkbox } from "antd";
import LoginImg4 from "../img/assets/loginimg4.svg";
import Background2 from "../img/assets/background2.png";
import { Row, Col } from "antd";
import "../Login.less";
import { typeOfLogin, login, adminLogin } from "../api";
import logo from "../img/Claircofinallogo.png";
import {
  setToken,
  setEmail,
  setCsrId,
  setAdminToken,
  setUsername,
  setSupervisorData,
  setUserRole,
  setUserId,
} from "../helper";
const FormItem = Form.Item;

const ROLE_ADMIN = {
  str: "admin",
};
const ROLE_CUSTOMER = {
  str: "customer",
};
const ROLE_SUPERVISOR = {
  str: "supervisor",
};
const ROLE_UV = {
  str: "uv",
}
const ROLE_MAINTAINENCE = {
  str: "park_admin",
};
class Login extends Component {
  state = {
    loggingIn: false,
    showAlert: false,
    error: "Something has gone wrong",
  };
  handleSubmit = (e) => {
    this.setState({ loggingIn: true, showAlert: false });
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const userData = (await typeOfLogin(values.email)).data;
          // console.log(userData);
          let res;
          if (userData.role === ROLE_ADMIN.str) {
            res = await adminLogin(values.email, values.password);
            setAdminToken(res.data.token);
            setUsername(res.data.name);
            this.props.history.push("/admin");
          } else if (userData.role === ROLE_CUSTOMER.str) {
            res = await login(values.email, values.password);
            setToken(res.data.token);
            setCsrId(res.data.CsrId);
            setUsername(res.data.name);
            setEmail(values.email);
            this.props.history.push("/dashboard");
          } else if (userData.role === ROLE_SUPERVISOR.str) {
            res = await login(values.email, values.password);
            setToken(res.data.token);
            setUsername(res.data.name);
            setEmail(values.email);
            setSupervisorData(res.data.customer);
            setUserId(res.data.userId);
            setUserRole(userData.role);
            this.props.history.push({
              pathname: "/customer/admin",
              state: { data: res.data.customer, referer: "login" },
            });
          } else if (userData.role === ROLE_UV.str) {
            res = await login(values.email, values.password);
            setToken(res.data.token);
            setCsrId(res.data.CsrId);
            setUsername(res.data.name);
            setEmail(values.email);
            this.props.history.push("/UV/dashboard");
          }
        } catch (err) {
          // console.log(err);
          this.setState({
            showAlert: true,
            error: err.response.data.message,
            loggingIn: false,
          });
        }
        this.setState({ loggingIn: false });
      } else {
        this.setState({ loggingIn: false });
      }
    });
  };

  render() {
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 10,
      },
    };

    const tailLayout = {
      wrapperCol: {
        offset: 4,
        span: 16,
      },
    };
    // console.log(this.props.location.state && this.props.location.state.referrer);
    const { getFieldDecorator } = this.props.form;

    return (
      <div style={style.outerdiv}>
        <a href="/">
          <img className="loginLogo" src={logo} style={{}} />
        </a>
        <div style={style.innerdiv}>
          <Row>
            <Col span={12}>
              <div align="middle" style={{ marginTop: "15%" }}>
                <h1 style={style.text}>Welcome back to Clairco</h1>
                <p style={({ color: "grey" }, style.text)}>
                  Clairco provides enhanced air purification and real-time{" "}
                  <br></br> insights to reduce maintenance costs, increase
                  energy <br></br> efficiency, and improve occupant experience
                </p>

                <div style={style.marginprop}>
                  <Form
                    style={(style.marginprop, style.text)}
                    {...layout}
                    onSubmit={this.handleSubmit}
                  >
                    <FormItem label="Email-ID" name="emailid">
                      {getFieldDecorator("email", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                        ],
                      })(
                        <Input
                          name="email"
                          type="email"
                          placeholder="janedoe@clairco.in"
                          autoFocus={true}
                        />,
                      )}
                    </FormItem>

                    <FormItem label="Password" name="password">
                      {getFieldDecorator("password", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ],
                      })(
                        <Input.Password
                          name="password"
                          placeholder="Enter password"
                        />,
                      )}
                    </FormItem>

                    <FormItem {...tailLayout}>
                      {getFieldDecorator("remember", {
                        valuePropName: "checked",
                        initialValue: true,
                      })(<Checkbox>Remember me</Checkbox>)}
                      <br />
                      <Button
                        style={{ backgroundColor: "#00796b" }}
                        loading={this.state.loggingIn}
                        type="primary"
                        htmlType="submit"
                        className="loginFormButton"
                      >
                        Log in
                      </Button>

                      {this.state.showAlert && (
                        <Alert
                          style={{
                            marginTop: 20,
                          }}
                          message={this.state.error}
                          type="error"
                          showIcon
                        />
                      )}
                    </FormItem>
                  </Form>
                </div>
              </div>
            </Col>
            <Col
              span={12}
              style={{
                backgroundColor: "#00796b",
                minHeight: "75vh",
                borderRadius: "0 10px 10px 0",
              }}
            >
              <div style={{ marginTop: "15%" }}>
                <h1
                  align="middle"
                  style={
                    ({ fontWeight: "bold", fontSize: "250%" }, style.coloring)
                  }
                >
                  Assured Air Quality Solutions for Facility Managers.
                </h1>
                <div align="middle">
                  <img
                    src={LoginImg4}
                    width="53%"
                    style={{
                      marginTop: "5%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <p
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "2%",
            color: "grey",
            fontFamily: "roboto",
          }}
        >
          Copyright &copy; by Aliferous Technologies pvt. ltd. All Rights
          Reserved.{" "}
        </p>
      </div>

      // <div className="loginContainer">
      //   <Card bordered={false} className="loginBox">
      //     <div className="loginLogoContainer">
      //       <a href="/"><img className="loginLogo" src={logo}/>
      //       </a>
      //     </div>
      //     <Form
      //       onSubmit={this.handleSubmit}
      //       className="login-form"
      //       style={{
      //       width: 300,
      //       textAlign: "center"
      //     }}>
      //       <FormItem>
      //         {getFieldDecorator("email", {
      //           rules: [
      //             {
      //               type: "email",
      //               message: "Please put valid email!"
      //             }, {
      //               required: true,
      //               message: "Please input your email!"
      //             }
      //           ]
      //         })(
      //           <Input
      //             prefix={<Icon type = "user" style = {{ color: "rgba(0,0,0,.25)" }} />}
      //             placeholder="Email"/>
      //         )}
      //       </FormItem>
      //       <FormItem>
      //         {getFieldDecorator("password", {
      //           rules: [
      //             {
      //               required: true,
      //               message: "Please input your Password!"
      //             }
      //           ]
      //         })(
      //           <Input.Password
      //             prefix={<Icon type = "lock" style = {{ color: "rgba(0,0,0,.25)" }}/>}
      //             type="password"
      //             placeholder="Password"/>
      //         )}
      //       </FormItem>

      // <FormItem>

      // {getFieldDecorator('remember', {
      //   valuePropName: "checked",
      //   initialValue: true
      // })(<Checkbox>Remember me</Checkbox>)}
      //   <br/>
      //   <Button
      //     loading={this.state.loggingIn}
      //     type="primary"
      //     htmlType="submit"
      //     className="loginFormButton">
      //     Log in
      //   </Button>

      //   {this.state.showAlert && (<Alert
      //     style={{
      //     marginTop: 20
      //   }}
      //     message={this.state.error}
      //     type="error"
      //     showIcon/>)}
      // </FormItem>
      //     </Form>
      //   </Card>
      // </div>
    );
  }
}

const style = {
  text: {
    fontFamily: "'Montserrat', sans-serif",
  },
  coloring: {
    color: "white",
    fontFamily: "'Montserrat', sans-serif",
  },
  marginprop: {
    marginTop: "10%",
  },
  innerdiv: {
    maxWidth: "80vw",
    backgroundColor: "white",
    minHeight: "75vh",
    margin: "0",
    maxHeight: "75vh",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "10px",
  },
  outerdiv: {
    margin: "0",
    padding: "0px",
    minHeight: "100vh",
    backgroundImage: `url(${Background2})`,
    backgroundSize: "cover",
  },
};

export default Form.create()(Login);
