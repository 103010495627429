import React from 'react'
import {Table, Typography} from 'antd';

const {Column} = Table;

export default() => {
  const pmRange = [
    {
      category: 'Good',
      pm2_5: '0-30',
      pm10: '0-50',
      co2: '0-450',
      voc: '0-109',
      impactPm2_5: 'minimal'
    }, {
      category: 'Satisfactory',
      pm2_5: '31-60',
      pm10: '51-100',
      co2: '451-900',
      voc: '110-218',
      impactPm2_5: 'Minor breathing discomfort to sensitive people'
    }, {
      category: 'Moderate',
      pm2_5: '61-90',
      pm10: '101-250',
      co2: '901-1350',
      voc: '219-327',
      impactPm2_5: 'Breathing discomfort to asthma patients, elderly and children'
    }, {
      category: 'Poor',
      pm2_5: '91-120',
      pm10: '251-350',
      co2: '1351-1800',
      voc: '327-436',
      impactPm2_5: 'Breathing discomfort to all'
    }, {
      category: 'Very poor',
      pm2_5: '121-250',
      pm10: '351-430',
      co2: '1801-2500',
      voc: '437-500',
      impactPm2_5: 'Respiratory illness on prolonged exposure.'
    }, {
      category: 'Severe',
      pm2_5: '250+',
      pm10: '430+',
      co2: '2500+',
      voc: '500+',
      impactPm2_5: 'Health impactPm2_5 even on light physical work. Serious impactPm2_5 on people with heart/lung diseases'
    }
  ]
  const render = (value)=>{
    if(value === 'Good'){
      return (<div><div className='colorInfoGood colorInfoBar'></div>{getCellText(value)}</div>)
    }
    if(value === 'Satisfactory'){
      return (<div><div className='colorInfoSatisfactory colorInfoBar'></div>{getCellText(value)}</div>)
    }
    if(value === 'Moderate'){
      return (<div><div className='colorInfoModerate colorInfoBar'></div>{getCellText(value)}</div>)
    }
    if(value === 'Poor'){
      return (<div><div className='colorInfoPoor colorInfoBar'></div>{getCellText(value)}</div>)
    }
    if(value === 'Very poor'){
      return (<div><div className='colorInfoVeryPoor colorInfoBar'></div>{getCellText(value)}</div>)
    }
    if(value === 'Severe'){
      return (<div><div className='colorInfoSevere colorInfoBar'></div>{getCellText(value)}</div>)
    }
  };
  const width = window.innerWidth;
  let colWidth, scrollY, cellFontSize;
  // console.log('width: ',width);
  if(width <= 1366) {
      colWidth = {
        'pm2_5': 55,
        'pm10': 55,
        'co2': 65,
        'voc': 55
      };
      cellFontSize = 10;
      scrollY = 100;
    } else if (width <= 1440) {
      colWidth = {
        'pm2_5': 58,
        'pm10': 60,
        'co2': 74,
        'voc': 62
      };
      cellFontSize = 12;
      scrollY = 100;
    } else {
      colWidth = {
        'pm2_5': 75,
        'pm10': 75,
        'co2': 95,
        'voc': 75
      };
      cellFontSize = 16;
      scrollY = 140;
    }
  window.colWidth = colWidth

  const getCellText = (value) => {
    return (
      <Typography.Text style={{ fontSize: cellFontSize }}>
        {value}
      </Typography.Text>
    );
  };

  return (
    <div>
      <Table // title={() => 'Air quality categories and health impactPm2_5'}
        size='middle' dataSource={pmRange} bordered={false} pagination={false}
        scroll={{ y: scrollY }} 
        >
        <Column dataIndex='category' key='category'
          title={getCellText('Category')} render={render}/>
        <Column width={colWidth.pm2_5} dataIndex='pm2_5' key='pm2_5'
          title={getCellText('PM2.5 (ug/m3)')} render={getCellText}/>
        <Column width={colWidth.pm10} dataIndex='pm10' key='pm10'
          title={getCellText('PM10 (ug/m3)')} render={getCellText}/>
        <Column width={colWidth.co2} dataIndex='co2' key='co2'
          title={getCellText('CO2 (ppm)')} render={getCellText}/>
        <Column width={colWidth.voc} dataIndex='voc' key='voc'
          title={getCellText('VOC (ppb)')} render={getCellText}/>
        {/* <Column title='Health Impact' dataIndex='impactPm2_5' key='impactPm2_5'/> */}
      </Table>
    </div>
  )
}
