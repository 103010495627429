import CHECK_DEVICE_CONFIG from "./checkDeviceConfig"; // Change it to direct call to function
import { func } from "prop-types";

const EPOCH_CORRECTION_MS_IST = 11 * 30 * 60 * 1000; // 5.5 hours
const TWENTY_THREE = 23;

function createAqData(data, spaceDevId, dateRangeArr) {
  // console.log('data:',JSON.stringify(data,null,2));
  // console.log('spaceDevId: ',spaceDevId);
  // console.log('dateRangeArr: ',dateRangeArr);
  let aqTrendPm2_5 = [];
  let aqTrendPm10 = [];
  let aqTrendCo = [];
  let aqTrendCo2 = [];
  let aqTrendVoc = [];
  let aqTrendLux = [];
  let aqTrendUv = [];
  let aqTrendSnd = [];

  let currentDateEpoch =
    (dateRangeArr[0] ? new Date(dateRangeArr[0]).getTime() : new Date()) +
    EPOCH_CORRECTION_MS_IST;
  // + epochCorrectionFactor;
  // console.log('currentDateEpoch: ',currentDateEpoch);
  // console.log('currentDateEpoch.toISOString(): ',(new Date(currentDateEpoch)).toISOString());
  const endDateEpoch =
    (dateRangeArr[1] ? new Date(dateRangeArr[1]).getTime() : new Date()) +
    EPOCH_CORRECTION_MS_IST;
  // + epochCorrectionFactor;
  // console.log('endDateEpoch: ',endDateEpoch);
  // console.log('endDateEpoch.toISOString(): ',(new Date(endDateEpoch)).toISOString());
  while (currentDateEpoch <= endDateEpoch) {
    let date = new Date(currentDateEpoch - EPOCH_CORRECTION_MS_IST);

    const presentDayFlag = checkIfCurrDayIsPresentDay(date);
    // console.log('presentDayFlag: ',presentDayFlag);
    // console.log('date: ',date);
    const currentHour = presentDayFlag ? date.getHours() : TWENTY_THREE; // 0-23 = 24 hours in a day
    // console.log('currentHour: ',currentHour);
    date.setHours(0);
    // console.log('currentHour: ',currentHour);
    while (date.getHours() <= currentHour) {
      // ERROR: Everyday at 11pm-12pm
      // the page in browser
      // eats up ram without loading
      // most components and then
      // page crashes
      // console.log('date.getHours(): ',date.getHours());
      // console.log('currentHour: ',currentHour);
      // console.log('CHECK_DEVICE_CONFIG.devIdIsInEnggList(spaceDevId): ',CHECK_DEVICE_CONFIG.devIdIsInEnggList(spaceDevId));
      const hour = date.getHours();
      // console.log('hour: ',hour);
      const day = getUTCDay(new Date(date.getTime() + EPOCH_CORRECTION_MS_IST));
      // console.log('day: ',day);
      const insideData = data.insAqTrendOverDateRange.find(
        (item) => item.day === day,
      );
      const outsideData = data.outAqTrendOverDateRange.find(
        (item) => item.day === day,
      );
      // console.log('insideData: ',JSON.stringify(insideData,null,2));
      // console.log('outsideData: ',JSON.stringify(outsideData,null,2));
      const itemPm2_5 = {
        inside: insideData && insideData.hours ? insideData.hours[hour] : null,
        outside:
          outsideData && outsideData.hours ? outsideData.hours[hour] : null,
        hour: date.toString(),
      };
      // console.log('itemPm2_5: ',itemPm2_5);
      const itemPm10 = {
        inside:
          insideData && insideData.hoursPm10
            ? insideData.hoursPm10[hour]
            : null,
        outside:
          outsideData && outsideData.hoursPm10
            ? outsideData.hoursPm10[hour]
            : null,
        hour: date.toString(),
      };
      // console.log('itemPm10: ',itemPm10);
      const itemCo = {
        inside:
          insideData && insideData.hoursCo ? insideData.hoursCo[hour] : null,
        outside:
          outsideData && outsideData.hoursCo ? outsideData.hoursCo[hour] : null,
        hour: date.toString(),
      };
      const itemCo2 = {
        inside:
          insideData && insideData.hoursCo2 ? insideData.hoursCo2[hour] : null,
        outside:
          outsideData && outsideData.hoursCo2
            ? outsideData.hoursCo2[hour]
            : null,
        hour: date.toString(),
      };
      // console.log('itemCo2: ',itemCo2);
      const itemVoc = {
        inside:
          insideData && insideData.hoursVoc
            ? insideData.hoursVoc[hour]
            : null,
        outside:
          outsideData && outsideData.hoursVoc
            ? outsideData.hoursVoc[hour]
            : null,
        hour: date.toString(),
      };

      // console.log('itemVoc: ',itemVoc);
      const itemLux = {
        inside:
          insideData && insideData.hoursLux ? insideData.hoursLux[hour] : null,
        outside:
          outsideData && outsideData.hoursLux
            ? outsideData.hoursLux[hour]
            : null,
        hour: date.toString(),
      };
      // console.log("itemLux: ", itemLux);

      const itemUv = {
        inside:
          insideData && insideData.hoursUv ? insideData.hoursUv[hour] : null,
        outside:
          outsideData && outsideData.hoursUv ? outsideData.hoursUv[hour] : null,
        hour: date.toString(),
      };
      const itemSnd = {
        inside:
          insideData && insideData.hoursSnd ? insideData.hoursSnd[hour] : null,
        outside:
          outsideData && outsideData.hoursSnd
            ? outsideData.hoursSnd[hour]
            : null,
        hour: date.toString(),
      };
      // console.log('aqTrendPm2_5: ',aqTrendPm2_5);
      aqTrendPm2_5.push(itemPm2_5);
      aqTrendPm10.push(itemPm10);
      aqTrendCo.push(itemCo);
      aqTrendCo2.push(itemCo2);
      aqTrendVoc.push(itemVoc);
      aqTrendLux.push(itemLux);
      aqTrendUv.push(itemUv);
      aqTrendSnd.push(itemSnd);

      // console.log('aqTrendPm2_5: ',aqTrendPm2_5);
      if (date.getHours() === TWENTY_THREE) {
        break;
      } else {
        date.setHours(date.getHours() + 1);
      }
    }

    let currentDate = new Date(currentDateEpoch);
    // console.log('currentDate: ',currentDate.toISOString());
    currentDate.setDate(currentDate.getDate() + 1);
    // console.log('currentDate: ',currentDate.toISOString());
    currentDateEpoch = new Date(currentDate).getTime();
    // console.log('end of while(): currentDateEpoch: ',currentDateEpoch);
  }

  let insAq;
  if (data.realtimeAq != undefined) {
    insAq = data.realtimeAq.inside;
    if (insAq.last_timestamp) {
      insAq.time = insAq.last_timestamp;
    }
    insAq["pData"] = [
      parseInt(insAq.pm25) || parseInt(insAq['pm2.5']),
      parseInt(insAq.pm10),
      parseInt(insAq.co),
      parseInt(insAq.co2),
      parseInt(insAq.voc),
      parseInt(insAq.temp),
      parseInt(insAq.hum),
    ];
  }
  let outAq = null;
  if (data.realtimeAq != undefined && data.realtimeAq.outside) {
    outAq = data.realtimeAq.outside;
    outAq["pData"] = [
      data.realtimeAq.outside.pm25 || parseInt(data.realtimeAq.outside['pm2.5']),
      data.realtimeAq.outside.pm10,
      data.realtimeAq.outside.co,
      data.realtimeAq.outside.co2
        ? parseInt(data.realtimeAq.outside.co2)
        : "NA",
      data.realtimeAq.outside.voc
        ? parseInt(data.realtimeAq.outside.voc)
        : "NA",
      data.realtimeAq.outside.temp
        ? parseInt(data.realtimeAq.outside.temp)
        : "NA",
      data.realtimeAq.outside.hum
        ? parseInt(data.realtimeAq.outside.hum)
        : "NA",
    ];
  }
  const aqTrendData = [
    aqTrendPm2_5,
    aqTrendPm10,
    aqTrendCo,
    aqTrendCo2,
    aqTrendVoc,
    aqTrendLux,
    aqTrendUv,
    aqTrendSnd,
  ];
  const avgAq = [
    data.avgInsSinceYesterday ? data.avgInsSinceYesterday.pm2_5 : undefined,
    data.avgInsSinceYesterday ? data.avgInsSinceYesterday.pm10 : undefined,
    data.avgInsSinceYesterday ? data.avgInsSinceYesterday.co : undefined,
    data.avgInsSinceYesterday ? data.avgInsSinceYesterday.co2 : undefined,
    data.avgInsSinceYesterday ? data.avgInsSinceYesterday.voc : undefined,
  ];
  // console.log(aqTrendData);
  return { aqTrendData, avgAq, selectedAqTrendIndex: 0, insAq, outAq };
}

function checkIfCurrDayIsPresentDay(dateObj) {
  const presentDayFlag =
    getUTCDay(new Date(dateObj.getTime() - EPOCH_CORRECTION_MS_IST)) ===
    getUTCDay(new Date());

  return presentDayFlag;
}

function getUTCDay(date) {
  // console.log('getUTCDay.date: ',date);

  const day = date.getUTCDate();
  // console.log('getUTCDay.day: ',day);
  const month = date.getUTCMonth() + 1;
  // console.log('getUTCDay.month: ',month);
  return (
    date.getUTCFullYear() +
    "-" +
    (month < 10 ? "0" + month : month) +
    "-" +
    (day < 10 ? "0" + day : day)
  );
}

function getRealtimeIAQ(data) {
  let insAq = data.realtimeAq.inside;
  insAq["pData"] = [
    parseInt(insAq.pm25),
    parseInt(insAq.pm10),
    parseInt(insAq.co),
    parseInt(insAq.co2),
    parseInt(insAq.voc),
    parseInt(insAq.temp),
    parseInt(insAq.hum),
  ];
  let outAq = null;
  if (data.realtimeAq.outside) {
    outAq = data.realtimeAq.outside;
    outAq["pData"] = [
      data.realtimeAq.outside.pm25,
      data.realtimeAq.outside.pm10,
      data.realtimeAq.outside.co,
      data.realtimeAq.outside.co2
        ? parseInt(data.realtimeAq.outside.co2)
        : "NA",
      data.realtimeAq.outside.voc
        ? parseInt(data.realtimeAq.outside.voc)
        : "NA",
      data.realtimeAq.outside.temp
        ? parseInt(data.realtimeAq.outside.temp)
        : "NA",
      data.realtimeAq.outside.hum
        ? parseInt(data.realtimeAq.outside.hum)
        : "NA",
    ];
  }
  return { insAq, outAq };
}

export const EPOCH_CORRECTION_FACTOR_IST = EPOCH_CORRECTION_MS_IST;

export function CheckIfCurrDayIsPresentDay(dateObj) {
  return checkIfCurrDayIsPresentDay(dateObj);
}

export function GetUtcDay(dateObj) {
  return getUTCDay(dateObj);
}

export function CreateAqData(data, spaceDevId, dateRangeArr) {
  return createAqData(data, spaceDevId, dateRangeArr);
}

export function GetRealtimeAQI(data) {
  return getRealtimeIAQ(data);
}
