import React from "react";
import { Table } from "antd";
import { isNull, property } from "lodash";

const PropertyTable = ({ properties, handleClickedSpace }) => {
 
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },

    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "Spaces",
      dataIndex: "spaces",

      render: (spaces) => {
        return spaces.map((space) => (
          <div>
            <a onClick={(e) => handleClickedSpace(e.target.textContent)}>
              {space}
            </a>
            <br />
          </div>
        ));
      },
    },
  ];

  const data = [];

  properties.map((property, i) => {
    const ob = { name: property.name, city: property.city, spaces: [] };

    property.Spaces.map((de) => ob.spaces.push(de.name));
    return data.push(ob);
  });

  return (
    <Table columns={columns} dataSource={data} pagination={{ pageSize: 4 }} />
  );
};

export default PropertyTable;
