const ENGG_ENV_DEV_SERIES_START = 30000000;
const ENGG_ENV_DEV_SERIES_END = 49999999;

exports.devIdIsInEnggList = function(devId) {
  if((devId >= ENGG_ENV_DEV_SERIES_START)
    && (devId <= ENGG_ENV_DEV_SERIES_END) || (devId.indexOf('CLAIRCO_')>-1)) {
    // console.log('Device is in EnggEnv List: ', devId);
    return true;
  } else {
    // console.log('Device is Not in EnggEnv List: ', devId);
    return false;
  }
}