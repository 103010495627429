import React, { Component } from "react";
import {
  Button,
  Icon,
  Tooltip,
  Table,
  Badge,
  Row,
  Col,
  Card,
  Affix,
  Input,
  DatePicker,
  Modal,
  Alert,
  Switch,
  Select,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { searchCustomer, getAllCustomer, getAqExcelFile } from "../../api";
import "./Admin.less";
// let sortedInfo = sortedInfo || {};
const { Option } = Select;
const capitaland_customerId = "9fb2d83a-7db7-475e-84ad-d26c56727366";
const wtc_customerId = "b38f6c08-dcaf-4139-aea0-d30ee75d4ee1";
let lastDate = new Date();
lastDate.setDate(lastDate.getDate() - 1);
lastDate = lastDate.toLocaleDateString();

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Customer name",
        dataIndex: "name",
        key: "name",

        onFilter: (value, record) => record.name.includes(value),
        sorter: (a, b) => {
          const sorted = new Intl.Collator().compare(a.name, b.name);
          return sorted;
        },

        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Devices disconnected",
        dataIndex: "deviceStatus",
        key: "deviceStatus",
      },
      {
        title: "Last maintenance",
        dataIndex: "last_maintenance",
        key: "last_maintenance",
      },
      {
        title: "Last login",
        dataIndex: "last_login",
        key: "last_login",
        render: (last_login) =>
          last_login !== "NA" ? moment(last_login).fromNow() : "NA",
      },
      {
        title: "Alerts",
        dataIndex: "alerts",
        key: "alerts",
        render: (text, record) => (
          <Badge
            showZero
            className={record.alerts < 1 ? "badge_low" : "badge_high"}
            count={record.alerts}
          />
        ),
      },
      {
        title: "Properties",
        dataIndex: "properties",
        key: "properties",
        render: (properties) => properties.map((property) => property.city),
      },
      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        render: (id, record) => (
          <div>
            <Button
              icon="edit"
              type="primary"
              onClick={() => this.props.onClick("Customer", id)}
            >
              Edit
            </Button>
            <Button
              icon="download"
              type="primary"
              style={{
                marginLeft: "10px",
                background: "#344A53",
                color: "white",
              }}
              onClick={() => {
                this.setState({
                  visible: true,
                  id,
                  customerName: record.name,
                  customer_property_cities: record.properties,
                });
              }}
            >
              Report{" "}
            </Button>
          </div>
        ),
      },
    ];
    this.state = {
      dataSource: [],
      searchText: "",
      searchedColumn: "",
      report_flag_disable: true,
      visible: false,
      startValue: null,
      endValue: null,
      endOpen: false,
      confirmLoading: false,
      id: "",
      showAlert: false,
      error: "Please enter all the details",
      fileType: true,

      reportType: true,
      selectedCity: "",
      customerName: "",
      customer_property_cities: [],
      // searchInput: "",
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    searchInput: "",
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
            // this.setState({ searchInput: node });
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
          onClick={() => {
            confirm({ closeDropdown: false });
            this.setState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
        >
          search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };
  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  switchChange = (checked, e) => {
    if (e === "report_type") {
      if (checked) {
        this.setState({
          reportType: true,
        });
      } else {
        this.setState({
          reportType: false,
        });
      }
    } else if (e === "data_type") {
      if (checked) {
        this.setState({
          fileType: true,
        });
      } else {
        this.setState({
          fileType: false,
        });
      }
    }
  };

  async componentDidMount() {
    try {
      let { data } = (await getAllCustomer()).data;
      var devices_disconnected = 0;
      var devices_total = 0;
      var space_count = data[0].space_count;
      for (var i = 0; i < data.length; i++) {
        data[i]["deviceStatus"] =
          data[i].devices_disconnected + " / " + data[i].total_devices;
        devices_disconnected += parseInt(data[i].devices_disconnected);
        devices_total += parseInt(data[i].total_devices);

        if (data[i].last_maintenance === null) {
          data[i].last_maintenance = "NA";
        }
      }
      this.setState({
        data,
        space_count,
        devices_disconnected: devices_disconnected,
        devices_total: devices_total,
      });
    } catch (error) {
      console.log(error);
    }
  }

  onSelect = (value) => {
    this.props.history.push("/admin/customer", { id: value });
  };

  addCustomer = () => {
    this.props.onClick("AddCustomer");
  };

  handleSearch = async (value) => {
    // console.log(value);
    const response = await searchCustomer(value);
    if (response.data.length) {
      this.setState({
        dataSource: response.data.map((item) => {
          return {
            value: item.id,
            key: item.id,
            text: item.name,
          };
        }),
      });
    }
  };

  handleDownloadClick = async (e) => {
    const deepData = true;
    const { startValue, endValue, selectedCity } = this.state;
    if (startValue === null || endValue === null || selectedCity === "") {
      this.setState({
        showAlert: true,
      });
    } else {
      this.setState({ confirmLoading: true, showAlert: false });
      try {
        await getAqExcelFile(
          this.state.id,
          deepData,
          startValue.utc().format("YYYY-MM-DD"),
          endValue.utc().format("YYYY-MM-DD"),
          this.state.fileType,
          this.state.selectedCity,
          this.state.customerName,
        );
        this.setState({
          selectedCity: "",
          startValue: null,
          endValue: null,
        });
      } catch (err) {
        console.log(err);
      }
      this.setState({ confirmLoading: false, visible: false });
    }
  };

  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  disabledStartDate = (current) => {
    let customDate = "2019-11-01";
    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  render() {
    // const [bottom, setBottom] = useState(10);
    let { data, space_count, devices_disconnected, devices_total } = this.state;
    return (
      <div style={{ padding: 10, minHeight: 360 }}>
        <Modal
          title="Download Excel Report"
          visible={this.state.visible}
          width={673}
          onOk={this.handleDownloadClick}
          confirmLoading={this.state.confirmLoading}
          onCancel={() => {
            this.setState({ visible: false });
          }}
          footer={[
            <Button
              key="back"
              onClick={() => this.setState({ visible: false })}
            >
              Cancel
            </Button>,
            <Button
              icon="download"
              key="submit"
              type="primary"
              loading={this.state.confirmLoading}
              onClick={this.handleDownloadClick}
            >
              Download
            </Button>,
          ]}
        >
          <div>
            {this.state.showAlert && (
              <Alert
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
                message={this.state.error}
                type="error"
                showIcon
              />
            )}
            <Select
              style={{ width: 196, marginRight: "20px" }}
              onChange={(val) => this.setState({ selectedCity: val })}
              value={this.state.selectedCity}
              placeholder="select city"
            >
              <Option value="" disabled selected>
                Select City
              </Option>

              {this.state.customer_property_cities.map((property) => (
                <Option value={property.city}>{property.city}</Option>
              ))}
            </Select>
            <DatePicker
              disabledDate={this.disabledDate}
              // defaultValue={moment(lastDate, "DD-MM-YYYY")}
              format="YYYY-MM-DD"
              value={this.state.startValue}
              placeholder="Start Date"
              onChange={this.onStartChange}
              onOpenChange={this.handleStartOpenChange}
            />
            <DatePicker
              className="endDatePicker"
              disabledDate={this.disabledDate}
              format="YYYY-MM-DD"
              value={this.state.endValue}
              placeholder="End Date"
              onChange={this.onEndChange}
              open={this.state.endOpen}
              onOpenChange={this.handleEndOpenChange}
            />
          </div>
          <div
            style={{
              marginTop: 20,
              marginLeft: 20,
            }}
          >
            {this.state.id === capitaland_customerId || wtc_customerId ? (
              <div>
                <div className="small_margin">
                  <b>Report Type:</b>
                  <Switch
                    disabled={this.state.report_flag_disable}
                    className="small_margin"
                    defaultChecked={this.state.reportType}
                    onClick={(checked, e) =>
                      this.switchChange(checked, "report_type")
                    }
                    checkedChildren="Data Report"
                    unCheckedChildren="Purifier Report"
                  />
                  <b>Data Type:</b>
                  <Switch
                    className="small_margin"
                    defaultChecked={this.state.fileType}
                    onClick={(checked, e) =>
                      this.switchChange(checked, "data_type")
                    }
                    checkedChildren="Daily Average"
                    unCheckedChildren="Hourly Average"
                  />
                  <br />
                </div>
                <div className="small_margin margin-top-15p">
                  <b>NOTE:</b> Toggle the switch to change the Report Type.
                </div>
              </div>
            ) : null}
          </div>
        </Modal>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Card title="Currently Monitoring">
              <Row>
                <Col span={12}>
                  <Icon
                    type="shop"
                    style={{
                      fontSize: "54px",
                      color: "#ffa534",
                    }}
                  />
                </Col>
                <Col span={12}>
                  <span style={{ fontSize: "40px" }}>{space_count}</span>
                  spaces
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Devices Connected">
              <Row>
                <Col span={12}>
                  <Icon
                    type="api"
                    style={{
                      fontSize: "54px",
                      color: "#87cb16",
                    }}
                  />
                </Col>
                {/* <Col span={12}>
                  <span style={{ fontSize: "40px" }}>
                    {devices_total - devices_disconnected}
                  </span>
                  /{devices_total}
                </Col> */}
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Avg. Device down time">
              <Row>
                <Col span={12}>
                  <Icon
                    type="disconnect"
                    style={{
                      fontSize: "54px",
                      color: "#fb404b",
                    }}
                  />
                </Col>
                <Col span={12}>
                  <span style={{ fontSize: "40px" }}>23</span>
                  hrs
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Avg. Customer dashboard time">
              <Row>
                <Col span={12}>
                  <Icon
                    type="user"
                    style={{
                      fontSize: "54px",
                      color: "#23ccef",
                    }}
                  />
                </Col>
                <Col span={12}>
                  <span style={{ fontSize: "40px" }}>1</span>
                  min
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <div style={style.container}>
          <div className="body-card-header-pop">Customer Details</div>
          <Table
            className="components-table-demo-nested"
            columns={this.columns.filter(
              (column) =>
                column.dataIndex !== "deviceStatus" &&
                column.dataIndex !== "properties",
            )}
            style={{ background: "#FFF", margin: "20px" }}
            bordered
            // title={() => 'Welcome Admin'}
            dataSource={data}
          />
          <Affix offsetBottom={0}>
            <Tooltip placement="top" title="Add Customer">
              <Button
                className="add_button"
                icon="plus"
                onClick={this.addCustomer}
                type="primary"
              ></Button>
            </Tooltip>
          </Affix>
        </div>
      </div>
    );
  }
}

const style = {
  welcome: {
    margin: 20,
  },
  container: {
    marginTop: 40,
    padding: 16,
    background: "#FFF",
    width: "100%",
  },
};
