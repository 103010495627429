import React, { Component } from "react";
import { Button, Form, Input, Radio, Upload, Icon, message } from "antd";
import S3FileUpload from "react-s3";
import AWS from "aws-sdk";

const FormItem = Form.Item;
// const props = {
//   name: "file",
//   action: "https://customers-asset.s3.amazonaws.com",

//   onChange(info) {
//     if (info.file.status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }
//     if (info.file.status === "done") {
//       message.success(`${info.file.name} file uploaded successfully`);
//     } else if (info.file.status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
// };
const S3 = new AWS.S3();

const props = {
  customRequest({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials,
  }) {
    AWS.config.update({
      accessKeyId: "AKIARC4ZKUPX3AMBYC4O",
      secretAccessKey: "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",
      region: "ap-south-1",
    });

    const S3 = new AWS.S3();
    console.log("DEBUG filename", file.name);
    console.log("DEBUG file type", file.type);

    const uploadParams = {
      Bucket: "customers-asset",
      Body: file,
      Key: file.name,
      ACL: "public-read",
      ContentType: file.type,
    };
    S3.upload(uploadParams, (err, data) => {
      if (err) {
        onError();
        console.log(err);
      } else {
        onSuccess(data.Location);
        console.log(data);
      }
    });
  },
};
const CustomerForm = Form.create()(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        fileUrl: "",
      };
      this.onFileChange = this.onFileChange.bind(this);
    }
    onFileChange = (event) => {
      // console.log(event.target.files[0]);
      // console.log(event.fileList);
      //s3 bucket details
      const config = {
        bucketName: "customers-asset",
        accessKeyId: "AKIARC4ZKUPX3AMBYC4O",
        secretAccessKey: "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",
        region: "ap-south-1",
      };

      //upload file to s3
      S3FileUpload.uploadFile(event.target.files[0], config)
        .then((data) => {
          // console.log(data.location); // it return the file url
          this.setState({
            fileUrl: data.location,
          });
          this.props.form.setFieldsValue({
            customer_logo: data.location,
          });
          // console.log(data);
        })
        .catch((err) => {
          // alert(err);
          console.log(err);
        });
    };

    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Form layout="vertical">
          <FormItem label="Business name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please fill business name!",
                },
              ],
            })(
              <Input placeholder="Ex: Treebo hotels pvt ltd, CureFit pvt ltd" />,
            )}
          </FormItem>
          <FormItem label="Upload file">
            <Input type="file" onChange={this.onFileChange} />
          </FormItem>
          <FormItem label="s3" style={{ display: "none" }}>
            {getFieldDecorator(
              "customer_logo",
              {},
            )(<input type="text" hidden />)}
          </FormItem>
        </Form>
      );
    }
  },
);

export default CustomerForm;
