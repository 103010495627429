import React, { Component } from "react";
import { Table, Spin } from "antd";
import { getAllDevices } from "../api";
import _ from "lodash";

export default class DeviceList extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null
  }
}

  async componentDidMount() {
    try {
      let { data } = await getAllDevices(); 
      if(data.length>0){
        data = _(data.id)
        .map((value, key) => ({name: key, id: value}))
        .value();
      }
      console.log(data);
      this.setState({
        data: data
      })
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    if(!this.state.data){
      return (
      <div>
        <Spin size="large"/>
      </div>);
    }
    return (
    <div>
      <Table dataSource={this.state.data}/>
    </div>  
    );
  }
}